import {
    REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
    SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
    FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestEfficientFrontierPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER
    }
}

export const successEfficientFrontierPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
        efficientFrontierData:result
    }
}

export const failureEfficientFrontierPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
    }
}

export const hanndleEfficientFrontierPortfolioAction = (dropDownPortFolio,analysisDate,accessToken ) => {
    return function(dispatch){
        dispatch(requestEfficientFrontierPortfolio())
        Api.EfficientFrontierPortfolioApi(dropDownPortFolio,analysisDate,accessToken).then((result)=>{
            dispatch(successEfficientFrontierPortfolio(result.data))
        }).catch(error=>{
            dispatch(failureEfficientFrontierPortfolio(error))
        })
    }
}