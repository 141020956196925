import {
        REQUEST_GET_SUMMARY_CARD,
        SUCCESS_GET_SUMMARY_CARD,
        FAILURE_GET_SUMMARY_CARD,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestSummaryCard = () =>{
    return{
        type: REQUEST_GET_SUMMARY_CARD,
    }
}

export const successSummaryCard = (result) => {
    return{
        type: SUCCESS_GET_SUMMARY_CARD,
        summaryCardData: result,
    }
}

export const failureSummaryCard = () => {
    return{
        type: FAILURE_GET_SUMMARY_CARD
    }
}

export const handleGetSummaryCardAction = (accessToken) =>{
    return function(dispatch){
        dispatch(requestSummaryCard())
        Api.summaryCardApi(accessToken).then((result)=>{
            dispatch(successSummaryCard(result.data))
        }).catch(error=>{
            dispatch(failureSummaryCard())
        })
    }
}