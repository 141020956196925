import {
    REQUEST_WITHDRAW_TRANSACTION,
    SUCCESS_WITHDRAW_TRANSACTION,
    FAILURE_WITHDRAW_TRANSACTION,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function performWithdrawTransactionReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_WITHDRAW_TRANSACTION:
            return{
                ...state,
            }
        case SUCCESS_WITHDRAW_TRANSACTION:
            return {
                ...state,
            }
        case FAILURE_WITHDRAW_TRANSACTION:
            return {
                ...state,
            }
        default: 
            return state;
    }
}