import {
    REQUEST_IBKR_INITIATE,
    SUCCESS_IBKR_INITIATE,
    FAILURE_IBKR_INITIATE,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestIbkrInitiate = () => {
    return {
        type: REQUEST_IBKR_INITIATE
    }
}

export const successIbkrInitiate = () => {
    return {
        type: SUCCESS_IBKR_INITIATE
    }
}

export const failureIbkrInitiate = () => {
    return {
        type: FAILURE_IBKR_INITIATE
    }
}

export const handleIbkrInitiateAction = (ids, ibkrAccount,analysisDate, token) => {
    return function (dispatch) {
        dispatch(requestIbkrInitiate())
        Api.ibkrInitiateApi(ids, ibkrAccount, analysisDate, token).then((result) => {
            dispatch(successIbkrInitiate(result))
        }).catch(error => {
            dispatch(failureIbkrInitiate())
        })
    }
}