import React, { Component } from "react";

class MultiSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            multiSelect: [10, 100, 12, 150, 20, 200, 26, 50],
            // abcd: [],
        }
    }

    closeOpenDropDown = () => {
        this.setState({
            toggle: !this.state.toggle
        }, () => { })
    }

    // handleMultiSelectChange = (event) => {
    //     this.state.abcd.length && this.state.abcd.includes(event.target.value)
    //         ?
    //         this.state.abcd.indexOf(event.target.value) > -1 && this.setState({ abcd: this.state.abcd.filter(item => item !== event.target.value) })
    //         : this.state.abcd.length < 3 && this.setState({ abcd: [...this.state.abcd, event.target.value] })

    // }

    render() {
        return (
            <div style={{ display: "flex" }}>
                {this.props.abcd.length < 3 ? null : <span style={{ color: 'red', fontSize: '15px', marginRight: 10 }}>Maximum 3</span>}
                <div style={{ display: "flex" }}>
                    <button onClick={this.closeOpenDropDown} style={{ width: 100, height: 30, background: '#94B3FC', color: '#ffffff', borderColor: '#94B3FC' }}>Select Option</button>
                    <div onMouseLeave={this.closeOpenDropDown} style={{ marginTop: 30, display: "flex", flexDirection: "column", zIndex: 1, position: "absolute", background: "#94B3FC", width: 100, maxHeight: 200, overflow: 'auto' }}>
                        {
                            this.state.toggle && this.props.abcd &&
                            this.state.multiSelect.map((item, index) => {
                                return <div key={index} style={{ verticalAlign: 'top', color: "#ffffff", fontSize: 18 }}>
                                    <input type="checkbox" checked={this.props.abcd.includes(item.toString())} value={item} onChange={this.props.handleMultiSelectChange} />
                                    <label>{item}</label>
                                </div>
                            })
                        }

                    </div>

                </div>
            </div>
        )
    }
}
export default MultiSelect;