import {
    REQUEST_SEARCH_TICKER,
    SUCCESS_SEARCH_TICKER,
    FAILURE_SEARCH_TICKER,
} from "../../Constant/actionTypes";

const initilizeState = {
    searchTestData:{
        data:[]
    }
}

export default function searchTickerReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_SEARCH_TICKER:
            return{
                ...state,
            }
        case SUCCESS_SEARCH_TICKER:
            return{
                ...state,
                searchTestData:{data:action.searchTestData.data}
            }
        case FAILURE_SEARCH_TICKER:
            return{
                ...state,
            }
        default:
            return state;
    }
}