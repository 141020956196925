import React, { Component } from "react";
import StatuBar from "./StatusBar";

class StatusProgressBar extends Component{
    constructor(props){
        super(props);

        this.state = {
            percentage: props.percentage
        }
    }

    render(){
        return(
            <div>
                <StatuBar percentage={this.state.percentage}/>
            </div>
        )
    }
}
export default StatusProgressBar;