import {
    REQUEST_CREATE_PORTFOLIO,
    SUCCESS_CREATE_PORTFOLIO,
    FAILURE_CREATE_PORTFOLIO,

    REQUEST_DELETE_PORTFOLIO,
    SUCCESS_DELETE_PORTFOLIO,
    FAILURE_DELETE_PORTFOLIO,

    REQUEST_GET_PORTFOLIO,
    SUCCESS_GET_PORTFOLIO,
    FAILURE_GET_PORTFOLIO,

    REQUEST_UPDATE_PORTFOLIO,
    SUCCESS_UPDATE_PORTFOLIO,
    FAILURE_UPDATE_PORTFOLIO,

    STORE_PORTFOLIO_ID,

 REQUEST_IBKR_VERIFY,
SUCCESS_IBKR_VERIFY,
FAILURE_IBKR_VERIFY,
} from "../../Constant/actionTypes";


import Api from "../Api/apiCalls";
import { handleGetUserDetailsAction } from "../actions/getUserDetailsAction";
import { handleCloseModalAction } from "../actions/modalAction";
import { toast } from "react-toastify";

//Create portfolio
export const requestCreatePortfolio = () => {
    return {
        type: REQUEST_CREATE_PORTFOLIO,
    }
}

export const successCreatePortfolio = (result) => {
    return {
        type: SUCCESS_CREATE_PORTFOLIO,
        portfolioData: result,
    }
}

export const failureCreatePortfolio = () => {
    return {
        type: FAILURE_CREATE_PORTFOLIO
    }
}

export const handleCreatePortfolioAction = (portfoliolist) => {
    return function (dispatch) {
        dispatch(requestCreatePortfolio())
        Api.CreatePortfolo(portfoliolist, localStorage.getItem('token')).then((result) => {
            dispatch(successCreatePortfolio(result))
            dispatch(handleCloseModalAction())
            dispatch(handleGetUserDetailsAction(localStorage.getItem('token')))
        }).catch((error) => {
            dispatch(failureCreatePortfolio(error))
        })
    }
}

//remove entire portfolio
export const requestRemoveEntirePortfolio = () => {
    return {
        type: REQUEST_DELETE_PORTFOLIO,
    }
}

export const successRemoveEntirePortfolio = (result) => {
    return {
        type: SUCCESS_DELETE_PORTFOLIO,
    }
}

export const failureRemoveEntirePortfolio = () => {
    return {
        type: FAILURE_DELETE_PORTFOLIO
    }
}

export const handleRemoveEntirePortfolioAction = (portfoliosName, token) => {
    return function (dispatch) {
        dispatch(requestRemoveEntirePortfolio())
        Api.RemoveEntirePortfolo(portfoliosName, token).then((result) => {
            dispatch(successRemoveEntirePortfolio(result))
            dispatch(handleCloseModalAction())
            dispatch(handleGetUserDetailsAction(token))
        }).catch((error) => {
            dispatch(failureRemoveEntirePortfolio(error))
        })
    }
}


//store the portfolio email id
export const storePortfolioId = (portfolioId) => {
    return {
        type: STORE_PORTFOLIO_ID,
        portfolioId: portfolioId
    }
}

export const handleStorePortfolioEmailId = (portfolioId) => {
    return function (dispatch) {
        dispatch(storePortfolioId(portfolioId))
    }
}


// GET PORTFOLIO

export const requestGetPortfolio = () => {
    return {
        type: REQUEST_GET_PORTFOLIO,
    }
}

export const successGetPortfolio = (result) => {
    return {
        type: SUCCESS_GET_PORTFOLIO,
        getPortfolioData: result,
    }
}

export const failureGetPortfolio = () => {
    return {
        type: FAILURE_GET_PORTFOLIO
    }
}

export const handleGetPortfolioAction = (portfolio_id) => {
    return function (dispatch) {
        dispatch(requestGetPortfolio())
        Api.portfolioGetApi(portfolio_id, localStorage.getItem('token')).then((result) => {
            dispatch(successGetPortfolio(result.data))
        }).catch((error) => {
            dispatch(failureGetPortfolio(error))
        })
    }
}

// UPDATE PORTFOLIO

export const requestUpdatePortfolio = () => {
    return {
        type: REQUEST_UPDATE_PORTFOLIO,
    }
}

export const successUpdatePortfolio = (result) => {
    return {
        type: SUCCESS_UPDATE_PORTFOLIO,
        updatePortfolioData: result,
    }
}

export const failureUpdatePortfolio = () => {
    return {
        type: FAILURE_UPDATE_PORTFOLIO
    }
}

export const handleUpdatePortfolioAction = (portfoliolist) => async (dispatch) => {
    dispatch(requestUpdatePortfolio())
    try {
        const result = await Api.portfolioUpdateApi(portfoliolist, localStorage.getItem('token'))
        if (result) {
            dispatch(successUpdatePortfolio(result))
            dispatch(handleCloseModalAction())
            toast.success('Portfolio updated')
        }
    } catch (error) {
        toast.error(error?.response?.data)
        dispatch(failureUpdatePortfolio(error))
    }

}



// ibkr verify 

export const requestibkrVerify = () => {
    return {
        type: REQUEST_IBKR_VERIFY,
    }
}

export const successibkrVerify = () => {
    return {
        type: SUCCESS_IBKR_VERIFY,
      
    }
}

export const failureibkrVerify = () => {
    return {
        type: FAILURE_IBKR_VERIFY
    }
}

export const ibkrVerifyAction = (data ,token) => {

    console.log(data, token)
    return function (dispatch) {
        dispatch(requestibkrVerify())
        Api.ibkrVerifyApi(data, token).then((result) => {
            dispatch(successibkrVerify ())
        }).catch((error) => {
            dispatch(failureibkrVerify(error))
        })
    }
}