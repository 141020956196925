import React, { useState, useEffect } from 'react';
import Integration from "../Page/integration";
import { handleIbkrInitiateAction } from "../Redux/actions/ibkrInitiateActions";
import { useDispatch, useSelector, useStore } from 'react-redux';
import ToastBar from "../Components/ToastBar/toastBar";

const IntegrationContainer = (props) => {
    const dispatch = useDispatch();
    const ibkrtInitiateFlag = useSelector(state => state.ibkrInitiateReducer.ibkrtInitiateFlag);
    // const [toast, setToast] = useState({open:false,toastState:'',type:''})
    const [toastOpen, setToastOpening] = useState(false)
    const [toastState, setToastState] = useState('')
    const [toastType, setToastType] = useState('')

    const [ibkrArray, setIbkrArray] = useState([{
        account_id: '',
        start_date: '',
        account_email: ''
    }])
    const [portfolio, setPortfolio] = useState([])

    const [input, setInput] = useState({
        name: '',
    })

    const [checkboxone, setCheckboxone] = useState(false)
    const [checkboxtwo, setCheckboxtwo] = useState(false)

    const handleIbkrAccountChange = (e) => {
        e.preventDefault()
        setInput({ ...input, [e.target.name]: e.target.value })
    }

    const handleChangeData = (value, key, index) => {
        ibkrArray[index][key] = value;
        setIbkrArray([...ibkrArray])
    }

    const handleAddDetails = (e) => {
        e.preventDefault()
        ibkrArray.push({
            account_id: '',
            start_date: '',
            account_email: ''
        })
        setIbkrArray([...ibkrArray])
    }

    const handleRemoveDetails = (index) => {
        ibkrArray.splice(index, 1);
        setIbkrArray([...ibkrArray])
    }

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    // }
    const handleCheckbox = (value) => {
        if (value == 'checkedone') {
            setCheckboxone(!checkboxone);
        }
        else {
            setCheckboxtwo(!checkboxtwo)
        }
    }

    const reset = () => {
        setIbkrArray([{
            account_id: '',
            start_date: '',
            account_email: ''
        }])
        setInput({ name: '' })
        setCheckboxone(false);
        setCheckboxtwo(false)
    }
    const onSelect = (value) => {
        setPortfolio(value)
    }

    const handleSendIbEmail = (e) => {
        e.preventDefault()
        if ((checkboxone === true) && (checkboxtwo === true)) {
            const ids = portfolio?.map((item) => {
                return item.name;
            });
            if (ids) {
                dispatch(handleIbkrInitiateAction(ids, ibkrArray, localStorage.getItem('token')))
            }
        }
        else {
            setToastOpening(true)
            setToastState('Accept all term and conditions ')
            setToastType('warning')
        }
    }

    const handleCancelIbEmail = () => { reset() }

    useEffect(() => {
        if (ibkrtInitiateFlag === 'success') {
            setToastOpening(true)
            setToastState('IBKR account linking process initiated')
            setToastType('success')
            reset()
        }
        if (ibkrtInitiateFlag === 'fail') {
            setToastOpening(true)
            setToastState('IBKR link failed to add!')
            setToastType('error')
        }
    }, [ibkrtInitiateFlag])

    const setToastOpen = () => {
        setToastOpening(false)
    }

    return (
        <div class="inner-part">
            <ToastBar
                vailed={toastOpen}
                setToastOpen={setToastOpen}
                toastState={toastState}
                type={toastType}
            />
            <Integration
                handleIbkrAccountChange={handleIbkrAccountChange}
                ibkrArray={ibkrArray}
                input={input}
                checkboxone={checkboxone}
                checkboxtwo={checkboxtwo}
                handleCheckbox={handleCheckbox}
                handleChangeData={handleChangeData}
                handleAddDetails={handleAddDetails}
                handleRemoveDetails={handleRemoveDetails}
                handleSendIbEmail={handleSendIbEmail}
                handleCancelIbEmail={handleCancelIbEmail}
                onSelect={onSelect}
                portfolio={portfolio}
            />
        </div>
    )
}

export default IntegrationContainer;