import {

    REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
} from "../../Constant/actionTypes";

const initilizeState = {
}

export default function homeCountryDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY:
            return{
                ...state,
                homeCountryData: action.homeCountryData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY:
            return{
                ...state,
                homeCountryError: action.homeCountryError
            }
        default:
            return state
    }
}