import React from "react"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ToastBar(props) {
    const handleToastClose = (event, reason) => {
        props.setToastOpen()
    }

    return (
        <>
            <Snackbar open={props.vailed} autoHideDuration={5000} onClose={handleToastClose}>
                <Alert onClose={handleToastClose} severity={props.type}>
                    {props.toastState}                
                </Alert>
            </Snackbar>

        </>
    )
}

export default ToastBar;