import {
    REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION,
    SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION,
    FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestCompositionPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION
    }
}

export const successCompositionPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION,
        compositionData:result
    }
}

export const failureCompositionPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION,
    }
}

export const hanndleCompositionPortfolioAction = (dropDownPortFolio,analysisDate,accessToken) => {
    return function(dispatch){
        dispatch(requestCompositionPortfolio())
        Api.compositionPortfolioApi(dropDownPortFolio,analysisDate,accessToken).then((result)=>{
            dispatch(successCompositionPortfolio(result))
        }).catch(error=>{
            dispatch(failureCompositionPortfolio(error))
        })
    }
}