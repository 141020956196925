import {
    REQUEST_PORTFOLIO_ANALYTICS_HEADING,
    SUCCESS_PORTFOLIO_ANALYTICS_HEADING,
    FAILURE_PORTFOLIO_ANALYTICS_HEADING,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestHeading = () =>{
    return{
        type: REQUEST_PORTFOLIO_ANALYTICS_HEADING,
    }
}

export const successHeading = (result) =>{
   return{
       type: SUCCESS_PORTFOLIO_ANALYTICS_HEADING,
       headingData: result
   }
}

export const failureHeading = () =>{
   return{
       type: FAILURE_PORTFOLIO_ANALYTICS_HEADING,
   }
}

export const handleHeadingAction = (dropDownPortFolio,headingDay,token) =>{
   return function(dispatch){
       dispatch(requestHeading())
       Api.HeadingApi(dropDownPortFolio,headingDay,token).then(result=>{
           dispatch(successHeading(result))
       }).catch(error=>{
           dispatch(failureHeading(error))
       })
   }
}