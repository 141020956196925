import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import WatchlistDropDown from "../WatchListDropDown/WatchListDropDown";
import "./Watchlisttable.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import Delete from "../../Assets/Images/delete.svg";
import { AutocompleteSelectCellEditor } from 'ag-grid-autocomplete-editor';
import 'ag-grid-autocomplete-editor/dist/main.css';
import ToastBar from "../ToastBar/toastBar";
import { API_BASE_URL } from "../../Constant/apiConstant";
import { connect } from "react-redux";
import { handleViewWatchListAction } from "../../Redux/actions/watchListAction"
class WatchlistTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridApi: null,
            gridColumnApi: null,
            open: false,
            toastState: '',
            type: '',
            height: ''
        }
    }

    componentDidUpdate(preProps, preState) {

        if (this.props.createWatchlistFlag === "success" && preProps.createWatchlistFlag === "request") {
            this.setState({
                open: true,
                toastState: 'Watchlist created',
                type: "success"
            })
        }
        if (this.props.createWatchlistFlag === "fail" && preProps.createWatchlistFlag === "request") {
            this.setState({
                open: true,
                toastState: 'Watchlist already exists!',
                type: "error"
            })
        }

        if (preProps.removeWatchlistFlag != this.props.removeWatchlistFlag) {
            if (preProps.removeWatchlistFlag === false) {
                this.setState({
                    open: true,
                    toastState: 'Watchlist deleted',
                    type: "success"
                })
            }
        }
        if (preProps.deleteWatchlistflag != this.props.deleteWatchlistflag) {
            if (preProps.deleteWatchlistflag === false) {
                this.setState({
                    open: true,
                    toastState: 'Watchlist deleted',
                    type: "success"
                })
            }
        }

        if (preProps.viewWatchListData != this.props.viewWatchListData) {
            if (this.props.viewWatchListData.length < 7) {
                this.setState({
                    height: this.props.viewWatchListData.length * 47 + 120
                })
            }
            else {
                this.setState({
                    height: 390
                })
            }
        }
    }

    setToastOpen = () => {
        this.setState({
            open: false
        })
    }

    onGridReady = params => {
        params.api.sizeColumnsToFit();
        this.setState({
            gridApi: params.api,
            gridColumnApi: params.columnApi
        })
    }
    componentDidMount() {
        let str = ""
        str = this.props.userData.watchlists[0]?.slice(this.props.userData.watchlists[0].indexOf('_') + 1)
        this.props.handleViewWatchList(str, localStorage.getItem('token'));
    }

    render() {
        const { toastState, type, height } = this.state;
        const { viewWatchListData } = this.props;
        // console.log(viewWatchListData )
        const defaultColDef = {
            ensureDomOrder: true,
            cellClass: 'locked-visible',
        };
        return (
            <div className="watchlistTableData-table-container">
                <TableHeader title='Watchlist'
                    component={<WatchlistDropDown {...this.props} />}
                />
                <ToastBar
                    vailed={this.state.open}
                    setToastOpen={this.setToastOpen}
                    toastState={toastState}
                    type={type}
                />
                <div className="watchlistTableData-table">
                    <div className="ag-theme-alpine" style={{ height: this.state.height + 40 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowDragManaged={true}
                            animateRows={true}
                            rowData={viewWatchListData}
                            singleClickEdit={true}
                            defaultColDef={defaultColDef}
                        // cellValueChanged={this.onCellValueChanged}
                        >
                            <AgGridColumn field="Ticker" wrapText={true} editable={true} rowDrag={true} style={{ display: 'flex' }}>
                                <AgGridColumn
                                    width={100}
                                    cellStyle={() => ({ backgroundColor: "#DB55EB", color: 'black' })}
                                    headerName=''
                                    field="eod_ticker"
                                    editable={true}
                                    rowDrag={true}
                                    onCellValueChanged={this.props.onCellValueChanged}
                                    cellEditor={AutocompleteSelectCellEditor}
                                    cellEditorParams={{
                                        required: true,
                                        placeholder: 'Enter Ticker',
                                        autocomplete: {
                                            fetch: (cellEditor, text, update) => {
                                                let match =
                                                    text.toLowerCase() || cellEditor.eInput.value.toLowerCase();
                                                let xmlHttp = new XMLHttpRequest();
                                                xmlHttp.onreadystatechange = () => {
                                                    if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
                                                        let data = JSON.parse(xmlHttp.responseText);
                                                        let items = data.map(d => ({
                                                            label: d.eod_ticker,
                                                            //   group: d.Name,
                                                        }));
                                                        update(items);
                                                    }
                                                    if (xmlHttp.status === 404) {
                                                        update(false);
                                                    }
                                                };
                                                xmlHttp.open("POST", `${API_BASE_URL}/security/search/ES`, true);
                                                xmlHttp.setRequestHeader("Content-type", "application/json");
                                                xmlHttp.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`)
                                                xmlHttp.send(JSON.stringify({ search_text: match }));
                                            }
                                        },
                                    }}
                                    valueFormatter=
                                    {params => {
                                        if (params.value) {
                                            return params.value.label || params.value.value || params.value;
                                        }
                                        return ''
                                    }}
                                ></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn field="Name" rowDrag={true}>
                                <AgGridColumn width={345} headerName='' field="Name" rowDrag={true} cellStyle={{ display: 'flex', justifyContent: 'left' }}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn field="Currency" rowDrag={true}>
                                <AgGridColumn headerName="" field="currency" width={150} rowDrag={true} cellStyle={{ display: 'flex', justifyContent: 'center' }}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn field="Price" rowDrag={true}>
                                <AgGridColumn headerName="" field="price" rowDrag={true}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn field="52 Week" marryChildren={true}>
                                <AgGridColumn cellStyle={() => ({ color: "#FE5B5B", display: 'flex', justifyContent: 'center' })} width={90} headerName="Low" field="52_week_low" rowDrag={true}></AgGridColumn>
                                <AgGridColumn cellStyle={() => ({ color: "#48FF65", display: 'flex', justifyContent: 'center' })} width={90} headerName="High" field="52_week_high" rowDrag={true}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn field="Returns" marryChildren={true}>
                                <AgGridColumn width={65} wrapText={true} headerName="1 D" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="1d" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="YTD" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="YTD" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="1 W" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="1w" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="1 M" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="1M" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="3 M" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="3M" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="1 Y" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="1Y" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="2 Y" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="2Y" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="3 Y" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="3Y" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={65} wrapText={true} headerName="5 Y" cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', justifyContent: 'center' } : { color: '#FF4C4D', justifyContent: 'center' })} field="5Y" rowDrag={true}></AgGridColumn>

                            </AgGridColumn>

                            <AgGridColumn field="Technicals" marryChildren={true}>
                                <AgGridColumn width={120} wrapText={true} headerName="10D EMA" field="adjusted_close_ema_10" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={120} wrapText={true} headerName="12D EMA" field="adjusted_close_ema_12" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={120} wrapText={true} headerName="50D EMA" field="adjusted_close_ema_50" rowDrag={true}></AgGridColumn>
                                <AgGridColumn width={120} wrapText={true} headerName="200D EMA" field="adjusted_close_ema_200" rowDrag={true}></AgGridColumn>
                            </AgGridColumn>
                            <AgGridColumn field="Delete" marryChildren={true}>
                                <AgGridColumn cellStyle={() => ({ cursor: "pointer" })} headerName ="" width={82} field="action" cellRendererFramework={(i) => <img onClick={() => this.props.deleteSecurities(i)} src={Delete}></img>} rowDrag={true}></AgGridColumn>
                            </AgGridColumn>
                        </AgGridReact>
                    </div>
                </div>
            </div >
        )
    }
}
const mapDisatchToProps = (dispatch) => {
    return {
        handleViewWatchList: (WatchListName, token) => dispatch(handleViewWatchListAction(WatchListName, token)),
    }
}

export default connect(null, mapDisatchToProps)(WatchlistTable);
