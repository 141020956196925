import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store/store";
import Login from './Components/Login/login';
import SignUp from './Components/SignUp/signup';
import RightContainer from './Components/RightHeader/rightContainer';
import ForgetPassword from './Components/ForgetPassword/forgetpassword';
import TermCondition from './Components/TermCondition/termCondition'
import Privacy from './Components/Privacy/privacy';
import Support from './Components/Support/support';
import VerifyIbkrInitiate from "./Components/VerifyIbkrInitiate/verifyIbkrInitiate"
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'G-5N1HTVNGGC'
}
TagManager.initialize(tagManagerArgs)

function App() {

  return (
    <div>
      <div>
        <Provider store={store}>
          <Router>
            <Route strict exact path='/' component={Login} />
            <Route strict exact path='/register' component={SignUp} />
            <Route exact path='/change-forgot-password' component={ForgetPassword} />
            <Route exact path='/rightContainer' component={RightContainer} />
            <Route exact path='/terms' component={TermCondition} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/support' component={Support} />
            <Route exact path='/verify-ibkr-initiate' component={VerifyIbkrInitiate} />
          </Router>
        </Provider>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;