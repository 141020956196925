import React, { useState, forwardRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default forwardRef((props, ref) => {
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');

    function onChangeHandler(e, value) {
        setValue(value);
    }
    function onInputChangeHandler(e, inputValue) {
        setInputValue(inputValue);
        
        // fetch(
        //     "https://41bwgu4exg.execute-api.us-east-1.amazonaws.com/staging/security/search/ES",
        //     {
        //     'method': 'POST',
        //     body: JSON.stringify({search_text : inputValue})
        //     ,
        //     headers: {
        //         'Content-type': 'application/json',
        //         "Authorization": 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjE0MDIxMTEsIm5iZiI6MTYyMTQwMjExMSwianRpIjoiODg1ZDE3YTItNzA0NS00MWM1LTljMTItMTcwYWZhZDAzMzAyIiwiZXhwIjoxNjIxNDg4NTExLCJpZGVudGl0eSI6Im1vaHNpbnZpbmRoYW5pQGdtYWlsLmNvbSIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyIsInVzZXJfY2xhaW1zIjp7InN0YXR1cyI6ImFwcHJvdmVkIiwicGxhbiI6bnVsbH19.L3En7j4yxy-HjVLZ4bKrHJwqdPg_DNDNRDWTTnvol5w',
        //     },
        //     }
        // ).then(response=>response.json())
        // .then(data=>{
        //       let temp = data.map((item,index)=>{
        //             return item.eod_ticker
        //         })
        //         setOptions(temp)
        // })
        // .catch((error)=>{})
    }
    // dispatch(handleSearchTickerAction(localStorage.getItem('token'),inputValue))

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value)
            }
        };
    });

    return (
        <Autocomplete
            style={{ padding: '0 10px' }}
            options={props.options}
            value={value}
            onChange={onChangeHandler}
            inputValue={inputValue}
            onInputChange={onInputChangeHandler}
            disableClearable
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ padding: '5px 0' }}
                    placeholder={'Select ' + props.column.colId} />
            )}
        />
    );
})
