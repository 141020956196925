import React, { Component } from "react";
import "./ToggleButton.scss";

class ToggleButton extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div style={{margin: '8px 0px 0px 40px'}}>
                <label className="switch">
                    <input type="checkbox" value={this.props.toggel} onChange={this.props.hendletoggel} name='share' />
                    <span className="slider round"></span>
                </label>
            </div>
        )
    }
}
export default ToggleButton;