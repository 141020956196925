import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import LockScreen from '../../Assets/Images/lockScreen.jpg'
import "./EfficientFrontier.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class EfficientFrontierTable extends Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    render(){
        const defaultColDef={
            resizable: true,
            cellStyle: {textAlign: 'center', paddingLeft: '0px'}
        };
        const hasEfficientFrontier = this.props.showTableData?.indexOf('efficient_frontier') >-1
        const {analysisEfficientFrontierDate, efficientFrontierData} = this.props;
        return(
            <div className="efficientfrontierData-table-container">
                <TableHeader title='Efficient Frontier'
                // component={<input type="date" value={analysisEfficientFrontierDate} onChange={this.props.handlePortfolioEfficientFrontierDate} className="efficient-date"></input>}
                />
                {
                    hasEfficientFrontier?<div className="efficientfrontierData-table"> 
                    <div className="ag-theme-alpine">
               <AgGridReact
                   onGridReady={this.onGridReady}
                   rowData={efficientFrontierData}
                   defaultColDef={defaultColDef}
               >
                   <AgGridColumn headerName='Stock Name' field="stock" wrapText={true} minWidth={120} width={120}></AgGridColumn>
                   <AgGridColumn headerName='Portfolio Weight' field="portfolio_weight" wrapText={true} minWidth={120} width={120}></AgGridColumn>
                   <AgGridColumn headerName='&ensp;Efficient Frontier Weight' field="efficient_frontier_weight" minWidth={160} wrapText={true} width={160}></AgGridColumn>
               </AgGridReact>
           </div>
                </div>:
                <div className="lockScreen">
                    <img alt='' src={LockScreen} width= '500' height= '508'/>
                </div>
                }
            </div>
        )
    }
}

export default EfficientFrontierTable;