import {
    REQUEST_PLAID_LINK_INITIATE,
    SUCCESS_PLAID_LINK_INITIATE,
    FAILURE_PLAID_LINK_INITIATE,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestGetPlaidLinkInitiate = () =>{
    return {
        type: REQUEST_PLAID_LINK_INITIATE
    }
}

export const successGetPlaidLinkInitiate = (result) =>{
    return {
        type: SUCCESS_PLAID_LINK_INITIATE,
        plaidLink: result
    }
}

export const failureGetPlaidLinkInitiate = () =>{
    return {
        type: FAILURE_PLAID_LINK_INITIATE
    }
}

export const handleGetPlaidLinkInitiateAction = (token) =>{
    return function(dispatch){
        dispatch(requestGetPlaidLinkInitiate())
        Api.getPlaidLinkInitiateApi(token).then((result)=>{
            dispatch(successGetPlaidLinkInitiate(result.data))
        }).catch((error=>{
            dispatch(failureGetPlaidLinkInitiate())
        }))
    }
}