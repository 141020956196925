import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import "./rightHeader.scss";
import Navbar from "../NavBar/navbar";
import HomeContainer from "../../Container/homeContainer";
import { handleContainerValueAction } from "../../Redux/actions/menuScrollAction";
import { handleViewWatchListAction } from "../../Redux/actions/watchListAction";
import WatchListContainer from "../../Container/watchlistContainer";
import EventsContainer from "../../Container/eventsContainer";
import PortFolioContainer from "../../Container/portfolioContainer";
import NewsContainer from "../../Container/newsContainer";
import DecisionStatsContainer from "../../Container/decsionStatsContainer";
import PaymentContainer from "../../Container/paymentContainer";
import LeftContainer from "../LeftHeader/LeftContainer";
import IntegrationContainer from "../../Container/integrationContainer";
import { handleGetUserDetailsAction } from "../../Redux/actions/getUserDetailsAction";
import { handleGetCurrencyListAction } from "../../Redux/actions/getListOfCurrencyAction";
import { handleGetBenchMarkListAction } from "../../Redux/actions/getListOfBenchMarkAction";
import { handleStorePortfolioEmailId } from "../../Redux/actions/portfoilioAction";
import PlaidIntegrationContainer from "../../Container/plaidIntegrationContainer";
import { handleOpenModalAction, handleCloseModalAction } from "../../Redux/actions/modalAction";
import { handleRemoveEntirePortfolioAction } from "../../Redux/actions/portfoilioAction";
import BrokerPortfolioLinkContainer from '../../Container/brokerPortfolioLinkContainer'
import { ToastContainer, toast } from "react-toastify";
import { handleHitRatioAction } from "../../Redux/actions/decisionStatsAction";
import TermCondition from "../TermCondition/termCondition";
import Privacy from "../Privacy/privacy";

const RightContainer = (props) => {
    const userData = useSelector(state => state.getUserDetailsReducer.userData);
    const watchlistData = useSelector(state => state.createNewWatchlistReducer.createWatchlistData)
    const dispatch = useDispatch();
    const accessToken = localStorage.getItem('token');
    const containerValue = useSelector(state => state.menuScrollReducer.containerValue);
    const currencyList = useSelector(state => state.getListOfCurrencyReducer.currencyList);
    const currencyListArrayofObject = currencyList && currencyList.map((p, k) => { return { value: k, label: p } });
    const benchmarksList = useSelector(state => state.getListOfBenchMarkReducer.benchmarksList);
    const benchmarksListArrayofObject = benchmarksList && benchmarksList.map((l, m) => { return { value: m, label: l } })
    const [PortfolioId, setPortfolioId] = useState(userData?.portfolios?.length && userData.portfolios[0] == 0 ? '-' : userData?.portfolios?.length && userData.portfolios[0]);
    const [dropDownPortFolio, setdropDownPortFolio] = useState('');
    const [analysisCompositionDate, setAnalysisCompositionDate] = useState(new Date().toISOString().slice(0, 10));
    const [analysisEfficientFrontierDate, setAnalysisEfficientFrontierDate] = useState(new Date().toISOString().slice(0, 10));
    const [headingDay, setHeadingDay] = useState('7')
    const [backtestingDay, setBacktestingDay] = useState('7')
    const [navDay, setNavDay] = useState('7');
    const [watch, setWatch] = useState('close');
    const [portfolioTab, setPortfolioTab] = useState('close');
    const [dropDownWatchlist, setDropDownWatchlist] = useState('');
    const [modalComponent, setModalComponent] = useState('');
    const [editPort, setEditPort] = useState('')
    const [transaction, setTransaction] = useState(false)
    const history = useHistory();
    useEffect(() => {
        setPortfolioId(userData?.portfolios?.length && userData?.portfolios[0] !== 0 ? userData?.portfolios?.length && userData.portfolios[0] : '-')
        setdropDownPortFolio(userData?.portfolios?.length && userData?.portfolios[0] !== 0 ? userData && userData.portfolios.length && userData.portfolios[0].slice(userData.portfolios[0].indexOf('_') + 1, userData.portfolios[0].length) : '-')
        // setDropDownWatchlist(userData && userData.watchlists.length && userData.watchlists[0].slice(userData.watchlists[0].indexOf('_') + 1, userData.watchlists[0].length))
        // console.log(userData?.watchlists[0]?.slice(userData?.watchlists[0]?.indexOf('_') + 1))
        // console.log(watchlistData?.data?.watchlist_name)
    }, [userData])

    useEffect(() => {
        dispatch(handleHitRatioAction(PortfolioId,navDay,accessToken))
    }, [navDay])

    useEffect(()=>{
        const token_expiry =  parseInt(localStorage.getItem("token_expiry"))*1000
        const real_time = new Date().getTime()
        if(real_time>token_expiry){
            localStorage.clear();
        }
    })
    
    useEffect(() => {
        setDropDownWatchlist(watchlistData && watchlistData?.data?.watchlist_name)
        // console.log(userData?.watchlists[0]?.slice(userData?.watchlists[0]?.indexOf('_') + 1))
        // console.log(watchlistData?.data?.watchlist_name)
    }, [watchlistData])
    
    useEffect(() => {
        dispatch(handleGetUserDetailsAction(accessToken))
        dispatch(handleGetCurrencyListAction(accessToken))
        dispatch(handleGetBenchMarkListAction(accessToken))
        if (localStorage.getItem("token") == null) {
            history.push('/');
        }
    }, [])

    const handlePortfolioDropDownChange = (e, value, id, component, open, transaction) => {
        e.preventDefault();
        setdropDownPortFolio(value)
        setWatch(open)
        setTransaction(transaction);
        dispatch(handleContainerValueAction(id, component))
        for (let i = 0; i < userData.portfolios.length; i++) {
            if (userData.portfolios[i].slice(userData.portfolios[i].indexOf('_') + 1, userData.portfolios[i].length) === value) {
                setPortfolioId(userData.portfolios[i]);
                dispatch(handleStorePortfolioEmailId(userData.portfolios[i]))
            }
        }
    }
    const handleWatchlistDropDownChange = (e, item, id, component, open) => {
        e.preventDefault();
        setWatch(open)
        setDropDownWatchlist(item);
        dispatch(handleViewWatchListAction(item, localStorage.getItem('token')));
        dispatch(handleContainerValueAction(id, component))
    }

    const handlePortfolioCompositionDate = (event) => {
        setAnalysisCompositionDate(event.target.value)
    }

    const handlePortfolioEfficientFrontierDate = (event) => {
        setAnalysisEfficientFrontierDate(event.target.value)
    }

    const handleOnChangeHeading = (event) => {
        setHeadingDay(event.target.value)
    }
    
    const handleNavDay = (value) => {
        setNavDay(value)
    }

    const handleOnChangeBacktesting = (event) => {
        setBacktestingDay(event.target.value)
    }
    const createWatchlist = (e, id, component, value, open) => {
        e.preventDefault();
        setModalComponent(value)
        setWatch(open);
        dispatch(handleContainerValueAction(id, component))
        dispatch(handleOpenModalAction())
    }
    const createPortfolio = (e, id, component, value, open) => {
        e.preventDefault();
        setModalComponent(value)
        setPortfolioTab(open)
        dispatch(handleContainerValueAction(id, component))
        dispatch(handleOpenModalAction())
    }
    const handleDeleteWatchListModal = (e, item, id, component, value) => {
        e.preventDefault();
        setModalComponent(value);
        setPortfolioId(item)
        setDropDownWatchlist(item.slice(item.indexOf('_') + 1))
        setdropDownPortFolio(item.slice(item.indexOf('_') + 1))
        dispatch(handleOpenModalAction())
        dispatch(handleContainerValueAction(id, component))
    }
    const handleDeleteEntirePortfolio = () => {
        dispatch(handleRemoveEntirePortfolioAction(PortfolioId, localStorage.getItem('token')))
    }

    const handleView = (e, id, component, open) => {
        e.preventDefault();
        setWatch(open);
        dispatch(handleContainerValueAction(id, component))
    }
    const handleIBKRintegration = (e, id, component, open) => {
        e.preventDefault();
        setWatch(open);
        dispatch(handleContainerValueAction(id, component))
    }
    const handlePortfolioEdit = (e, id, component, value, open, item) => {
        e.preventDefault();
        setWatch(open);
        dispatch(handleOpenModalAction())
        setModalComponent(value);
        setEditPort(item)
        dispatch(handleContainerValueAction(id, component))
    }
    const handleSubmit = (e, value) => {
        e.preventDefault()
        setModalComponent(value);
        dispatch(handleOpenModalAction())
    }
    return (
        <div className="main-outer">
            <ToastContainer />
            <LeftContainer watch={watch} portfolioTab={portfolioTab} transaction={transaction} dropDownPortFolio={dropDownPortFolio} />
            <div className="right-header-container">
                <div className="nav-bar">
                    <Navbar
                        userData={userData}
                        dropDownPortFolio={dropDownPortFolio}
                        handlePortfolioDropDownChange={handlePortfolioDropDownChange}
                        handleWatchlistDropDownChange={handleWatchlistDropDownChange}
                        dropDownWatchlist={dropDownWatchlist}
                        createWatchlist={createWatchlist}
                        createPortfolio={createPortfolio}
                        handleDeleteWatchListModal={handleDeleteWatchListModal}
                        handleView={handleView}
                        handleIBKRintegration={handleIBKRintegration}
                        handleDeleteEntirePortfolio={handleDeleteEntirePortfolio}
                        handlePortfolioEdit={handlePortfolioEdit}
                    />
                </div>
                {/* style={{ color: "white", marginTop: '7%', marginLeft: '16%', marginRight: '1%' }} */}
                <div className="stoxdoc-part"  style={{ color: "black",}}>                    {
                        containerValue === 'Home' ? <HomeContainer {...props} /> :
                            containerValue === 'Watchlist' ? <WatchListContainer
                                watch={watch}
                                modalComponent={modalComponent}
                                dropDownWatchlist={dropDownWatchlist}
                                handleWatchlistDropDownChange={handleWatchlistDropDownChange}
                            /> :
                                containerValue === 'Portfolio' ? <PortFolioContainer
                                    dropDownPortFolio={PortfolioId}
                                    analysisEfficientFrontierDate={analysisEfficientFrontierDate}
                                    analysisCompositionDate={analysisCompositionDate}
                                    handlePortfolioEfficientFrontierDate={handlePortfolioEfficientFrontierDate}
                                    handlePortfolioCompositionDate={handlePortfolioCompositionDate}
                                    portfoliosName={dropDownPortFolio}
                                    currencyList={currencyListArrayofObject}
                                    benchmarksList={benchmarksListArrayofObject}
                                    headingDay={headingDay}
                                    handlePortfolioDropDownChange={handlePortfolioDropDownChange}
                                    handleOnChangeHeading={handleOnChangeHeading}
                                    // transaction={transaction}
                                    handleNavDay={handleNavDay}
                                    navDay={navDay}
                                    backtestingDay={backtestingDay}
                                    handleOnChangeBacktesting={handleOnChangeBacktesting}
                                    modalComponent={modalComponent}
                                    handleDeleteEntirePortfolio={handleDeleteEntirePortfolio}
                                    editPort={editPort}
                                    {...props}

                                /> :
                                    containerValue === 'DecisionStats' ? <DecisionStatsContainer dropDownPortFolio={PortfolioId} handleNavDay={handleNavDay} navDay={navDay} {...props} /> :
                                        containerValue === 'Payment' ? <PaymentContainer dropDownPortFolio={PortfolioId} /> :
                                        containerValue === 'terms' ? <TermCondition/> :
                                        containerValue === 'privacy' ? <Privacy/> :
                                            containerValue === 'Integrations' ? <IntegrationContainer /> :
                                                containerValue === 'PLAID' ? <PlaidIntegrationContainer /> :
                                                    containerValue === 'News' ? <NewsContainer /> :
                                                        containerValue === 'Events' ? <EventsContainer /> :
                                                            containerValue === 'BrokerPortfolioLink' ?
                                                                <BrokerPortfolioLinkContainer modalComponent={modalComponent} handleSubmit={handleSubmit} analysisEfficientFrontierDate={analysisEfficientFrontierDate} handlePortfolioEfficientFrontierDate={handlePortfolioEfficientFrontierDate}/>
                                                                : <HomeContainer />
                    }
                </div>
            </div>
        </div>
    )
}

export default RightContainer;

//dropDownPortFolio={PortfolioId}  Hint :this is portfolio email id mohsinvindhani@gmail.com_test_portfolio_name111
//portfoliosName={dropDownPortFolio} Hint :this is portfolio test_portfolio_name111
// modalComponent={modalComponent} handleSubmit={handleSubmit} 