import React from 'react';
import './topmenu.scss';
export default function SimpleMenu(props) {

    return (
        <div>
            <div className="main-navigation">
                <ul className="menu">
                    <li className="menu-item-has-children "><a className='tag-line'>Watchlist</a>
                        <ul className="sub-menu">
                            <li><a onClick={(e) => props.createWatchlist(e, 2, "Watchlist", 'Create watchlist')}>Create</a></li>
                            <li><a >View</a>
                                <ul className="sub-menu">
                                    {props?.userData?.watchlists?.map((item, index) => {
                                        return <li key={index}><a onClick={(e) => props.handleWatchlistDropDownChange(e, item.slice(item.indexOf('_') + 1), 2, "Watchlist", 'Watch')}>{item.slice(item.indexOf('_') + 1)}</a></li>
                                    })}
                                </ul>
                            </li>
                            <li><a>Delete</a>
                                <ul className="sub-menu">
                                    {props?.userData?.watchlists?.map((item, index) => {
                                        return <li key={index}><a onClick={(e) => props.handleDeleteWatchListModal(e, item, 2, "Watchlist", 'Remove Watch List')}>{item.slice(item.indexOf('_') + 1)}</a></li>
                                    })}
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item-has-children "><a>Portfolio</a>
                        <ul className="sub-menu">
                            <li><a onClick={(e) => props.createPortfolio(e, 3, "Portfolio", 'Create Portfolio')}>Create</a></li>
                            <li>
                                <a>View</a>
                                <ul className="sub-menu">
                                    {props?.userData?.portfolios?.map((item, index) => {
                                        return <li key={index}><a onClick={(e) => props.handlePortfolioDropDownChange(e, item.slice(item.indexOf('_') + 1), 3, "Portfolio", 'Port')}>{item.slice(item.indexOf('_') + 1)}</a></li>
                                    })}
                                </ul>
                            </li>
                            <li>
                                <a>Edit</a>
                                <ul className="sub-menu">
                                    {props?.userData?.portfolios?.map((item, index) => {
                                        return <li key={index}><a onClick={(e) => props.handlePortfolioEdit(e, 3, "Portfolio", 'Edit Portfolio', 'Port', item)}>{item.slice(item.indexOf('_') + 1)}</a></li>
                                    })}
                                </ul>
                            </li>
                            <li><a>Delete</a>
                                <ul className="sub-menu">
                                    {props?.userData?.portfolios?.map((item, index) => {
                                        return <li key={index}><a onClick={(e) => props.handleDeleteWatchListModal(e, item, 3, "Portfolio", 'Remove Entire Portfolio')}>{item.slice(item.indexOf('_') + 1)}</a></li>
                                    })}
                                </ul>
                            </li>
                            <li><a>Broker-connect</a>
                                <ul className="sub-menu">
                                    <li><a onClick={(e) => props.handleIBKRintegration(e, 8, "Integrations", 'close')}>IBKR Integration</a></li>
                                    <li><a onClick={(e) => props.handleIBKRintegration(e, 5, "PLAID", 'close')}>NON IBKR Integration</a></li>
                                </ul>
                            </li>
                            <li><a onClick={(e) => props.handleIBKRintegration(e, 10, 'BrokerPortfolioLink', 'close')}>Broker Portfolio Link</a>
                                {/* <ul className="sub-menu">
                                    <li><a onClick={(e) => handleIBKRintegration(e, 8, "Integrations")}>IBKR Integration</a></li>
                                    <li><a onClick={(e) => handleIBKRintegration(e, 5, "PLAID")}>NON IBKR Integration</a></li>
                                </ul> */}
                            </li>
                        </ul>
                    </li>
                    <li className="menu-item-has-children ">
                        <a>Transaction</a>
                        <ul className="sub-menu">
                            {props?.userData?.portfolios?.map((item, index) => {
                                return <li key={index}><a onClick={(e) => props.handlePortfolioDropDownChange(e, item.slice(item.indexOf('_') + 1), 3, "Portfolio", 'Port', 'transaction')}>{item.slice(item.indexOf('_') + 1)}</a></li>
                            })}
                        </ul>
                    </li>

                </ul>
            </div>
        </div>
    );
}