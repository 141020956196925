import {
    REQUEST_INITIATE_PLAN,
    SUCCESS_INITIATE_PLAN,
    FAILURE_INITIATE_PLAN,
    REQUEST_COUPON_VERIFICATION,
    SUCCESS_COUPON_VERIFICATION,
    FAILURE_COUPON_VERIFICATION,
    RESET,
    PAYMENT_INITIATE_DATA,
    PAYMENT_INITIATE_VALIDATE
} from "../../Constant/actionTypes";

const initilizeState = {
    initiatePlanFlag: '',
    CouponVerificationData: {},
    couponFlag: '',
    CouponVerificationErrorMessage: '',
    initiatePlanData: ''
}


export default function initiatePlanReducer(state = initilizeState, action) {
    switch (action.type) {
        case REQUEST_INITIATE_PLAN:
            return {
                ...state,
                initiatePlanFlag: 'request'
            }
        case SUCCESS_INITIATE_PLAN:
            return {
                ...state,
                initiatePlanMessage: action.initiatePlanMessage,
                initiatePlanData: action.initiatePlanData,
                initiatePlanFlag: 'success'
            }
        case FAILURE_INITIATE_PLAN:
            return {
                ...state,
                initiatePlanErrorMessage: action.initiatePlanErrorMessage,
                initiatePlanFlag: 'fail'
            }

        // COUPON VERIFICATION

        case REQUEST_COUPON_VERIFICATION:
            return {
                ...state,
                couponFlag: 'request'
            }
        case SUCCESS_COUPON_VERIFICATION:
            return {
                ...state,
                CouponVerificationData: action.CouponVerificationData,
                couponFlag: 'success'
            }
        case FAILURE_COUPON_VERIFICATION:
            return {
                ...state,
                CouponVerificationErrorMessage: action.CouponVerificationErrorMessage,
                couponFlag: 'fail'
            }

        // RESET
        case RESET:
            return {
                ...state,
                CouponVerificationData: action?.CouponVerificationData
            }
        case PAYMENT_INITIATE_DATA: 
            return {
                ...state,
                paymentIntentData: action.paymentIntentData
            }
        case PAYMENT_INITIATE_VALIDATE: 
            return {
                ...state,
                paymentIntentData: action.paymentIntentData
            }
        default: return state
    }
}