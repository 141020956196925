import React from "react";
import BacktestingTable from "../Components/BacktestingTable/BacktestingTable";
import CorrelationMatrixTable from "../Components/CorrelationMatrix/CorrelationMatrix";
import FundamentalsTable from "../Components/Fundamentals/Fundamentals";
import LiquidityVolatilityTable from "../Components/LiquidityVolatility/LiquidityVolatility";
import MaxDrawdown from "../Components/MaxDrawdown/MaxDrawdown";
import Heading from "../Components/Heading/Heading";
import PercentageHeading from "../Components/Heading/PercentageHeading";
import AttributionTable from "../Components/Attribution/Attribution";
// import Performance from "../Components/Performance/Performance";
import PriceTrendsTable from "../Components/PriceTrends/PriceTrends";
import EfficientFrontierTable from "../Components/EfficientFrontier/EfficientFrontier";
import Composition from "../Components/Composition/CompositionGraph";
import NetAssetValue from "../Components/NetAssetValue/NetAssetValue";
import HoldingTable from "../Components/HoldingsTable/holdingTable";
import NameRouteList from "../Components/NameRouteList/NameRouteList";
import Modal from "../Components/Modal/modal";
import TransactionTable from "../Components/TransactionTable/TransactionsTable";
import ToastBar from "../Components/ToastBar/toastBar";
import "./portfolio.scss";
import DecisionStatsContainer from "../Container/decsionStatsContainer";
// import DecisionStats from "./decisionStats";
// import PerformanceTable from "../Components/DicisionStateTables/decisionStatsPerformanceTable/PerformanceTable";
// import DicisionStateTables from "../Components/DicisionStateTables/BestWorstPositionTable/index"
// import DecisionStatsHitRatioTable from "../Components/DicisionStateTables/decisionStatsHitRatioTable/decisionStatsHitRatioTable.js";
// import AreaRechartComponent from "../Components/AreaGraph/AreaGraph";
class Portfolio extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            aggregate_period: 1,
            open: false,
            toastState: '',
            type: ""
        }
    }
    componentDidUpdate(preProps, preState) {

        if (this.props.createPortfolioFlag === "success" && preProps.createPortfolioFlag === "request") {
            this.setState({
                open: true,
                toastState: "Portfolio created",
                type: "success"
            })
        }
        if (this.props.createPortfolioFlag === "fail" && preProps.createPortfolioFlag === "request") {
            this.setState({
                open: true,
                toastState: "Portfolio already exists. Please check!",
                type: "error"
            })
        }
        if (this.props.EditTransaction === "success" && preProps.EditTransaction === "request") {
            this.setState({
                open: true,
                toastState: "Transaction entered",
                type: "success"
            })
        }
        if (this.props.EditTransaction === "fail" && preProps.EditTransaction === "request") {
            this.setState({
                open: true,
                toastState: this.props.ErrorTransactionMessage,
                type: "error"
            })
        }
        if (this.props.deleteTransaction === "success" && preProps.deleteTransaction === "request") {
            this.setState({
                open: true,
                toastState: this.props.deleteTransactionMessage,
                type: "success"
            })
        }
        if (this.props.deleteTransaction === "fail" && preProps.deleteTransaction === "request") {
            this.setState({
                open: true,
                toastState: this.props.deleteTransactionMessage,
                type: "error"
            })
        }
    }

    setToastOpen = () => {
        this.setState({
            open: false
        })
    }

    render() {
        return (
            <div className="portfolio-names stoxdoc-innerpart">
                <Modal {...this.props} />
                {
                    <NameRouteList {...this.props} />
                }
                <ToastBar
                    vailed={this.state.open}
                    setToastOpen={this.setToastOpen}
                    toastState={this.state.toastState}
                    type={this.state.type}
                />
                <div className="w-100">
                    {/* <HoldingTable  {...this.props} /> */}
                    {this.props.holdingFlag ? <HoldingTable  {...this.props} /> : <HoldingTable />}
                </div>
                {/*<div className="w-100">
                    //  <Performance {...this.props} /> 
                    {this.props.performanceFlag ? <Performance {...this.props} /> : <Performance />}
            </div>*/}
                <div className="w-100" >
                    <NetAssetValue {...this.props} title="Net Asset Value" />
                </div>
                <div className="w-100" >
                    {/* <TransactionTable {...this.props} /> */}
                    {this.props.transactionFlag ? <TransactionTable {...this.props} /> : <TransactionTable />}
                </div>
                <div className="w-100 status-table">
                    <Composition {...this.props} />
                    <EfficientFrontierTable {...this.props} />
                    {/* {this.props.compositionFlag ?  <Composition {...this.props} /> : <Composition  /> } */}
                    {/* {this.props.efficientFrontierFlag ? <EfficientFrontierTable {...this.props} /> : <EfficientFrontierTable /> } */}
                </div>
                <div className="w-100">
                    {/* <PriceTrendsTable {...this.props} /> */}
                    {this.props.priceTrendFlag ? <PriceTrendsTable {...this.props} /> : <PriceTrendsTable />}
                </div>

                <div className="w-100">
                    {/* <AttributionTable {...this.props} /> */}
                    {this.props.attributionsFlag ? <AttributionTable {...this.props} /> : <AttributionTable />}
                </div>
                <div className="w-100 wdew">
                    {/* <Heading {...this.props} /> */}
                    {this.props.headingFlag ? <Heading {...this.props} /> : <Heading />}
                </div>
                <div className="w-100 wdew">
                    {/* <Heading {...this.props} /> */}
                    {this.props.headingFlag ? <PercentageHeading {...this.props} /> : <PercentageHeading />}
                </div>
                <div className="w-100 status-table" >
                    {/* <LiquidityVolatilityTable title='Liquidity' {...this.props} /> */}
                    {/* <LiquidityVolatilityTable title='Volatility' {...this.props} /> */}
                    {this.props.liquidityFlag ? <LiquidityVolatilityTable title='Liquidity' {...this.props} /> : <LiquidityVolatilityTable title='Liquidity' />}
                    {this.props.volatilityFlag ? <LiquidityVolatilityTable title='Volatility' {...this.props} /> : <LiquidityVolatilityTable title='Volatility' />}
                </div>
                <div className="w-100">
                    {/* <CorrelationMatrixTable {...this.props} /> */}
                    {this.props.correlationFlag ? <CorrelationMatrixTable {...this.props} /> : <CorrelationMatrixTable />}
                </div>
                <div className="w-100">
                    {/* <MaxDrawdown {...this.props} /> */}
                    {this.props.maxDrawdownDetailsFlag ? <MaxDrawdown {...this.props} /> : <MaxDrawdown />}
                </div>
                <div className="w-100">
                    {/* <FundamentalsTable {...this.props} /> */}
                    {this.props.fundamenAddTransactionsModaltalsFlag ? <FundamentalsTable {...this.props} /> : <FundamentalsTable />}
                </div>
                <div className="w-100">
                    {/* <BacktestingTable {...this.props} /> */}
                    {this.props.backtestingFlag ? <BacktestingTable {...this.props} /> : <BacktestingTable />}
                </div>
                <div className="w-100">
                    <DecisionStatsContainer dropDownPortFolio={this.props.PortfolioId} handleNavDay={this.props.handleNavDay} returnData={this.props.returnData} navDay={this.props.navDay} {...this.props}/>
                </div>
            </div>
        )
    }
}

export default Portfolio;