import React, { Component } from 'react';
import { connect } from "react-redux";
import Portfolio from "../Page/portfolio";
import CreatePortfolioModal from "../Components/CreatePortfolio/createPortfolio";
import PropTypes from 'prop-types';
import AddTransactionsModal from '../Components/AddTransactionsModal/AddTransactionsModal';
import DeletePortfolioModal from '../Components/Delete/deletePortfolioModal';
import EditModal from '../Components/Edit/EditModal';
import { handleContainerValueAction } from "../Redux/actions/menuScrollAction";
import { handleOpenModalAction, handleCloseModalAction } from "../Redux/actions/modalAction";
import { handleCreatePortfolioAction } from "../Redux/actions/portfoilioAction";
import { handleGetTransactionHistoryAction, handleDeleteSecurityTransactionHistoryAction } from "../Redux/actions/transactionAction";
import { hanndleHoldingPortfolioAction } from "../Redux/actions/holdingPortfolioAction";
import { hanndleCompositionPortfolioAction } from "../Redux/actions/compositionPortfolioAction";
import { hanndleEfficientFrontierPortfolioAction } from "../Redux/actions/efficientFrontierPortfolioAction";
import { handlePriceTrendAction } from "../Redux/actions/priceTrendPortfolioAction";
import { handlePerformanceAction } from "../Redux/actions/performancePortfolioAction";
import { handleAttributionsAction } from "../Redux/actions/attributionsPortfolioAction";
import { handleMaxDrawdownAction } from "../Redux/actions/maxDrawDownPortfolioAction";
import { handleCorrelationAction } from "../Redux/actions/correlationPortfolioAction";
import { handleHeadingAction } from "../Redux/actions/headingPortfolioAction";
import { handleNAVPortfolioAction } from "../Redux/actions/navPortfolioAction";
import { handleLiquidityPortfolioAction } from "../Redux/actions/liquidityPortfolioAction";
import { handleVolatilityPortfolioAction } from "../Redux/actions/volatilityPortfolioAction";
import { hanndleFundamentalsPortfolioAction } from "../Redux/actions/fundamentalsPortfolioAction";
import { handlebacktestingPortfolioAction } from "../Redux/actions/backtestingPortfolioAction";
import { handleShowTableAction } from '../Redux/actions/showTableAction';
import { handleRemoveEntirePortfolioAction } from "../Redux/actions/portfoilioAction";
import { ToastContainer, toast } from "react-toastify";
import { jsonSchema } from 'uuidv4';


class PortfolioContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            portfoliolist: ['Holdings', 'Net Asset Value', 'Transaction', 'Composition', 'Price Trends', 'Performance', 'Attribution', 'Heading', 'Liquidity', 'Correlation Matrix', 'Max Drawdown', 'Fundamentals', 'Backtesting'],
            modalComponent: '',
            currencyarr: [],
            benchmarkarr: [],
            toggle: false,
            transactionTypeValue: '',
            commission: "",
            currency: "",
            num_shares: "",
            order_date: "",
            security: "",
            security_price: "",
            tax: 0,
            tax_basis_election: 0,
            tag1: '',
            margin_percentage: '',
            exchange_fees: 10,
            rowId: '',
            offset: 0,
            aggregate_period: 1,
        }
    }
    
    componentDidMount() {
        this.props.handleShowTable(this.props.showTable, localStorage.getItem('token'));
        if (this.props.userData && this.props.userData.portfolios.length !== 0) {
                this.props.hanndleHoldingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.hanndleCompositionPortfolio(this.props.dropDownPortFolio, new Date().toISOString().slice(0, 10), localStorage.getItem('token'));
                this.props.handleGetTransactionHistory(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.hanndleEfficientFrontierPortfolio(this.props.dropDownPortFolio, new Date().toISOString().slice(0, 10), localStorage.getItem('token'));
                this.props.handlePriceTrend(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handlePerformance(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleAttributions(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleMaxDrawdown(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleCorrelation(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleNAV(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
                this.props.handleHeading(this.props.dropDownPortFolio, this.props.headingDay, localStorage.getItem('token'));
                this.props.handleLiquidityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleVolatilityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.hanndleFundamentalsPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handlebacktestingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        }
        // else {
        //     // toast.warning('Portfolio Not Found ! Please create a portfolio')
        // }
        // // if (this.props.transaction === true) {
        // //     window.scroll({
        // //         top: offset + (id.getBoundingClientRect().top - 300),
        // //         behavior: 'smooth'
        // //     });
        // // }
    }

    componentDidUpdate(preprops) {
        // if(JSON.stringify(preprops.showTableData)!== JSON.stringify(this.props.showTableData)){
        //     this.props.showTableData?.forEach((table)=>{
        //         switch(table) {
        //             case 'holdings':
        //                 this.props.hanndleHoldingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //               break;
        //             case 'transactions':
        //                 this.props.handleGetTransactionHistory(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case 'liquidity':
        //                 this.props.handleLiquidityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //               break;
        //             case 'volatility':
        //                 this.props.handleVolatilityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case 'correlation_matrix':
        //                 this.props.handleCorrelation(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case 'max_drawdown':
        //                 this.props.handleMaxDrawdown(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case "price_trends":
        //                 this.props.handlePriceTrend(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case "fundamentals":
        //                 this.props.hanndleFundamentalsPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case "backtesting":
        //                 this.props.handlebacktestingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //               break;
        //             case "attributions":
        //                 this.props.handleAttributions(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //             break;
        //             case "composition":
        //                 if (preprops.analysisCompositionDate != this.props.analysisCompositionDate || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //                         this.props.hanndleCompositionPortfolio(this.props.dropDownPortFolio, this.props.analysisCompositionDate, localStorage.getItem('token'));
        //                     }
        //             break;
        //             case "efficient_frontier":
        //                 if (preprops.analysisEfficientFrontierDate != this.props.analysisEfficientFrontierDate || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //                         this.props.hanndleEfficientFrontierPortfolio(this.props.dropDownPortFolio, this.props.analysisEfficientFrontierDate, localStorage.getItem('token'));
        //                    }
        //             break;
        //             case "return_attributions (in USD)":
        //                 if (preprops.headingDay != this.props.headingDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //                         this.props.handleHeading(this.props.dropDownPortFolio, this.props.headingDay, localStorage.getItem('token'));
        //                     }
        //             break;
        //             case "return_attributions (in %)":
        //                 if (preprops.headingDay != this.props.headingDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //                         this.props.handleHeading(this.props.dropDownPortFolio, this.props.headingDay, localStorage.getItem('token'));
        //                     }
        //             break;
        //             case 'net_asset_value':
        //                 if (preprops.navDay != this.props.navDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //                     this.props.handleNAV(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
        //                 }
        //             break;
        //             default:
        //               // code block
        //           }
        //     })
        // }
        if (preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
            if (this.props.dropDownPortFolio !== '-') {
                this.props.handleGetTransactionHistory(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.hanndleHoldingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handlePriceTrend(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handlePerformance(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleAttributions(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleMaxDrawdown(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleCorrelation(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleLiquidityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handleVolatilityPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.hanndleFundamentalsPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
                this.props.handlebacktestingPortfolio(this.props.dropDownPortFolio, localStorage.getItem('token'));
            }
        }
        if (preprops.navDay != this.props.navDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {

            this.props.handleNAV(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
        }
        if (preprops.headingDay != this.props.headingDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
            this.props.handleHeading(this.props.dropDownPortFolio, this.props.headingDay, localStorage.getItem('token'));
        }
        if (preprops.analysisCompositionDate != this.props.analysisCompositionDate || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
            this.props.hanndleCompositionPortfolio(this.props.dropDownPortFolio, this.props.analysisCompositionDate, localStorage.getItem('token'));
        }
        if (preprops.analysisEfficientFrontierDate != this.props.analysisEfficientFrontierDate || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
            this.props.hanndleEfficientFrontierPortfolio(this.props.dropDownPortFolio, this.props.analysisEfficientFrontierDate, localStorage.getItem('token'));
        }
    }
        // if (preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //     if (this.props.dropDownPortFolio !== '-') {
        //         this.props.handlePerformance(this.props.dropDownPortFolio, localStorage.getItem('token'));
        //     }
        // }
        // if (preprops.navDay != this.props.navDay || preprops.dropDownPortFolio != this.props.dropDownPortFolio) {
        //     this.props.handleNAV(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
        // }
        
        
    
    
    handleCreateWatchlistPortfolio = () => {
        this.props.handleOpenModal();
        this.setState({
            modalComponent: 'Create Portfolio',
        })
    }

    handleAddTransactions = () => {
        this.props.handleOpenModal();
        this.setState({
            modalComponent: 'Add Transactions',
        })
    }

    handleCreatePortfolios = (createPortfolio) => {
        this.props.handleCreatePortfolio(createPortfolio)
    }

    handleRemovePortfolio = () => {
        this.props.handleOpenModal();
        this.setState({
            modalComponent: 'Remove Entire Portfolio',
        })
    }


    onCellValueChanged = (event) => {
        this.setState({
            commission: event.data.commission,
            currency: event.data.currency && event.data.currency.label,
            num_shares: event.data.num_shares,
            order_date: event.data.order_date,
            security: event.data.security,
            security_price: event.data.security_price,
            tax: event.data.tax,
            tag1: event.data.tag1,
            margin_percentage: event.data.commission,
            exchange_fees: event.data.exchange_fees,
        }, () => {
        })
    }

    render(){
        const { portfoliolist, modalComponent, createPortfolio, toggle, transactionTypeValue, security, rowId } = this.state;
        const {
            openCloseModal,
            handleCloseModal,
            portfoliosName,
            transactionHistoryDetails,
            currencyList,
            holdingData,
            handlePortfolioCompositionDate,
            handlePortfolioEfficientFrontierDate,
            analysisEfficientFrontierDate,
            analysisCompositionDate,
            efficientFrontierData,
            showTableData,
            compositionData,
            priceTrendData,
            performanceData,
            attributionsData,
            maxDrawdownDetails,
            correlationData,
            headingData,
            navData,
            headingDay,
            handleOnChangeHeading,
            handleNavDay,
            volatilityData,
            liquidityData,
            fundamentalsData,
            backtestingData,
            handleOnChangeBacktesting,
            createPortfolioFlag,
            EditTransaction,
            ErrorTransactionMessage,
            userData
        } = this.props;

        return (
            <div className="inner-part">
                <ToastContainer />
                <Portfolio
                    component={
                        this.props.modalComponent === 'Create Portfolio' ?
                            <CreatePortfolioModal
                                createPortfolio={createPortfolio}
                                currencyarr={this.props.currencyList}
                                benchmarkarr={this.props.benchmarksList}
                                handleChangeCreatePortfolio={this.handleChangeCreatePortfolio}
                                handleChangeCheckBox={this.handleChangeCheckBox}
                                handleCreatePortfolios={this.handleCreatePortfolios}
                            /> :
                            this.props.modalComponent === 'Add Transactions' ?
                                <AddTransactionsModal /> :
                                this.props.modalComponent === 'Remove Entire Portfolio' ?
                                    <DeletePortfolioModal
                                        handleCloseModal={handleCloseModal}
                                        handleDeleteEntirePortfolio={this.props.handleDeleteEntirePortfolio}
                                        portfoliosName={portfoliosName}
                                    /> :
                                    this.props.modalComponent == "Edit Portfolio" ?
                                        <EditModal {...this?.props} /> :
                                        null
                    }
                    modalComponent={this.props.modalComponent}
                    handleCreateWatchlistPortfolio={this.handleCreateWatchlistPortfolio}
                    portfoliolist={portfoliolist}
                    name='Portfolio'
                    btnName='Create Portfolio'
                    openCloseModal={openCloseModal}
                    handleCloseModal={handleCloseModal}
                    handleAddTransactions={this.handleAddTransactions}
                    handleEditHolding={this.handleEditHolding}
                    handleDeleteHolding={this.handleDeleteHolding}
                    transactionHistoryDetails={transactionHistoryDetails}
                    onCellValueChanged={this.onCellValueChanged}
                    toggle={toggle}
                    rowId={rowId}
                    userData={userData}
                    handlePortfolioDropDownChange={this.props.handlePortfolioDropDownChange}
                    transactionTypeValue={transactionTypeValue}
                    security={security}
                    currencyList={currencyList}
                    portfoliosName={portfoliosName}
                    holdingData={holdingData}
                    handlePortfolioCompositionDate={handlePortfolioCompositionDate}
                    handlePortfolioEfficientFrontierDate={handlePortfolioEfficientFrontierDate}
                    analysisEfficientFrontierDate={analysisEfficientFrontierDate}
                    analysisCompositionDate={analysisCompositionDate}
                    efficientFrontierData={efficientFrontierData}
                    showTableData={this.props.showTableData}
                    compositionData={compositionData}
                    priceTrendData={priceTrendData}
                    performanceData={performanceData}
                    attributionsData={attributionsData}
                    maxDrawdownDetails={maxDrawdownDetails}
                    correlationData={correlationData}
                    headingDay={headingDay}
                    handleOnChangeHeading={handleOnChangeHeading}
                    headingData={headingData}
                    navData={navData}
                    handleNavDay={handleNavDay}
                    liquidityData={liquidityData}
                    volatilityData={volatilityData}
                    fundamentalsData={fundamentalsData}
                    backtestingData={backtestingData}
                    handleOnChangeBacktesting={handleOnChangeBacktesting}
                    handleRemovePortfolio={this.handleRemovePortfolio}
                    dropDownPortFolio={this.props.dropDownPortFolio}
                    createPortfolioFlag={createPortfolioFlag}
                    EditTransaction={EditTransaction}
                    ErrorTransactionMessage={ErrorTransactionMessage}
                    deleteTransactionMessage={this.props.deleteTransactionMessage}
                    deleteTransaction={this.props.deleteTransaction}
                    attributionsFlag={this.props.attributionsFlag}
                    transactionFlag={this.props.transactionFlag}
                    holdingFlag={this.props.holdingFlag}
                    compositionFlag={this.props.compositionFlag}
                    efficientFrontierFlag={this.props.efficientFrontierFlag}
                    priceTrendFlag={this.props.priceTrendFlag}
                    performanceFlag={this.props.performanceFlag}
                    headingFlag={this.props.headingFlag}
                    liquidityFlag={this.props.liquidityFlag}
                    volatilityFlag={this.props.volatilityFlag}
                    correlationFlag={this.props.correlationFlag}
                    maxDrawdownDetailsFlag={this.props.maxDrawdownDetailsFlag}
                    fundamenAddTransactionsModaltalsFlag={this.props.fundamentalsFlag}
                    backtestingFlag={this.props.backtestingFlag}
                    {...this.props}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    let transactions = [];
    if (state.getTransactionHistoryReducer.transactionHistoryDetails.data) {
        state.getTransactionHistoryReducer.transactionHistoryDetails.data.map((item, index) => {
            return transactions.push({
                ...item,
                "security_price": item['security_price'] === null ? '-' : item['security_price'] && (item['security_price']).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,' ) ,
                "num_shares": item['num_shares'] === null ? '-' : item['num_shares'] && (item['num_shares']).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ,
                // "num_shares": !item.num_shares ? '-' : item.num_shares?.toLocaleString + '%',
            })
        })
        transactions.push({
            order_date: ''
        })
    }

    let holding = [];
    if (state.holdingPortfolioReducer.holdingData && state.holdingPortfolioReducer.holdingData.data) {
        state.holdingPortfolioReducer.holdingData.data.map((item, i) => {
            return holding.push({
                ...item,
                "num_shares": item.num_shares === null ? '-' : item.num_shares && (item.num_shares).toLocaleString(),
                "price": item.price === null ? '-' : item.price && (item.price).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "percent" : item.percent === null ? '-' : item.percent && ((item.percent)*100).toFixed(2)+'%',
                "market_value": item.market_value === null ? '-' : item.market_value && (item.market_value),
                // "market_value": item.market_value === null ? '-' : item.market_value && (Math.round(item.market_value)).toLocaleString(),
                "returns": {
                    "1d": item.returns['1d'] === null ? '-' : item.returns['1d'] && (item.returns['1d'] * 100).toFixed(2) + '%',
                    "1w": item.returns['1w'] === null ? '-' : item.returns['1w'] && (item.returns['1w'] * 100).toFixed(2) + '%',
                    "1M": item.returns['1M'] === null ? '-' : item.returns['1M'] && (item.returns['1M'] * 100).toFixed(2) + '%',
                    "1Y": item.returns['1Y'] === null ? '-' : item.returns['1Y'] && (item.returns['1Y'] * 100).toFixed(2) + '%',
                    "2Y": item.returns['2Y'] === null ? '-' : item.returns['2Y'] && (item.returns['2Y'] * 100).toFixed(2) + '%',
                    "3M": item.returns['3M'] === null ? '-' : item.returns['3M'] && (item.returns['3M'] * 100).toFixed(2) + '%',
                    "5Y": item.returns['5Y'] === null ? '-' : item.returns['5Y'] && (item.returns['5Y'] * 100).toFixed(2) + '%',
                    "YTD": item.returns['YTD'] === null ? '-' : item.returns['YTD'] && (item.returns['YTD'] * 100).toFixed(2) + '%',
                }
            })
        })
        // holding.push({})
    }

    let performance = [];
    if (state.performancePortfolioReducer.performanceData) {
        state.performancePortfolioReducer.performanceData.map((item, i) => {
            return performance.push({
                ...item,
                'percent': item.percent === null ? '-' : item.percent && (item.percent * 100).toFixed(2) + "%",
                "price": item.price === null ? '-' : item.price && (item.price).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                'market_value': item.market_value === null ? '-' : item.market_value && ((item.market_value).toFixed(2)).toLocaleString(),
                "returns": {
                    "1d": item.returns['1d'] === null ? '-' : item.returns['1d'] && (item.returns['1d'] * 100).toFixed(2) + '%',
                    "1w": item.returns['1w'] === null ? '-' : item.returns['1w'] && (item.returns['1w'] * 100).toFixed(2) + '%',
                    "2Y": item.returns['2Y'] === null ? '-' : item.returns['2Y'] && (item.returns['2Y'] * 100).toFixed(2) + '%',
                    "3M": item.returns['3M'] === null ? '-' : item.returns['3M'] && (item.returns['3M'] * 100).toFixed(2) + '%',
                    "5Y": item.returns['5Y'] === null ? '-' : item.returns['5Y'] && (item.returns['5Y'] * 100).toFixed(2) + '%',
                    "YTD": item.returns['YTD'] === null ? '-' : item.returns['YTD'] && (item.returns['YTD'] * 100).toFixed(2) + '%',
                }
            })
        })
        // performance.push({})
    }

    let headingTableData = [];
    if (state.headingPortfolioReducer.headingData && state.headingPortfolioReducer.headingData.data) {
        state.headingPortfolioReducer.headingData && state.headingPortfolioReducer.headingData.data.map((item, index) => {
            return headingTableData.push({
                ...item,
                'attribution': {
                    'absolute_returns': item.attribution.absolute_returns === null ? '-' : item.attribution.absolute_returns && (item.attribution.absolute_returns.toFixed(2)),
                    'absolute_returns_table': item.attribution.absolute_returns === null ? '-' : item.attribution.absolute_returns && (item.attribution.absolute_returns.toFixed(2)).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,' ),
                    'percentage_returns': item.attribution.percentage_returns === null ? '-' : item.attribution.percentage_returns && ((item.attribution['percentage_returns'] * 100).toFixed(2)),
                    'percentage_returns_table': item.attribution.percentage_returns === null ? '-' : item.attribution.percentage_returns && ((item.attribution['percentage_returns'] * 100).toFixed(2))+'%'
                }
            })
        })
        // headingTableData.push({})
    }

    let liquidity = [];
    if (state?.liquidityPortfolioReducer?.liquidityData) {
       
        if(Array.isArray(state?.liquidityPortfolioReducer?.liquidityData)){
        state?.liquidityPortfolioReducer?.liquidityData?.map((item, index) => {
            return liquidity.push({
                ...item,
                'bar_percentage': item.bar_percentage === null ? '-' : item.bar_percentage && (item.bar_percentage * 100).toFixed(2),
                'liquidity': item.liquidity === null ? '-' : item.liquidity && (item.liquidity).toFixed(1),
            })
        })
    }
    }

    let volatility = [];
    if (state.volatilityPortfolioReducer.volatilityData) {
        state.volatilityPortfolioReducer.volatilityData.map((item, index) => {
            return volatility.push({
                ...item,
                'bar_percentage': item.bar_percentage === null ? '-' : item.bar_percentage && (item.bar_percentage * 100).toFixed(2),
                'volatility': item.volatility === null ? '-' : item.volatility && (item.volatility).toFixed(1),
            })
        })
    }

    let attribution = [];
    if (state.attributionsReducer.attributionsData && state.attributionsReducer.attributionsData.asset_absolute_returns && state.attributionsReducer.attributionsData.asset_absolute_returns.benchmark_returns) {
        state.attributionsReducer.attributionsData.asset_absolute_returns.benchmark_returns.map((item, index) => {
            return attribution.push({
                ...item,
                '1M': item['1M'] === null ? '-' : item['1M'] && (item['1M'] * 100).toFixed(2) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 100).toFixed(2) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 100).toFixed(2) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 100).toFixed(2) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 100).toFixed(2) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 100).toFixed(2) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 100).toFixed(2) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 100).toFixed(2) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 100).toFixed(2) + '%',
            })
        })
    }

    let maxDrawDown = [];
    let max_drawdown_Object = { '1d': '-', '1m': '-', '1w': '-', '3m': '-', '6m': '-' }
    if (state.maxDrawdownReducer.maxDrawdownDetails) {
        state.maxDrawdownReducer.maxDrawdownDetails.map((item, index) => {
            return maxDrawDown.push({
                ...item,
                "max_drawdown":
                    state.maxDrawdownReducer.maxDrawdownDetails && state.maxDrawdownReducer.maxDrawdownDetails[index].max_drawdown === null
                        ?
                        {
                            ...max_drawdown_Object
                        }
                        :
                        {
                            '1d': item.max_drawdown['1d'] === null ? '-' : item.max_drawdown['1d'] && (item.max_drawdown['1d']).toFixed(1) + '%',
                            '1m': item.max_drawdown['1m'] === null ? '-' : item.max_drawdown['1m'] && (item.max_drawdown['1m']).toFixed(1) + '%',
                            '1w': item.max_drawdown['1w'] === null ? '-' : item.max_drawdown['1w'] && (item.max_drawdown['1w']).toFixed(1) + '%',
                            '3m': item.max_drawdown['3m'] === null ? '-' : item.max_drawdown['3m'] && (item.max_drawdown['3m']).toFixed(1) + '%',
                            '6m': item.max_drawdown['6m'] === null ? '-' : item.max_drawdown['6m'] && (item.max_drawdown['6m']).toFixed(1) + '%',
                        }
            })
        })
    }

    let fundamental = [];
    if (state.fundametalsReducer.fundamentalsData?.length !== 0) {
        state.fundametalsReducer.fundamentalsData.map((item, index) => {
            return fundamental.push({
                ...item,
                'percent': item['percent'] === null ? '-' : item['percent'] && (item['percent'] * 100).toFixed(2),
                "EVEbidta": item['EVEbidta'] === null ? '-' : item['EVEbidta'] && (item['EVEbidta']).toFixed(2),
                "EVRevenue": item['EVRevenue'] === null ? '-' : item['EVRevenue'] && (item['EVRevenue']).toFixed(2),
                "P/S": item['P/S'] === null ? '-' : item['P/S'] && (item['P/S']).toFixed(2),
                "PB": item['PB'] === null ? '-' : item['PB'] && (item['PB']).toFixed(2),
                "PE": item['PE'] === null ? '-' : item['PE'] && (item['PE']).toFixed(2),
                "PE1": item['PE1'] === null ? '-' : item['PE1'] && (item['PE1']).toFixed(2),
                "market_cap_usd": item['market_cap_usd'] === null ? '-' : item['market_cap_usd'] && (item['market_cap_usd']/1000000).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ,
            })
        })
    }
    else {
        fundamental = [];
    }
    
    let efficientFrontier = [];
    if (state.efficientFrontierPortfolioReducer.efficientFrontierData) {
        state.efficientFrontierPortfolioReducer.efficientFrontierData.map((item, index) => {
            return efficientFrontier.push({
                ...item,
                'efficient_frontier_weight': item.efficient_frontier_weight === null ? '-' : item.efficient_frontier_weight && ((item.efficient_frontier_weight) * 100).toFixed(1) + '%',
                'portfolio_weight': item.portfolio_weight === null ? '-' : item.portfolio_weight && ((item.portfolio_weight) * 100).toFixed(1) + '%',
            })
        })
    }

    let priceTrend = [];
    if (state.priceTrendPortfolioReducer.priceTrendData) {
        let new_array = state.priceTrendPortfolioReducer.priceTrendData.map((value) => {
            if (value != null) return value; else return {
                "52_week_high": '',
                "52_week_high_dist": '',
                "52_week_low": '',
                "52_week_low_dist": '',             
                adjusted_close: '',
                adjusted_close_ema_10: '',
                adjusted_close_ema_12: '',
                adjusted_close_ema_20: '',
                adjusted_close_ema_26: '',
                adjusted_close_ema_50: '',
                adjusted_close_ema_100: '',
                adjusted_close_ema_150: '',
                adjusted_close_ema_200: '',
                adjusted_close_sma_10: '',
                adjusted_close_sma_12: '',
                adjusted_close_sma_20: '',
                adjusted_close_sma_26: '',
                adjusted_close_sma_50: '',
                adjusted_close_sma_100: '',
                adjusted_close_sma_150: '',
                adjusted_close_sma_200: '',
                close: '',
                date: '',
                eod_ticker: '',
                macd_adjusted_close: '',
                macd_histogram_adjusted_close: '',
                macd_signal_adjusted_close: '',
                object_id: '',
                rsi: '',
                volume_sma_7: '',
                volume_sma_30: '',
                volume_sma_90: '',
                volume_sma_180: '',
                volume_yesterday: '',
            }
        })
        new_array.map((item, index) => {
            return priceTrend.push({
                ...item,
                "52_week_high": item['52_week_high'] === null ? '-' : Number((item['52_week_high']) && (item['52_week_high']).toPrecision(3)).toLocaleString() ,
                "52_week_high_dist": item['52_week_high_dist'] === null ? '-' : (item['52_week_high_dist']) && (item['52_week_high_dist']).toFixed(2),
                "52_week_low": item['52_week_low'] === null ? '-' : Number((item['52_week_low']) && (item['52_week_low']).toPrecision(3)).toLocaleString() ,
                "52_week_low_dist": item['52_week_low_dist'] === null ? '-' : (item['52_week_low_dist']) && (item['52_week_low_dist']).toFixed(2),
                "adjusted_close": item['adjusted_close'] === null ? '-' : (item['adjusted_close']) && (item['adjusted_close']).toFixed(2),
                "adjusted_close_ema_10": item['adjusted_close_ema_10'] === null ? '-' : Number((item['adjusted_close_ema_10']) && (item['adjusted_close_ema_10']).toPrecision(3)).toFixed(2),
                "adjusted_close_ema_100": item['adjusted_close_ema_100'] === null ? '-' : Number((item['adjusted_close_ema_100']) && (item['adjusted_close_ema_100']).toPrecision(3)).toFixed(2),
                "adjusted_close_ema_12": item['adjusted_close_ema_12'] === null ? '-' : Number((item['adjusted_close_ema_12']) && (item['adjusted_close_ema_12']).toPrecision(3)).toFixed(2),
                "adjusted_close_ema_150": item['adjusted_close_ema_150'] === null ? '-' : Number((item['adjusted_close_ema_150']) && (item['adjusted_close_ema_150']).toPrecision(3)),
                "adjusted_close_ema_20": item['adjusted_close_ema_20'] === null ? '-' : Number((item['adjusted_close_ema_20']) && (item['adjusted_close_ema_20']).toPrecision(3)).toFixed(2),
                "adjusted_close_ema_200": item['adjusted_close_ema_200'] === null ? '-' : Number((item['adjusted_close_ema_200']) && (item['adjusted_close_ema_200']).toPrecision(3)),
                "adjusted_close_ema_26": item['adjusted_close_ema_26'] === null ? '-' : Number((item['adjusted_close_ema_26']) && (item['adjusted_close_ema_26']).toPrecision(3)).toFixed(2),
                "adjusted_close_ema_50": item['adjusted_close_ema_50'] === null ? '-' : Number((item['adjusted_close_ema_50']) && (item['adjusted_close_ema_50']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_10": item['adjusted_close_sma_10'] === null ? '-' : Number((item['adjusted_close_sma_10']) && (item['adjusted_close_sma_10']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_100": item['adjusted_close_sma_100'] === null ? '-' : Number((item['adjusted_close_sma_100']) && (item['adjusted_close_sma_100']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_12": item['adjusted_close_sma_12'] === null ? '-' : Number((item['adjusted_close_sma_12']) && (item['adjusted_close_sma_12']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_150": item['adjusted_close_sma_150'] === null ? '-' : Number((item['adjusted_close_sma_150']) && (item['adjusted_close_sma_150']).toPrecision(3)),
                "adjusted_close_sma_20": item['adjusted_close_sma_20'] === null ? '-' : Number((item['adjusted_close_sma_20']) && (item['adjusted_close_sma_20']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_200": item['adjusted_close_sma_200'] === null ? '-' : Number((item['adjusted_close_sma_200']) && (item['adjusted_close_sma_200']).toPrecision(3)),
                "adjusted_close_sma_26": item['adjusted_close_sma_26'] === null ? '-' : Number((item['adjusted_close_sma_26']) && (item['adjusted_close_sma_26']).toPrecision(3)).toFixed(2),
                "adjusted_close_sma_50": item['adjusted_close_sma_50'] === null ? '-' : Number((item['adjusted_close_sma_50']) && (item['adjusted_close_sma_50']).toPrecision(3)).toFixed(2),
                "close": item['close'] === null ? '-' : (item['close']) && (item['close']).toFixed(2),
                "macd_adjusted_close": item['macd_adjusted_close'] === null ? '-' : (item['macd_adjusted_close']) && (item['macd_adjusted_close']).toFixed(2),
                "macd_histogram_adjusted_close": item['macd_histogram_adjusted_close'] === null ? '-' : (item['macd_histogram_adjusted_close']) && (item['macd_histogram_adjusted_close']).toFixed(2),
                "macd_signal_adjusted_close": item['macd_signal_adjusted_close'] === null ? '-' : (item['macd_signal_adjusted_close']) && (item['macd_signal_adjusted_close']).toFixed(2),
                "rsi": item['rsi'] === null ? '-' : (item['rsi']) && (item['rsi']).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') ,
                "volume_sma_180": item['volume_sma_180'] === null ? '-' : (item['volume_sma_180']) && (item['volume_sma_180']),
                "volume_sma_30": item['volume_sma_30'] === null ? '-' : (item['volume_sma_30']) && (item['volume_sma_30']).toFixed(2),
                "volume_sma_7": item['volume_sma_7'] === null ? '-' : (item['volume_sma_7']) && (item['volume_sma_7']).toFixed(2),
                "volume_sma_90": item['volume_sma_90'] === null ? '-' : (item['volume_sma_90']) && (item['volume_sma_90']).toFixed(2),
                "volume_yesterday": item['volume_yesterday'] === null ? '-' : (item['volume_yesterday']) && (item['volume_yesterday']).toFixed(2),
            })
        })
    }

    let backtest = [];
    if (state.backtestingPortfolioReducer.backtestingData) {
        state.backtestingPortfolioReducer.backtestingData?.map((item, index) => {
            return backtest?.push({
                ...item,
                "alpha": item["alpha"] === null ? '-' : item['alpha'] && item['alpha'],
                // "backtest_days": item['backtest_days']===null ? '-' : (item['backtest_days']) && (item['backtest_days'])?.toFixed(2),
                "beta": item['beta'] === null ? '-' : (item['beta']) && (item['beta']).toFixed(2),
                "burke_ratio": item['burke_ratio'] === null ? '-' : (item['burke_ratio']) && (item['burke_ratio']).toFixed(2),
                "calmar_ratio": item['calmar_ratio'] === null ? '-' : (item['calmar_ratio']) && (item['calmar_ratio']).toFixed(2),
                "information_ratio": item['information_ratio'] === null ? '-' : (item['information_ratio']) && (item['information_ratio']).toFixed(2),
                "kurtosis": item['kurtosis'] === null ? '-' : (item['kurtosis']) && (item['kurtosis']).toFixed(2),
                "max_drawdown": item['max_drawdown'] === null ? '-' : (item['max_drawdown']) && (item['max_drawdown']).toFixed(2),
                "omega_ratio": item['omega_ratio'] === null ? '-' : (item['omega_ratio']) && (item['omega_ratio']).toFixed(2),
                "sharpe_ratio": item['sharpe_ratio'] === null ? '-' : (item['sharpe_ratio']) && (item['sharpe_ratio']).toFixed(2),
                "sortino_ratio": item['sortino_ratio'] === null ? '-' : (item['sortino_ratio']) && (item['sortino_ratio']).toFixed(2),
                "sterling_ratio": item['sterling_ratio'] === null ? '-' : (item['sterling_ratio']) && (item['sterling_ratio']).toFixed(2),
                "treynor_performance_index": item['treynor_performance_index'] === null ? '-' : (item['treynor_performance_index']) && (item['treynor_performance_index']).toFixed(2),
                "ulcer_index": item['ulcer_index'] === null ? '-' : (item['ulcer_index']) && (item['ulcer_index']).toFixed(2),
            })
        })
    }

    return {
        showTableData: state.showTableReducer.showTableData,
        openCloseModal: state.modalReducer.openCloseModal,
        userData: state.getUserDetailsReducer.userData,
        transactionHistoryDetails: transactions,
        transactionFlag: state.getTransactionHistoryReducer.transactionFlag,
        holdingData: holding,
        holdingFlag: state.holdingPortfolioReducer.holdingFlag,
        compositionData: state.compositionPortfolioReducer.compositionData,
        compositionFlag: state.compositionPortfolioReducer.compositionFlag,
        efficientFrontierData: efficientFrontier,
        efficientFrontierFlag: state.efficientFrontierPortfolioReducer.efficientFrontierFlag,
        priceTrendData: priceTrend,
        priceTrendFlag: state.priceTrendPortfolioReducer.priceTrendFlag,
        performanceData: performance,
        performanceFlag: state.performancePortfolioReducer.performanceFlag,
        attributionsData: attribution,
        attributionsFlag: state.attributionsReducer.attributionsFlag,
        maxDrawdownDetails: maxDrawDown,
        maxDrawdownDetailsFlag: state.maxDrawdownReducer.maxDrawdownDetailsFlag,
        correlationData: state.correlationPortfolioReducer.correlationData,
        correlationFlag: state.correlationPortfolioReducer.correlationFlag,
        headingData: headingTableData,
        headingFlag: state.headingPortfolioReducer.headingFlag,
        navData: state.navPortfolioReducer.navData,
        liquidityData: liquidity,
        liquidityFlag: state.liquidityPortfolioReducer.liquidityFlag,
        volatilityData: volatility,
        volatilityFlag: state.volatilityPortfolioReducer.volatilityFlag,
        fundamentalsData: fundamental,
        fundamentalsFlag: state.fundametalsReducer.fundamentalsFlag,
        backtestingData: backtest,
        backtestingFlag: state.backtestingPortfolioReducer.backtestingFlag,
        createPortfolioFlag: state.createPortfolioReducer.createPortfolioFlag,
        EditTransaction: state.performEditTransactionReducer.EditTransaction,
        ErrorTransactionMessage:state.performEditTransactionReducer.ErrorTransactionMessage,
        deleteTransactionMessage: state.deleteSecurityTransactionHistoryReducer.deleteTransactionMessage,
        deleteTransaction: state.deleteSecurityTransactionHistoryReducer.deleteTransaction,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleShowTable: (showTable,token) =>dispatch(handleShowTableAction(showTable,token)),
        handleOpenModal: () => dispatch(handleOpenModalAction()),
        handleCloseModal: () => dispatch(handleCloseModalAction()),
        handleContainerValue: (id, name) => dispatch(handleContainerValueAction(id, name)),
        handleCreatePortfolio: (createPortfolio) => dispatch(handleCreatePortfolioAction(createPortfolio)),
        handleGetTransactionHistory: (dropDownPortFolio, token) => dispatch(handleGetTransactionHistoryAction(dropDownPortFolio, token)),
        handleDeleteSecurityTransactionHistory: (transaction_id, portfolio_id, token) => dispatch(handleDeleteSecurityTransactionHistoryAction(transaction_id, portfolio_id, token)),
        hanndleHoldingPortfolio: (dropDownPortFolio, token) => dispatch(hanndleHoldingPortfolioAction(dropDownPortFolio, token)),
        hanndleCompositionPortfolio: (dropDownPortFolio, analysisDate, token) => dispatch(hanndleCompositionPortfolioAction(dropDownPortFolio, analysisDate, token)),
        hanndleEfficientFrontierPortfolio: (dropDownPortFolio, analysisDate, token) => dispatch(hanndleEfficientFrontierPortfolioAction(dropDownPortFolio, analysisDate, token)),
        handlePriceTrend: (dropDownPortFolio, token) => dispatch(handlePriceTrendAction(dropDownPortFolio, token)),
        handlePerformance: (dropDownPortFolio, token) => dispatch(handlePerformanceAction(dropDownPortFolio, token)),
        handleAttributions: (dropDownPortFolio, token) => dispatch(handleAttributionsAction(dropDownPortFolio, token)),
        handleMaxDrawdown: (dropDownPortFolio, token) => dispatch(handleMaxDrawdownAction(dropDownPortFolio, token)),
        handleCorrelation: (dropDownPortFolio, token) => dispatch(handleCorrelationAction(dropDownPortFolio, token)),
        handleHeading: (dropDownPortFolio, headingDay, token) => dispatch(handleHeadingAction(dropDownPortFolio, headingDay, token)),
        handleNAV: (dropDownPortFolio, navDay, token) => dispatch(handleNAVPortfolioAction(dropDownPortFolio, navDay, token)),
        handleLiquidityPortfolio: (dropDownPortFolio, token) => dispatch(handleLiquidityPortfolioAction(dropDownPortFolio, token)),
        handleVolatilityPortfolio: (dropDownPortFolio, token) => dispatch(handleVolatilityPortfolioAction(dropDownPortFolio, token)),
        hanndleFundamentalsPortfolio: (dropDownPortFolio, token) => dispatch(hanndleFundamentalsPortfolioAction(dropDownPortFolio, token)),
        handlebacktestingPortfolio: (dropDownPortFolio, token) => dispatch(handlebacktestingPortfolioAction(dropDownPortFolio, token)),
        handleShowTable: (showTable, token) => dispatch(handleShowTableAction(showTable,token)),
        handleRemoveEntirePortfolio: (dropDownPortFolio, token) => dispatch(handleRemoveEntirePortfolioAction(dropDownPortFolio, token)),
    }
};

PortfolioContainer.propTypes = {
    openCloseModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PortfolioContainer);