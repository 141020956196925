import {
    REQUEST_REMOVE_WATCHLIST,
    SUCCESS_REMOVE_WATCHLIST,
    FAILURE_REMOVE_WATCHLIST,
} from "../../Constant/actionTypes";

const initilizeState = {
    removeWatchlistMessage:'',
    removeWatchlistFlag: false,
}

export default function removeWatchlistReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_REMOVE_WATCHLIST:
            return{
                ...state,
                removeWatchlistFlag: false
            }
        case SUCCESS_REMOVE_WATCHLIST:
            return {
                ...state,
                removeWatchlistMessage:action.removeWatchlistMessage,
                removeWatchlistFlag: true
            }
        case FAILURE_REMOVE_WATCHLIST:
            return {
                ...state,
                removeWatchlistFlag: false,
            }
        default: 
            return state;
    }
}