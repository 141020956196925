import React, { Component } from 'react';
import PLAID from "../Page/plaid";
import { connect } from "react-redux";
import { handleGetPlaidLinkInitiateAction } from "../Redux/actions/plaidAction";

class PlaidIntegrationContainer extends Component {
    state = {
        portfolio: []
    }
    onSelect = (value) => {
        this.setState({ portfolio: value })
    }
    onRemove = (value) => {
        this.setState({ portfolio: value })
    }

    componentDidMount() {
        this.props.handleGetPlaidLinkInitiate(localStorage.getItem('token'))
    }



    render() {
        const { plaidLink } = this.props
        return (
            <div>
                {
                    plaidLink && plaidLink.length &&
                    <PLAID plaidLink={plaidLink}
                        onSelect={this.onSelect}
                        onRemove={this.onRemove}
                        portfolio={this.state.portfolio} />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        plaidLink: state.getPlaidLinkInitiateReducer.plaidLink
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetPlaidLinkInitiate: (token) => dispatch(handleGetPlaidLinkInitiateAction(token)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaidIntegrationContainer);