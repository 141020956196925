import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as LogoTagline } from "../../Assets/Images/logoTagline.svg";
import { Link, useHistory } from 'react-router-dom';
import "../Login/login.scss";
import { handleSignUpAction } from "../../Redux/actions/signUpAction";


const SignUp = (props) => {

    let history = useHistory();

    const dispatch = useDispatch();
    const [registerUser, setRegisterUser] = useState({ email: '', password: '', name: '', emailError: '', passwordError: '' });
   
    const [AgreeTerms , setAgreeTerms] = useState(false)
    const userDetails = useSelector(state => {
        return state.signUpReducer.signUpUserDetails
    }
    );
    const loading = useSelector(state => state.signUpReducer.loading)
    const errorMessage = useSelector(state => state.signUpReducer.errorMessage);
    const [errorEmail, setErrorEmail] = useState('');

    const handleChange = (event) => {
        setRegisterUser((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }))

       
    }
 
    const handleSignUpSubmit = async (event) => {
        event.preventDefault();
        if (registerUser.email.includes('@')) {
            await dispatch(handleSignUpAction(registerUser))
            setErrorEmail('')
        }
        else {
            setErrorEmail('Invalid Email')
        }
    }
    useEffect(() => {
        if (loading == false) {
            history.push('/')
        }
        if (errorMessage) {
            setErrorEmail(errorMessage)
        }

    }, [errorMessage, loading])

    const handleCheckbox=()=>{
   setAgreeTerms(!AgreeTerms)
    }


    return (
        <div className="login-container">

            <div className="SignUp reg-form">
                <LogoTagline className="reg-logo" width="150" height="120" />
                <div className="reg-tab">
                    <Link to='/' className="tab-btn">Log In</Link>
                    <span className="tab-btn active">Sign Up</span>
                </div>
                <form className="form" onSubmit={(e) => handleSignUpSubmit(e)}>
                    <fieldset className="fieldset">
                        <label className="label">Email address</label>
                        <input className="input" type="text" name="email" value={registerUser.email} onChange={handleChange} placeholder="e.g. example@google.com" required />
                        {errorEmail === 'Invalid' && errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
                        <span style={{ color: 'red' }}>{errorEmail}</span>
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">Password</label>
                        <input className="input" type="password" name="password" value={registerUser.password} onChange={handleChange} placeholder="••••••••••" required />
                        <div style={{ color: 'red' }}>{registerUser.passwordError}</div>
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">Name</label>
                        <input className="input" type="text" name="name"  onChange={handleChange} value={registerUser.name} placeholder="e.g. Example" required />
                    </fieldset>
                    <div style={{marginBottom:"10px"}}>

                    <input type="checkbox"   onClick={handleCheckbox} /> 


                    <span style={{marginLeft:"10px"}} className="bottom-text">  
                    I agree to <Link to='/terms' style={{color: 'white'}}> Terms and Conditions </Link>
                    </span>
                    </div>
                    <button disabled ={AgreeTerms==true ? false : true} className="reg-btn" type="submit">Sign Up</button>
                </form>

                <div className="reg-bottom bottom-text">
                    <span>By using Stoxdoc you are agreeing to our </span>
                    <Link to='/terms' className="text-link">terms and conditions.</Link>
                    <span>Stoxdoc provides general investment advice only.</span>
                </div>
            </div>
        </div>
    )
}

export default SignUp;