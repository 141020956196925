import {
    REQUEST_SOURCE_LINK,
    SUCCESS_SOURCE_LINK,
    FAILURE_SOURCE_LINK,
    REQUEST_PORTFOLIO_SOURCE_LINK,
    SUCCESS_PORTFOLIO_SOURCE_LINK,
    FAILURE_PORTFOLIO_SOURCE_LINK,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const requestSourceLink = () => {
    return {
        type: REQUEST_SOURCE_LINK,
    }
}

export const successSourceLink = (result) => {
    return {
        type: SUCCESS_SOURCE_LINK,
        SourceLinkDetails: result,
    }
}

export const failureSourceLink = () => {
    return {
        type: FAILURE_SOURCE_LINK,
    }
}

// add
export const requestPortfolioSourceLink = () => {
    return {
        type: REQUEST_PORTFOLIO_SOURCE_LINK,
    }
}

export const successPortfolioSourceLink = (result) => {
    return {
        type: SUCCESS_PORTFOLIO_SOURCE_LINK,
    }
}

export const failurePortfolioSourceLink = () => {
    return {
        type: FAILURE_PORTFOLIO_SOURCE_LINK,
    }
}

export const handleSourceLinkAction = () => async (dispatch) => {
    dispatch(requestSourceLink())
    try {
        const result = await Api.sourceLinkApi(localStorage.getItem('token'))
        dispatch(successSourceLink(result.data))
    }
    catch (error) {
        if (error?.response?.data) {
            for (let err in error?.response?.data) {
                toast.warning(error?.response?.data[err])
            }
        }
    }
}
export const handlePortfolioSourceLinkAction = (data) => async (dispatch) => {
    dispatch(requestPortfolioSourceLink())
    try {
        const result = await Api.portfolioSourceLinkApi(localStorage.getItem('token'), data)
        if (result?.data) {
            toast.success('Added successfully !')
            dispatch(successPortfolioSourceLink(result.data))
        }
    }
    catch (error) {
        if (error?.response?.data) {
            toast.warning(error?.response?.data)
        }
    }
}