import {
    REQUEST_DEPOSIT_TRANSACTION,
    SUCCESS_DEPOSIT_TRANSACTION,
    FAILURE_DEPOSIT_TRANSACTION,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function performDepositTransactionReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_DEPOSIT_TRANSACTION:
            return{
                ...state,
            }
        case SUCCESS_DEPOSIT_TRANSACTION:
            return {
                ...state,
            }
        case FAILURE_DEPOSIT_TRANSACTION:
            return {
                ...state,
            }
        default: 
            return state;
    }
}