import {
    REQUEST_CANCEL_PLAN,
    SUCCESS_CANCEL_PLAN,
    FAILURE_CANCEL_PLAN,
} from "../../Constant/actionTypes";
import { handleGetUserDetailsAction } from '../actions/getUserDetailsAction'
import Api from "../Api/apiCalls";

export const requestCancelPlan = () => {
    return {
        type: REQUEST_CANCEL_PLAN
    }
}

export const successCancelPlan = (result) => {
    return {
        type: SUCCESS_CANCEL_PLAN,
        cancelPlanMessage: result
    }
}

export const failureCancelPlan = () => {
    return {
        type: FAILURE_CANCEL_PLAN
    }
}

export const handleCancelPlanAction = (token) => {
    return function (dispatch) {
        dispatch(requestCancelPlan())
        Api.cancelPlanApi(token).then(result => {
            dispatch(successCancelPlan(result))
            dispatch(handleGetUserDetailsAction(token))
        }).catch(error => {
            dispatch(failureCancelPlan(error))
        })
    }
}