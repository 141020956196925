import {
    REQUEST_GET_USER_DETAILS,
    SUCCESS_GET_USER_DETAILS,
    FAILURE_GET_USER_DETAILS,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestUserDetails = () => {
    return {
        type: REQUEST_GET_USER_DETAILS,
    }
}

export const successUserDetails = (userData) => {
    return {
        type: SUCCESS_GET_USER_DETAILS,
        userData: userData
    }
}

export const failureUserDetails = () => {
    return {
        type: FAILURE_GET_USER_DETAILS,
    }
}

export const handleGetUserDetailsAction = (accessToken) => {
    return function (dispatch) {
        dispatch(requestUserDetails())
        Api.GetUserDetailsApi(accessToken).then(result => {
            dispatch(successUserDetails(result.data))
        }).catch(error => {
            dispatch(failureUserDetails(error))
        })
    }
}