import React, { Component } from "react";
import NameRouteList from "../Components/NameRouteList/NameRouteList";
import WatchlistTable from "../Components/watchlistTable/Watchlisttable";
import Modal from "../Components/Modal/modal";

class WatchList extends Component{
    render(){
        return(
            <div className="stoxdoc-innerpart">
                <Modal {...this.props}/>
                <NameRouteList {...this.props}/>
                <WatchlistTable {...this.props}/>
            </div>
        )
    }
}
export default WatchList;