import React, { Component } from "react";
import "./CreateNewWatchlist.scss";
import PropTypes from 'prop-types';

class CreateNewWatchlist extends Component{

    render(){
        const {handleCreateWatchList, watchListName, handleChangeCreateWatchList} = this.props;
        return(
            <div className="create-new-watchlist-container">
                {
                    <>
                    <input type="text" value={watchListName} onChange={handleChangeCreateWatchList} className="create-new-watchlist-input" placeholder='Name'></input>
                    <div className="create-new-watchlist-btn">
                        <button onClick={()=>handleCreateWatchList(watchListName)}>Create</button>
                    </div>
                    </>
                }
            </div>
        )
    }
}

CreateNewWatchlist.propTypes = {
    handleCreateWatchList: PropTypes.func,
    watchlistLoading: PropTypes.bool,
  };

export default CreateNewWatchlist;