import "./plaid.scss";
import React, { useState, useCallback, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import Multiselect from 'multiselect-react-dropdown';
import axios from "axios";
import ToastBar from "../Components/ToastBar/toastBar";
import { API_BASE_URL } from "../Constant/apiConstant";
import { useSelector } from "react-redux";

const sendTokenURL = `${API_BASE_URL}/user/source/link/plaid/link/set_token`;

const PLAID = (props) => {
  const [data, setData] = useState()
  const [toastOpen, setToastOpening] = useState(false)
  const [toastState, setToastState] = useState('')
  const [toastType, setToastType] = useState('')

  useEffect(() => {
    if (props.plaidLink) { setData(props.plaidLink) }
  })
  useEffect(() => {
    if (props.portfolio) {
      localStorage.setItem('portfolio', JSON.stringify(props?.portfolio?.map((item) => { return item?.name; })))
    }
  })

  const state = useSelector(state => state?.getUserDetailsReducer.userData)
  const ans = state?.portfolios;
    var splitData = [];
    for(let i=0;i<ans.length;i++){
        const arraySplit = ans[i].split('.com_')
        splitData.push(arraySplit[1])
        
    }
    if (ans) {
        var newarr = [];
        for (let i of splitData) {
            newarr.push({ name: i });
        }
    }


  const onSuccess = useCallback(async (token, metadata) => {
    var retrievedData = localStorage.getItem("portfolio");
    var ids = JSON.parse(retrievedData);
    // send token to server 5d79b7c81923d1dcd8162a168a2c08
    const config = {
      method: "post",
      url: sendTokenURL,
      data: {
        public_token: token,
        portfolio_ids: ids
      },
      headers: {
        "content-type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem('token')}`,
      },
    };
    try {
      const response = await axios(config);
      if (response) {
        localStorage.removeItem('portfolio')
        setToastOpening(true)
        setToastState("Token set successfully for user")
        setToastType('success')
      }
    } catch (error) {
      if (error?.response?.data) {
        setToastOpening(true)
        setToastState(error?.response?.data)
        setToastType('error')
      }
    }

    // else {
    //   setToastOpening(true)
    //   setToastState('Select portfolio')
    //   setToastType('error')
    // }
  }, []);

  const config = {
    token: data,
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  if (err) return <p>Error!</p>;

  const setToastOpen = () => {
    setToastOpening(false)
  }

  return (
    <>
      <ToastBar
        vailed={toastOpen}
        setToastOpen={setToastOpen}
        toastState={toastState}
        type={toastType}
      />
      <div className="plaid-container" style={{ marginBottom: 170 }}>
        {newarr?.length != 0 ? <Multiselect
          selectedValues={props?.portfolio}
          options={newarr} // Options to display in the dropdown
          onSelect={(e) => props?.onSelect(e)}
          onRemove={(e) => props?.onRemove(e)} // Function will trigger on select event
          displayValue="name" // Property name to display in the dropdown options
          placeholder='Select Portfolio'
        // style={{ width: 100 }}
        /> : null}
      </div>
      <div className="container">
        <div className="vertical-center">
          <button className="plaid-btn" onClick={() => open()} disabled={!ready}>
            Connect to a non IBKR brokerage account
          </button>
        </div>
      </div>

    </>
  );
}

export default PLAID;
