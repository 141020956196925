import {
    REQUEST_UPDATE_PASSWORD,
    SUCCESS_UPDATE_PASSWORD,
    FAILURE_UPDATE_PASSWORD,
} from "../../Constant/actionTypes";

const initilizeState = {
    updatePasswordMessage: ''
}

export default function updatePasswordReducer (state=initilizeState,action){
    switch(action.type){
        case REQUEST_UPDATE_PASSWORD:
            return{
                ...state
            }
        case SUCCESS_UPDATE_PASSWORD:
            return{
                ...state,
                updatePasswordMessage:action.updatePasswordMessage
            }
        case FAILURE_UPDATE_PASSWORD:
            return{
                ...state
            }
        default:
            return state;
    }
}