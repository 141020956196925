import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./LiquidityVolatility.scss";
import StatusProgressBar from "../StatusBar/index";
import PropTypes from 'prop-types';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class LiquidityVolatilityTable extends Component{
    constructor(props){
        super(props);
        this.state = {
            height:100,
            // volatilityheight: 100
        }
    }
    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    componentDidUpdate(preProps){
        if(preProps.liquidityData!=this.props.liquidityData){
           if(this.props.liquidityData && this.props.liquidityData.length<7){
               this.setState({ 
                    height:this.props.liquidityData.length*47+100
                })  
            } 
             else{ 
               this.setState({ 
                    height:300 
                }) 
            }  
        } 
        //volatilityData
        if(preProps.volatilityData!=this.props.volatilityData){
            if(this.props.volatilityData && this.props.volatilityData.length<7){
                this.setState({
                    height:this.props.volatilityData.length*47+100
                },  )
            }
            
            else{
                this.setState({
                    height:300
                },  )
            }
        }
    }

    render(){
        const {title,liquidityData,volatilityData} = this.props;
        const defaultColDef={
            cellStyle: {textAlign: 'center'}
        };
        const hasLiquidity = this.props.showTableData?.indexOf('liquidity') >-1
        const hasVolatility = this.props.showTableData?.indexOf('volatility') >-1
        return( 
            <div className="liquidityvolatility-table-container" id="liquidityvolatility"> 
                <TableHeader title={title}/> 
                  {
                     hasLiquidity&&hasVolatility?<div className="liquidityvolatility-table">  
                     <div className="ag-theme-alpine w-100" style={{ height:this.state.height}}> 
                     {   title === 'Liquidity' ?  
                         <AgGridReact
                         onGridReady={this.onGridReady}
                         rowData={liquidityData}
                         defaultColDef={defaultColDef} 
                         > 
                             {/* <AgGridColumn headerName='Stock' field="eod_ticker"></AgGridColumn> */}
                             <AgGridColumn headerName='Stocks' field="security" minWidth={100} ></AgGridColumn>
                             <AgGridColumn headerName='Realized Liquidity' field="liquidity" minWidth={200} ></AgGridColumn>
                             <AgGridColumn headerName='Status' field="bar_percentage" minWidth={150}
                                 cellRendererFramework={(i)=>
                                     <StatusProgressBar percentage={i.data.bar_percentage}/>
                                 } 
                             ></AgGridColumn>   
                         </AgGridReact> 
                         :
                          <AgGridReact 
                          onGridReady={this.onGridReady} 
                          rowData={volatilityData} 
                          defaultColDef={defaultColDef} 
                          >
                              {/* <AgGridColumn headerName='Stock' field="eod_ticker"></AgGridColumn> */}
                              <AgGridColumn headerName='Stock' field="security" minWidth={100}></AgGridColumn>
                              <AgGridColumn headerName='Realized Volatility' field="volatility" minWidth={200} ></AgGridColumn>
                              <AgGridColumn headerName='Status' field="bar_percentage" minWidth={150}
                                 cellRendererFramework={(i)=>
                                     <StatusProgressBar percentage={i.data.bar_percentage}/>
                                 }
                             ></AgGridColumn>
                          </AgGridReact>
                         }
                     </div>
                       
                     </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '540' height= '400' />
                </div>
                  }        
            </div>
        )
    }
}



LiquidityVolatilityTable.propTypes = {
    title: PropTypes.string
  };

export default LiquidityVolatilityTable;