import {
    REQUEST_FORGET_PASSWORD,
    SUCCESS_FORGET_PASSWORD,
    FAILURE_FORGET_PASSWORD,
} from "../../Constant/actionTypes";

const initilizeState = {
    forgetpasswordMessage: '',
}

export default function forgetPasswordReducer(state=initilizeState, action) {
    switch(action.type){
        case REQUEST_FORGET_PASSWORD:
            return{
                ...state,
            }
        case SUCCESS_FORGET_PASSWORD:
            return{
                ...state,
                forgetpasswordMessage: action.ForgetPasswordDetails,
            }
        case FAILURE_FORGET_PASSWORD:
            return{
                ...state,
                forgetpasswordMessage: action.ForgetPasswordDetails
            }
        default: 
            return state;
    }   
}