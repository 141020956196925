import {
    REQUEST_FORGET_PASSWORD,
    SUCCESS_FORGET_PASSWORD,
    FAILURE_FORGET_PASSWORD,
} from "../../Constant/actionTypes";

import Api from "../Api/apiCalls";

export const requestForgetPassword = () => {
    return {
        type: REQUEST_FORGET_PASSWORD,
    }
}

export const successForgetPassword = (result) => {
    return{
        type: SUCCESS_FORGET_PASSWORD,
        ForgetPasswordDetails: result,
    }
}

export const failureForgetPassword = (error) => {
    return{
        type: FAILURE_FORGET_PASSWORD,
        ForgetPasswordDetails: error
    }
}

export const handleSubmitForgetPasswordAction = (loginUser) => {
    return function(dispatch){
        Api.ForgetPasswordApi(loginUser).then(result=>{
            dispatch(successForgetPassword(result.data))
        }).catch(error=>{
            dispatch(failureForgetPassword(error.response.data))
        })
    }
}