import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./BacktestingTable.scss";
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class BacktestingTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }
    
    render() {
        const { backtestingData } = this.props;
        const hasBacktesting = this.props.showTableData?.indexOf("backtesting") >-1
        return (
            <div className="backtesting-table-container" id="backtesting">
                <TableHeader title='Backtesting'
                // component={<select valur={backtestingDay} onChange={handleOnChangeBacktesting} style={{backgroundColor: '#27ED82', borderColor: '#27ED82', verticalAlign: 'top',height:30, width: 80}} className="heading-select"><option value='7'>1 Week</option><option value='30'>1 Month</option><option value='90'>3 Month</option><option value='365'>1 Year</option></select>}
                />
                {
                    hasBacktesting?<div className="backtesting-table">
                    <div className="ag-theme-alpine" style={{ height: 280 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={backtestingData}
                            defaultColDef={{
                                resizable:true,
                                wrapText: true,
                            }}
                        >
                            <AgGridColumn headerName=''
                                field="backtest_days" 
                                pinned="left"
                                // maxWidth={100}
                                minWidth={150}
                                // background: '#708DD2',paddingTop: '15px', lineHeight: '19px', letterSpacing: '0.015em'
                                cellStyle={() => ({ color: '#94B3FC', fontFamily: 'LatoRegular',fontSize: '16px' ,})}
                                cellRenderer={(i) => (
                                    i.data.backtest_days === 1 ? '1 Day' :
                                        i.data.backtest_days === 7 ? '1 Week' :
                                            i.data.backtest_days === 30 ? '1 Month' :
                                                i.data.backtest_days === 90 ? '3 Month' :
                                                    i.data.backtest_days === 180 ? '6 Month' :
                                                        null
                                )}
                            ></AgGridColumn>
                            <AgGridColumn headerName='alpha' field="alpha" minWidth={100} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            {/* <AgGridColumn headerName='backtest days' field="backtest_days" width={150} cellStyle={()=>({textAlign: 'center'})}></AgGridColumn> */}
                            <AgGridColumn headerName='beta' field="beta" minWidth={100} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='burke ratio' field="burke_ratio" minWidth={150} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='calmar ratio' field="calmar_ratio" minWidth={150} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='information ratio' field="information_ratio" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='kurtosis' field="kurtosis" minWidth={100} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='max drawdown' field="max_drawdown" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='omega ratio' field="omega_ratio" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='sharpe ratio' field="sharpe_ratio" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='sortino ratio' field="sortino_ratio" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='sterling ratio' field="sterling_ratio" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='treynor performance index' field="treynor_performance_index" minWidth={250} cellStyle={() => ({ wordWrap: 'break-word', textAlign: 'center' })}></AgGridColumn>
                            <AgGridColumn headerName='ulcer index' field="ulcer_index" minWidth={160} cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                        </AgGridReact>
                    </div>
                    </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
            </div>
        )
    }
}

export default BacktestingTable;