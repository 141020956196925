import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./CompositionGraph.scss";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class CompositionGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compositionWeight: 'sector_weight',
            sector_weight: '#DB55EB',
            sub_sector_weight: '',
            stock_weight: '',
            country_weight: ''
        }
    }

    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];
    CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip" style={{ background: '#DB55EB', lineHeight: '1.5', borderRadius: '2px', width: '250px', height: '70px', border: '1px solid #DB55EB' }}>
                    <label>{`${payload[0].name} : ${payload[0].value.toFixed(2) * 100}%`}</label>
                </div>
            );
        }
        return null;
    };

    RADIAN = Math.PI / 180;
    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.77;
        if ((percent * 100).toFixed(0) == 0) {
            const x = cx + radius * Math.cos(-midAngle * this.RADIAN) + 20;
            const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
            return (
                <text x={x} y={y} fill="white">
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        } else {
            const x = cx + radius * Math.cos(-midAngle * this.RADIAN) - 11;
            const y = cy + radius * Math.sin(-midAngle * this.RADIAN);
            return (
                <text x={x} y={y} fill="white" dominantBaseline="central" >
                    {`${(percent * 100).toFixed(0)}%`}
                </text>
            );
        }
    };

    handleChartWeight = (data) => {
        this.setState({
            compositionWeight: data,
        })
        if (data === 'sector_weight') {
            this.setState({
                sub_sector_weight: '', stock_weight: '', country_weight: '',
                sector_weight: '#DB55EB',
            }, () => { })
        }
        if (data === 'sub_sector_weight') {
            this.setState({
                sector_weight: '', stock_weight: '', country_weight: '',
                sub_sector_weight: '#DB55EB',
            }, () => { })
        }
        if (data === 'stock_weight') {
            this.setState({
                sector_weight: '', sub_sector_weight: '', country_weight: '',
                stock_weight: '#DB55EB',
            }, () => { })
        }
        if (data === 'country_weight') {
            this.setState({
                sector_weight: '', sub_sector_weight: '', stock_weight: '',
                country_weight: '#DB55EB',
            })
        }
    }

    render() {
        const { compositionWeight } = this.state;
        const { analysisCompositionDate, compositionData } = this.props;
        const hasCompositionGraph = this.props.showTableData?.indexOf("composition") >-1
        return (
            <div className="composition-table-container" style={{ height: 600 }} id="composition">
                <TableHeader title='Composition' 
                // component={<input type="date" value={analysisCompositionDate} onChange={this.props.handlePortfolioCompositionDate} className="composition-date"></input>}
                />
              {
                hasCompositionGraph?
                 <div>
                 <div className="weight-list">
                      <h4 onClick={() => this.handleChartWeight('sector_weight')} style={{ cursor: "pointer", color: `${this.state.sector_weight}` }}>Sector</h4>
                      <h4 onClick={() => this.handleChartWeight('sub_sector_weight')} style={{ cursor: "pointer", color: `${this.state.sub_sector_weight}` }}>Sub Sector</h4>
                      <h4 onClick={() => this.handleChartWeight('stock_weight')} style={{ cursor: "pointer", color: `${this.state.stock_weight}` }}>Stock</h4>
                      <h4 onClick={() => this.handleChartWeight('country_weight')} style={{ cursor: "pointer", color: `${this.state.country_weight}` }}>Country</h4>
                  </div>
                  <div style={{ display: "flex" }}>
                      <div className="pie-chart">
                          <div  style={{ width: "90%" }} >
                              <ResponsiveContainer  >
                                  <PieChart  >
                                      <Pie style={{ stroke: 'none' }}
                                          data={
                                              !compositionData ? [] :
                                                  compositionWeight === 'sector_weight' ? compositionData.data['sector_weight'] :
                                                      compositionWeight === 'sub_sector_weight' ? compositionData.data['sub_sector_weight'] :
                                                          compositionWeight === 'stock_weight' ? compositionData.data['stock_weight'] :
                                                              compositionWeight === 'country_weight' ? compositionData.data['country_weight'] : compositionData.data['sector_weight']
                                          }
                                          color="#000000"
                                          dataKey="value"
                                          nameKey="name"
                                          cx="50%"
                                          cy="50%"
                                          outerRadius={180}
                                          isAnimationActive={false}
                                          fill="#8884d8"
                                          // paddingAngle={5}
                                          type="Pie"
                                          label={this.renderCustomizedLabel}
                                      >
                                          {
                                              !compositionData ? [] :
                                                  compositionWeight === 'sector_weight'
                                                      ?
                                                      compositionData.data['sector_weight'] && compositionData.data['sector_weight'].map((entry, index) => <Cell st key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                                      :
                                                      compositionWeight === 'sub_sector_weight'
                                                          ? compositionData.data['sub_sector_weight'] && compositionData.data['sub_sector_weight'].map((entry, index) => <Cell st key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                                          :
                                                          compositionWeight === 'stock_weight'
                                                              ? compositionData.data['stock_weight'] && compositionData.data['stock_weight'].map((entry, index) => <Cell st key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                                              :
                                                              compositionWeight === 'country_weight'
                                                                  ? compositionData.data['country_weight'] && compositionData.data['country_weight'].map((entry, index) => <Cell st key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                                                  : compositionData.data['sector_weight'] && compositionData.data['sector_weight'].map((entry, index) => <Cell st key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)
                                          }
                                      </Pie>
                                      <Tooltip content={<this.CustomTooltip />} />
                                  </PieChart>
                              </ResponsiveContainer>
                          </div>
                          <div className="sector" style={{ borderLeft: '4px dotted #1EF2F2', padding: '10px 20px', width: '35%', height: 410, overflowY: "scroll", overflowX: "hidden" }}>
                              {!compositionData ? [] :
                                  compositionWeight === 'sector_weight' ?
                                      compositionData && compositionData.data.sector_weight === undefined
                                          ?
                                          <div style={{ borderBottom: '2px solid #FE5377', borderRadius: '1px' }}>Sector Weight</div>
                                          :
                                          compositionData && compositionData.data.sector_weight.map((item, index) => {
                                              return <div key={index} style={{ borderBottom: `2px solid ${this.COLORS[index % this.COLORS.length]}`, borderBottomWidth: '6px', borderRadius: '1px', marginBottom: 50 }}>{((item.value) * 100).toFixed(0) + '%'} {item.name}</div>
                                          })
                                      :
                                      compositionWeight === 'sub_sector_weight' ?
                                          compositionData && compositionData.data.sub_sector_weight === undefined
                                              ?
                                              <div style={{ borderBottom: '2px solid #FE5377', borderRadius: '1px' }}>Sub Sector Weight</div>
                                              :
                                              compositionData && compositionData.data.sub_sector_weight.map((item, index) => {
                                                  return <div key={index} style={{ borderBottom: `2px solid ${this.COLORS[index % this.COLORS.length]}`, borderBottomWidth: '6px', borderRadius: '1px', marginBottom: 50 }}>{((item.value) * 100).toFixed(0) + '%'} {item.name}</div>
                                              })
                                          :
                                          compositionWeight === 'stock_weight' ?
                                              compositionData && compositionData.data.stock_weight === undefined
                                                  ?
                                                  <div style={{ borderBottom: '2px solid #FE5377', borderRadius: '1px' }}>Sub Sector Weight</div>
                                                  :
                                                  compositionData && compositionData.data.stock_weight.map((item, index) => {
                                                      return <div key={index} style={{ borderBottom: `2px solid ${this.COLORS[index % this.COLORS.length]}`, borderBottomWidth: '6px', borderRadius: '1px', marginBottom: 50 }}>{((item.value) * 100).toFixed(0) + '%'} {item.name}</div>
                                                  })
                                              :
                                              compositionWeight === 'country_weight' ?
                                                  compositionData && compositionData.data.country_weight === undefined
                                                      ?
                                                      <div style={{ borderBottom: '2px solid #FE5377', borderRadius: '1px' }}>Sub Sector Weight</div>
                                                      :
                                                      compositionData && compositionData.data.country_weight.map((item, index) => {
                                                          return <div key={index} style={{ borderBottom: `2px solid ${this.COLORS[index % this.COLORS.length]}`, borderBottomWidth: '6px', borderRadius: '1px', marginBottom: 50 }}>{((item.value) * 100).toFixed(0) + '%'} {item.name}</div>
                                                      })
                                                  :
                                                  compositionData && compositionData.data.sector_weight === undefined
                                                      ?
                                                      <div style={{ borderBottom: '2px solid #FE5377', borderRadius: '1px' }}>Sector Weight</div>
                                                      :
                                                      compositionData && compositionData.data.sector_weight.map((item, index) => {
                                                          return <div key={index} style={{ borderBottom: `2px solid ${this.COLORS[index % this.COLORS.length]}`, borderBottomWidth: '6px', borderRadius: '1px', marginBottom: 50 }}>{((item.value) * 100).toFixed(0) + '%'} {item.name}</div>
                                                      })
                              }
                          </div>
                      </div>
                      <div>
                      </div>
                  </div>
                 </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '695' height= '508' />
                </div>
              }
            </div>
        )
    }
}

export default CompositionGraph;