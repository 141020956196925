import React,{useState} from 'react';
import './Search.scss';
const Search = (props) => {
    const [inputValue,setinputValue]=useState("");
    return(
        <div className="search-container">
            <input type="search" onChange={(event)=>{
                props.onSearch(event.target.value.toUpperCase())
                setinputValue(event.target.value)
                }} name='search' value={inputValue} placeholder='Search'/>
        </div>
    )
}
export default Search;