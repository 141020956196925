import React, { Component } from "react";

class News extends Component{
    render(){
        return(
            <div>
                News
            </div>
        )
    }
}
export default News;