import {
    REQUEST_PORTFOLIO_ANALYTICS_NAV,
    SUCCESS_PORTFOLIO_ANALYTICS_NAV,
    FAILURE_PORTFOLIO_ANALYTICS_NAV,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestNAVPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_NAV
    }
}

export const successNAVPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_NAV,
        navData:result
    }
}

export const failureNAVPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_NAV,
    }
}

export const handleNAVPortfolioAction = (dropDownPortFolio,navDay,accessToken ) => {
    return function(dispatch){
        dispatch(requestNAVPortfolio())
        Api.NAVPortfolioApi(dropDownPortFolio,navDay,accessToken).then((result)=>{
            dispatch(successNAVPortfolio(result.data))
        }).catch(error=>{
            dispatch(failureNAVPortfolio(error))
        })
    }
}