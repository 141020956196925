import {
    REQUEST_GET_CURRENCY_LIST,
    SUCCESS_GET_CURRENCY_LIST,
    FAILURE_GET_CURRENCY_LIST,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function getListOfCurrencyReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_GET_CURRENCY_LIST:
            return {
                ...state,
            }
        case SUCCESS_GET_CURRENCY_LIST:
            return {
                ...state,
                currencyList: action.currencyList
            }
        case FAILURE_GET_CURRENCY_LIST:
            return{
                ...state
            }
        default: return state;
    }
}