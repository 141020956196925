import {
    REQUEST_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
    SUCCESS_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
    FAILURE_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestAttributions = () =>{
    return{
        type: REQUEST_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
    }
}

export const successAttributions = (result) =>{
   return{
       type: SUCCESS_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
       attributionsData : result
    }
}

export const failureAttributions = (error) =>{
   return{
       type:  FAILURE_PORTFOLIO_ANALYTICS_ATTRIBUTIONS,
       attributionsError: error
   }
}

export const handleAttributionsAction = (dropDownPortFolio,accessToken) =>{
   return function(dispatch){
       dispatch(requestAttributions())
       Api.AttributionsApi(dropDownPortFolio,accessToken).then(result=>{
           dispatch(successAttributions(result.data))
       }).catch(error=>{
           dispatch(failureAttributions(error))
       })
   }
}