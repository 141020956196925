import React, { Component } from "react";
import "./summaryCard.scss";

class SummaryCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            summaryCardDetails: ['return 1Day', 'return Since Inception', 'total Cash', 'total Nav']
        }
    }
    oneDay = (day) => {
        if (1000000000 < day) {
            return ('$ '+((day / 1000000000)).toFixed(1) + 'B')
        }
        if (1000000000 < day) {
            return ('$ '+((day / 1000000000)).toFixed(1) + 'B')
        }
        else if (1000000 <= day && day < 1000000000) {
            return ('$ '+((day / 1000000)).toFixed(1) + 'M')
        } else if (1000 <= day && day < 1000000) {
            return ('$ '+((day / 1000)).toFixed(1) + 'K')
        } else if (-1000000000 > day) {
            return ('$ '+((day / 1000000000)).toFixed(1) + 'B')
        }
        else if (-1000000 >= day && day > -1000000000) {
            return ('$ '+((day / 1000000)).toFixed(1) + 'M')
        }
        else if (-1000 >= day && day > -1000000) {
            return ('$ '+((day / 1000)).toFixed(1) + 'K')
        } else {
            return ( day && '$'+ day.toFixed(1))
        }
    }
    render() {
        const { summaryCard } = this.props;
        var day = summaryCard && summaryCard["return_1d"];

        // summaryCard && '$' + summaryCard['return_1d'].toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        return (
            // style={{ display: "flex", justifyContent: 'space-between', margin: '0px 105px' }}
            <div className="summarycard-outer">                {
                    this.state.summaryCardDetails.map((sc, index) => {
                        return <div className="summary-card" key={index}>
                            <p className="summary-title">{sc}</p>

                            <p className="summary-amount">{
                                sc === 'return 1Day' ? this.oneDay(summaryCard && summaryCard['return_1d']) :
                                    sc === 'return Since Inception' ? this.oneDay(summaryCard && summaryCard['return_since_inception']) :
                                        sc === 'total Cash' ? this.oneDay(summaryCard && summaryCard['total_cash']) :
                                            sc === 'total Nav' ? this.oneDay(summaryCard && summaryCard['total_nav']) : null
                            }</p>
                        </div>
                    })
                }
            </div>
        )
    }
}
export default SummaryCard;