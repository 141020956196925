import {

    REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK,

} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeRiskDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK:
            return{
                ...state,
                homeRiskData: action.homeRiskData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK:
            return{
                ...state,
            }
        default:
            return state
    }
}
export default homeRiskDashboardReducer;
