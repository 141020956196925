import {
    REQUEST_NAV_LINES,
    SUCCESS_NAV_LINES,
    FAILURE_NAV_LINES,
} from "../../Constant/actionTypes";

const initilizeState = {
    navLineFlag: false
}

export default function decesionStatsNavLinesReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_NAV_LINES:
            return{
                ...state,
                navLineFlag: false
            }
        case SUCCESS_NAV_LINES:
            return{
                ...state,
                navLineData:action.navLineData,
                navLineFlag: true
            }
        case FAILURE_NAV_LINES:
            return{
                ...state,
                navLineFlag: false
            }
        default:
            return state;
    }
}