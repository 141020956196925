import {
    REQUEST_SOURCE_LINK,
    SUCCESS_SOURCE_LINK,
    FAILURE_SOURCE_LINK,
    REQUEST_PORTFOLIO_SOURCE_LINK,
    SUCCESS_PORTFOLIO_SOURCE_LINK,
    FAILURE_PORTFOLIO_SOURCE_LINK,
} from "../../Constant/actionTypes";

const initilizeState = {
    loading: true,
    sourceLinkDetails: '',
    flag: '',

}

export default function sourceLinkReducer(state = initilizeState, action) {
    switch (action.type) {
        case REQUEST_SOURCE_LINK:
            return {
                ...state,
                loading: true,
            }
        case SUCCESS_SOURCE_LINK:
            return {
                ...state,
                sourceLinkDetails: action.SourceLinkDetails,
                loading: false,
            }
        case FAILURE_SOURCE_LINK:
            return {
                ...state,
                loading: true,
            }
        case REQUEST_PORTFOLIO_SOURCE_LINK:
            return {
                ...state,
                loading: true,
            }
        case SUCCESS_PORTFOLIO_SOURCE_LINK:
            return {
                ...state,
                flag: 'success',
                loading: false,
            }
        case FAILURE_PORTFOLIO_SOURCE_LINK:
            return {
                ...state,
                loading: true,
            }
        default:
            return state;
    }
}