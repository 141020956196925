import {
    REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE,
    SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE,
    FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE,
} from "../../Constant/actionTypes";

const initilizeState = {
    performanceData: '',
    performanceFlag: false
}

export default function performancePortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE:
            return {
                ...state,
                performanceFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE:
            return {
                ...state,
                performanceData: action.performanceData,
                performanceFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE:
            return{
                ...state,
                performanceFlag: false
            }
        default: return state;
    }
}