import React, { Component } from "react";
import "./deletePortfolioModal.scss";

class DeletePortfolioModal extends Component{
    render(){
        return(
            <div className="delete-container">
                <div className="delete-quote">Are You Sure You Want To Remove <span style={{color: "#DB55EB"}}>{this.props.portfoliosName}</span>  Portfolio?</div>
                <div className="delete-btn">
                    <button className="cancel-btn" onClick={this.props.handleCloseModal}>Cancel</button>
                    <button className="yes-btn" onClick={this.props.handleDeleteEntirePortfolio}>Yes</button>
                </div>
            </div>
        )
    }
}
export default DeletePortfolioModal;