import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList
} from "recharts";

function Waterfall(props) {
  const { headingData } = props;

  const [headingGraphData, setheadingGraphData] = useState();

  useEffect(()=>{
    setheadingGraphData(props.headingData)
  },[props.headingData])

  // const getData = (i) => {
  //   const inter = i?.attribution?.absolute_returns;
    // if (1000000000 < inter) {
    //   return ('$ ' + ((inter / 1000000000)).toFixed(2) + 'B')
    // }
    // else if (1000000 <= inter && inter < 1000000000) {
    //   return ('$ ' + ((inter / 1000000)).toFixed(2) + 'M')
    // } else if (1000 <= inter && inter < 1000000) {
    //   return ('$ ' + ((inter / 1000)).toFixed(2) + 'K')
    // } else if (-1000000000 > inter) {
    //   return ('$ ' + ((inter / 1000000000)).toFixed(2) + 'B')
    // }
    // else if (-1000000 >= inter && inter > -1000000000) {
    //   return ('$ ' + ((inter / 1000000)).toFixed(2) + 'M')
    // }
    // else if (-1000 >= inter && inter > -1000000) {
    //   return ('$ ' + ((inter / 1000)).toFixed(2) + 'K')
    // } else {
    //   return (inter && '$' + inter.toFixed(3))
    // }
  // }
  const labelFormatter = (value) => {
    return (value.toLocaleString());
  };
  
  return (
    // <ResponsiveContainer  width="100%">
    <BarChart className='barBaseChart'
    width={900}
    height={550}
      data={headingGraphData}
      margin={{
        top: 5,
        right: 3,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid stroke="none" />
      <XAxis dataKey="eod_ticker" tick={{ fill: '#99DDA4' }} style={{ fontSize: '13px', fontFamily: 'LatoMedium', fontStyle: 'normal',
      fontWeight: 500, lineHeight: '14px', letterSpacing: '0.015em', textTransform: 'capitalize' }}   /> 
      <YAxis 
        tickFormatter={(nav) => `${(1000000000 < nav) ? ((nav / 1000000000)).toFixed(1) + 'B' :
          (1000000 <= nav && nav < 1000000000) ? ((nav / 1000000)).toFixed(1) + 'M' :
            (1000 <= nav && nav < 1000000) ? ((nav / 1000)).toFixed(1) + 'K' :
              (-1000000000 > nav) ? ((nav / 1000000000)).toFixed(1) + 'B' :
                (-1000000 >= nav && nav > -1000000000) ? ((nav / 1000000)).toFixed(1) + 'M' :
                  (-1000 >= nav && nav > -1000000) ? ((nav / 1000)).toFixed(1) + 'K' :
                    ((nav)).toFixed(1)}`}
        tick={{ fill: '#99DDA4' }}
        style={{ fontSize: '17px', fontFamily: 'LatoMedium', fontStyle: 'normal', fontWeight: 500, lineHeight: '14px', letterSpacing: '0.015em', textTransform: 'capitalize' }} />
      <Bar dataKey="pv" stackId="a" fill="transparent" />
      <defs>
        <linearGradient id="greencolor" x1="0" y1="0" x2="0" y2="1">
          <stop offset="13.52%" stopColor=" #1EF2BF" stopOpacity={1} />
          <stop offset="87.09%" stopColor="#09FA75" stopOpacity={1} />
        </linearGradient>
      </defs>
      <defs>
        <linearGradient id="redcolor" x1="0" y1="0" x2="0" y2="1">
          <stop offset="13.52%" stopColor="#FF6060" stopOpacity={1} />
          <stop offset="87.09%" stopColor="#FF1C1C" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Bar isAnimationActive={false}  dataKey='attribution.absolute_returns' stackId="a"
        label={{ position: 'top', formatter: labelFormatter }}
      >
        {/* <LabelList dataKey="attribution.absolute_returns" position="top" content='aagya' /> */}
        {/*<LabelList dataKey="attribution.percentage_returns" content='aagya' />*/} 
        {headingGraphData && headingGraphData.map((item, index) => {
          if (item.attribution?.absolute_returns < 0) {
            return (
              <Cell key={index} fill="url(#redcolor)" />
            );
          }
          return (<Cell key={index} fill="url(#greencolor)" />);
        })}
      </Bar>
    </BarChart>

    //  </ResponsiveContainer>
  );
}

export default Waterfall;