import {
    REQUEST_VIEW_CREATE_WATCHLIST,
    SUCCESS_VIEW_CREATE_WATCHLIST,
    FAILURE_VIEW_CREATE_WATCHLIST,
} from "../../Constant/actionTypes";

const initilizeState = {
    viewWatchlistFlag: false,
    viewWatchListData:''
}

export default function viewWatchListReducer(state=initilizeState, action){
    switch(action.type){
        case REQUEST_VIEW_CREATE_WATCHLIST:
            return{
                ...state,
                viewWatchlistFlag: false,
            }
        case SUCCESS_VIEW_CREATE_WATCHLIST:
            return{
                ...state,
                viewWatchListData: action.viewWatchListData,
                viewWatchlistFlag: true,
            }
        case FAILURE_VIEW_CREATE_WATCHLIST:
            return{
                ...state,
                viewWatchlistFlag: false,
            }
        default:
            return{ ...state}
    }
}