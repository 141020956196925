import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import LockScreen from '../../Assets/Images/lockScreen.jpg'
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./PriceTrends.scss";
import Select from "./MultiSelect";

class PriceTrendsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abcd: [],
            multiselect: ['10', '20', '100'],
            height: ''
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    handleMultiSelectChange = (event) => {
        this.state.abcd.length && this.state.abcd.includes(event.target.value)
            ?
            this.state.abcd.indexOf(event.target.value) > -1 && this.setState({ abcd: this.state.abcd.filter(item => item !== event.target.value) })
            : this.state.abcd.length < 3 && this.setState({ abcd: [...this.state.abcd, event.target.value] })
    }

    componentDidUpdate(preProps) {
        if (preProps.priceTrendData != this.props.priceTrendData) {
            if (this.props.priceTrendData && this.props.priceTrendData.length < 7) {
                this.setState({
                    height: this.props.priceTrendData.length * 47 + 100
                })
            }

            else {
                this.setState({
                    height: 400
                })
            }
        }
    }
    render() {
        const { priceTrendData } = this.props;
        const defaultColDef = {
            // cellStyle: { textAlign: 'center' }
        };
        const hasPriceTrends = this.props.showTableData?.indexOf("price_trends") >-1
        return (
            <div className="pricetrendsData-table-container" id="pricetrends">
                <TableHeader title='Price Trends'
                    component={
                        <Select handleMultiSelectChange={this.handleMultiSelectChange} abcd={this.state.abcd}/>
                    }
                />
                {
                    hasPriceTrends?<div className="pricetrendsData-table">
                    <div className="ag-theme-alpine w-100" style={{ height: this.state.height+5 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={priceTrendData}
                            defaultColDef={defaultColDef}
                        // cellStyle={{width:5}}
                        >
                            <AgGridColumn headerName='Stock' field="Stock" minWidth={100}>
                                <AgGridColumn headerName='' field="eod_ticker" minWidth={100}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='RSI' field="rsi" minWidth={100} >
                                <AgGridColumn headerName='' field="rsi" minWidth={100}  cellStyle={{ background: '#051C55' }}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='52week' minWidth={300} >
                                <AgGridColumn headerName='low' cellStyle={() => ({ color: "#FE5B5B", textAlign: 'left' })} field="52_week_low" minWidth={150}></AgGridColumn>
                                <AgGridColumn headerName='high' cellStyle={() => ({ color: "#48FF65", textAlign: 'left' })} field="52_week_high" minWidth={150}></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='MACD' field="macd" minWidth={224} >
                                <AgGridColumn headerName='Histogram' field="macd_histogram_adjusted_close" minWidth={112} ></AgGridColumn>
                                <AgGridColumn headerName='Signal' field="macd_signal_adjusted_close" minWidth={112}></AgGridColumn>
                            </AgGridColumn>
                            <AgGridColumn headerName='SMA' minWidth={300}>
                                {this.state.abcd.length < 3 ?
                                    this.state.multiselect.map((item, index) => {
                                        return (<AgGridColumn key={index} field={index === 0 ? `adjusted_close_sma_${item}` : index === 1 ? `adjusted_close_sma_${item}` : index === 2 ? `adjusted_close_sma_${item}` :null} headerName={index === 0 ? `${item}D` : index === 1 ? `${item}D` : index === 2 ? `${item}D` : null} minWidth={100} cellRendererFramework={(i) => (i.data[`adjusted_close_sma_${item}`])}  cellStyle={{ background: '#051C55' }}>
                                        </AgGridColumn>)
                                    })
                                    : this.state.abcd.map((item, index) => {
                                        return (<AgGridColumn key={index} headerName={index === 0 ? `${item}D` : index === 1 ? `${item}D` : index === 2 ? `${item}D` : null}
                                        field={index === 0 ? `adjusted_close_sma_${item}` : index === 1 ? `adjusted_close_sma_${item}` : index === 2 ? `adjusted_close_sma_${item}` :null}
                                            cellRendererFramework={(i) => (i.data[`adjusted_close_sma_${item}`])}
                                            cellStyle={{ background: '#051C55' }}
                                            minWidth={100}
                                        ></AgGridColumn>
                                        )
                                    })
                                }
                            </AgGridColumn>
                            <AgGridColumn headerName='EMA' minWidth={300}>
                                {this.state.abcd.length < 3 ?
                                    this.state.multiselect.map((item, index) => {
                                        return (<AgGridColumn key={index} field={index === 0 ? `adjusted_close_ema_${item}` : index === 1 ? `adjusted_close_ema_${item}` : index === 2 ? `adjusted_close_ema_${item}` : null} headerName={index === 0 ? `${item}D` : index === 1 ? `${item}D` : index === 2 ? `${item}D` : null} minWidth={100} cellRendererFramework={(i) => (i.data[`adjusted_close_ema_${item}`])} cellStyle={{ background: '#051C55' }}>
                                        </AgGridColumn>)
                                    })
                                    : this.state.abcd.map((item, index) => {
                                        return (<AgGridColumn key={index} field={index === 0 ? `adjusted_close_ema_${item}` : index === 1 ? `adjusted_close_ema_${item}` : index === 2 ? `adjusted_close_ema_${item}` :null} headerName={index === 0 ? `${item}D` : index === 1 ? `${item}D` : index === 2 ? `${item}D` : null}
                                            cellRendererFramework={(i) => (i.data[`adjusted_close_ema_${item}`])} cellStyle={{ background: '#051C55' }}
                                            minWidth={100}
                                        ></AgGridColumn>
                                        )
                                    })
                                }
                            </AgGridColumn>
                        </AgGridReact>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
                
            </div>
        )
    }
}

export default PriceTrendsTable;