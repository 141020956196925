import {
    REQUEST_SHOW_TABLE,
    SUCCESS_SHOW_TABLE,
    FAILURE_SHOW_TABLE,
} from "../../Constant/actionTypes";

const initilizeState = {
    showTable : [],
}

export default function showTableReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_SHOW_TABLE:
            return{
                ...state,
                showTableFlag: false
            }
        case SUCCESS_SHOW_TABLE:
            return{
                ...state,
                showTableData: action.showTableData,
                showTableFlag: true,
                
            }
        case FAILURE_SHOW_TABLE:
            return{
                ...state,
                
                showTableFlag: false,
                showTabelError:action.showTabelError
            }
        default:
            return state;
    }
}