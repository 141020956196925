import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./styles.css";
import { handleInitiatePlanAction, handleCouponVerificationAction, handleResetAction, paymentIntentDataD, handlePaymentInitiateValidateAction } from "../../Redux/actions/initiatePlanAction";
import { toast } from "react-toastify";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import '../../Components/CreateNewWatchlist/CreateNewWatchlist.scss'
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';

const StripeModel = (props) => {

  const stripe = loadStripe(
    "pk_live_51HYqWBJcwZ7CCzua05WWPmnp4YuR9SZs6jHRGjcICQfz9LrlIpIajQmQp5S41okVjHV8mrDMHxNEq6RaF6dh8H5j00LT27cu7X"
  );
  return (
    <Elements stripe={stripe}>
      <CheckoutForm {...props} />
    </Elements>
  );
};
export default StripeModel;

function CheckoutForm(props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [discount, setDiscount] = useState('')
  const [message, setMessage] = useState('')
  const [price, setPrice] = useState(props.plan_charges)
  const reducerData = useSelector(state => state?.initiatePlanReducer)
  const paymentIntentData1 = useSelector(state => state?.initiatePlanReducer)

  const userData = useSelector(state => state?.getUserDetailsReducer?.userData)
  const modal = useSelector(state => state?.modalReducer?.openCloseModal)
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  React.useEffect(() => {
    if (reducerData?.couponFlag == 'fail') {
      setSuccess(false);
      setLoading(false);
      if ((reducerData?.CouponVerificationErrorMessage).includes('Invalid couponRequest')) {
        setMessage('Invalid Coupon code ')
      }
    }
    else if (reducerData?.couponFlag == 'request') {
      setSuccess(false);
      setLoading(true);
    }
    else if (reducerData?.couponFlag == 'success') {
      setMessage('')
      setSuccess(true);
      setLoading(false);
    }
  }, [reducerData])


  React.useEffect(async () => {

    if (reducerData.initiatePlanData != '') {
      let paymentResultId = localStorage.getItem('paymentResultId')
      console.log("bvjbdsjsds", paymentResultId)

      const paymentConfirmation = await stripe?.confirmCardPayment(
        reducerData.initiatePlanData['stripe_details']['payment_intent'],
        {
          payment_method: paymentResultId
        }
      )
      // paymentIntentData.stripe_details.subscription_id
 
      if (paymentConfirmation?.error) {
        dispatch(handlePaymentInitiateValidateAction(localStorage.getItem('token'),
          reducerData?.paymentIntentData?.stripe_details?.subscription_id,
          false
        ))
      }
      else {
        dispatch(handlePaymentInitiateValidateAction(localStorage.getItem('token'),
          reducerData?.paymentIntentData?.stripe_details?.subscription_id,
          true
        ))
      }

    }


    // console.log(reducerData.initiatePlanData['stripe_details']['payment_intent']);

    // if (paymentIntentData1?.paymentIntentData != undefined) { // this condition will open the otp popup 
    //   const paymentConfirmation = await stripe.confirmCardPayment(
    //     paymentIntentData1.paymentIntentData['stripe_details']['payment_intent']
    //   )
    //   dispatch(paymentIntentDataD("result?.data"))
    //   // i need  ['payment_intent'] data before calling this  "InitiatePlanApi" api 


    //   //   {
    //   //     "subscripttion_id" : reducerData?.paymentIntentData?.stripe_details?.subscription_id,
    //   //     "confirm_card" : true
    //   // }


    //   console.log("ngdvshdvsd", paymentConfirmation)
    //   // step 1  = i have to store the data of "payment_intent" , which is come's from the api of InitiatePlanApi 
    //   //  then i'll call the   dispatch(successInitiatePlan(result?.data))

    //   // console.log(reducerData.initiatePlanData['stripe_details']['payment_intent']);




    // }
  }, [reducerData])

  React.useEffect(() => {
    if (reducerData?.CouponVerificationData?.valid == true) {
      const plan_charge = props.plan_charges;
      const discount_charge = reducerData?.CouponVerificationData?.percent_off;
      const apply = ((plan_charge * (discount_charge / 100)))
      if (apply) {
        setPrice(plan_charge - apply)
      }
    }
    else {
      setPrice(props.plan_charges)
    }
  }, [reducerData?.CouponVerificationData])

  React.useEffect(() => {
    if (modal == true) {
      setSuccess(false);
      dispatch(handleResetAction())
    }
  }, [modal])

  const payMoney = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentLoading(true);

    const paymentResult = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })
      .then(function (result) {
        console.log("nbvdhsbd",result)
        if (result.error != undefined) {

        }
        else if (result?.paymentMethod?.id) { 
          localStorage.setItem('paymentResultId', result?.paymentMethod?.id)
        }
         
      })




// 4069 7750 1995 9391

    const tokenResult = await stripe.createToken(elements.getElement(CardElement));

    if (tokenResult) {
      if (tokenResult?.error?.message) {
        toast.warn(tokenResult?.error?.message)
      }
      if (tokenResult?.token?.id) {
        dispatch(handleInitiatePlanAction('test line 1', 'Dubai', 'Dubai', 'UAE', tokenResult.token.id, props.plan_name, props.payment_frequency, localStorage.getItem('token'), discount))
      }

    }
    else {
    }
    if (userData?.status == 'active') {
      setPaymentLoading(false);
    }
  }
  const Varification = () => {
    if (discount == '') {
      setMessage('Enter Discount Coupon')
    }
    else {
      setSuccess(false);
      setLoading(true);
      dispatch(handleCouponVerificationAction(localStorage.getItem('token'), discount))
    }
  }

  return (
    <div style={{ padding: "3rem", }} >
      <div style={{ maxWidth: "500px", margin: "0 auto", }} >
        <form style={{ display: "block", width: "100%" }} onSubmit={payMoney}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }} >
            <CardElement
              className="card"
              options={{
                style: {
                  base: { backgroundColor: "white" }
                },
              }}
            />
            <div className='align-items-center justify-content-beetween mt-15 w-100'>
              <input type="text" className="create-new-watchlist-input" name='discount' value={discount} onChange={(e) => { return setDiscount(e.target.value), setSuccess(false) }} placeholder='Discount '></input>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ m: 1, position: 'relative' }}>
                  <Fab
                    aria-label="save"
                    color="default"
                    sx={buttonSx}
                    onClick={Varification}
                  >
                    {success ? <CheckIcon /> : <ArrowForwardIosIcon onClick={Varification} />}
                  </Fab>
                  {loading && (
                    <CircularProgress className='coupon-process'
                      size={68}
                    />
                  )}
                </Box>
              </Box></div>
            <p style={{ color: 'white' }}>{message}</p>
            <div style={{ display: 'flex' }}>
              <button className="pay-button" disabled={isPaymentLoading} >
                {isPaymentLoading ? "Loading..." : `Pay $${price}`}
              </button>
              <button className="cancel-button" onClick={props.handleCloseModal}>Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}