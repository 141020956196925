import {
    REQUEST_MONTHLY_RETURNS,
    SUCCESS_MONTHLY_RETURNS,
    FAILURE_MONTHLY_RETURNS,
    FAILURE_SELL_TRANSACTION,
    REQUEST_MONTHLY_ABSOLUTE_RETURNS,
    SUCCESS_MONTHLY_ABSOLUTE_RETURNS,
    FAILURE_MONTHLY_ABSOLUTE_RETURNS
} from "../../Constant/actionTypes";

const initilizeState = {
    monthlyReturnFlag: false,
    monthlyAbsoluteReturnFlag: false
}

export default function decesionStatsMonthlyReturnsReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_MONTHLY_RETURNS:
            return{
                ...state,
                monthlyReturnFlag: false
            }
        case SUCCESS_MONTHLY_RETURNS:
            return{
                ...state,
                monthlyReturnsData:action.monthlyReturnsData,
                monthlyReturnFlag: true
            }
        case FAILURE_MONTHLY_RETURNS:
            return{
                ...state,
                monthlyReturnFlag: false
            }

            case REQUEST_MONTHLY_ABSOLUTE_RETURNS:
            return{
                ...state,
                monthlyAbsoluteReturnFlag: false
            }
        case SUCCESS_MONTHLY_ABSOLUTE_RETURNS:
            return{
                ...state,
                monthlyAbsoluteReturnsData:action.monthlyAbsoluteReturnsData,
                monthlyAbsoluteReturnFlag: true
            }
        case FAILURE_MONTHLY_ABSOLUTE_RETURNS:
            return{
                ...state,
                monthlyAbsoluteReturnFlag: false
            }


        default:
            return state;
    }
}

 