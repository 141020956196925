import React, { Component } from "react";
import "./tableHeader.scss";

class TableHeader extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const  {title, component} = this.props;
        return(
            <div className="tableheader-container">
                <div className="tableheader-title">
                    <span>{title}</span>
                    <span className="days-drop-down search">{component}</span>
                </div>
            </div>
        )
    }
}
export default TableHeader;