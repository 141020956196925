import {
    REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR,

} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeSectorDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR:
            return{
                ...state,
                homeSectorData: action.homeSectorData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR:
            return{
                ...state,
            }
        default:
            return state
    }
}
export default  homeSectorDashboardReducer;
