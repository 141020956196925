import {
    REQUEST_DELETE_SECURITY_WATCHLIST,
    SUCCESS_DELETE_SECURITY_WATCHLIST,
    FAILURE_DELETE_SECURITY_WATCHLIST,
} from "../../Constant/actionTypes";

const initilizeState = {
    deleteMessage: '',
    deleteWatchlistflag: false,
}

export default function deleteSecuritiesWatchlistReducer(state=initilizeState, action) {
    switch(action.type){
        case REQUEST_DELETE_SECURITY_WATCHLIST:
            return{
                ...state,
                deleteWatchlistflag: false
            }
        case SUCCESS_DELETE_SECURITY_WATCHLIST:
            return{
                ...state,
                deleteMessage: action.deleteMessage,
                deleteWatchlistflag: true
            }
        case FAILURE_DELETE_SECURITY_WATCHLIST:
            return{
                ...state,
                deleteWatchlistflag: false
            }
        default: 
            return state;
    }   
}