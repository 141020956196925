import {
    REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
    SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
    FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER,
} from "../../Constant/actionTypes";

const initilizeState = {
    efficientFrontierFlag: false
}

export default function efficientFrontierPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER:
            return {
                ...state,
                efficientFrontierFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER:
            return {
                ...state,
                efficientFrontierData: action.efficientFrontierData,
                efficientFrontierFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER:
            return{
                ...state,
                efficientFrontierFlag: false
            }
        default: return state;
    }
}