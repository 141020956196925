import React, { Component } from "react";
import "./AddTransactionsModal.scss";

class AddTransactionsModal extends Component {
    render() {
        return (
            <div className="add-transactions-modal-container">
                <div className="add-transactions-modal-table">
                    <table className="add-transactions-table">
                        <thead>
                            <tr>
                                <th>Ticker / Symbol</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Shares</th>
                                <th>Currency</th>
                                <th>Price</th>
                                <th>Commission</th>
                                <th>Cost</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="add-transaction-data">
                                <td>GOOGL</td>
                                <td>06/02/2021</td>
                                <td>Sell</td>
                                <td>100</td>
                                <td>USD</td>
                                <td>100</td>
                                <td>15%</td>
                                <td>10000</td>
                                <td>
                                   <span className="add-transactions-save">Save</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="create-btn">
                    <button className="btn">Add Transactions</button>
                </div>
            </div>
        )
    }
}
export default AddTransactionsModal;