import {

    REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,

} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeCommoditiesDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES:
            return{
                ...state,
                homeCommoditiesData: action.homeCommoditiesData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES:
            return{
                ...state,
            }
        default:
            return state
    }
}
export default homeCommoditiesDashboardReducer;
