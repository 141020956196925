import {
    REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING,
    SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING,
    FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";


export const requestbacktesting = () =>{
    return{
        type: REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING,
    }
}

export const successbacktesting = (result) =>{
   return{
       type: SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING,
       backtestingData: result
   }
}

export const failurebacktesting = () =>{
   return{
       type: FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING,
   }
}

export const handlebacktestingPortfolioAction = (dropDownPortFolio,token) => {
    return function(dispatch){
        dispatch(requestbacktesting())
        Api.backtestingApi(dropDownPortFolio,token).then(result=>{
            dispatch(successbacktesting(result['data']))
        }).catch(error=>{
            dispatch(failurebacktesting(error))
        })
    }
}
