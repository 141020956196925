import React, { Component } from "react";
import IBKR from "../Components/IBKR/ibkr";
import TableHeader from "../Components/tableHeader/tableHeader";
import "./integration.scss"

class Integration extends Component{
    render(){
        return(
            <div className="stoxdoc-innerpart">
                <div className="integration-container">
                    <TableHeader title='IBKR Integration'/>
                    <IBKR {...this.props}/>
                </div>
            </div>
        )
    }
}
export default Integration;