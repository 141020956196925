import {
    CLOSE_MODAL,
    OPEN_MODAL,
} from "../../Constant/actionTypes";

export const openModal = () => {
    return{
        type: OPEN_MODAL,
    }
}

export const closeModal = () => {
    return{
        type: CLOSE_MODAL
    }
}

export const handleOpenModalAction = () =>{
    return function (dispatch){
        dispatch(openModal());
    }
}

export const handleCloseModalAction = () =>{
    return function (dispatch){
        dispatch(closeModal())
    }
}