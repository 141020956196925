import {
    REQUEST_GET_USER_DETAILS,
    SUCCESS_GET_USER_DETAILS,
    FAILURE_GET_USER_DETAILS,
} from "../../Constant/actionTypes";

const initilizeState = {
    userData: ''
}

export default function getUserDetailsReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_GET_USER_DETAILS:
            return{
                ...state,
            }
        case SUCCESS_GET_USER_DETAILS:
            return{
                ...state,
                userData:action.userData,
            }
        case FAILURE_GET_USER_DETAILS:
            return{
                ...state,
            }
        default: return state;
    }
} 