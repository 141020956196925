import React, { Component } from "react";
import './modal.scss';
import PropTypes from 'prop-types';

class Modal extends Component {
        render() {
        const {openCloseModal, component, handleCloseModal,modalComponent} = this.props;
        return (
            <div>
                {
                    openCloseModal && 
                    <div id="myModal" className="modal">
                        <div className={`modal-content ${modalComponent==='Delete Holding'&& 'delete-modal-content'}`}>
                            <div className="modal-header">
                                <span className="close" onClick={()=>handleCloseModal()}>&times;</span>
                                <h2 className="header-title">{modalComponent}</h2>
                            </div>
                            <div className="modal-body">
                                {component}
                            </div>
                            {/* <div className="modal-footer">
                                <h3  style={{color: "white"}}>Modal Footer</h3>
                            </div> */}
                        </div>
                    </div>
                }
            </div>
        )
    }
}

Modal.propTypes = {
    openCloseModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
  };

export default Modal;