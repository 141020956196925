import React, { Component } from "react";
import SummaryCard from "../Components/SummaryCard/summaryCard";
import HomeTable from "../Components/HomeTable/homeTable";
import './home.scss'
class Home extends Component {

    render() {
        return (
            <div className="stoxdoc-innerpart">
                <SummaryCard {...this.props} />
                {/* {
                    this.props.homelist.map((list,index)=>{ */}
                {/* return  */}
                <HomeTable title='Global Markets'  {...this.props} />
                <HomeTable title='Sectors'  {...this.props} />
                <HomeTable title='Style'  {...this.props} />
                <HomeTable title='Countries' {...this.props} />
                <HomeTable title='Fixed Income' {...this.props} />
                <HomeTable title='Commodities'  {...this.props} />
                <HomeTable title='Risk'  {...this.props} />
                {/* }) */}
                {/* } */}
            </div>
        )
    }
}

export default Home;