import React,{useState, useEffect} from 'react';
import {ibkrVerifyAction} from "../../Redux/actions/portfoilioAction"
import { useSelector, useDispatch } from 'react-redux';

const VerifyIbkrInitiate = () => {
    const dispatch = useDispatch();
    const [ibkr, setibkr] =useState({
    email:"",
    account_id:""
    
    });
    const [token, settoken] =useState("")
 
    const [errorEmail, setErrorEmail] = useState('');

    useEffect(() =>{
        const url = window.location.search;
        const queryParams = new URLSearchParams(url);
        const token = queryParams.get("token");
        const account_id = queryParams.get("account_id");
        setibkr({...ibkr,account_id:account_id})
        settoken(token)
    },[])
   
    

   const handleChange = (e) => {
   console.log(e.target.value)
   setibkr({...ibkr,email:e.target.value})
   }

   const handleSubmit = (e) => {
       e.preventDefault()
     

     
       if (ibkr?.email?.includes('@')) {
        setErrorEmail('')

        if(ibkr?.email && ibkr?.account_id && token){
            dispatch(ibkrVerifyAction(ibkr,token))
        }else{
            alert("Something went wrong")
        }
        
    }
    else {
        setErrorEmail('Invalid Email format')
    }
   
      
}



    return ( <>
    
    <div className="login-container">
            <div className="Login reg-form">
             
                <div className="reg-tab">
                    <span className="tab-btn active" style={{ cursor: 'pointer' }}>IBKR Verification</span>
               
                </div>
                <form className="form">
                    <fieldset className="fieldset">
                        <label className="label">Email address</label>
                        <input className="input" type="text" name="email" value={ibkr?.email} onChange={(e)=>handleChange(e)} placeholder="e.g. example@gmail.com" required />
                        <span style={{ color: 'red' }}>{errorEmail}</span>
                    </fieldset>
                    <button className="reg-btn" type="submit" onClick={handleSubmit}>Submit</button>
                </form>
             
            
            </div>


</div>

    
    </> );
}
 
export default VerifyIbkrInitiate;