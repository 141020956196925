import React, { Component } from "react";
import PercentageHeadingTable from "./percentageHeadingTable";

class PercentageHeading extends Component {
    render(){
        return(
            <div>
                <PercentageHeadingTable {...this.props}/>
            </div>
        )
    }
}

export default PercentageHeading;