import React, { Component } from "react";
import HeadingTable from "./headingTable";

class Heading extends Component {
    render(){
        return(
            <div>
                <HeadingTable {...this.props}/>
            </div>
        )
    }
}

export default Heading;