import React from 'react'
import './Breker.scss'
import Modal from "../Modal/modal";
const today = new Date().toISOString().split("T")[0];
const BrekerDetail = (props) => {
    return (
        <div>
            <div className="edit-transaction-modal-container">
            <div style={{ display : 'flex', justifyContent : 'end'}}>
            {
                !props.dataStream?.some(ds=> ds.link_alias === 'Non IBKR stream 1') && <input type="date" max={today} value={props.analysisEfficientFrontierDate} onChange={props.handlePortfolioEfficientFrontierDate} className='efficient-date' />
            }
            </div>
                <table className='broker_table' cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr>
                            <td>Portfolio</td>
                            <td>DataStream</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{props?.portfolio?.slice(props?.portfolio?.indexOf('_') + 1)}</td>
                            <td>{props?.dataStream?.map((data, index) => {
                                return <ul key={index}>
                                    {/* <li>{data?.object_id}</li> */}
                                    <li>{data?.link_alias}</li>
                                    
                                </ul>
                            })}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    <div className='button-div'>
                        <button onClick={props.reset} className="broker-btn ">Cancel</button>
                        <button className="broker-btn" onClick={() => props.modalSubmit()} >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default BrekerDetail;
