import React, { Component } from "react";
import TableHeader from "../../tableHeader/tableHeader";
import "./index.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../../Assets/Images/lockScreen.jpg'

class DicisionStateTables extends Component {
    constructor(props) {
        super(props);
        this.state = {
            best_positionData: [],
            worst_positionData: [],
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    componentDidMount() {
        // this.setState({
        //     newData: this.props.navData.data
        // })
    }

    componentDidUpdate(pre) {
        if (pre.navData?.data !== this.props.navData?.data) {
            let temp_best = []
            let temp_worst = []
            let nav = this.props?.navData?.data
            if (nav?.best_worst_positions && Object.keys(nav?.best_worst_positions)) {
                if ((nav) && (Object.keys(nav?.best_worst_positions).length > 0)) {
                    // let formate_best = Object.keys(!!nav !== undefined || null && nav?.best_worst_positions?.best_day?.compositions?.security_positions)
                    // formate_best.map((v, i) => temp_best.push({ ticker: v, num_shares: nav?.best_worst_positions?.best_day?.compositions?.security_positions[v]?.num_shares }))
                    let formate_best = nav?.best_worst_positions?.best_day?.compositions;
                    formate_best.map((v, i) => temp_best.push({ ticker: v.eod_ticker, num_shares: Number(v.num_shares.toPrecision(3)).toLocaleString(), weight: Number(v.weight *100).toPrecision(3).toLocaleString()}))

                    this.setState({
                        best_positionData: temp_best
                    })

                    // let formate_worst = Object.keys(!!nav !== undefined || null &&  nav?.best_worst_positions?.worst_day?.compositions?.security_positions)
                    // formate_worst.map((v, i) => temp_worst.push({ ticker: v, num_shares: nav?.best_worst_positions?.worst_day?.compositions?.security_positions[v]?.num_shares }))

                    let formate_worst = nav?.best_worst_positions?.worst_day?.compositions;
                    formate_worst.map((v, i) => temp_worst.push({ ticker: v.eod_ticker, num_shares: Number(v.num_shares.toPrecision(3)).toLocaleString(), weight: Number(v.weight*100).toPrecision(3).toLocaleString()}))

                    this.setState({
                        worst_positionData: temp_worst
                    })
                }
            }
        }
    }

    render() {
        const { navData, title } = this.props;
        const defaultColDef = {
            cellStyle: { textAlign: 'center' }
        };
        const hasBestPortfolio = this.props.showTableData?.indexOf("best_portfolio") >-1
        const hasWorstPortfolio = this.props.showTableData?.indexOf("worst_portfolio") >-1
        return (
            <div className="liquidityvolatility-table-container" id="liquidityvolatility">
                <TableHeader title={title}
                    component=
                    {title === 'best portfolio' && navData.data ?
                        <span style={{ fontSize: 20, color: "#27ED82", verticalAlign: 'top' }}>
                            {navData?.data?.best_worst_positions?.best_day?.date}
                        </span>
                        : title === 'worst portfolio' && navData.data ?
                            <span style={{ fontSize: 20, color: "#27ED82", verticalAlign: 'top' }}>
                                {navData?.data?.best_worst_positions?.worst_day?.date}
                            </span>
                            : <p></p>
                    }
                />
                {
                    (hasBestPortfolio && hasWorstPortfolio)?<div className="liquidityvolatility-table">
                    <div className="ag-theme-alpine w-100" style={{ height: 220 }}>
                        {title === 'best portfolio' ?
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                rowData={this.state.best_positionData}
                                defaultColDef={defaultColDef}
                            >

                                <AgGridColumn headerName='Ticker' field="ticker" minWidth={210}></AgGridColumn>
                                <AgGridColumn headerName='Weight' field="weight" minWidth={210} ></AgGridColumn>

                            </AgGridReact>
                            :
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                rowData={this.state.worst_positionData}
                                defaultColDef={defaultColDef}
                            >
                                <AgGridColumn headerName='Ticker' field="ticker" minWidth={210}></AgGridColumn>
                                <AgGridColumn headerName='Weight' field="weight" minWidth={210} ></AgGridColumn>

                            </AgGridReact>
                        }
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '540' height= '400' />
                </div>
                }      
            </div>
        )
    }
}

export default DicisionStateTables;