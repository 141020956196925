import SimpleEditor from './Editors/SimpleEditor';
import AsyncValidationEditor from './Editors/AsyncValidationEditor';
import AutoCompleteEditor from './Editors/AutoCompleteEditor';
import DateEditor from './Editors/DateEditor';
import MyDatePicker from './DatePicker/MyDatePicker';
import ActionsRenderer from './Renderers/ActionsRenderer';
import AddRowStatusBar from './StatusBar/AddRowStatusBar';
import AutoSearchEditor from "./Editors/AutoSearchEditor";
import EditSearch from "../../NavBar/navbar";

export default {
    SimpleEditor,
    AsyncValidationEditor,
    AutoCompleteEditor,
    AutoSearchEditor,
    EditSearch,
    DateEditor,
    MyDatePicker,
    ActionsRenderer,
    AddRowStatusBar,
}