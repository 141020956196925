import {
    REQUEST_BUY_TRANSACTION,
    SUCCESS_BUY_TRANSACTION,
    FAILURE_BUY_TRANSACTION,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function performBuyTransactionReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_BUY_TRANSACTION:
            return{
                ...state,
            }
        case SUCCESS_BUY_TRANSACTION:
            return {
                ...state,
            }
        case FAILURE_BUY_TRANSACTION:
            return {
                ...state,
            }
        default: 
            return state;
    }
}