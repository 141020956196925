import {
    REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY,
    SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY,
    FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY,
} from "../../Constant/actionTypes";

const initilizeState = {
    deleteTransactionMessage: '',
    deleteTransaction: ''
}

export default function deleteSecurityTransactionHistoryReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY:
            return {
                ...state,
                deleteTransaction: 'request'
            }
        case SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY:
            return {
                ...state,
                deleteTransactionMessage: action.deleteTransactionMessage,
                deleteTransaction: 'success'
            }
        case FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY:
            return{
                ...state,
                deleteTransaction: 'fail'
            }
        default: return state;
    }
}