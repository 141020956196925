import React, { useState } from "react";
import "./ibkr.scss";
import Multiselect from 'multiselect-react-dropdown';
import { useSelector } from "react-redux";
import add from "../../Assets/Images/add.svg";
import vector from "../../Assets/Images/Vector.svg";
import { useHistory } from "react-router-dom";


const IBKR = (props) => {
    const state = useSelector(state => state?.getUserDetailsReducer.userData)
    const history = useHistory();
    const ans = state?.portfolios;
    var splitData = [];
    for(let i=0;i<ans.length;i++){
        const arraySplit = ans[i].split('.com_')
        splitData.push(arraySplit[1])
        
    }
    if (ans) {
        var newarr = [];
        for (let i of splitData) {
            newarr.push({ name: i });
        }
    }
    return (
        <form onSubmit={(e) => props.handleSendIbEmail(e)}>
            <div className="ibkr-container" style={{color: 'black'}}>
                {newarr?.length != 0 ? <Multiselect
                    selectedValues={props?.portfolio}
                    options={newarr} // Options to display in the dropdown
                    onSelect={(e) => props.onSelect(e)} // Function will trigger on select event
                    displayValue="name" // Property name to display in the dropdown options
                    placeholder='Select Portfolio'
                    placeholderTextColor='white'
                /> : <p style={{ color: "#DB55EB" }}>//Create a portfolio for IBKR integration</p>}

                <div className='ibkr-label-div'>
                    <label>Name (on IB account) : &nbsp;
                    <input type='text' name='name' className='input-field' value={props?.input?.name} onChange={(e) => props.handleIbkrAccountChange(e)} required></input>
                    </label>
                    {props.ibkrArray?.map((item, index) => {
                        return <div className='ibkr-label-inline-div' key={index}>
                            <div>
                                <label>IB Account Number : &nbsp;
                                <input type='text' name='account_id' value={item.account_id} className='input-field' onChange={(e) => props.handleChangeData(e.target.value, 'account_id', index)} required></input>
                                </label>&nbsp;

                            <label>Start date :&nbsp;
                                <input type='date' name='start_date' value={item.start_date} className='input-date' onChange={(e) => props.handleChangeData(e.target.value, 'start_date', index)} required></input>
                                </label>&nbsp;
                            <label>Email :&nbsp;
                                <input type='email' autoComplete='off' name='account_email' value={item.account_email} className='input-field' onChange={(e) => props.handleChangeData(e.target.value, 'account_email', index)} required></input>
                                </label>
                            </div>&nbsp;
                        <div className='count-icon'>
                                <div className='add-icon'> <button onClick={(e) => props.handleAddDetails(e)} src={add} style={{ margin: '0px 5px',background: '#94B3FC',borderColor: '#94B3FC', borderRadius: '5px' }}>Add</button></div>
                                <div className='remove-icon'>{props.ibkrArray.length === 1 ? '' : <img style={{ width: '80%' }} onClick={(e) => props.handleRemoveDetails(index)} src={vector}></img>}</div>
                            </div>
                        </div>
                    })}

                </div>
                <div className='ibkr-content' style = {{color: 'white'}}>
                    <div>
                        <p>Dear Account Manager,</p>
                        <p>Interactive Brokers</p>
                        <p>Could you kindly enable reporting integration for the account number as detailed above with start
                        date mentioned as the start_date in the form.</p>
                        <p>Thank You</p>
                    </div>
                    <div>
                        <div className='checkbox-div'>
                            <input type="checkbox" className='ibkr-checkbox' checked={props.checkboxone} onChange={() => props.handleCheckbox('checkedone')} />
                            <label>You agree to our Terms &amp;&nbsp;	Conditions (linked to <a onClick={()=>{window.open("https://stoxdoc.com/tc_ib",'_self')}} style={{ color: "#DB55EB" }}>https://stoxdoc.com/tc_ib</a> )</label>
                        </div>
                        <div>
                            <input type="checkbox" className='ibkr-checkbox' checked={props.checkboxtwo} onChange={() => props.handleCheckbox('checkedtwo')} />
                            <label>You understand that IB will provide us with portfolio positions and transaction reports but we do not have access to your account and carry no liabilities</label>
                        </div>
                    </div>
                </div>
                <div className='btn-div'>
                    <button type="submit" className='ibkr-btn' >Send Email to IB</button>
                    <button type="button" className='ibkr-btn' onClick={()=>window.location.reload(true)}>Cancel</button>
                </div>
            </div>

        </form>
    )
}

export default IBKR;