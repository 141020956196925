import React from 'react';
import Filler from './Filler';
import "./StatusBar.scss";

const StatuBar = (props) => {
    return(
        <div className="status-bar">
            <Filler percentage={props.percentage}/>
        </div>
    )
}

export default StatuBar;