import React, { Component } from 'react';
import Events from "../Page/events";

class EventsContainer extends Component {
  
    render(){
        return(
            <div>
                <Events/>
            </div>
        )
    }
}

export default EventsContainer;