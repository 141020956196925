import {
    REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
    SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
    FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestPriceTrend = () =>{
    return{
        type: REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
    }
}

export const successPriceTrend = (result) =>{
   return{
       type: SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
       priceTrendData : result
    }
}

export const failurePriceTrend = () =>{
   return{
       type:  FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
   }
}

export const handlePriceTrendAction = (dropDownPortFolio,accessToken) =>{
   return function(dispatch){
       dispatch(requestPriceTrend())
       Api.priceTrendApi(dropDownPortFolio,accessToken).then(result=>{
           dispatch(successPriceTrend(result.data))
       }).catch(error=>{
           dispatch(failurePriceTrend(error))
       })
   }
}