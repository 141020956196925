import {

    REQUEST_PAYMENT_PLAN,
    SUCCESS_PAYMENT_PLAN,
    FAILURE_PAYMENT_PLAN,

} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestPaymentPlan = () => {
    return {
        type: REQUEST_PAYMENT_PLAN
    }
}

export const successPaymentPlan = (result) => {
    return {
        type: SUCCESS_PAYMENT_PLAN,
        paymentPlan: result
    }
}

export const failurePaymentPlan = () => {
    return {
        type: FAILURE_PAYMENT_PLAN
    }
}

export const handleGetPaymentPlanAction = (accessToken) => {
    return function (dispatch) {
        dispatch(requestPaymentPlan())
        Api.GetPaymentPlanApi(accessToken).then((result) => {
            dispatch(successPaymentPlan(result.data))
        }).catch((error) => {
            dispatch(failurePaymentPlan())
        })
    }
}

