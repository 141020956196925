import { toast } from "react-toastify";
import jwt from 'jwt-decode'
import {
    REQUEST_USER_LOGIN,
    SUCCESS_USER_LOGIN,
    FAILURE_USER_LOGIN,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestLogin = () => {
    return {
        type: REQUEST_USER_LOGIN,
    }
}

export const successLogin = (result) => {
    return {
        type: SUCCESS_USER_LOGIN,
        LoginUserDetails: result,
    }
}

export const failureLogin = (errorMessage) => {
    return {
        type: FAILURE_USER_LOGIN,
        errorMessage: errorMessage,
    }
}

export const handleLoginAction = (loginUser) => {
    return function (dispatch) {
        dispatch(requestLogin())
        Api.LoginApi(loginUser).then(result => {
            if (result) {
                toast.success('Logged in!')
                const user = jwt(result.data.accessToken)
                localStorage.setItem('token', result.data.accessToken)
                localStorage.setItem('token_expiry', user.exp)
                dispatch(successLogin(result.data))
            }
        }).catch(error => {
            dispatch(failureLogin(error.response && error.response.data))
        })
    }
}