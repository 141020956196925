import {
    REQUEST_PORTFOLIO_ANALYTICS_HEADING,
    SUCCESS_PORTFOLIO_ANALYTICS_HEADING,
    FAILURE_PORTFOLIO_ANALYTICS_HEADING,
} from "../../Constant/actionTypes";

const initilizeState = {
    headingFlag: false
}

export default function headingPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HEADING:
            return{
                ...state,
                headingFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HEADING:
            return{
                ...state,
                headingData: action.headingData,
                headingFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HEADING:
            return{
                ...state,
                headingFlag: false
            }
        default:
            return state
    }
}
