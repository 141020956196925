import {

    REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeGlobalMarketDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET:
            return{
                ...state,
                homeGlobalMarketData: action.homeGlobalMarketData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET:
            return{
                ...state,
            }
        default:
            return state
    }
}
export default homeGlobalMarketDashboardReducer;