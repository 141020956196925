import {
    REQUEST_CASH_VALUE,
    SUCCESS_CASH_VALUE,
    FAILURE_CASH_VALUE,
} from "../../Constant/actionTypes";

const initilizeState = {
    cash: false
}

export default function cashDataReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_CASH_VALUE:
            return{
                ...state,
                cash: false
            }
        case SUCCESS_CASH_VALUE:
            return{
                ...state,
                cash: action.cash,
            }
        case FAILURE_CASH_VALUE:
            return{
                ...state,
                cash: false
            }
        default:
            return state
    }
}