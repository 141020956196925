import React, { useState, forwardRef, useImperativeHandle } from "react";
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
//yyyy-mm-dd
export default forwardRef((props, ref) => {
    const [selectedDate, setSelectedDate] = useState(null);
    function handleDateChange(d) {
        if (d) {
            d.setHours(0, 0, 0, 0);
        }
        setSelectedDate(d);
    }
    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                let dateString = null;
                if (selectedDate) {
                    dateString = format(selectedDate, 'dd/MM/yyyy');
                }
                return dateString;
            },
            isCancelAfterEnd: () => {
                return !selectedDate;
            },
            afterGuiAttached: () => {
                // let current_datetime = new Date()
                // // let formatted_date =current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate();
                // //  const [_, day, month, year] =  props.value==""? formatted_date: props.value.split('-').reverse().join('/').match(/(\d{2})\/(\d{2})\/(\d{4})/);
                // let selectedDate = new Date(year, month - 1, day);
                // if(props.value==""){
                //     setSelectedDate(current_datetime);
                // }
                // else{
                //     setSelectedDate(selectedDate);
                // }
            }
        };
    });
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                style={{ width: '100%', margin: 0, padding: '6px 10px', }}
                margin="normal"
                id="date-picker-dialog"
                format="dd/MM/yyyy"
                value={selectedDate}
                maxDate={new Date()}
                onChange={handleDateChange}
                variant="inline"
                disableToolbar
                placeholder={'Enter ' + props.column.colId}
            />
        </MuiPickersUtilsProvider>
    )
}); 