import React from "react";
import "./privacy.css";

export default function Privacy() {
  return (
    
      
        <div className="cantainer">
          <div>
            <h1>Stoxdoc Privacy Policy</h1>
            <p>
              This page informs you of our policies regarding the collection,
              use, and disclosure of personal data when you use our Service and
              the choices you have associated with that data. We use your data
              to provide and improve the Service. By using the Service, you
              agree to the collection and use of information in accordance with
              this policy. Unless otherwise defined in this Privacy Policy,
              terms used in this Privacy Policy have the same meanings as in our
              Terms of Use
            </p>
            <h3>Definitions</h3>
            <h4>Cookies</h4>
            <p>Cookies are small pieces of data stored on a User’s device.</p>
            <h3>Data Controller</h3>
            <p>
              Data Controller means a person who (either alone or jointly or in
              common with other persons) determines the purposes for which and
              the manner in which any personal data are, or are to be,
              processed. <br />
              For the purpose of this Privacy Policy, we are a Data Controller
              of your data.
            </p>
            <h3>Data Processor (or Service Providers)</h3>
            <p>
              Data Processor (or Service Provider) means any person (other than
              an employee of the Data Controller) who processes the data on
              behalf of the Data Controller.
              <br />
              We may use the services of various Service Providers in order to
              process your data more effectively.
            </p>
            <h3>Data Subject</h3>
            <p>
              {" "}
              Data Subject is any living individual who is the subject of
              Personal Data.
            </p>
            <h3>Personal Data</h3>
            <p>
              Personal Data means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
            </p>
            <h3>Privacy Act</h3>
            <p>
              Privacy Act refers to the Australian Privacy Principles{" "}
              <a>
                https://www.oaic.gov.au/privacy-law/privacy-act/australian-privacy-principles
              </a>
            </p>
            <h3>GDPR</h3>
            <p>
              GDPR means the General Data Protection Regulation, being
              Regulation 2016/679 of the European Parliament and of the Council.
            </p>
            <h3>User</h3>
            <p>
              The User is the individual using our Service. The User corresponds
              to the Data Subject, who is the subject of Personal Data.
            </p>
            <h3>Usage Data</h3>
            <p>
              Usage Data is data collected automatically either generated by the
              use of the Service or from the Service infrastructure itself (for
              example, the duration of a page visit).
            </p>
            <h3>Information Collection And Use</h3>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you. The types of
              data we collect are listed below.
            </p>
            <h3>Personal Data</h3>
            <p>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you ("Personal Data"). Personally identifiable
              information may include, but is not limited to:
            </p>
            <h3>Email address</h3>
            <p>
              First name and last name
              <br /> Usage Data
              <br /> Watchlists or Portfolio Data (can include transactions)
              <br /> We may use your Personal Data to contact you with
              newsletters, marketing or promotional materials and other
              information that may be of interest to you. You may opt out of
              receiving any, or all, of these communications from us by
              following the unsubscribe link or instructions provided in any
              email we send.
            </p>
            <h3>Usage Data</h3>
            <p>
              We may also collect information how the Service is accessed and
              used ("Usage Data"). This Usage Data may include information such
              as your computer's Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </p>
            <h3>Location Data</h3>
            <p>
              We may use information about your location if you give us
              permission to do so (“Location Data”). We use this data to provide
              features of our Service, to improve and customize our Service.
              <br />
              You can enable or disable location services when you use our
              Service at any time, through your device settings.
            </p>
            <h3>Tracking & Cookies Data</h3>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Service and hold certain information.
              <br />
              Cookies are files with small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Tracking technologies also
              used are beacons, tags, and scripts to collect and track
              information and to improve and analyse our Service.
              <br />
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </p>
            <h4>Examples of Cookies we use:</h4>
            <p>
              <span style={{ fontWeight: "bold" }}>Session Cookies:</span> We
              use Session Cookies to operate our Service.
              <br />
              <span style={{ fontWeight: "bold" }}>Preference Cookies:</span> We
              use Preference Cookies to remember your preferences and various
              settings.
              <br />
              <span style={{ fontWeight: "bold" }}>Security Cookies:</span> We
              use Security Cookies for security purposes.
            </p>
            <h3>Use of Data</h3>
            <p>
              <span style={{ fontWeight: "bold" }}>
                Stoxdoc uses the collected data for various purposes:
              </span>
            </p>
            <p>
              To provide and maintain our Service;
              <br />
              To notify you about changes or updates to our Service;
              <br />
              To allow you to participate in interactive features of our Service
              when you choose to do so;
              <br />
              To provide customer support and administrative purposes;
              <br />
              To gather analysis or valuable information so that we can improve
              our Service;
              <br />
              To monitor the usage of our Service;
              <br />
              To detect, prevent and address technical issues;
              <br />
              To provide updates on the status of any application you may have
              submitted;
              <br />
              To contact you regarding any information or other content that you
              have posted to the Stoxdoc website;
              <br />
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information.
            </p>
            <h3>Your Choice</h3>
            <p>
              You may, of course, decline to share your personally-identifiable
              information with Stoxdoc, in which case Stoxdoc will not be able
              to provide to you some of the features and functionality found on
              the Stoxdoc Service. If you are a Registered User (as defined in
              the Terms of Use) with a registered account on the Stoxdoc
              Service, you may update, correct, or delete your account
              information and preferences at any time by going to{" "}
              <a>https://Stoxdoc.com/user/profile</a>. <br />
              To protect your privacy and security, we take reasonable steps to
              verify your identity before granting your account access or making
              corrections to your information. You are responsible for
              maintaining the secrecy of your unique password and account
              information at all times.
            </p>
            <h3>Retention of Data</h3>
            <p>
              Stoxdoc will retain your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
              <br />
              Stoxdoc will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of our Service, or we are legally
              obligated to retain this data for longer time periods.
            </p>
            <h3>Transfer Of Data</h3>
            <p>
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ than those from your jurisdiction.
              <br />
              If you are located outside India and choose to provide information
              to us, please note that we transfer the data, including Personal
              Data, to the India and process it there.
              <br />
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
              <br />
              Stoxdoc will take all steps reasonably necessary to ensure that
              your data is treated securely and in accordance with this Privacy
              Policy and no transfer of your Personal Data will take place to an
              organization or a country unless there are adequate controls in
              place including the security of your data and other personal
              information.
            </p>
            <h3>Disclosure Of Data</h3>
            <p>
              Stoxdoc does not share your personally identifiable information
              with other organizations for their marketing or promotional uses
              without your express consent.
              <br />
              Stoxdoc may disclose automatically collected and other aggregate
              non-personally-identifiable information with interested third
              parties to, among other things, assist such parties in
              understanding the usage patterns for certain content, services,
              advertisements, promotions, and/or functionality on the Stoxdoc
              Service.
              <br />
              Stoxdoc may disclose User information to affiliated companies or
              other businesses or persons to: provide website hosting,
              maintenance, and security services; conduct data analysis and
              create reports; offer certain functionality; and assist Stoxdoc in
              improving the Stoxdoc Service and creating new services features.
              We require that these parties process such information in
              compliance with this Privacy Policy, we authorize only a limited
              use of such information, and we require these parties to use
              reasonable confidentiality measures.
            </p>
            <h3>Disclosure for Law Enforcement</h3>
            <p>
              Under certain circumstances, Stoxdoc may be required to disclose
              your Personal Data if required to do so by law or in response to
              valid requests by public authorities (e.g. a court or a government
              agency).
            </p>
            <h3>Legal Requirements</h3>
            <h4>Stoxdoc may disclose your Personal Data in the good faith belief that such action is necessary to:</h4>
            <p>
              To comply with a legal obligation;<br/>To protect and defend the rights or property of Stoxdoc.com<br/>To prevent or investigate possible wrongdoing in connection with the Service;<br/>To protect the personal safety of users of the Service or the public;<br/>To protect against legal liability.
            </p>
            <h3>Security Of Data</h3>
            <p>
              The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.<br/>If Stoxdoc learns of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Stoxdoc Service or providing personal information to us, you agree that we can communicate with you electronically regarding security and privacy issues relating to your use of the Stoxdoc Service. Stoxdoc may post a notice on the Stoxdoc Site if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.
            </p>
            <h3>"Do Not Track" Signals</h3>
            <p>
              We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.<br/>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
            </p>
            <h3>Your Rights</h3>
            <p>
              Stoxdoc aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.<br/>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.<br/>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
            </p>
            <h4>In certain circumstances, you have the right:</h4>
            <p>
              To access and receive a copy of the Personal Data we hold about you;<br/>To rectify any Personal Data held about you that is inaccurate;<br/>To request the deletion of Personal Data held about you.
            </p>
            <p>
              You have the right to data portability for the information you provide to Stoxdoc. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.<br/>Please note that we may ask you to verify your identity before responding to such requests.
            </p>
            <h3>Service Providers</h3>
            <p>
              We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.<br/>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
            </p>
            <h3>Analytics</h3>
            <p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
            <h4>These include but are not limited to:</h4>
            <p>Google Analytics;<br/>Hotjar;</p>
            <h3>Behavioural Remarketing</h3>
            <p>Stoxdoc does not use remarketing services to advertise on third party websites to you after you visited our Service.</p>
            <h3>Payments</h3>
            <p>
              We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).<br/>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.<br/>The payment processor we work with is Stripe. Their Privacy Policy can be viewed at: <a>https://stripe.com/us/privacy</a>
            </p>
            <h3>Links To Other Sites</h3>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br/>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
            </p>
            <h3>Children's Privacy</h3>
            <p>Protecting the privacy of young children is especially important. Our Service does not address anyone under the age of 13 ("Children"). For that reason, Stoxdoc does not knowingly collect or maintain personally identifiable information from persons under 13 years of age, and no part of the Stoxdoc Service is directed to persons under 13. If you are under 13 years of age, then please do not use or access the Stoxdoc Service at any time or in any manner. Any person who provides their personal information to Stoxdoc through the Stoxdoc Service represents that they are 13 years of age or older. If Stoxdoc learns that personally identifiable information of persons less than 13 years of age has been collected without verifiable parental consent, then Stoxdoc will take the appropriate steps to delete this information from our servers.</p>
            <h3>In the Event of Merger, Sale or Bankruptcy</h3>
            <p>
            In the event that Stoxdoc should ever be acquired by or merged with a third-party entity, we reserve the right, in any of these circumstances, to transfer or assign the information that we have collected from Users as part of such merger, acquisition, sale, or other change of control. In the event of Stoxdoc’s bankruptcy, insolvency, or assignment for the benefit of creditors, we may not be able to control how information that we have collected from Users is transferred or used.
            </p>
            <h3>European Union</h3>
            <p>Stoxdoc is a data controller and processor for the purposes of the GDPR and by your consenting to this Privacy Statement Stoxdoc is able to processes your Personal Data in accordance with this Privacy Statement.</p>
            <h3>Changes To This Privacy Policy</h3>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.<br/>We may let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.<br/>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
            <h3>Feedback and Complaints</h3>
            <p>If you have any complaints about our dealings with your Personal Information including any breaches by us of any Indian Privacy Principles or any questions regarding this Privacy Statement you are able to submit that complaint or query by contacting us<br/>Any complaints received by us will be referred to our compliance team for prompt investigation and a written response will be provided to you as soon as possible.</p>
            <h3>Contact Us</h3>
            <p>If you have any questions about this Privacy Policy, please contact us by email: <a>support@stoxdoc.com</a><br/>Any advice contained in this website is general advice only and has been prepared without considering your objectives, financial situation or needs. You should not rely on any advice and/or information contained in this website and before making any investment decision we recommend that you consider whether it is appropriate for your situation and seek appropriate financial, taxation and legal advice.</p>
          </div>
        </div>
        
  );
}
