import React from "react";
import "./WatchListDropDown.scss";
import PropTypes from 'prop-types';
import { ReactComponent as Delete } from "../../Assets/Images/delete.svg";

class WatchlistDropDown extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { openCloseModal } = this.props;
        return (
            <div className="watchlist-dropdown-container">
                <div style={{ display: "flex", width: '170px' }}>
                    {/* <Delete style={{cursor: "pointer", marginRight: '15px', marginTop: '3px', width: '50px'}} onClick={this.props.handleDeleteWatchListModal}/> */}
                    <select value={this.props.dropDownWatchlist} onChange={(e) => this.props.handleWatchlistDropDownChange(e, e.target.value, 2, "Watchlist")}>
                        {
                            this.props.userData.watchlists && this.props.userData.watchlists.map((item, index) => {
                                return <option key={index} value={item.slice(item.indexOf('_')+1,item.length)}>{item.slice(item.indexOf('_') + 1)}</option>
                            })
                        }
                    </select>
                </div>
            </div>
        )
    }
}

WatchlistDropDown.propTypes = {
    dropDownWatchlist: PropTypes.string,
    handleWatchlistDropDownChange: PropTypes.func,
    handleDeleteWatchListModal: PropTypes.func,
};

export default WatchlistDropDown;