import {
    REQUEST_GET_TRANSACTION_DETAILS_HISTORY,
    SUCCESS_GET_TRANSACTION_DETAILS_HISTORY,
    FAILURE_GET_TRANSACTION_DETAILS_HISTORY,
} from "../../Constant/actionTypes";

const initilizeState = {
    transactionHistoryDetails: '',
    transactionFlag: false
}

export default function getTransactionHistoryReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_GET_TRANSACTION_DETAILS_HISTORY:
            return{
                ...state,
                transactionFlag: false
            }
        case SUCCESS_GET_TRANSACTION_DETAILS_HISTORY:
            return{
                ...state,
                transactionHistoryDetails: action.transactionHistoryDetails,
                transactionFlag: true
            }
        case FAILURE_GET_TRANSACTION_DETAILS_HISTORY:
            return{
                ...state,
                transactionFlag: false
            }
        default:
            return state;
    }
}