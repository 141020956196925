import {
    REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING,
    SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING,
    FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING,
} from "../../Constant/actionTypes";

const initilizeState = {
    backtestingFlag: false
}

export default function backtestingPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING:
            return{
                ...state,
                backtestingFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING:
            return{
                ...state,
                backtestingData:action.backtestingData,
                backtestingFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING:
            return{
                ...state,
                backtestingFlag: false
            }
        default:
            return state
    }
}
