import {
    REQUEST_PORTFOLIO_ANALYTICS_NAV,
    SUCCESS_PORTFOLIO_ANALYTICS_NAV,
    FAILURE_PORTFOLIO_ANALYTICS_NAV,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function navPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_NAV:
            return {
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_NAV:
            return {
                ...state,
                navData: action.navData,
            }
        case FAILURE_PORTFOLIO_ANALYTICS_NAV:
            return{
                ...state
            }
        default: return state;
    }
}