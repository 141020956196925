import {
    REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY,
    SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY,
    FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestLiquidityPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY
    }
}

export const successLiquidityPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY,
        liquidityData:result
    }
}

export const failureLiquidityPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY,
    }
}

export const handleLiquidityPortfolioAction = (dropDownPortFolio,accessToken ) => {
    return function(dispatch){
        dispatch(requestLiquidityPortfolio())
        Api.LiquidityPortfolioApi(dropDownPortFolio,accessToken).then((result)=>{
            dispatch(successLiquidityPortfolio(result.data))
        }).catch(error=>{
            dispatch(failureLiquidityPortfolio(error))
        })
    }
}