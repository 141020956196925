import {
    REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE,
    SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE,
    FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestPerformance = () =>{
    return{
        type: REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE,
    }
}

export const successPerformance = (result) =>{
   return{
       type: SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE,
       performanceData : result
    }
}

export const failurePerformance = () =>{
   return{
       type:  FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE,
   }
}

export const handlePerformanceAction = (dropDownPortFolio,accessToken) =>{
   return function(dispatch){
       dispatch(requestPerformance())
       Api.PerformanceApi(dropDownPortFolio,accessToken).then(result=>{
           dispatch(successPerformance(result.data))
       }).catch(error=>{
           dispatch(failurePerformance(error))
       })
   }
}