import {
    REQUEST_GET_PORTFOLIO,
    SUCCESS_GET_PORTFOLIO,
    FAILURE_GET_PORTFOLIO,

    REQUEST_UPDATE_PORTFOLIO,
    SUCCESS_UPDATE_PORTFOLIO,
    FAILURE_UPDATE_PORTFOLIO,
    REQUEST_IBKR_VERIFY,
    SUCCESS_IBKR_VERIFY,
    FAILURE_IBKR_VERIFY,

} from "../../Constant/actionTypes";

const initilizeState = {
    getPortfolioData: {},
    ibkrVerifyflag:""
}

export default function portfolioReducer(state = initilizeState, action) {
    switch (action.type) {
        case REQUEST_GET_PORTFOLIO:
            return {
                ...state,
            }
        case SUCCESS_GET_PORTFOLIO:
            return {
                ...state,
                getPortfolioData: action.getPortfolioData
            }
        case FAILURE_GET_PORTFOLIO:
            return {
                ...state,
            }
        case REQUEST_UPDATE_PORTFOLIO:
            return {
                ...state,
            }
        case SUCCESS_UPDATE_PORTFOLIO:
            return {
                ...state,
            }
        case FAILURE_UPDATE_PORTFOLIO:
            return {
                ...state,
            }
            case REQUEST_IBKR_VERIFY:
                return {
                    ...state,
                    ibkrVerifyflag:"request"
                }
            case SUCCESS_IBKR_VERIFY:
                return {
                    ...state,
                    ibkrVerifyflag:"success"
                }
            case FAILURE_IBKR_VERIFY:
                return {
                    ...state,
                    ibkrVerifyflag:"failure"
                }
        default:
            return state
    }
}
