import React,{useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as LogoTagline } from "../../Assets/Images/logoTagline.svg";
import { useHistory } from 'react-router-dom';
import "./forgetpassword.scss";
import jwt from 'jwt-decode'
import { handleSubmitUpdatePasswordAction } from "../../Redux/actions/updatePasswordAction";

const ForgetPassword = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const updatePasswordMessage = useSelector(state => state.updatePasswordReducer.updatePasswordMessage)
    const [forgetpasswordUser, setForgetPasswordUser] = useState({ email: '', newpassword: '', confirmpassword:'' });
    const [usesrID, setUsesrID] = useState('')
    const [checkPasswordMatchError, setcheckPasswordMatchError] = useState('');

    const t = window.location.href.split('=')[1];
    
    useEffect(() => {
       setUsesrID(jwt(t).identity)
    }, [])

    const handleChange = (event) => {
        setForgetPasswordUser((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }))
    }
    
    const handleForgetPasswordSubmit = (event) => {
        event.preventDefault();
        if (usesrID.includes('@') && forgetpasswordUser.newpassword===forgetpasswordUser.confirmpassword) {
            dispatch(handleSubmitUpdatePasswordAction(usesrID,forgetpasswordUser.newpassword,t))
            setcheckPasswordMatchError('')
            if(updatePasswordMessage.length){
                    history.push('/')
            }
        }
        else {
            setcheckPasswordMatchError('New password and confirm password not same')
        }
    }
    
    return (
        <div className="forgetpassword-container">
            <div className="ForgetPassword reg-form">
                <LogoTagline className="reg-logo" width="120" height="120" />
                <div className="reg-tab">
                    <span className="tab-btn active" style={{ cursor: 'pointer' }}>Update Password</span>
                </div>
                <form className="form">
                    <fieldset className="fieldset">
                        <label className="label">Email address</label>
                        <input className="input" type="text" name="email" value={usesrID} onChange={handleChange} placeholder="e.g. example@gmail.com" />
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">New Password</label>
                        <input className="input" type="password" name="newpassword" value={forgetpasswordUser.newpassword} onChange={handleChange} placeholder="••••••••••" />
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">Confirm Password</label>
                        <input className="input" type="password" name="confirmpassword" value={forgetpasswordUser.confirmpassword} onChange={handleChange} placeholder="••••••••••" />
                        {<span style={{color: 'red'}}>{checkPasswordMatchError}</span>}
                    </fieldset>
                    <button className="reg-btn" type="submit" onClick={handleForgetPasswordSubmit}>Submit</button>
                </form>
            </div>
        </div>
    )
}

export default ForgetPassword;