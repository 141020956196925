import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import {
    REQUEST_USER_SIGN_UP,
    SUCCESS_USER_SIGN_UP,
    FAILURE_USER_SIGN_UP,
    RESET_SIGNUP
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestSignUp = () => {
    return {
        type: REQUEST_USER_SIGN_UP,
    }
}

export const successSignUp = (result) => {
    return {
        type: SUCCESS_USER_SIGN_UP,
        signUpUserDetails: result,
    }
}

export const failureSignUp = (errorMessage) => {
    return {
        type: FAILURE_USER_SIGN_UP,
        errorMessage: errorMessage,
    }
}

export const handleSignUpAction = (registerUser) => {
    return function (dispatch) {
        dispatch(requestSignUp())
        Api.SignUpApi(registerUser).then(result => {
            if (result.data) {
                toast.success('Signup Successful')
            }
            // localStorage.setItem('accessToken',result.data.accessToken)
            dispatch(successSignUp(result.data))
        }).catch(error => {
            dispatch(failureSignUp(error.response.data))
        })
    }
}
export const reset = () => {
    return {
        type: RESET_SIGNUP,
        CouponVerificationData: {}
    }
}

export const handleResetAction = () => {
    return function (dispatch) {
        dispatch(reset())
    }
}
