// src/components/area.rechart.js
import { format, parseISO } from "date-fns";
import React from "react";
import { connectAdvanced } from "react-redux";
import './AreaGraph.scss'
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line, Legend, Label } from 'recharts';

class AreaRechartComponent extends React.Component {
    constructor(props) {
        // console.log({ props })
        super(props);
    }
    CustomTooltip = ({ active, payload, label }) => {
        // console.log(active,payload,label)
        if (active == true && payload?.length > 0) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Date : ${label} `}</p>
                    <p className="label">{`Stocks : ${payload && (payload[0]?.value).toFixed(2)}`}</p>
                </div>
            );
        }
        return null;
    };
    render() {
        const { navData, title, navSwitchTitle, navLineData } = this.props;
        const data_ts_rec = navLineData?.data?.data_ts;
        const dataTs = data_ts_rec?.map(item => ({ actual: item.actual * 100, date: item.date, frozen: item.frozen * 100}));

        function CustomizedTick(str) {
            const { x, y, payload } = str;
            return (
                <g transform={`translate(${x},${y})`}>
                    <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        fontWeight= 'bolder'
                        fill="#d1d1d1"
                        transform="rotate(-60)"
                    >
                        {payload?.value}
                    </text>
                </g>
            );
        };
        return (
            <div>
                <ResponsiveContainer width='100%' height={400}>
                    {
                        title === 'Decision Stats Return Line (%)' ?
                            <LineChart
                                width={500}
                                height={300}
                                data={dataTs}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <XAxis dataKey='date'
                                    height={100} tick={<CustomizedTick />}
                                    style={{ fontWeight: 'bold' }}
                                />
                                <YAxis style={{ fontWeight: 'bolder', fill: '#d1d1d1' }} domain tickCount={10}
                                    tickFormatter={(nav) => `${(1000000000 < nav) ? ((nav / 1000000000)).toFixed(2) + 'B' :
                                        (1000000 <= nav && nav < 1000000000) ? ((nav / 1000000)).toFixed(2) + 'M' :
                                            (1000 <= nav && nav < 1000000) ? ((nav / 1000)).toFixed(2) + 'K' :
                                                (-1000000000 > nav) ? ((nav / 1000000000)).toFixed(2) + 'B' :
                                                    (-1000000 >= nav && nav > -1000000000) ? ((nav / 1000000)).toFixed(2) + 'M' :
                                                        (-1000 >= nav && nav > -1000000) ? ((nav / 1000)).toFixed(2) + 'K' :
                                                            ((nav)).toFixed(1) +'%'
                                        }`} />
                                <Tooltip
                                    //  contentStyle={styles.tooltipWrapper}
                                    //  labelStyle={styles.tooltip}
                                    formatter={value => `${(parseFloat(value)).toFixed(2)+'%'}`}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="actual"
                                    stroke="#8884D8"
                                    activeDot={{ r: 1 }}
                                />
                                <Line type="monotone" dataKey="frozen" stroke="#82CA9D" />
                                {/* <Line type="monotone" dataKey="frozen" stroke="#82CA9D" /> */}
                                {/* <Tooltip content={this.CustomTooltip} />
                                <Legend />
                                <Tooltip content={this.CustomTooltip} />
                                {/* <Legend /> */}
                                <Line type="monotone" dataKey="actual" stroke="#ffffff" activeDot={{ r: 8 }} />
                                {
                                    navSwitchTitle === "levered_nav_lines" ?
                                        navData.map((v, i) => {
                                            return (
                                                <Line key={i} type="monotone" dataKey={(data) => data.leaverd[`nav${i}`]} stroke="#ffffff" strokeDasharray="3 4 5 2" />
                                            )
                                        })
                                        :
                                        navData.map((v, i) => {
                                            return (
                                                <Line key={i} type="monotone" dataKey={(data) => data.unleaverd[`nav${i}`]} stroke="#ffffff" strokeDasharray="3 4 5 2" />
                                            )
                                        })
                                }
                            </LineChart>
                            :
                            <AreaChart
                                data={navData}
                                margin={{ top: 10, right: 30, left: 35, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="rgba(219, 85, 235, 0.6)" stopOpacity={0.8} />
                                        <stop offset="75%" stopColor="rgba(56, 56, 56, 0)" stopOpacity={0.05} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="date"
                                    height={100} tick={<CustomizedTick />}
                                    style={{ fontWeight: 'bold' }}
                                />
                                <YAxis dataKey="nav" domain
                                    style={{ fontWeight: 'bold', fill: '#d1d1d1' }} 
                                    tickFormatter={(nav) => `${(1000000000 < nav) ? ((nav / 1000000000)).toFixed(2) + 'B' :
                                        (1000000 <= nav && nav < 1000000000) ? ((nav / 1000000)).toFixed(2) + 'M' :
                                            (1000 <= nav && nav < 1000000) ? ((nav / 1000)).toFixed(2) + 'K' :
                                                (-1000000000 > nav) ? ((nav / 1000000000)).toFixed(2) + 'B' :
                                                    (-1000000 >= nav && nav > -1000000000) ? ((nav / 1000000)).toFixed(2) + 'M' :
                                                        (-1000 >= nav && nav > -1000000) ? ((nav / 1000)).toFixed(2) + 'K' :
                                                            ((nav)).toFixed(3)

                                        }`}
                                />
                                <CartesianGrid strokeDasharray="3 3" stroke="none" />
                                <Tooltip
                                    contentStyle={styles.tooltipWrapper}
                                    labelStyle={styles.tooltip}
                                    formatter={value => `${(parseFloat(value)).toFixed(1)}`}
                                />
                                <Area
                                    type="monotone"
                                    dataKey="nav"
                                    stroke="#ffffff"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                />
                            </AreaChart>
                    }
                </ResponsiveContainer>
            </div>
        )
    };
}

const styles = {
    container: {
        maxWidth: 700,
        margin: "0 auto",
    },
    tooltipWrapper: {
        background: "#DB55EB",
        border: "none",
        color: 'white',
        fontWeight: 'bold'
    },
    tooltip: {
        color: "white"
    }
};
export default AreaRechartComponent;