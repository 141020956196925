import React, { Component } from "react";
import NetAssetValue from "../Components/NetAssetValue/NetAssetValue";
import BestWorstPositionTable from "../Components/DicisionStateTables/BestWorstPositionTable/index"
import DecisionStatsHitRatioTable from "../Components/DicisionStateTables/decisionStatsHitRatioTable/decisionStatsHitRatioTable.js"
// decisionStatsHitRatioTable
import PerformanceTable from "../Components/DicisionStateTables/decisionStatsPerformanceTable/PerformanceTable";
import DecisionStatsReturnLine from "../Components/DecisionStatsReturnLine/DecisionStatsReturnLine";
import PerformanceTablePercentage from "../Components/DicisionStateTables/decisionStatsPerformanceTable/PerformanceTablePercetage";

class DecisionStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            decisionStatsNavGraphData: []
        }
    }

    componentDidUpdate(preProps) {
        if (preProps.navLineData != this.props.navLineData) {
            let leaverd_line_date = []
            if (this.props?.navLineData?.data?.levered_nav_lines && Object.keys(this.props?.navLineData?.data?.levered_nav_lines)) {
                Object.keys(this.props?.navLineData?.data?.levered_nav_lines).map((date, index) => (
                    leaverd_line_date.push(date)
                ));
            }
            let leaverd = []
            const myfun = (a) => {
                leaverd.push({})
                leaverd_line_date.map((x, i) => {
                    leaverd[a] = Object.assign({
                        [`nav${i}`]: this.props?.navLineData?.data?.levered_nav_lines[x][a]?.levered_nav
                    }, leaverd[a]);
                })
            }
            for (let i = 0; i < this.props?.navLineData?.data?.actual?.length; i++) {
                myfun(i)
            }

            // //unleaverd_lines...................................................
            let unleaverd_line_date = []
            if (this.props?.navLineData?.data?.unlevered_nav_lines && Object.keys(this.props?.navLineData?.data?.unlevered_nav_lines)) {
                Object.keys(this.props?.navLineData?.data?.unlevered_nav_lines)?.map((date, index) => (
                    unleaverd_line_date?.push(date)
                ));
            }
            let unleaverd = []
            const myfun1 = (a) => {
                unleaverd.push({})
                unleaverd_line_date?.map((x, i) => {
                    unleaverd[a] = Object.assign({
                        [`nav${i}`]: this.props?.navLineData?.data?.unlevered_nav_lines[x][a]?.nav
                    }, unleaverd[a]);
                })
            }
            for (let i = 0; i < this.props?.navLineData?.data?.actual?.length; i++) {
                myfun1(i)
            }
            //...........................................................................
            let formated = []
            for (let i = 0; i < this.props?.navLineData?.data?.actual?.length; i++) {
                formated.push({
                    actual: this.props?.navLineData?.data?.actual[i]?.stocks_nav,
                    leaverd: leaverd[i],
                    unleaverd: unleaverd[i],
                    leaverd_line_date: leaverd_line_date[i],
                    unleaverd_line_date: unleaverd_line_date[i],
                    date: this.props?.navLineData?.data?.actual[i]?.date
                })
            }
            this.setState({
                decisionStatsNavGraphData: formated
            }, () => { })
        }
    }


    render() {
        let bestWorstData = [];
        if (this.props.navLineData) {
            bestWorstData = this.props?.navLineData
        }

        return (
            <div
            // className="inner-part"
            >
                <div
                // className="stoxdoc-innerpart"
                >
                    <div>
                        <DecisionStatsReturnLine title='Decision Stats Return Line (%)' {...this.props} navData={this.state.decisionStatsNavGraphData} />
                        {/* <NetAssetValue title='Decision Stats Return Line' {...this.props} navData={this.state.decisionStatsNavGraphData}  /> */}
                    </div>
                    <div className="status-table" id="bestWorstpositiontable">
                        <BestWorstPositionTable title='best portfolio' {...this.props} navData={bestWorstData} />
                        <BestWorstPositionTable title='worst portfolio' {...this.props} navData={bestWorstData} />
                    </div>
                    <div style={{ width: '100%', }}>
                        <PerformanceTable {...this.props} />
                        <PerformanceTablePercentage {...this.props}/>
                    </div>
                    <DecisionStatsHitRatioTable {...this.props} />
                </div>
            </div>
        )
    }
}

export default DecisionStats;