import React, { useState, useEffect } from "react";
import "./LeftHeader.scss";
import logo from "../../Assets/Images/logo.svg";
import homegreen from "../../Assets/Images/homegreen.png";
import homewhite from "../../Assets/Images/homewhite.png";
import watchlistgray from "../../Assets/Images/watchlistgray.png";
import watchlistgreen from "../../Assets/Images/watchlistgreen.png";
import portfoliogreen from "../../Assets/Images/portfoliogreen.png";
import portfoliogray from "../../Assets/Images/portfoliogray.png";
import newsgray from "../../Assets/Images/newsgray.svg";
import newsgreen from "../../Assets/Images/newsgreen.svg";
import eventgray from "../../Assets/Images/eventgray.png";
import eventgreen from "../../Assets/Images/eventgreen.png";
import dropdowngray from "../../Assets/Images/down.png";
import dropdowngreens from "../../Assets/Images/down.png";
import { useDispatch, useSelector } from 'react-redux';
import { handleContainerValueAction } from "../../Redux/actions/menuScrollAction";

const LeftContainer = (props) => {
    const [statehome, stateHome] = useState(false)
    const [statewatchlist, stateWatchlist] = useState(false)
    const [stateportfolio, statePortfolio] = useState(false)
    const [statedecisionstats, stateDecisionstats] = useState(false)
    const [statestripPayment, stateStripPayment] = useState(false)
    const [stateintegration, stateIntegration] = useState(false)
    const [stateplaidintegration, statePlaidIntegration] = useState(false)
    const [stateevents, stateEvents] = useState(false)
    const [statenews, stateNews] = useState(false)
    const [openWatch, setOpenWatch] = useState(props.watch)
    const [offset, setOffset] = useState(0);
    const [portfolio, setportfolio] = useState(0);
    const [home, sethome] = useState(0);
    window.onscroll = () => {
        setOffset(window.pageYOffset)
    }

    const dispatch = useDispatch();
    const handleCollapse = (id, value) => {
        if (id === 1) {
            handleScroll('0')
            stateHome(!statehome);
            setOpenWatch('close');
            stateWatchlist(false);
            statePortfolio(false);
            stateEvents(false);
            stateNews(false);
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
            stateIntegration(false)
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 2) {
            handleScroll('0')
            stateWatchlist(!statewatchlist);
            dispatch(handleContainerValueAction(id, value))
            stateHome(false);
            setOpenWatch('close');
            statePortfolio(false);
            stateEvents(false);
            stateNews(false);
            stateIntegration(false)
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
        } else if (id === 3) {
            handleScroll('Holdings')
            stateHome(false);
            stateWatchlist(false);
            setOpenWatch('close');
            stateEvents(false);
            stateNews(false);
            statePortfolio(!stateportfolio);
            stateDecisionstats(false)
            stateStripPayment(false)
            statePlaidIntegration(false)
            stateIntegration(false)
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 5) {
            handleScroll('0')
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateNews(false);
            stateEvents(!stateevents);
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
            stateIntegration(false)
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 4) {
            handleScroll('0')
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateNews(false);
            stateEvents(false);
            stateIntegration(false)
            stateDecisionstats(!statedecisionstats)
            stateStripPayment(false);
            statePlaidIntegration(false)
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 6) {
            handleScroll('0')
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateEvents(false);
            stateNews(!statenews);
            stateDecisionstats(false)
            stateStripPayment(false);
            stateIntegration(false)
            statePlaidIntegration(false)
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 7) {
            handleScroll('0')
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateEvents(false);
            stateNews(false);
            stateDecisionstats(false)
            statePlaidIntegration(false)
            stateIntegration(false)
            stateStripPayment(!statestripPayment);
            dispatch(handleContainerValueAction(id, value))
        }
        else if (id === 8) {
            handleScroll('0')
            stateIntegration(!stateintegration)
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateEvents(false);
            stateNews(false);
            stateDecisionstats(false)
            statePlaidIntegration(false)
            stateStripPayment(false);
            dispatch(handleContainerValueAction(id, value))
        } else if (id === 9) {
            handleScroll('0')
            stateIntegration(false)
            stateHome(false);
            stateWatchlist(false);
            statePortfolio(false);
            setOpenWatch('close');
            stateEvents(false);
            stateNews(false);
            stateDecisionstats(false)
            statePlaidIntegration(!stateplaidintegration)
            stateStripPayment(false);
            dispatch(handleContainerValueAction(id, value))
        }
    }


    const handleScroll = (menuName) => {
        if (document.getElementById(menuName) != null) {
            let id = document.getElementById(menuName)
            window.scroll({
                top: offset + (id.getBoundingClientRect().top - 90),
                behavior: 'smooth'
            });
        }
    }

    useEffect(() => {
        if (document.getElementById('Global Markets') != null && document.getElementById('Global Markets').getBoundingClientRect().top <= 550) {
            sethome(0);
        }
        if (document.getElementById('Sectors') != null && document.getElementById('Sectors').getBoundingClientRect().top <= 350) {
            sethome(1);
        }
        if (document.getElementById('Style') != null && document.getElementById('Style').getBoundingClientRect().top <= 350) {
            sethome(2);
        }
        if (document.getElementById('Countries') != null && document.getElementById('Countries').getBoundingClientRect().top <= 350) {
            sethome(3);
        }
        if (document.getElementById('Fixed Income') != null && document.getElementById('Fixed Income').getBoundingClientRect().top <= 350) {
            sethome(4);
        }
        if (document.getElementById('Commodities') != null && document.getElementById('Commodities').getBoundingClientRect().top <= 350) {
            sethome(5);
        }
        if (document.getElementById('Risk') != null && document.getElementById('Risk').getBoundingClientRect().top <= 350) {
            sethome(6);
        }
        if (document.getElementById('Holdings') != null && document.getElementById('Holdings').getBoundingClientRect().top <= 350) {
            setportfolio(0);
        }
        // if (document.getElementById('performance') != null && document.getElementById('performance').getBoundingClientRect().top <= 350) {
        //     setportfolio(1);
        // }
        if (document.getElementById('netassetvalue') != null && document.getElementById('netassetvalue').getBoundingClientRect().top <= 350) {
            setportfolio(1);
        }
        if (document.getElementById('transaction') != null && document.getElementById('transaction').getBoundingClientRect().top <= 350) {
            setportfolio(2);
        }
        if (document.getElementById('composition') != null && document.getElementById('composition').getBoundingClientRect().top <= 350) {
            setportfolio(3);
        }
        if (document.getElementById('pricetrends') != null && document.getElementById('pricetrends').getBoundingClientRect().top <= 350) {
            setportfolio(4);
        }
        if (document.getElementById('attribution') != null && document.getElementById('attribution').getBoundingClientRect().top <= 350) {
            setportfolio(5);
        }
        if (document.getElementById('heading') != null && document.getElementById('heading').getBoundingClientRect().top <= 350) {
            setportfolio(6);
        }
        if (document.getElementById('headingpercentage') != null && document.getElementById('headingpercentage').getBoundingClientRect().top <= 350) {
            setportfolio(7);
        }
        if (document.getElementById('liquidityvolatility') != null && document.getElementById('liquidityvolatility').getBoundingClientRect().top <= 350) {
            setportfolio(8);
        }
        if (document.getElementById('correlationmatrix') != null && document.getElementById('correlationmatrix').getBoundingClientRect().top <= 350) {
            setportfolio(9);
        }
        if (document.getElementById('maxdrawdown') != null && document.getElementById('maxdrawdown').getBoundingClientRect().top <= 350) {
            setportfolio(10);
        }
        if (document.getElementById('fundamentables') != null && document.getElementById('fundamentables').getBoundingClientRect().top <= 350) {
            setportfolio(11);
        }
        if (document.getElementById('backtesting') != null && document.getElementById('backtesting').getBoundingClientRect().top <= 350) {
            setportfolio(12);
        }
        if (document.getElementById('decisionstatsreturnline') != null && document.getElementById('decisionstatsreturnline').getBoundingClientRect().top <= 350) {
            setportfolio(13);
        }
        if (document.getElementById('bestWorstpositiontable') != null && document.getElementById('bestWorstpositiontable').getBoundingClientRect().top <= 350) {
            setportfolio(14);
        }
        if (document.getElementById('performancetableusd') != null && document.getElementById('performancetableusd').getBoundingClientRect().top <= 350) {
            setportfolio(15);
        }
        if (document.getElementById('performancetablepercentage') != null && document.getElementById('performancetablepercentage').getBoundingClientRect().top <= 350) {
            setportfolio(16);
        }
        if (document.getElementById('decisionstatshitratiotable') != null && document.getElementById('decisionstatshitratiotable').getBoundingClientRect().top <= 350) {
            setportfolio(17);
        }
    });

    useEffect(() => {
        if (props.watch == 'Watch') {
            setOpenWatch(props.watch);
            stateWatchlist(false);
            stateHome(false);
            statePortfolio(false);
            stateEvents(false);
            stateNews(false);
            stateIntegration(false)
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
        }
        else if (props.watch == 'Port') {
            setOpenWatch(props.watch);
            stateWatchlist(false);
            stateHome(false);
            statePortfolio(false);
            stateEvents(false);
            stateNews(false);
            stateIntegration(false)
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
        }
        else {
            setOpenWatch(props.watch);
            stateWatchlist(false);
            stateHome(false);
            statePortfolio(false);
            stateEvents(false);
            stateNews(false);
            stateIntegration(false)
            stateDecisionstats(false)
            stateStripPayment(false);
            statePlaidIntegration(false)
        }

    }, [props.watch]);
    useEffect(() => {
        if (props.transaction == 'transaction') {
            let id = document.getElementById('transaction')
            window.scroll({
                top: offset + (id.getBoundingClientRect().top - 300),
                behavior: 'smooth'
            });
            if (document.getElementById('transaction') != null && document.getElementById('transaction').getBoundingClientRect().top <= 350) {
                setportfolio(3);
            }
        }
    }, [props.transaction])
    const openCloseModal = useSelector(state => state.modalReducer.openCloseModal)
    return (<>

        {/* <div className="menu-btn"> 
                <span></span>
                <span></span>
                <span></span>
            </div> */}

        <div className={`left-header-container ${openCloseModal && 'left-header-zindex'} `}>

            <div className={`left-header ${openCloseModal && 'left-header-zindex'} `}>
                <div className="left-logo">
                    <img alt='' src={logo} />
                </div>
                <div className="main-list">
                    {<>
                        {/* Home Section Start */}
                        <div className="list" >
                            <div>
                                <p className={`list-title ${(!statehome) ? "" : ""}`} onClick={() => handleCollapse(1, "Home")}>
                                    <img alt='' className="icon" src={(!statehome) ? homegreen : homegreen} />Home
                                </p>
                                <img alt='' className="home-dropdown" onClick={() => handleCollapse(1, "Home")} src={(!statehome) ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                statehome &&
                                <>

                                    <div onClick={() => handleScroll('Global Markets')} className="main-sub-list">
                                        {home === 0 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Global Markets</p>
                                    </div>
                                    <div onClick={() => handleScroll('Sectors')} className="main-sub-list">
                                        {home === 1 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Sectors</p>
                                    </div>
                                    <div onClick={() => handleScroll('Style')} className="main-sub-list">
                                        {home === 2 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Style</p>
                                    </div>
                                    <div onClick={() => handleScroll('Countries')} className="main-sub-list">
                                        {home === 3 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Countries</p>
                                    </div>

                                    <div onClick={() => handleScroll('Fixed Income')} className="main-sub-list">
                                        {home === 4 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Fixed Income</p>
                                    </div>
                                    <div onClick={() => handleScroll('Commodities')} className="main-sub-list">
                                        {home === 5 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Commodities</p>
                                    </div>
                                    <div onClick={() => handleScroll('Risk')} className="main-sub-list">
                                        {home === 6 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Risk</p>
                                    </div>
                                </>
                            }
                        </div>
                        {/* Home Section End */}

                        {/* WatchList Start */}
                        <div className="list">
                            <div >
                                <p className={`list-title ${((!statewatchlist) && (openWatch != 'Watch')) ? "text-color" : ""}`} onClick={() => handleCollapse(2, "Watchlist")}>
                                    <img alt='' className="icon" src={((!statewatchlist) && (openWatch != 'Watch')) ? watchlistgray : watchlistgreen} />Watchlist
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(2, "Watchlist")} src={((!statewatchlist) && (openWatch != 'Watch')) ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                ((statewatchlist) && (openWatch != 'Watch')) &&
                                <><div></div>
                                </>
                            }
                        </div>
                        {/* Watchlist End */}

                        {/* Portfolio Start */}
                        <div className="list" >
                            <div>
                                <p className={`list-title ${((!stateportfolio) && (openWatch != 'Port')) ? "text-color" : ""}`} onClick={() => handleCollapse(3, "Portfolio")}>
                                    <img alt='' className="icon" src={((!stateportfolio) && (openWatch != 'Port')) ? portfoliogray : portfoliogreen} />Portfolio
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(3, "Portfolio")} src={((!stateportfolio) && (openWatch != 'Port')) ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                ((stateportfolio) && (openWatch != 'Port')) &&
                                <>
                                    <div onClick={() => handleScroll('Holdings')} className="main-sub-list">
                                        {portfolio === 0 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Holdings</p>
                                    </div>
                                    {/* <div onClick={() => handleScroll('performance')} className="main-sub-list">
                                        {portfolio === 1 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Performance</p>
                                    </div>*/}
                                    <div onClick={() => handleScroll('netassetvalue')} className="main-sub-list">
                                        {portfolio === 1 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Net Asset Value</p>
                                    </div>
                                    <div onClick={() => handleScroll('transaction')} className="main-sub-list">
                                        {portfolio === 2 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Transaction</p>
                                    </div>
                                    <div onClick={() => handleScroll('composition')} className="main-sub-list">
                                        {portfolio === 3 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Composition</p>
                                    </div>
                                    <div onClick={() => handleScroll('pricetrends')} className="main-sub-list">
                                        {portfolio === 4 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Price Trends</p>
                                    </div>

                                    <div onClick={() => handleScroll('attribution')} className="main-sub-list">
                                        {portfolio === 5 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Attribution</p>
                                    </div>
                                    <div onClick={() => handleScroll('heading')} className="main-sub-list">
                                        {portfolio === 6 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Return in USD</p>
                                    </div>
                                    <div onClick={() => handleScroll('headingpercentage')} className="main-sub-list">
                                        {portfolio === 7 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Return in Percentage</p>
                                    </div>
                                    <div onClick={() => handleScroll('liquidityvolatility')} className="main-sub-list">
                                        {portfolio === 8 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Liquidity</p>
                                    </div>
                                    <div onClick={() => handleScroll('correlationmatrix')} className="main-sub-list">
                                        {portfolio === 9 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Correlation Matrix</p>
                                    </div>
                                    <div onClick={() => handleScroll('maxdrawdown')} className="main-sub-list">
                                        {portfolio === 10 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Max Drawdown</p>
                                    </div>
                                    <div onClick={() => handleScroll('fundamentables')} className="main-sub-list">
                                        {portfolio === 11 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Fundamentals</p>
                                    </div>
                                    <div onClick={() => handleScroll('backtesting')} className="main-sub-list">
                                        {portfolio === 12 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Backtesting</p>
                                    </div>
                                    <div onClick={() => handleScroll('decisionstatsreturnline')} className="main-sub-list">
                                        {portfolio === 13 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Return Lines</p>
                                    </div>
                                    <div onClick={() => handleScroll('bestWorstpositiontable')} className="main-sub-list">
                                        {portfolio === 14 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Best-Worst Position</p>
                                    </div>
                                    <div onClick={() => handleScroll('performancetableusd')} className="main-sub-list">
                                        {portfolio === 15 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Performance (base currency)</p>
                                    </div>
                                    <div onClick={() => handleScroll('performancetablepercentage')} className="main-sub-list">
                                        {portfolio === 16 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Performance (%)</p>
                                    </div>
                                    <div onClick={() => handleScroll('decisionstatshitratiotable')} className="main-sub-list">
                                        {portfolio === 17 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">Hit Ratio</p>
                                    </div>
                                </>
                            }
                        </div>
                        {/* Portfolio End */}

                        {/* DecisionStats Start */}
                        {/* <div className="list" >
                            <div>
                                <p className={`list-title ${!statedecisionstats ? "text-color" : ""}`} onClick={() => handleCollapse(4, "DecisionStats")}>
                                    <img alt='' className="icon" src={!statedecisionstats ? eventgray : eventgreen} />DecisionStats
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(4, "DecisionStatss")} src={!statedecisionstats ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                statedecisionstats &&
                                <><div></div>
                                </>
                            }
                        </div> */}
                        {/* DecisionStats End */}

                        {/* Stripe Payment Start */}
                        {/* <div className="list" >
                            <div>
                                <p className={`list-title ${!statestripPayment ? "text-color" : ""}`} onClick={() => handleCollapse(7, "Payment")}>
                                    <img alt='' className="icon" src={!statestripPayment ? eventgray : eventgreen} />Payment
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(7, "Payment")} src={!statestripPayment ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                statestripPayment &&
                                <><div></div>
                                </>
                            }
                        </div> */}
                        {/* Stripe Payment  End */}

                        {/* Integration Start */}
                        <div className="list" >
                            {/* <div>
                                <p className={`list-title ${!stateintegration ? "text-color" : ""}`} onClick={() => handleCollapse(8, "Integrations")}>
                                    <img alt='' className="icon" src={!stateintegration ? eventgray : eventgreen} />IBKR Integrations
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(8, "Integrations")} src={!stateintegration ? dropdowngray : dropdowngreens} />
                            </div> */}
                            {
                                stateintegration &&
                                <>
                                    {/* <div onClick={() => handleScroll('IBKR')} className="main-sub-list">
                                        {integration === 0 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">IBKR</p>
                                    </div>
                                    <div onClick={() => handleScroll('PLAID')} className="main-sub-list">
                                        {integration === 1 ?
                                            <span className="main-active">
                                                <span className="main-sub-active" />
                                            </span>
                                            :
                                            <span className="main-inactive" />
                                        }
                                        <p className="sub-list">PLAID</p>
                                    </div> */}
                                </>
                            }
                        </div>
                        {/* Integration End */}

                        {/* Event Start */}
                        {/* <div className="list" >
                            <div>
                                <p className={`list-title ${!stateplaidintegration ? "text-color" : ""}`} onClick={() => handleCollapse(9, "PLAID")}>
                                    <img alt='' className="icon" src={!stateplaidintegration ? eventgray : eventgreen} />PLAID Integration
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(5, "PLAID")} src={!stateplaidintegration ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                stateplaidintegration &&
                                <><div></div>
                                </>
                            }
                        </div> */}
                        {/* Event End */}

                        {/* Event Start */}
                        {/* <div className="list" >
                            <div>
                                <p className={`list-title ${!stateevents ? "text-color" : ""}`} onClick={() => handleCollapse(5, "Events")}>
                                    <img alt='' className="icon" src={!stateevents ? eventgray : eventgreen} />Events
                                </p>
                                <img alt='' style={{ cursor: "pointer" }} onClick={() => handleCollapse(5, "Events")} src={!stateevents ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                stateevents &&
                                <><div></div>
                                </>
                            }
                        </div> */}
                        {/* Event End */}

                        {/* News Start */}
                        {/* <div className="list" >
                            <div>
                                <p className={`list-title ${!statenews ? "text-color" : ""}`} onClick={() => handleCollapse(6, "News")} >
                                    <img alt='' className="icon" src={!statenews ? newsgray : newsgreen} />News
                                </p>
                                <img alt='' onClick={() => handleCollapse(6, "News")} style={{ cursor: "pointer" }} src={!statenews ? dropdowngray : dropdowngreens} />
                            </div>
                            {
                                statenews &&
                                <><div></div>
                                </>
                            }
                        </div> */}
                        {/* News End */}
                    </>
                    }
                </div>
            </div>
            <div>
            </div>
        </div>
    </>
    )
};
export default LeftContainer;