import {
    REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
    SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
    FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
} from "../../Constant/actionTypes";

const initilizeState = {
    fundamentalsFlag: false,
    fundamentalsData: []
}

export default function fundametalsReducer(state = initilizeState, action) {
    switch (action.type) {
        case REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS:
            return {
                ...state,
                fundamentalsFlag: false,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS:
            return {
                ...state,
                fundamentalsData: action.fundamentalsData,
                fundamentalsFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS:
            return {
                ...state,
                fundamentalsFlag: false,
            }
        default:
            return state
    }
}