import React from "react";
import "../userDropDown/userDropDown.scss";
import { CgChevronDown } from "react-icons/cg";
import { Link, useHistory } from 'react-router-dom'
import { handleContainerValueAction } from "../../Redux/actions/menuScrollAction";
import { useDispatch } from "react-redux";

const UserDropDown = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const logout = () => {
        dispatch(handleContainerValueAction(1, 'Home'))
        localStorage.removeItem("token");
        history.push('/');
    }

    return (
        <div className="user-dropdown">
            <button className="user-name-dropbtn">{props.userData.name}<CgChevronDown /></button>
            <div className="user-dropdown-content">
                <a onClick={(e)=>props.handleView(e,7, "Payment",'close')}>Billing</a>
                {/* <Link to='/support'>Support</Link> */}
                <a onClick={(e)=>props.handleView(e,7, "privacy",'close')}>Privacy</a>
                <a onClick={(e)=>props.handleView(e,7, "terms",'close')}>Terms</a>
                <a onClick={logout} style={{ cursor: 'pointer' }}>Logout</a>
            </div>
        </div>
    )
}

export default UserDropDown;