import React, { useState, useEffect } from "react";
import "./performanceTable.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import TableHeader from "../../tableHeader/tableHeader";
import { number } from "prop-types";
import LockScreen from '../../../Assets/Images/lockScreen.jpg'
import * as _ from 'lodash';

const formatter = new Intl.NumberFormat('en-US', {
  currency: 'USD',
  minimumFractionDigits: 2
})

const PerformanceTable = (props) => {
  const monthlyReturns = props?.monthlyAbsoluteReturnsData?.data 

  const [recRowData, setRecRowData] = useState(null)
  const [cloneData, setCloneData] = useState(monthlyReturns)
  const [changeFormat, setChangeFormat] = useState('USD')
  const [gridApi, setGridApi] = useState(null);

  const onGridReady = params => {
    params.api.sizeColumnsToFit();
  };
  useEffect(() => {
    setCloneData(monthlyReturns)
  }, [monthlyReturns]);

  console.log("propsprops",props?.monthlyAbsoluteReturnsData)

  function onChangeDataForm(event = { target: { value: 'USD' } }) {
    setChangeFormat(event.target.value)
    let preData = _.cloneDeep(cloneData);
    preData?.map((elemItem, i) => {
      Object.keys(elemItem).map(item => {
        if (typeof (preData[i][item]) === 'number') {
          // preData[i][item] = formatter.format(parseFloat(preData[i][item]).toFixed(2))
          preData[i][item] = parseFloat(preData[i][item] ).toFixed(0) ;
          // event.target.value === 'USD' ? formatter.format(parseFloat(preData[i][item]).toFixed(2)) : parseFloat(preData[i][item] * 100).toFixed(2);
        }
        else if (preData[i][item] === "") {
          preData[i][item] = "-";
        }
      });
    })
    // console.log('preData',preData);
    setRecRowData(preData)
  }

  useEffect(() => {
    onChangeDataForm()
  }, [cloneData])

  // const [columnDefs] = useState([
  //   { field: 'year', width: 70, marginLeft:-1 }, { field: 'January',width: 89 }, { field: 'February',width: 97 }, { field: 'March',width: 80 }, { field: 'April',width: 70 }, { field: 'May',width: 70 }, { field: 'June',width: 73, marginLeft: -1 },
  //   { field: 'July',width: 75 }, { field: 'August',width: 85 }, { field: 'September',width: 112 }, { field: 'October',width: 96 }, { field: 'November',width: 108 }, { field: 'December',width: 107 }, { field: 'all',width: 65 }
  // ])

  const hasperformanceTable = props.showTableData?.indexOf("performance_table (in USD)") >-1
  return (
    <div className="decisionStatsPerformanceTable-table-container " id="performancetableusd" >
      <TableHeader title={`Performance (base currency)`}
        // component={
        //   <div style={{ display: "flex", verticalAlign: 'top' }}>
        //     <div className="watchlist-dropdown-container mr-10">
        //       <select onChange={onChangeDataForm}>
        //         <option value='USD'>In USD</option>
        //         <option value='Percentage'>In Percentage</option>
        //       </select>
        //     </div>
        //   </div>
        // }
      />
      {
        hasperformanceTable?<div className="decisionStatsPerformanceTable-table">
        <div className="ag-theme-alpine decisionStatsPerformanceTable-inner-table">
          <AgGridReact
            rowData={recRowData}
            onGridReady={onGridReady}
            // columnDefs={columnDefs}
            >
              <AgGridColumn headerName='Year' field="year" minWidth={85} cellStyle={(i) => ({ textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Jan' field="January" minWidth={85} ccellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Feb' field="February" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Mar' field="March" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Apr' field="April" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='May' field="May" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Jun' field="June" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Jul' field="July" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Aug' field="August" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Sep' field="September" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Oct' field="October" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Nov' field="November" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Dec' field="December" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
              <AgGridColumn headerName='Total' field="all" minWidth={85} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32', textAlign: 'left' } : { color: '#FF4C4D', textAlign: 'left' })}></AgGridColumn>
          </AgGridReact>
        </div>
      </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
      }
    </div>
  )
}
export default PerformanceTable;