import React, {
  useState
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "./TransactionTable.scss";
import './App.scss';
import { WITHOUT_CASH_TICKER, WITH_CASH_TICKER } from "./olympic_lists.js";
import { useSelector } from 'react-redux';
import Search from "../Search/Search";
import TableHeader from "../tableHeader/tableHeader";
import GridComponents from './Components';

let values;
function TransactionsTable(props) {
  const { transactionHistoryDetails } = props;
  const {EditTransaction}=props
  const [transactionHistory, setTransactionHistory] = useState([])
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [rowData, setRowData] = useState(null);
  const cashData = useSelector(state => state.cashDataReducer.cash)
  const currencyList = useSelector(state => state.getListOfCurrencyReducer.currencyList);
  const [transactionData, setTransactionData] = useState(props.transactionHistoryDetails)
  const editTransactionMessage = useSelector(state => state.performEditTransactionReducer.editTransactionMessage)
  const [height, setheight] = useState(100)
  const [width, setwidth] = useState('100%')

  React.useEffect(() => {
    setTransactionHistory(transactionHistoryDetails)
  }, [transactionHistoryDetails])

  React.useEffect(() => {
    if (transactionHistory && transactionHistory.length == 0) {
      setheight(100)
    }
    else if (transactionHistory && transactionHistory.length > 1 && transactionHistory && transactionHistory.length < 10) {
      setheight(transactionHistory.length * 47 + 100)
    }
    else {
      setheight(520)
      setwidth('100%')
    }
    setTransactionHistory(transactionHistoryDetails)
  }, [transactionHistoryDetails])

  const frameworkComponents = {
    simpleEditor: GridComponents.SimpleEditor,
    asyncValidationEditor: GridComponents.AsyncValidationEditor,
    autoCompleteEditor: GridComponents.AutoCompleteEditor,
    autoSearchEditor: GridComponents.AutoSearchEditor,
    EditSearch: GridComponents.EditSearch,
    agDateInput: GridComponents.MyDatePicker,
    dateEditor: GridComponents.DateEditor,
    actionsRenderer: GridComponents.ActionsRenderer,
    addRowStatusBar: GridComponents.AddRowStatusBar
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
    params.api.sizeColumnsToFit();
    setTransactionData(props.transactionHistoryDetails)
  }
  function dateFormatter(params) {
    var dateAsString = params?.value;
    var dateParts = dateAsString.split("-");
    var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    return ` ${dateParts[2]}${month[parseInt(dateParts[1]) - 1]} ${dateParts[0]}`;
  }

  const columnDefs = [
    {
      headerName: "Ticker",
      field: "security",
      cellEditor: "autoSearchEditor",
      // cellEditorParams: param => {
      //   // options: WITHOUT_CASH_TICKER
      // },
      rowDrag: true,
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
      cellStyle: { width: 500, }
    },
    {
      headerName: "Type",
      field: "transaction_type",
      cellEditor: "autoCompleteEditor",
      cellEditorParams: {
        options: WITHOUT_CASH_TICKER
      },
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
    },
    {
      headerName: "Date",
      field: "order_date",
      cellEditor: "dateEditor",
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
      filter: true,
      wrapText: true,
    },
    {
      headerName: "Share",
      field: "num_shares",
      cellEditor: "simpleEditor",
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
    },
    {
      headerName: "Currency",
      field: "currency",
      cellEditor: "autoCompleteEditor",
      cellEditorParams: {
        options: currencyList
      },
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },

    },
    {
      headerName: "Name",
      field: "security_name",
      // cellEditor: "simpleEditor",
      // wrapText: true,
      editable: false,
    },
    {
      headerName: "Price",
      field: "security_price",
      cellEditor: "simpleEditor",
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
    },
    {
      headerName: "Commission",
      minWidth: 140,
      field: "commission",
      cellEditor: "simpleEditor",
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
    },
    {
      headerName: "Cost",
      field: "exchange_fees",
      cellEditor: "simpleEditor",
      wrapText: true,
      editable: param => {
        return (param?.data?.order_date == '' || param?.data?.transaction_source == 'manual_entry' ? true : true)
      },
    },
    {
      headerName: "Action",
      // width: 160,
      colId: "actions",
      cellRenderer: "actionsRenderer",
      editable: false,
      filter: false,
      wrapText: true,
    },
  ];

  const defaultColDef = {
    // width: 170,
    editable: true,
    filter: true,
    // cellStyle: { textAlign: 'center' },
    resizable: true,
    portfolioId: props.dropDownPortFolio,
    suppressKeyboardEvent: params => params.editing
  };

  const onSearch = (event) => {
    let temp = []
    props.transactionHistoryDetails.map((value) => {
      if (value.security) {
        if (value.security.indexOf(event) > -1) {
          temp.push(value)
        }
      }
    })
    setTransactionHistory(temp)
  }

  return (
    <div className="transactionData-table-container" id="transaction">
      <TableHeader title='Transactions'
        component={
          <Search onSearch={onSearch} />
        }
      />
      <div className="transactionData-table">
        <div className="ag-theme-alpine w-100" style={{ height: height,width: width }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowDragManaged={true}
            animateRows={true}
            defaultColDef={defaultColDef}
            rowData={transactionHistory}
            getRowNodeId={data => data.id}
            onGridReady={onGridReady} //this will make responsive the table
            frameworkComponents={frameworkComponents} //this allow to create a new row
            editType="fullRow" //this will allow edit all the row no need to click every column 
            pagination={true}
            paginationPageSize={500}
          >
          </AgGridReact>
        </div>
      </div>
    </div>
  );
}

export default TransactionsTable;