import {
    REQUEST_CREATE_PORTFOLIO,
    SUCCESS_CREATE_PORTFOLIO,
    FAILURE_CREATE_PORTFOLIO,
} from "../../Constant/actionTypes";

const initilizeState = {
    createPortfolioFlag : '',
}

export default function createPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_CREATE_PORTFOLIO:
            return{
                ...state,
                createPortfolioFlag: 'request'
            }
        case SUCCESS_CREATE_PORTFOLIO:
            return{
                ...state,
                createPortfolioFlag: 'success'
            }
        case FAILURE_CREATE_PORTFOLIO:
            return{
                ...state,
                createPortfolioFlag:'fail'
            }
        default:
            return state
    }
}