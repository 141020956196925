import {
    REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION,
    SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION,
    FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION,
} from "../../Constant/actionTypes";

const initilizeState = {
    compositionFlag: false
}

export default function compositionPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION:
            return {
                ...state,
                compositionFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION:
            return {
                ...state,
                compositionData: action.compositionData,
                compositionFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION:
            return{
                ...state,
                compositionFlag: false
            }
        default: return state;
    }
}