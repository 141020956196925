import { 
    OPEN_MODAL,
    CLOSE_MODAL,
} from "../../Constant/actionTypes";

const initilizeState = {
    openCloseModal : false,
}

export default function modalReducer (state=initilizeState, action){
    switch(action.type){
        case OPEN_MODAL:
            return{
                ...state,
                openCloseModal:true,
            }
        case CLOSE_MODAL:
            return{
                ...state,
                openCloseModal: false,
            }
        default: 
            return state;
    }
}