import React from "react";
import bellicon from "../../Assets/Images/bellicon.svg";
import userphoto from "../../Assets/Images/userphoto.svg";
import "./navbar.scss";
import NavBarSearch from "../NavBarSearch/search";
import PortfolioDropDown from "../PortfolioDropDown/portfolioDropDown";
import TopMenu from "../TopMenu/TopMenu"
import onlinenotation from "../../Assets/Images/online.svg";
import UserDropDown from "../userDropDown/userDropDown";
// import CountryDropDown from "../Country/CountryDropDown";
import { useSelector } from 'react-redux';

const Navbar = (props) => {
  
    const openCloseModal = useSelector(state => state.modalReducer.openCloseModal)
    return (
        <div className={`navbar-container ${openCloseModal && 'navbar-zindex'} `}>
            <div className="navbar-list">
                <div className="portfolio-drop-down">
                    <div className="watchlist-dropdown-container">
                       <TopMenu {...props} />
                    </div>
                </div>
                <NavBarSearch  />
                <div className="bell-icon">
                    <img alt='' src={bellicon} />
                </div>
                {/* <div className="portfolio-drop-down">
                    <PortfolioDropDown {...props} />
                </div> */}
                {/* style={{ display: "flex", width: '22%', height: '90%' }} */}
                <div className="user-photo-name">
                    <div  className="user-photo">
                        <img alt=''  src={userphoto} />
                        <img alt=''  src={onlinenotation} className="user-pic" />
                    </div>
                    <div>
                        <UserDropDown {...props} />
                    </div>
                    {/* <div>
                        <CountryDropDown />
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Navbar;