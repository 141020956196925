import React, { useState } from "react";
import "./search.scss";
import { useDispatch, useSelector } from 'react-redux';
import { handleSearchTickerAction } from '../../Redux/actions/searchTickerAction';

const Search =() => {
    const dispatch = useDispatch();
    const [userInput, setuserInput] = useState('')
    const [suggestValueToggle, setsuggestValueToggle] = useState(true)
    
    let searchValue= useSelector(state => state.searchTickerReducer.searchTestData)
    const NavBarChange =(event) => {
        setsuggestValueToggle(true)
        setuserInput(event.target.value)
        dispatch(handleSearchTickerAction(localStorage.getItem("token"),event.target.value))
    }

    return (
        <div className="navbar-search">
            <input
                type="text"
                onChange={NavBarChange}
                value={userInput}
            />
            <div style={{
                maxHeight: "100px",
                overflowY: "scroll",
                overflowX: "hidden",
                width: "540px"
            }}>
                 {
                    searchValue.data.map((value) => {
                        if (searchValue && suggestValueToggle===true && userInput.length>0) {
                            return (
                                <li style={{
                                    color: "white", listStyle: "none",
                                    backgroundColor: "#0F1F30", fontWeight: "300",
                                    fontSize: "18px", fontFamily: "LatoLight", width: "540px"
                                }}
                                    onClick={() => {setuserInput(value.eod_ticker)
                                    setsuggestValueToggle(false)}
                                    }
                                >{value.eod_ticker}
                                </li>
                            )
                           
                        }
                    })
                }
            </div>
        </div>
    )
}
export default Search;