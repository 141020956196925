import React, { Component } from "react";
import TableHeader from "../../tableHeader/tableHeader";
import "./decisionStatsHitRatioTable.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../../Assets/Images/lockScreen.jpg'

class DecisionStatsHitRatioTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            week1: "#C851DB", month1: '', month3: '', month6: '', Y2: '', Y3: '',
            length: '1week',
            Data: []
        }
    }
    onChangeNavStatsValue = (value) => {

        this.setState({
            navSwitchTitle: value
        }, () => { })
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    handleclick = (i) => {
        this.props.handleNavDay(i);
        if (i === "7") {
            this.setState({
                week1: "#C851DB", month1: '', month3: '', month6: '', Y2: '', Y3: '',
                length: '1week'
            }, () => { })
        }
        if (i === "30") {
            this.setState({
                week1: "", month1: '#C851DB', month3: '', month6: '', Y2: '', Y3: '',
                length: '1month'
            }, () => { })
        }
        if (i === "90") {
            this.setState({
                week1: "", month1: '', month3: '#C851DB', month6: '', Y2: '', Y3: '',
                length: '3month'
            }, () => { })
        }
        if (i === "180") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '#C851DB', Y2: '', Y3: '',
                length: '6month'
            }, () => { })
        }
        if (i === "730") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '#C851DB', Y3: '',
                length: '2Y'
            }, () => { })
        }
        if (i === "1095") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '', Y3: '#C851DB',
                length: '3Y'
            }, () => { })
        }
    }

    componentDidUpdate(pre) {
        const { hitRatioData } = this.props
        // console.log("HIT RATIO DAA", pre.hitRatioData !== hitRatioData )
        if (pre.hitRatioData !== hitRatioData) {
            this.setState({
                Data: this.props?.hitRatioData?.data?.hit_ratio
            }, () => { })
        }
    }
    render() {
        let hit_reatioData = []
        hit_reatioData = this.props.hitRatioData?.data?.hit_ratio;

        const hitRatio = hit_reatioData?.map(item => {
            var formatter = new Intl.NumberFormat('en-US', {
                // style: 'currency',
                currency: 'USD',
            });
            return {
                buys: formatter.format(item.buys),
                hit_ratio_absolute_buys: item?.hit_ratio_absolute_buys?.toFixed(2),
                hit_ratio_absolute_sells: item?.hit_ratio_absolute_sells?.toFixed(2),
                hit_ratio_relative_buys:item?.hit_ratio_relative_buys?.toFixed(2),
                hit_ratio_relative_sells: item?.hit_ratio_relative_sells?.toFixed(2),

                security: item.security,
                sells: formatter.format(item.sells)
            }
        });
        const hasHitRatioTable = this.props.showTableData?.indexOf("hit_ratio") > -1
        return (
            <div className="decisionStatsHitRatioTable-table-container" id="decisionstatshitratiotable">
                <TableHeader title='Hit Ratio'
                    component={
                        <div style={{ display: "flex", verticalAlign: 'top' }}>
                            <div className="watchlist-dropdown-container mr-10" style={{ display: 'flex', justifyContent: 'end' }}>
                                <select style={{ width: '60px', padding: '7px 20px 10px 8px' }} onChange={(e) => this.handleclick(e.target.value)}>
                                    <option value='7'>1W</option>
                                    <option value='YTD'>YTD</option>
                                    <option value='30'>1M</option>
                                    <option value='90'>3M</option>
                                    <option value='180'>6M</option>
                                    <option value='730'>2Y</option>
                                    <option value='1095'>3Y</option>
                                    <option value='-1'>Since Inception</option>
                                </select>
                            </div>
                            {/* <h4 style={{ margin: "5px", fontSize: '19px', fontWeight: 'bold', cursor: "pointer", color: `${this.state.week1}` }} onClick={() => this.handleclick('7')}>1W</h4>
                            <h4 style={{ margin: "5px", fontSize: '19px', fontWeight: 'bold', cursor: "pointer", color: `${this.state.month1}` }} onClick={() => this.handleclick('30')}>1M</h4>
                            <h4 style={{ margin: "5px", fontSize: '19px', fontWeight: 'bold', cursor: "pointer", color: `${this.state.month3}` }} onClick={() => this.handleclick('90')}>3M</h4>
                            <h4 style={{ margin: "5px", fontSize: '19px', fontWeight: 'bold', cursor: "pointer", color: `${this.state.month6}` }} onClick={() => this.handleclick('180')}>6M</h4> */}
                        </div>
                    }
                />
                {
                    hasHitRatioTable ? <div className="decisionStatsHitRatioTable-table">
                        <div className="ag-theme-alpine decisionStatsHitRatioTable-inner-table">
                            <AgGridReact
                                onGridReady={this.onGridReady}
                                rowData={hitRatio}
                            >
                                <AgGridColumn headerName='Ticker' field="security" cellStyle={(i) => ({ background: '#0D1013', textAlign: 'center' })}></AgGridColumn>
                                <AgGridColumn headerName='Buys' field="buys" cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                                <AgGridColumn headerName='Sells' field="sells" cellStyle={(i) => ({ background: '#0D1013', textAlign: 'center' })}></AgGridColumn>
                                <AgGridColumn headerName='HitRatio Relative Buys' field="hit_ratio_absolute_buys" cellStyle={() => ({ textAlign: 'center' })}></AgGridColumn>
                                <AgGridColumn headerName='HitRatio Relative Sells' field="hit_ratio_absolute_sells" cellStyle={() => ({ textAlign: 'center', background: '#0D1013', })}></AgGridColumn>
                                <AgGridColumn headerName='HitRatio absolute Buys' field="hit_ratio_relative_buys" cellStyle={(i) => ({ textAlign: 'center' })}></AgGridColumn>
                                <AgGridColumn headerName='HitRatio absolute Sells' field="hit_ratio_relative_sells" cellStyle={(i) => ({ background: '#0D1013', textAlign: 'center' })}></AgGridColumn>

                            </AgGridReact>
                        </div>
                    </div> : <div className="lockScreen">
                        <img alt='' src={LockScreen} width='1200' height='500' />
                    </div>
                }
            </div>
        )
    }
}

export default DecisionStatsHitRatioTable;