import React from 'react';
import "../paymentCard/billingHistory.scss";
import pdf from "../../Assets/Images/pdf.svg";

const BillingHistory = (props) => {
    return (
        <div className="billing-history-container">
            <h4 className="billing">BILLING HISTORY</h4>
            <div className="billing-history">
                <table className="billing-table">
                    {props.invoiceData && props.invoiceData.data && props.invoiceData.data.data.map((value,index)=>(
                        <tr key={index}>
                            <td>{new Date(value.created*1000).toDateString()}</td>
                            <td>${value.amount_due/100}</td>
                            <td><a href = {value.invoice_pdf} target = "_blank"><img src={pdf}></img></a></td>
                        </tr>
                    ))}
                </table>
            </div>
        </div>
    )
}

export default BillingHistory;