import {
    REQUEST_CREATE_WATCHLIST,
    SUCCESS_CREATE_WATCHLIST,
    FAILURE_CREATE_WATCHLIST,
} from "../../Constant/actionTypes";

const initilizeState = {
   createWatchlistFlag : '',
}

export default function createNewWatchlistReducer(state=initilizeState, action){
    switch(action.type){
        case REQUEST_CREATE_WATCHLIST:
            return{
                ...state,
                createWatchlistFlag: 'request',
            }
        case SUCCESS_CREATE_WATCHLIST:
            return{
                ...state,
                createWatchlistData: action.createWatchlistData,
                createWatchlistFlag: 'success',
            }
        case FAILURE_CREATE_WATCHLIST:
            return{
                ...state,
                createWatchlistFlag: 'fail',
            }
        default:
            return state;
    }
}