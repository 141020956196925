import {
    REQUEST_PORTFOLIO_ANALYTICS_HOLDING,
    SUCCESS_PORTFOLIO_ANALYTICS_HOLDING,
    FAILURE_PORTFOLIO_ANALYTICS_HOLDING,
} from "../../Constant/actionTypes";

const initilizeState ={
    holdingFlag : false
}

export default function holdingPortfolioReducer(state=initilizeState, action){
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOLDING:
            return{
                ...state,
                holdingFlag : false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOLDING:
            return{
                ...state,
                holdingData: action.holdingData,
                holdingFlag : true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOLDING:
            return{
                ...state,
                holdingError: action.holdingError,
                holdingFlag : false
            }
        default: return state;
    }
}