import { 
    STORE_PORTFOLIO_ID,

} from "../../Constant/actionTypes";

const initilizeState = {
    portfolioid: ''
}

export default function storePortfolioIdReducer (state=initilizeState, action){
    switch(action.type){
        case STORE_PORTFOLIO_ID:
            return{
                ...state,
                portfolioid:action.portfolioId,
            }
        default: 
            return state;
    }
}