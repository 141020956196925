import {
    REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
} from "../../Constant/actionTypes";

const initilizeState = {
    maxDrawdownDetailsFlag: false
}

export default function maxDrawdownReducer(state=initilizeState, action){
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN:
            return{
                ...state,
                maxDrawdownDetailsFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN:
            return{
                ...state,
                maxDrawdownDetails: action.maxDrawdownDetails,
                maxDrawdownDetailsFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN:
            return{
                ...state,
                maxDrawdownDetailsFlag: false
            }
        default:
            return state;
    }
}