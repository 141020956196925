import React,{Component} from "react";
import "./MoreActions.scss";
import threeDot from "../../Assets/Images/threedot.svg";
import { ReactComponent as Delete} from "../../Assets/Images/delete.svg"

class MoreActions extends Component{
       render(){
      const {name} = this.props;
        return(
            <div className="more-action-container">
                {name!=='WatchList' &&
                     <Delete onClick={this.props.handleRemovePortfolio} style={{cursor: "pointer"}}/> 
                // <button className="more-action">
                //     {
                //         <span>
                //             Delete Portfolio
                //         </span> 
                //     }
                // </button>
                }
            </div>
        )
    }
}

export default MoreActions;