import {
    REQUEST_IBKR_INITIATE,
    SUCCESS_IBKR_INITIATE,
    FAILURE_IBKR_INITIATE,
} from "../../Constant/actionTypes";

const initilizeState = {
    ibkrtInitiateFlag:''
}

export default function ibkrInitiateReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_IBKR_INITIATE:
            return{
                ...state,
                ibkrtInitiateFlag:'request'
            }
        case SUCCESS_IBKR_INITIATE: 
            return{
                ...state,
                ibkrtInitiateFlag:'success'
            }
        case FAILURE_IBKR_INITIATE:
            return{
                ...state,
                ibkrtInitiateFlag:'fail'
            }
        default:
            return state
    }
}