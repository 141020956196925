import {
    REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY,
    SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY,
    FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestVolatilityPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY
    }
}

export const successVolatilityPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY,
        volatilityData:result
    }
}

export const failureVolatilityPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY,
    }
}

export const handleVolatilityPortfolioAction = (dropDownPortFolio,accessToken ) => {
    return function(dispatch){
        dispatch(requestVolatilityPortfolio())
        Api.VolatilityPortfolioApi(dropDownPortFolio,accessToken).then((result)=>{
            dispatch(successVolatilityPortfolio(result.data))
        }).catch(error=>{
            dispatch(failureVolatilityPortfolio(error))
        })
    }
}
