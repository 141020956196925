import React, { useState, useEffect } from "react";
import "./EditModal.scss";
import { withStyles } from '@material-ui/styles';
import { createStyles, makeStyles, Switch, Theme } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { handleGetPortfolioAction, handleUpdatePortfolioAction } from '../../Redux/actions/portfoilioAction';
import { useDispatch, useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 0,
            // margin: theme.spacing(1),
            // margin: theme.spacing(1),
            '& label.Mui-focused': {
                color: 'white',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#94B3FC',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                },
                '&:hover fieldset': {
                    borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#94B3FC',
                },
            },
            '&.MuiSwitch-colorPrimary.Mui-checked': {
                color: '#94B3FC',
            }
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                // This is the part that animates the thumb when the switch is toggled (to the right)
                transform: 'translateX(16px)',
                // This is the thumb color
                color: theme.palette.common.white,
                '& + $track': {
                    // This is the track's background color (in this example, the iOS green)
                    backgroundColor: '#94B3FC',
                    opacity: 1,
                    border: 'none',
                },
            },
        },
        thumb: {
            width: 20,
            height: 20,
        },
        track: {
            borderRadius: 19,
            border: `1px solid ${theme.palette.grey[300]}`,
            // This is the background color when the switch is off
            backgroundColor: theme.palette.grey[200],
            height: 20,
            opacity: 1,
            marginTop: 1,
            width: 38,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
);

const EditModal = (props) => {
    const dispatch = useDispatch()
    const state = useSelector(state => state?.portfolioReducer?.getPortfolioData);
    const [toggle, setToggle] = useState(true);
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    useEffect(() => {
        if (props?.editPort) {
            dispatch(handleGetPortfolioAction(props?.editPort))
        }
    }, [props?.editPort])

    useEffect(() => {
        setToggle(state?.share_portfolio)
        setPassword(state?.portfolio_password)
    }, [state])

    const styles = useStyles();
    const hendletoggel = () => {
        setToggle(!toggle)
        // setUpdateData({ ...updateData, [e.target.name]: value })
    }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const heldlevalue = (event, name) => {
        setPassword(event.target.value)
    }

    const handleSubmit = () => {
        dispatch(handleUpdatePortfolioAction({
            portfolio_id: props?.editPort,
            portfolio_password: password ? password : '',
            share_portfolio: toggle
        }))
    }

    return (
        <div className="edit-transaction-modal-container">
            <div className="edit-transaction-modal-table">
                <table className="edit-transaction-table">
                    <thead>
                        <tr>
                            <th>Share portfolio</th>
                            <th>Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="edit-data">
                            <td>
                                {/* <ToggleButton toggel={this.state.toggel} hendletoggel={this.hendletoggel} /> */}
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={toggle}
                                            onChange={() => hendletoggel()}
                                            name="portfolio_password"
                                            classes={{
                                                root: styles.root,
                                                switchBase: styles.switchBase,
                                                thumb: styles.thumb,
                                                track: styles.track,
                                                checked: styles.checked,
                                            }}
                                        />
                                    }
                                // label="Primary"
                                />
                            </td>
                            <td>
                                <Input
                                    // id="standard-adornment-password"
                                    classes={{ root: styles.root }}
                                    value={password}
                                    name="password"
                                    placeholder="Enter the password..."
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(event) => { heldlevalue(event, 'password') }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="edit-transaction-modal">
                <button className="edit-transaction-modal-btn" onClick={() => handleSubmit()}>Edit</button>
            </div>
            {/* <div className="create-btn">
                    <button className="btn">Create</button>
                </div> */}
        </div>
    )
}

export default EditModal;