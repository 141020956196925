import React, { useState, forwardRef,useRef, useImperativeHandle } from "react";
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {handleCashData} from './../../../../Redux/actions/cashDataAction';
import { API_BASE_URL } from "../../../../Constant/apiConstant";
import Popper from "@material-ui/core/Popper";
// import { height } from "@mui/system";
export default forwardRef((props, ref) => {
    const dispatch = useDispatch()
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = React.useState([]);
    // const styles = (theme) => ({
    //     paper: {
    //       width: "450px"
    //     }
    //   });
    const styles = (theme) => ({
        popper: {
           width: "450px",
        //    height: "600px"
        }
    });
    const PopperMy = function (props) {
        return <Popper {...props} style={styles.popper} placement="bottom-start" />;
     };

    function onChangeHandler(e, value) {
        setValue(value);
        // dispatch(handleCashData(value))
    }
    
    function onInputChangeHandler(e, inputValue) {
        fetch(`${API_BASE_URL}/security/search/ES`,
            {
                'method': 'POST',
                body: JSON.stringify({search_text : inputValue})
                ,
                headers: {
                    'Content-type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
            }
            ).then(response=>response.json())
            .then(data=>{
                let temp = data.map((item,index)=>{
                   return item.eod_ticker
                })
                temp.push('CASH')
                setOptions(temp)
            })
            .catch((error)=>{})
            setInputValue(inputValue);
        }
        
        useImperativeHandle(ref, () => {
            return {
                getValue: () => {
                    return value;
                },
                afterGuiAttached: () => {
                    setValue(props.value)
                }
            };
        });
    
    return (
        <Autocomplete
            style={{ padding: '0 10px' }}
            PopperComponent={PopperMy}
            options={options}
            filterOptions={x => x}
            value={value}
            onChange={onChangeHandler}
            inputValue={inputValue}
            // selectOnFocus={true}
            onInputChange={onInputChangeHandler}
            disableClearable
            freeSolo
            renderInput={(params) => (
                <TextField
                    {...params}
                    style={{ padding: '5px 0' }}
                    placeholder={'Select ' + props.column.colId} />
            )}
        />
    );
})
