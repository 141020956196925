import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Home from "../Page/home";
import { failureHomeCountriesDashboard, handleHomeCountriesDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeFixedIncomeDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeSectorsDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeStyleDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeCommoditiesDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeRiskDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleHomeGlobalMarketsDashboardAction } from "../Redux/actions/homeSectionAction";
import { handleGetSummaryCardAction } from "../Redux/actions/summaryCardAction";

class HomeContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homelist: ['Countries', 'Fixed Income', 'Sectors', 'Style', 'Commodities', 'Risk', 'Global Markets'],
            errorMessage: props.homeCountryError,
        }
    }

    componentDidMount() {
        this.props.handleHomeCountriesDashboard('countries', localStorage.getItem('token'))
        this.props.handleHomeFixedIncomeDashboard('fixed_income_stocks', localStorage.getItem('token'))
        this.props.handleHomeSectorsDashboard('sectors', localStorage.getItem('token'))
        this.props.handleHomeStyleDashboard('style', localStorage.getItem('token'))
        this.props.handleHomeCommoditiesDashboard('commodities', localStorage.getItem('token'))
        this.props.handleHomeRiskDashboard('risk', localStorage.getItem('token'))
        this.props.handleHomeGlobalMarketsDashboard('global_marktes', localStorage.getItem('token'))
        this.props.handleGetSummaryCard(localStorage.getItem('token'))
    }

    componentDidUpdate() {
        if (this.props.homeCountryError === 'Token has expired') {
            localStorage.clear();
            this.props.failureHomeCountriesDashboard('')
            this.props.history.push('/')
        }
    }

    render() {
        const {
            homeGlobalMarketData,
            homeRiskData,
            homeCommoditiesData,
            homeStyleData,
            homeSectorData,
            homeFixedIncomeData,
            homeCountryData,
            summaryCard,
        } = this.props;

        return (
            <div className="inner-part"> 
                <Home
                    homeGlobalMarketData={homeGlobalMarketData}
                    homeRiskData={homeRiskData}
                    homeCommoditiesData={homeCommoditiesData}
                    homeStyleData={homeStyleData}
                    homeSectorData={homeSectorData}
                    homeFixedIncomeData={homeFixedIncomeData}
                    homeCountryData={homeCountryData}
                    homelist={this.state.homelist}
                    summaryCard={summaryCard}
                />
            </div>
        )
    }
}
const mapStateToProps = state => {
    let country = [];
    if (state.homeCountryDashboardReducer.homeCountryData) {
        state.homeCountryDashboardReducer.homeCountryData.map((item, i) => {
            country.push({
                ...item,
                '1M': item['1M'] === null ? '-' : item['1M'] && (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
    }
    let fixedIncome = [];
    if (state.homeFixedIncomeDashboardReducer.homeFixedIncomeData) {
        state.homeFixedIncomeDashboardReducer.homeFixedIncomeData.map((item, index) => {
            fixedIncome.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : (index < 7 ?
                    ((item['value'] && item['value']).toFixed(2) + '%')
                    : (item['value'] && item['value']).toLocaleString()
                )
            })
        })
    }

    let style = [];
    if (state.homeStyleDashboardReducer.homeStyleData) {
        state.homeStyleDashboardReducer.homeStyleData.map((item, index) => {
            style.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
    }

    let commodities = [];
    if (state.homeCommoditiesDashboardReducer.homeCommoditiesData) {
        state.homeCommoditiesDashboardReducer.homeCommoditiesData.map((item, index) => {
            commodities.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
    }

    let risk = [];
    if (state.homeRiskDashboardReducer.homeRiskData) {
        state.homeRiskDashboardReducer.homeRiskData.map((item, index) => {
            risk.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
    }

    let globalmarket = [];
    if (state.homeGlobalMarketDashboardReducer.homeGlobalMarketData) {
        state.homeGlobalMarketDashboardReducer.homeGlobalMarketData.map((item, index) => {
            globalmarket.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
    }

    let sectorEurope = [];
    let sectorMSCIWorld = [];
    let sectorSP500 = [];
    let sector = {}
    if (state.homeSectorDashboardReducer.homeSectorData) {
        state.homeSectorDashboardReducer.homeSectorData['Europe'].map((item, index) => {
            sectorEurope.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
        state.homeSectorDashboardReducer.homeSectorData['MSCI World'].map((item, index) => {
            sectorMSCIWorld.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
        state.homeSectorDashboardReducer.homeSectorData['S&P 500'].map((item, index) => {
            sectorSP500.push({
                ...item,
                '1M': item['1M'] === null ? '-' : (item['1M'] * 10).toFixed(1) + '%',
                '1Y': item['1Y'] === null ? '-' : item['1Y'] && (item['1Y'] * 10).toFixed(1) + '%',
                '1d': item['1d'] === null ? '-' : item['1d'] && (item['1d'] * 10).toFixed(1) + '%',
                '1w': item['1w'] === null ? '-' : item['1w'] && (item['1w'] * 10).toFixed(1) + '%',
                '2Y': item['2Y'] === null ? '-' : item['2Y'] && (item['2Y'] * 10).toFixed(1) + '%',
                '3M': item['3M'] === null ? '-' : item['3M'] && (item['3M'] * 10).toFixed(1) + '%',
                '3Y': item['3Y'] === null ? '-' : item['3Y'] && (item['3Y'] * 10).toFixed(1) + '%',
                '5Y': item['5Y'] === null ? '-' : item['5Y'] && (item['5Y'] * 10).toFixed(1) + '%',
                'YTD': item['YTD'] === null ? '-' : item['YTD'] && (item['YTD'] * 10).toFixed(1) + '%',
                'value': item['value'] === null ? '-' : Math.ceil(item['value'] && item['value']).toLocaleString()
            })
        })
        sector = {
            'Europe': sectorEurope,
            'MSCI World': sectorMSCIWorld,
            'S&P 500': sectorSP500,
        }
    }

    return {
        homeGlobalMarketData: globalmarket,
        // state.homeGlobalMarketDashboardReducer.homeGlobalMarketData,
        homeRiskData: risk,
        // state.homeRiskDashboardReducer.homeRiskData,
        homeCommoditiesData: commodities,
        //  state.homeCommoditiesDashboardReducer.homeCommoditiesData,
        homeStyleData: style,
        // state.homeStyleDashboardReducer.homeStyleData,
        homeSectorData: sector,
        // state.homeSectorDashboardReducer.homeSectorData,
        homeFixedIncomeData: fixedIncome,
        // state.homeFixedIncomeDashboardReducer.homeFixedIncomeData,
        homeCountryData: country,
        homeCountryError: state.homeCountryDashboardReducer.homeCountryError,
        //  state.homeCountryDashboardReducer.homeCountryData,
        summaryCard: state.summaryCardReducer.summaryCardData,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleHomeCountriesDashboard: (tableName, token) => dispatch(handleHomeCountriesDashboardAction(tableName, token)),
        handleHomeFixedIncomeDashboard: (tableName, token) => dispatch(handleHomeFixedIncomeDashboardAction(tableName, token)),
        handleHomeSectorsDashboard: (tableName, token) => dispatch(handleHomeSectorsDashboardAction(tableName, token)),
        handleHomeStyleDashboard: (tableName, token) => dispatch(handleHomeStyleDashboardAction(tableName, token)),
        handleHomeCommoditiesDashboard: (tableName, token) => dispatch(handleHomeCommoditiesDashboardAction(tableName, token)),
        handleHomeRiskDashboard: (tableName, token) => dispatch(handleHomeRiskDashboardAction(tableName, token)),
        handleHomeGlobalMarketsDashboard: (tableName, token) => dispatch(handleHomeGlobalMarketsDashboardAction(tableName, token)),
        handleGetSummaryCard: (token) => dispatch(handleGetSummaryCardAction(token)),
        failureHomeCountriesDashboard: (error) => dispatch(failureHomeCountriesDashboard(error)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeContainer));