import {
    REQUEST_HIT_RATIO,
    SUCCESS_HIT_RATIO,
    FAILURE_HIT_RATIO,
} from "../../Constant/actionTypes";

const initilizeState = {
    hitRatioFlag: false
}

export default function decesionStatsHitRatioReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_HIT_RATIO:
            return{
                ...state,
                hitRatioFlag: false
            }
        case SUCCESS_HIT_RATIO:
            return{
                ...state,
                hitRatioData: action.hitRatioData,
                hitRatioFlag: true
            }
        case FAILURE_HIT_RATIO:
            return{
                ...state,
                hitRatioFlag: false
            }
        default:
            return state;
    }
}