import React, { Component } from "react";
import "./RemoveWatchlist.scss";

class EditWatchlistModal extends Component {
 
    render() {
        return (
            <div className="edit-watchlist-modal-container">
                <div className="edit-watchlist-modal-table">
                    <p>
                        Do You want to remove {<span style={{color: "#DB55EB"}}>{this.props.dropDownWatchlist}</span>} Watch List ?
                    </p>
                </div>
                <div className="create-btn">
                    <button className="btn" onClick={this.props.handleRemoveWatchlistData}>Yes</button>
                    <button className="btn" onClick={this.props.handleCloseModal}>No</button>
                </div>
            </div>
        )
    }
}
export default EditWatchlistModal;