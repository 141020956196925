import {
    REQUEST_INITIATE_PLAN,
    SUCCESS_INITIATE_PLAN,
    FAILURE_INITIATE_PLAN,
    REQUEST_COUPON_VERIFICATION,
    SUCCESS_COUPON_VERIFICATION,
    FAILURE_COUPON_VERIFICATION,
    PAYMENT_INITIATE_DATA,
    RESET,
    PAYMENT_INITIATE_VALIDATE
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";
import { handleCloseModalAction } from "../actions/modalAction";
import { handleGetUserDetailsAction } from '../actions/getUserDetailsAction'

export const requestInitiatePlan = () => {
    return {
        type: REQUEST_INITIATE_PLAN,
    }
}

export const successInitiatePlan = (result) => {
    return {
        type: SUCCESS_INITIATE_PLAN,
        initiatePlanMessage: 'Successfully initiate plan ',
        initiatePlanData: result
    }
}

export const failureInitiatePlan = (initiatePlanErrorMessage) => {
    return {
        type: FAILURE_INITIATE_PLAN,
        initiatePlanErrorMessage: initiatePlanErrorMessage
    }
}

export const paymentIntentDataD = (paymentIntentDataValue) => {
    console.log("dnsdkjksdj",paymentIntentDataValue)
    return {
        type:PAYMENT_INITIATE_DATA ,
        paymentIntentData: paymentIntentDataValue
    }
}


// i want ['payment_intent'] before calling this api "InitiatePlanApi"  and then only we will stop the  Initiate Plan before payment

export const handleInitiatePlanAction = (line1, city, state, country, stripe_card_token, plan_name, payment_frequency, token, discount) => {
     return function (dispatch) {
        dispatch(requestInitiatePlan())
        Api.InitiatePlanApi(line1, city, state, country, stripe_card_token, plan_name, payment_frequency, token, discount)
        .then(result => {   
            console.log("jdghsdfhd",result?.data)
            dispatch(paymentIntentDataD(result?.data)) 
            dispatch(successInitiatePlan(result?.data)) //this will generate the plan
            dispatch(handleCloseModalAction())
            // dispatch(handleGetUserDetailsAction(token)) // if i will not use this one ,then plan will not so after filling the  card cradantila
            // but after refresh it will show the plans 

        }).catch(error => {
            dispatch(failureInitiatePlan(error.response.data))
            if (error.response.data === 'User is already subscribed to plan') {
                dispatch(handleCloseModalAction())
            }
        })
    }
}


export const paymentInitiateValidate = () => {
    return {
        type:PAYMENT_INITIATE_VALIDATE,
    }
}

export const handlePaymentInitiateValidateAction = (accessToken,id,Truefalse) => {
    console.log("sdjhvsdv",accessToken,id,Truefalse)

    return function (dispatch) { 
        Api.PaymentInitiateValidateApi(accessToken,id,Truefalse).then((result) => { 
            console.log("nbvdshdvs",result)
            dispatch(paymentInitiateValidate(result.data))
            localStorage.removeItem("paymentResultId"); 
        }).catch((error) => {
            console.log("nbvdshdvs",error)

            // dispatch(failurePaymentPlan())
        })
    }
}

 

export const requestCouponVerification = () => {
    return {
        type: REQUEST_COUPON_VERIFICATION,
    }
}

export const successCouponVerification = (result) => {
    return {
        type: SUCCESS_COUPON_VERIFICATION,
        CouponVerificationData: result
    }
}

export const failureCouponVerification = (CouponVerificationErrorMessage) => {
    return {
        type: FAILURE_COUPON_VERIFICATION,
        CouponVerificationErrorMessage: CouponVerificationErrorMessage
    }
}

export const handleCouponVerificationAction = (token, data) => {
    return function (dispatch) {
        dispatch(requestCouponVerification())
        Api.CouponValidation(token, data).then(result => {
            dispatch(successCouponVerification(result?.data))
        }).catch(error => {
            dispatch(failureCouponVerification(error.response.data))
        })
    }
}
export const reset = () => {
    return {
        type: RESET,
        CouponVerificationData: {}
    }
}

export const handleResetAction = () => {
    return function (dispatch) {
        dispatch(reset())
    }
}

