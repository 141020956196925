import {
    REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
    SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
    FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS,
} from "../../Constant/actionTypes";

const initilizeState = {
    priceTrendFlag: false
}

export default function priceTrendPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS:
            return {
                ...state,
                priceTrendFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS:
            return {
                ...state,
                priceTrendData: action.priceTrendData,
                priceTrendFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS:
            return{
                ...state,
                priceTrendFlag: false
            }
        default: return state;
    }
}