import {
    REQUEST_PAST_INVOICE,
    SUCCESS_PAST_INVOICE,
    FAILURE_PAST_INVOICE,
} from "../../Constant/actionTypes";

const initilizeState = {
}

export default function pastInvoiceReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_PAST_INVOICE:
            return{
                ...state,
            }
        case SUCCESS_PAST_INVOICE:
            return{
                ...state,
                invoiceData: action.invoiceData
            }
        case FAILURE_PAST_INVOICE:
            return{
                ...state,
            }
        default:
            return state
    }
}