import {
    REQUEST_SELL_TRANSACTION,
    SUCCESS_SELL_TRANSACTION,
    FAILURE_SELL_TRANSACTION,
} from "../../Constant/actionTypes";

const initilizeState = {
    
}

export default function performSellTransactionReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_SELL_TRANSACTION:
            return{
                ...state,
            }
        case SUCCESS_SELL_TRANSACTION:
            return {
                ...state,
            }
        case FAILURE_SELL_TRANSACTION:
            return {
                ...state,
            }
        default: 
            return state;
    }
}