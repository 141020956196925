import {
    REQUEST_USER_LOGIN,
    SUCCESS_USER_LOGIN,
    FAILURE_USER_LOGIN,
} from "../../Constant/actionTypes";

const initilizeState = {
    loading: false,
    loginUserDetails: '',
    errorMessage:''
}

export default function loginReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_USER_LOGIN:
            return{
                ...state,
                loading: false
            }
        case SUCCESS_USER_LOGIN:
            return{
                ...state,
                loginUserDetails: action.LoginUserDetails,
                loading: true,
                
            }
        case FAILURE_USER_LOGIN:
            return{
                ...state,
                loginUserDetails:'',
                loading: false,
                errorMessage:action.errorMessage
            }
        default:
            return state;
    }
}