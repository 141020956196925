import React, { Component } from 'react';
import News from "../Page/news";

class NewsContainer extends Component {
  

    render(){
        return(
            <div>
                <News/>
            </div>
        )
    }
}

export default NewsContainer;