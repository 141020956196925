import {
    REQUEST_PAYMENT_PLAN,
    SUCCESS_PAYMENT_PLAN,
    FAILURE_PAYMENT_PLAN,
} from "../../Constant/actionTypes";

const initilizeState = {

}

export default function paymentPlanReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_PAYMENT_PLAN:
            return {
                ...state,
            }
        case SUCCESS_PAYMENT_PLAN:
            return{
                ...state,
                paymentPlan: action.paymentPlan
            }
        case FAILURE_PAYMENT_PLAN:
            return{
                ...state
            }
        default: return state;
    }
}