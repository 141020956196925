

export const WITHOUT_CASH_TICKER = [
 "Buy",
 "Sell",
 "Deposit",
 "Withdraw"
];

export const WITH_CASH_TICKER = [
    "Deposit",
    "Withdraw"
];

