import React from 'react';
import "./StatusBar.scss";

const Filler = (props) => {
    return(
        <div className="filler" style={{marginTop:'20px', width: `${props.percentage}%`,background:props.percentage>50?"#1DA598":props.percentage>25?"#FFBD2E":"#FF5757"}}>
        </div>
    )
}

export default Filler;