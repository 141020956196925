import React, { Component } from "react";
import { connect } from "react-redux";
import DecisionStats from "../Page/decisionStats";
import { handleNavLinesAction } from "../Redux/actions/decisionStatsAction";
import { handleHitRatioAction } from "../Redux/actions/decisionStatsAction";
import { handleMonthlyReturnsAction,handleMonthlyAbsoluteReturnsAction } from "../Redux/actions/decisionStatsAction";

class DecisionStatsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aggregate_period: 1,
        }
    }

    componentDidMount() {

        if (this.props.dropDownPortFolio != '') {
            this.props.handleNavLines(this.props.dropDownPortFolio, this.props.navDay, this.state.aggregate_period, localStorage.getItem('token'));
            this.props.handleHitRatio(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
            this.props.handleMonthlyReturns(this.props.dropDownPortFolio, this.props.returnData,this.props.navDay, localStorage.getItem('token'));
            this.props.handleMonthlyAbsoluteReturns(this.props.dropDownPortFolio, this.props.returnData,this.props.navDay, localStorage.getItem('token')); 
        }
    }

    

    componentDidUpdate(preprops) {
        const { portfoliosName } = this.props;
        if (preprops.navDay !== this.props.navDay || preprops.portfoliosName !== portfoliosName) {

            // this.props.handleNavLines(this.props.dropDownPortFolio, this.props.navDay, this.state.aggregate_period, localStorage.getItem('token'));
            // this.props.handleHitRatio(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
            // this.props.handleMonthlyReturns(this.props.dropDownPortFolio, this.props.returnData, this.props.navDay, localStorage.getItem('token'));

            if(JSON.stringify(preprops.showTableData)!== JSON.stringify(this.props.showTableData)){
                this.props.showTableData?.forEach((table)=>{
                    switch(table) {
                        case 'performance_table (in USD)':
                            this.props.handleMonthlyReturns(this.props.dropDownPortFolio, this.props.returnData, this.props.navDay, localStorage.getItem('token'));
                          break;
                        case 'performance_table (in %)':
                            this.props.handleMonthlyReturns(this.props.dropDownPortFolio, this.props.returnData, this.props.navDay, localStorage.getItem('token'));
                        break;
                        case 'performance_table (in USD)':
                            this.props.handleMonthlyAbsoluteReturns(this.props.dropDownPortFolio, this.props.returnData, this.props.navDay, localStorage.getItem('token'));
                          break;
                        case 'performance_table (in %)':
                            this.props.handleMonthlyAbsoluteReturns(this.props.dropDownPortFolio, this.props.returnData, this.props.navDay, localStorage.getItem('token'));
                        break;
                        case 'hit_ratio':
                            this.props.handleHitRatio(this.props.dropDownPortFolio, this.props.navDay, localStorage.getItem('token'));
                        break;
                        case "best_portfolio":
                            this.props.handleNavLines(this.props.dropDownPortFolio,this.props.navDay,this.state.aggregate_period,localStorage.getItem('token'));
                          break;
                        case "worst_portfolio":
                            this.props.handleNavLines(this.props.dropDownPortFolio,this.props.navDay,this.state.aggregate_period,localStorage.getItem('token'));
                        break;
                        case 'decision_stats':
                            this.props.handleNavLines(this.props.dropDownPortFolio,this.props.navDay,this.state.aggregate_period,localStorage.getItem('token'));
                        break;
                        default:
                          // code block
                      }
                })
            }
        }
        // if (preprops.portfoliosName !== portfoliosName){
        //     this.props.handleNavLines(this.props.dropDownPortFolio,this.props.navDay,this.state.aggregate_period,localStorage.getItem('token'));
        //     this.props.handleHitRatio(this.props.dropDownPortFolio,this.props.navDay,localStorage.getItem('token'));   
        //     this.props.handleMonthlyReturns(this.props.dropDownPortFolio,this.props.returnData,this.props.returnData,localStorage.getItem('token'));  
        // }
    }

    render() {
        console.log("kfguysdifyg",this.props);
        return (
            <div id="decisionstate">
                <DecisionStats {...this.props} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        monthlyReturnsData: state.decesionStatsMonthlyReturnsReducer.monthlyReturnsData,
        monthlyAbsoluteReturnsData: state.decesionStatsMonthlyReturnsReducer.monthlyAbsoluteReturnsData,
        monthlyReturnFlag: state.decesionStatsMonthlyReturnsReducer.monthlyReturnFlag,
        monthlyAbsoluteReturnFlag: state.decesionStatsMonthlyReturnsReducer.monthlyAbsoluteReturnFlag,
        hitRatioData: state.decesionStatsHitRatioReducer.hitRatioData,
        hitRatioFlag: state.decesionStatsHitRatioReducer.hitRatioFlag,
        navLineData: state.decesionStatsNavLinesReducer.navLineData,
        navLineFlag: state.decesionStatsNavLinesReducer.navLineFlag,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleNavLines: (dropDownPortFolio, backtest_days, aggregate_period, token) => dispatch(handleNavLinesAction(dropDownPortFolio, backtest_days, aggregate_period, token)),
        handleHitRatio: (dropDownPortFolio, backtest_days, token) => dispatch(handleHitRatioAction(dropDownPortFolio, backtest_days, token)),
        handleMonthlyReturns: (dropDownPortFolio, returnData, token) => dispatch(handleMonthlyReturnsAction(dropDownPortFolio, returnData, token)),
        handleMonthlyAbsoluteReturns: (dropDownPortFolio, returnData, token) => dispatch(handleMonthlyAbsoluteReturnsAction(dropDownPortFolio, returnData, token)),
    }
}
 

export default connect(mapStateToProps, mapDispatchToProps)(DecisionStatsContainer);