

import {
    REQUEST_DELETE_PORTFOLIO,
    SUCCESS_DELETE_PORTFOLIO,
    FAILURE_DELETE_PORTFOLIO,
} from "../../Constant/actionTypes";

const initilizeState = {
}

export default function removePortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_DELETE_PORTFOLIO:
            return{
                ...state,
                
            }
        case SUCCESS_DELETE_PORTFOLIO:
            return {
                ...state,
                
            }
        case FAILURE_DELETE_PORTFOLIO:
            return {
                ...state,
            }
        default: 
            return state;
    }
}