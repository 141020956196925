import {
    REQUEST_USER_SIGN_UP,
    SUCCESS_USER_SIGN_UP,
    FAILURE_USER_SIGN_UP,
    RESET_SIGNUP
} from "../../Constant/actionTypes";

const initilizeState = {
    loading: true,
    signUpUserDetails: '',
    errorMessage: '',
}

export default function signUpReducer(state = initilizeState, action) {
    switch (action.type) {
        case REQUEST_USER_SIGN_UP:
            return {
                ...state,
                loading: true,
            }
        case SUCCESS_USER_SIGN_UP:
            return {
                ...state,
                signUpUserDetails: action.signUpUserDetails,
                loading: false,
            }
        case FAILURE_USER_SIGN_UP:
            return {
                ...state,
                loading: true,
                errorMessage: action.errorMessage
            }
        // RESET
        case RESET_SIGNUP:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}