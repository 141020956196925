import {
    REQUEST_ADD_SECURITY_WATCHLIST,
    SUCCESS_ADD_SECURITY_WATCHLIST,
    FAILURE_ADD_SECURITY_WATCHLIST,
} from "../../Constant/actionTypes";

const initilizeState = {
    addSecuritesMessage: ''
}

export default function addSecuritiesWatchlistReducer(state=initilizeState, action) {
    switch(action.type){
        case REQUEST_ADD_SECURITY_WATCHLIST:
            return{
                ...state,
            }
        case SUCCESS_ADD_SECURITY_WATCHLIST:
            return{
                ...state,
                addSecuritesMessage: action.addSecuritesMessage
            }
        case FAILURE_ADD_SECURITY_WATCHLIST:
            return{
                ...state,
            }
        default: 
            return state;
    }   
}