import {
    SCROLL_ONCLICK,
    SCROLL_POSITION,
    LEFT_NAV_VALUE,
    CONTAINER_VALUE,
    // TRANSACTION_VALUE,
} from "../../Constant/actionTypes";

const initilizeState = {
    menuName: '',
    leftNavValue: '',
    containerValue: 'Home',
    // components:''
}

export default function menuScrollReducer (state=initilizeState, action) {
    switch(action.type){
        case SCROLL_ONCLICK:
            return{
                ...state,
                menuName: action.menuName
            }
        case SCROLL_POSITION:
            return {
                ...state,
                menuName: action.componentName,
            }
        case LEFT_NAV_VALUE :
            return {
                ...state,
                leftNavValue: action.navValue,
            }
        case CONTAINER_VALUE:
            return{
                ...state,
                containerValue: action.containerValue,
            }
        // case TRANSACTION_VALUE:
        //     return{
        //         ...state,
        //         transactionValue: action.transactionValue,
        //     }
        default: 
            return state;
    }
}