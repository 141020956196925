import {
    REQUEST_GET_CURRENCY_LIST,
    SUCCESS_GET_CURRENCY_LIST,
    FAILURE_GET_CURRENCY_LIST,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestGetCurrencyList = () =>{
    return{
        type: REQUEST_GET_CURRENCY_LIST,
    }
}

export const successGetCurrencyList = (currencyList) =>{
   return{
       type: SUCCESS_GET_CURRENCY_LIST,
        currencyList: currencyList
    }
}

export const failureGetCurrencyList = () =>{
   return{
       type: FAILURE_GET_CURRENCY_LIST,
   }
}

export const handleGetCurrencyListAction = (accessToken) =>{
   return function(dispatch){
       dispatch(requestGetCurrencyList())
       Api.GetCurrencyListApi(accessToken).then(result=>{
           dispatch(successGetCurrencyList(result.data))
       }).catch(error=>{
           dispatch(failureGetCurrencyList(error))
       })
   }
}