import React, { Component } from "react";
import { connect } from "react-redux";
import StripePayment from "../Page/stripePayment";
import { handleOpenModalAction, handleCloseModalAction } from "../Redux/actions/modalAction";
import StripeModel from "../Components/StripeComponent/stripeComponent";
import { handleCancelPlanAction } from "../Redux/actions/cancelPaymentPlanAction";
import { handlePastInvoiceAction } from "../Redux/actions/pastInvoiceAction";
import { handleUpdatePlanAction } from "../Redux/actions/updatePlanAction";
import ToastBar from "../Components/ToastBar/toastBar";

class PaymentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalComponent: '',
            plan_charges: '',
            payment_frequency: '',
            plan_name: '',
            open: false,
            toastState: '',
            type: ""
        }
    }

    componentDidMount() {
        this.props.handleInvoice(localStorage.getItem('token'))
    }

    componentDidUpdate(preProps, preState) {
        if (this.props.updatePlanFlag === 'success' && preProps.updatePlanFlag === 'request') {
            // this.props.handleInvoice(localStorage.getItem('token'))
            this.setState({
                open: true,
                toastState: "Subscription plan updated",
                type: "success"
            })
        }
        if (this.props.updatePlanFlag === 'fail' && preProps.updatePlanFlag === 'request') {
            this.setState({
                open: true,
                toastState: "Subscription plan update failed!",
                type: "error"
            })
        }
        if (this.props.cancelPlanFlag === 'success' && preProps.cancelPlanFlag === 'request') {
            this.setState({
                open: true,
                toastState: "Subscription cancelled",
                type: "success"
            })
        }
        if (this.props.cancelPlanFlag === 'fail' && preProps.cancelPlanFlag === 'request') {
            this.setState({
                open: true,
                toastState: "Subscription cancellation failed!",
                type: "error"
            })
        }
    }

    setToastOpen = () => {
        this.setState({
            open: false
        })
    }

    handleSubscribe = (payment_frequency, plan_name, plan_charges, updateText) => {
        updateText === 'Update' && this.props.handleUpdatePlan(payment_frequency, plan_name, localStorage.getItem('token'))
        updateText != 'Update' && this.props.handleOpenModal();
        this.setState({
            modalComponent: 'Stripe Payment',
            plan_charges: plan_charges,
            payment_frequency: payment_frequency,
            plan_name: plan_name,
        }, () => { })
    }

    // handleInitiatePlan = (payment_frequency,plan_name,plan_charges) => {
    //     this.handleSubscribe(payment_frequency, plan_name, plan_charges);
    // }

    handleCancelPlan = () => {
        this.props.handleCancelPaymentPlan(localStorage.getItem('token'));
    }

    render() {
        const { modalComponent, plan_charges, plan_name, payment_frequency, changePlan } = this.state;
        const {openCloseModal, handleCloseModal, userData, initiatePlanFlag, initiatePlanMessage, invoiceData, loginUserDetails, initiatePlanErrorMessage } = this.props;
        return (
            <div>
                <ToastBar
                    vailed={this.state.open}
                    setToastOpen={this.setToastOpen}
                    toastState={this.state.toastState}
                    type={this.state.type}
                />
                <StripePayment
                    component={
                        modalComponent === 'Stripe Payment' ?
                            <StripeModel
                                plan_charges={plan_charges}
                                handleCloseModal={handleCloseModal}
                                customer_id={userData.stripe_details.customer_id}
                                payment_frequency={payment_frequency}
                                plan_name={plan_name}
                                changePlan={changePlan}
                            /> : null
                    }
                    handleSubscribe={this.handleSubscribe}
                    modalComponent={this.state.modalComponent}
                    openCloseModal={openCloseModal}
                    handleCloseModal={handleCloseModal}
                    initiatePlanFlag={initiatePlanFlag}
                    initiatePlanMessage={initiatePlanMessage}
                    initiatePlanErrorMessage={initiatePlanErrorMessage}
                    // handleInitiatePlan={this.handleInitiatePlan}
                    handleCancelPlan={this.handleCancelPlan}
                    invoiceData={invoiceData}
                    loginUserDetails={loginUserDetails}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        openCloseModal: state.modalReducer.openCloseModal,
        userData: state.getUserDetailsReducer.userData,
        initiatePlanMessage: state.initiatePlanReducer.initiatePlanMessage,
        initiatePlanFlag: state.initiatePlanReducer.initiatePlanFlag,
        initiatePlanErrorMessage: state.initiatePlanReducer.initiatePlanErrorMessage,
        invoiceData: state.pastInvoiceReducer.invoiceData,
        loginUserDetails: state.getUserDetailsReducer.userData,
        // loginUserDetails: state.loginReducer.loginUserDetails.userObj,
        updatePlanFlag: state.updatePlanReducer.updatePlanFlag,
        cancelPlanFlag: state.cancelPlanReducer.cancelPlanFlag,
        cancelPlanMessage: state.cancelPlanReducer.cancelPlanMessage,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleOpenModal: () => dispatch(handleOpenModalAction()),
        handleCloseModal: () => dispatch(handleCloseModalAction()),
        handleCancelPaymentPlan: (token) => dispatch(handleCancelPlanAction(token)),
        handleInvoice: (token) => dispatch(handlePastInvoiceAction(token)),
        handleUpdatePlan: (payment_frequency, plan_name, token) => dispatch(handleUpdatePlanAction(payment_frequency, plan_name, token))
        // handleInitiateBillingPlan: (line1,city,state,country,stripe_card_token,plan_name,payment_frequency,token) => dispatch(handleInitiatePlanAction(line1,city,state,country,stripe_card_token,plan_name,payment_frequency,token)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer);