import {
    REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
    SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
    FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestFundamentalsPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS
    }
}

export const successFundamentalsPortfolio = (result)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
        fundamentalsData:result
    }
}

export const failureFundamentalsPortfolio = ()  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS,
    }
}

export const hanndleFundamentalsPortfolioAction = (dropDownPortFolio,accessToken) => {
    return function(dispatch){
        dispatch(requestFundamentalsPortfolio())
        Api.fundamentalsPortfolioApi(dropDownPortFolio,accessToken).then((result)=>{
            dispatch(successFundamentalsPortfolio(result.data))
        }).catch(error=>{
            dispatch(failureFundamentalsPortfolio(error))
        })
    }
}