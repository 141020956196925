import React from "react";
import "./portfolioDropDown.scss";

const PortfolioDropDown = (props) => {
    return(
            <div className="watchlist-dropdown-container">
                <select value={props.portfoliosName} onChange={event=>props.handlePortfolioDropDownChange(event,event.target.value, 3, "Portfolio", 'open')}>
                    {
                        props.userData && props.userData.portfolios.map((item, index)=>{
                            return <option key={index} value={item.slice(item.indexOf('_')+1,item.length)}>{item.slice(item.indexOf('_')+1,item.length)}</option>
                        })
                    }
                </select>
            </div>
    )
}
export default PortfolioDropDown;
