import {
    REQUEST_CREATE_WATCHLIST,
    SUCCESS_CREATE_WATCHLIST,
    FAILURE_CREATE_WATCHLIST,

    REQUEST_VIEW_CREATE_WATCHLIST,
    SUCCESS_VIEW_CREATE_WATCHLIST,
    FAILURE_VIEW_CREATE_WATCHLIST,

    REQUEST_DELETE_SECURITY_WATCHLIST,
    SUCCESS_DELETE_SECURITY_WATCHLIST,
    FAILURE_DELETE_SECURITY_WATCHLIST,

    REQUEST_ADD_SECURITY_WATCHLIST,
    SUCCESS_ADD_SECURITY_WATCHLIST,
    FAILURE_ADD_SECURITY_WATCHLIST,

    REQUEST_REMOVE_WATCHLIST,
    SUCCESS_REMOVE_WATCHLIST,
    FAILURE_REMOVE_WATCHLIST,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";
import {handleCloseModalAction} from "../actions/modalAction";
import {handleGetUserDetailsAction} from "../actions/getUserDetailsAction";

//CreateWatchList Action
export const requestCreateWatchList = () => {
    return {
        type: REQUEST_CREATE_WATCHLIST,
    }
}

export const successCreateWatchList = (result) => {
    return {
        type : SUCCESS_CREATE_WATCHLIST,
        createWatchlistData: result,
    }
}

export const failureCreateWatchList = () => {
    return {
        type: FAILURE_CREATE_WATCHLIST,
    }
}

export const handleCreateWatchListAction = (watchListName) => {
    return function(dispatch) {
        dispatch(requestCreateWatchList())
        Api.CreateWatchlist(watchListName,localStorage.getItem('token')).then((result)=>{
            dispatch(successCreateWatchList(result))
            dispatch(handleCloseModalAction())
            dispatch(handleGetUserDetailsAction(localStorage.getItem('token')))
        }).catch((error)=>{
            dispatch(failureCreateWatchList(error))
        })
    }
}


//View Watch List Action
export const requestViewWatchList = () => {
    return{
        type: REQUEST_VIEW_CREATE_WATCHLIST
    }
}

export const successViewWatchList = (result) =>{
    return{
        type: SUCCESS_VIEW_CREATE_WATCHLIST,
        viewWatchListData: result
    }
}

export const failureViewWatchList = () => {
    return{
        type: FAILURE_VIEW_CREATE_WATCHLIST,
    }
}

export const handleViewWatchListAction = (watchListName,token) => {
    return function (dispatch){
        dispatch(requestViewWatchList())
        Api.ViewWatchListApi(watchListName,token).then((result)=>{
            dispatch(successViewWatchList(result))
        }).catch((error)=>{
            dispatch(failureViewWatchList(error))
        })
    }
}

//delete securites in watchlist
export const requestDeleteSecurities = () => {
    return{
        type: REQUEST_DELETE_SECURITY_WATCHLIST,
    }
}

export const successDeleteSecurities = (result) =>{
    return{
        type: SUCCESS_DELETE_SECURITY_WATCHLIST,
        deleteMessage: result
    }
}

export const failureDeleteSecurities = () => {
    return{
        type: FAILURE_DELETE_SECURITY_WATCHLIST,
    }
}

export const handleDeleteSecuritiesAction = (watchListName,securities,token) =>{
    return function(dispatch){
        dispatch(requestDeleteSecurities())
        Api.DeleteSecuritiesApi(watchListName,securities,token).then((result)=>{
            dispatch(successDeleteSecurities(result))
            dispatch(handleViewWatchListAction(watchListName,token))
        }).catch((error)=>{
            dispatch(failureDeleteSecurities(error))
        })
    }
}

//add securities in watchlist 
export const requestAddSecurities = () => {
    return{
        type: REQUEST_ADD_SECURITY_WATCHLIST,
    }
}

export const successAddSecurities = ()=>{
    return{
        type: SUCCESS_ADD_SECURITY_WATCHLIST
    }
}

export const failureAddSecurities =()=>{
    return{
        type: FAILURE_ADD_SECURITY_WATCHLIST
    }
}

export const handleAddSecuritiesAction = (watchListName, securites, token) =>{
    return function(dispatch){
        dispatch(requestAddSecurities())
        Api.AddSecuritiesApi(watchListName, securites, token).then((result)=>{
            dispatch(successAddSecurities(result))
            dispatch(handleViewWatchListAction(watchListName,token))
        }).catch((error)=>{
            dispatch(failureAddSecurities(error))
        })
    }
}

//remove entire watch list from watchlist table
export const requestRemoveWatchList =()=>{
    return{
        type: REQUEST_REMOVE_WATCHLIST
    }
}

export const successRemoveWatchList = (result) =>{
    return{
        type: SUCCESS_REMOVE_WATCHLIST,
        removeWatchlistMessage: result
    }
}

export const failureRemoveSecurities = () => {
    return{
        type: FAILURE_REMOVE_WATCHLIST
    }
}

export const handleRemoveWatchlistAction = (watchListName,token,userData) =>{
    return function(dispatch){
        dispatch(requestRemoveWatchList())
        Api.RemoveWatchList(watchListName, token).then((result)=>{
            dispatch(successRemoveWatchList(result))
            dispatch(handleCloseModalAction())
            dispatch(handleGetUserDetailsAction(token))
            // dispatch(handleViewWatchListAction(watchListName,token))
        }).catch((error)=>{
            dispatch(failureRemoveSecurities(error))
        })
    }
}