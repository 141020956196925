import {
    REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestMaxDrawdown = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    }
}

export const successMaxDrawdown = (result) => {
    return{
        type: SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
        maxDrawdownDetails: result,
    }
}

export const failureMaxDrawdown = (error) => {
    return{
        type: FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN,
    }
}

export const handleMaxDrawdownAction = (dropDownPortFolio,accessToken) => {
    return function(dispatch){
        dispatch(requestMaxDrawdown())
        Api.MaxDrawdownApi(dropDownPortFolio,accessToken).then(result=>{
            dispatch(successMaxDrawdown(result.data))
        }).catch(error=>{
            dispatch(failureMaxDrawdown(error))
        })
    }
}