import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "../NetAssetValue/NetAssetValue.scss";
import AreaRechartComponent from "../AreaGraph/AreaGraph";
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class DecisionStatsReturnLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            week1: "#C851DB", month1: '', month3: '', month6: '', Y2: '',Y3: '',YTD: '',SinceInception: '',
            length: '1week',
            navSwitchTitle: 'levered_nav_lines'
        }
    }

    onChangeNavStatsValue = (value) => {

        this.setState({
            navSwitchTitle: value
        }, () => { })
    }

    handleclick = (i) => {
        this.props.handleNavDay(i);
        if (i === "7") {
            this.setState({
                week1: "#C851DB", month1: '', month3: '', month6: '', Y2: '',Y3: '',YTD: '',SinceInception: '',
                length: '1week'
            }, () => { })
        }
        if (i === "30") {
            this.setState({
                week1: "", month1: '#C851DB', month3: '', month6: '', Y2: '',Y3: '',YTD: '',SinceInception: '',
                length: '1month'
            }, () => { })
        }
        if (i === "90") {
            this.setState({
                week1: "", month1: '', month3: '#C851DB', month6: '', Y2: '',Y3: '',YTD: '',SinceInception: '',
                length: '3month'
            }, () => { })
        }
        if (i === "180") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '#C851DB', Y2: '',Y3: '',YTD: '',SinceInception: '',
                length: '6month'
            }, () => { })
        }
        if (i === "730") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '#C851DB',Y3: '',YTD: '',SinceInception: '',
                length: '2Y'
            }, () => { })
        }
        if (i === "1095") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '',Y3: '#C851DB',YTD: '',SinceInception: '',
                length: '3Y'
            }, () => { })
        }
        if (i === "YTD") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '',Y3: '',YTD: '#C851DB',SinceInception:'',
                length: 'YTD'
            }, () => { })
        }
        if (i === "-1") {
            this.setState({
                week1: "", month1: '', month3: '', month6: '', Y2: '',Y3: '',YTD: '',SinceInception:'#C851DB',
                length: 'Since Inception'
            }, () => { })
        }
    }

    render() {
        const hasDecisionStatsReturnLine = this.props.showTableData?.indexOf('decision_stats') >-1
        return (
            <div className="netassetvalue-container" id="decisionstatsreturnline" >
                <TableHeader title={this.props.title} component={
                    this.props.title === 'Decision Stats Return Line' && this.state.navSwitchTitle === 'levered_nav_lines'
                    //?
                    // <div>
                    //     <button onClick={() => this.onChangeNavStatsValue('levered_nav_lines')} style={{ height: 30, width: 100, verticalAlign: 'top', backgroundColor: '#C851DB', border: 'none', marginRight: 50, fontSize: 20, cursor: 'pointer' }}>Levered</button>
                    //     <button onClick={() => this.onChangeNavStatsValue('unlevered_nav_lines')} style={{ height: 30, width: 150, verticalAlign: 'top', backgroundColor: '#708DD2', color: "white", border: 'none', fontSize: 20, cursor: 'pointer' }}>Non Levered</button>
                    // </div>
                    // : this.state.navSwitchTitle === 'unlevered_nav_lines' ?
                    //     <div>
                    //         <button onClick={() => this.onChangeNavStatsValue('levered_nav_lines')} style={{ height: 30, width: 100, verticalAlign: 'top', backgroundColor: '#708DD2', color: "white", border: 'none', marginRight: 50, fontSize: 20, cursor: 'pointer' }}>Levered</button>
                    //         <button onClick={() => this.onChangeNavStatsValue('unlevered_nav_lines')} style={{ height: 30, width: 150, verticalAlign: 'top', backgroundColor: '#C851DB', border: 'none', fontSize: 20, cursor: 'pointer' }}>Non Levered</button>
                    //     </div>
                    //     :
                    //     <div>

                    //     </div>
                }
                />
                {hasDecisionStatsReturnLine?<div>
                <div className='net-asset-value-days'>
                    <div className="watchlist-dropdown-container mr-10" style={{ display: 'flex', justifyContent: 'end' }}>
                        <select style={{ width: '60px', marginRight: '30px' }} onChange={(e) => this.handleclick(e.target.value)}>
                        <option value='7'>1W</option>
                        <option value ='YTD'>YTD</option>
                        <option value='30'>1M</option>
                        <option value='90'>3M</option>
                        <option value='180'>6M</option>
                        <option value='730'>2Y</option>
                        <option value='1095'>3Y</option>
                        <option value='-1'>Since Inception</option>
                        </select>
                    </div>
                </div>
                <div>
                    {
                        <AreaRechartComponent {...this.props} length={this.state.length} navSwitchTitle={this.state.navSwitchTitle} />
                    }
                    
                </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>}
            </div>
        )
    }
}

export default DecisionStatsReturnLine;