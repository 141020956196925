import React, { Component } from "react";
import "./CreatePortfolioButton.scss";
import PropTypes from 'prop-types';

class CreatePortfolioButton extends Component{

    render(){
        const {btnName,handleCreateWatchlistPortfolio} = this.props;
        return(
            <div className="create-portfolio-button-container">
                <button onClick={handleCreateWatchlistPortfolio} className="create-portfolio-button">{btnName}</button>
            </div>
        )
    }
}

CreatePortfolioButton.propTypes = {
    btnName: PropTypes.string,
    handleCreateWatchlistPortfolio: PropTypes.func,
  };
  
export default CreatePortfolioButton;