import { combineReducers } from "redux";
import menuScrollReducer from "../Reducer/menuScrollReducer";
import modalReducer from "../Reducer/modalReducer";
import signUpReducer from "../Reducer/signUpReducer";
import loginReducer from "../Reducer/loginReducer";
import forgetPasswordReducer from "../Reducer/forgetPasswordReducer";
import getUserDetailsReducer from "../Reducer/getUserDetailsReducer";
import createPortfolioReducer from "../Reducer/createPortfolioReducer";
import createNewWatchlistReducer from "../Reducer/createWatchlistReducer";
import viewWatchListReducer from "../Reducer/viewWatchListReducer";
import deleteSecuritiesWatchlistReducer from "../Reducer/deleteSecuritiesWatchlistReducer";
import addSecuritiesWatchlistReducer from "../Reducer/addSecuritiesWatchlistReducer";
import removeWatchlistReducer from "../Reducer/removeWatchlistReducer";
import getTransactionHistoryReducer from "../Reducer/getTransactionHistoryReducer";
import deleteSecurityTransactionHistoryReducer from "../Reducer/deleteSecurityTransactionHistoryReducer";
import performBuyTransactionReducer from "../Reducer/performBuyTransactionReducer";
import performSellTransactionReducer from "./performSellTransactionReducer";
import performDepositTransactionReducer from "./performDepositTransactionReducer";
import performWithdrawTransactionReducer from "../Reducer/performWithdrawTransactionReducer";
import performEditTransactionReducer from "../Reducer/performEditTransactionReducer";
import getListOfCurrencyReducer from "../Reducer/getListOfCurrencyReducer";
import getListOfBenchMarkReducer from "../Reducer/getListOfBenckMarkReducer";
import searchTickerReducer from "../Reducer/searchTickerReducer";
import holdingPortfolioReducer from "../Reducer/holdingPortfolioReducer";
import showTableReducer from "../Reducer/showTableReducer";
import compositionPortfolioReducer from "../Reducer/compositionPortfolioReducer";
import efficientFrontierPortfolioReducer from "../Reducer/efficientFrontierPortfolioReducer";
import priceTrendPortfolioReducer from "../Reducer/priceTrendPortfolioReducer";
import performancePortfolioReducer from "../Reducer/performancePortfolioReducer";
import attributionsReducer from "../Reducer/attributionPortfolioReducer";
import maxDrawdownReducer from "../Reducer/maxDrawdownReducer";
import correlationPortfolioReducer from "../Reducer/correlationPortfolioReducer";
import headingPortfolioReducer from "../Reducer/headingPortfolioReducer";
import navPortfolioReducer from "../Reducer/navPortfolioReducer";
import volatilityPortfolioReducer from "../Reducer/volatilityPortfolioReducer";
import liquidityPortfolioReducer from "../Reducer/liquidityPortfolioReducer";
import fundametalsReducer from "../Reducer/fundamentalsPortfolioReducer";
import backtestingPortfolioReducer from "../Reducer/backtestingPortfolioReducer";
import homeGlobalMarketDashboardReducer from "../Reducer/homeGlobalMarketDashboardReducer";
import homeRiskDashboardReducer from "../Reducer/homeRiskDashboardReducer";
import homeCommoditiesDashboardReducer from "../Reducer/homeCommoditiesDashboardReducer";
import homeStyleDashboardReducer from "../Reducer/homeStyleDashboardReducer";
import homeSectorDashboardReducer from "../Reducer/homeSectorDashboardReducer";
import homeFixedIncomeDashboardReducer from "../Reducer/homeFixedIncomeDashboardReducer";
import homeCountryDashboardReducer from "../Reducer/homeCountryDashboardReducer";
import summaryCardReducer from "../Reducer/summaryCardReducer";
import removePortfolioReducer from "../Reducer/removePortfolioReducer";
import storePortfolioIdReducer from "../Reducer/storePortfolioIdReducer";
import updatePasswordReducer from "../Reducer/updatePasswordReducer";
import decesionStatsNavLinesReducer from "../Reducer/decesionStatsNavLinesReducer";
import decesionStatsHitRatioReducer from "../Reducer/decesionStatsHitRatioReducer";
import decesionStatsMonthlyReturnsReducer from "../Reducer/decesionStatsMonthlyReturnsReducer";
import paymentPlanReducer from "../Reducer/paymentPlanReducer";
import initiatePlanReducer from "../Reducer/initiatePlanReducer";

import updatePlanReducer from "../Reducer/updatePlanReducer";
import cancelPlanReducer from "../Reducer/cancelPaymentPlanReducer";
import pastInvoiceReducer from "../Reducer/pastInvoiceReducer";
import ibkrInitiateReducer from "../Reducer/ibkrInitiateReducer";
import getPlaidLinkInitiateReducer from "../Reducer/plaidReducer";
import cashDataReducer from "../Reducer/cashValueReducer";
import sourceLinkReducer from '../Reducer/sourceLinkReducer'
import portfolioReducer from '../Reducer/portfolioReducer'
const rootReducer = combineReducers({
    menuScrollReducer,
    modalReducer,
    loginReducer,
    signUpReducer,
    forgetPasswordReducer,
    getUserDetailsReducer,
    createNewWatchlistReducer,
    viewWatchListReducer,
    showTableReducer,
    createPortfolioReducer,
    removePortfolioReducer,
    deleteSecuritiesWatchlistReducer,
    addSecuritiesWatchlistReducer,
    removeWatchlistReducer,
    getTransactionHistoryReducer,
    deleteSecurityTransactionHistoryReducer,
    performBuyTransactionReducer,
    performSellTransactionReducer,
    performDepositTransactionReducer,
    performWithdrawTransactionReducer,
    performEditTransactionReducer,
    getListOfCurrencyReducer,
    getListOfBenchMarkReducer,
    searchTickerReducer,
    holdingPortfolioReducer,
    compositionPortfolioReducer,
    efficientFrontierPortfolioReducer,
    priceTrendPortfolioReducer,
    performancePortfolioReducer,
    attributionsReducer,
    maxDrawdownReducer,
    correlationPortfolioReducer,
    headingPortfolioReducer,
    navPortfolioReducer,
    volatilityPortfolioReducer,
    liquidityPortfolioReducer,
    fundametalsReducer,
    backtestingPortfolioReducer,
    homeGlobalMarketDashboardReducer,
    homeRiskDashboardReducer,
    homeCommoditiesDashboardReducer,
    homeStyleDashboardReducer,
    homeSectorDashboardReducer,
    homeFixedIncomeDashboardReducer,
    homeCountryDashboardReducer,
    summaryCardReducer,
    storePortfolioIdReducer,
    updatePasswordReducer,
    decesionStatsNavLinesReducer,
    decesionStatsHitRatioReducer,
    decesionStatsMonthlyReturnsReducer,
    paymentPlanReducer,
    initiatePlanReducer,
    updatePlanReducer,
    cancelPlanReducer,
    pastInvoiceReducer,
    ibkrInitiateReducer,
    getPlaidLinkInitiateReducer,
    cashDataReducer,
    sourceLinkReducer,
    portfolioReducer
});

export default rootReducer;