import {
    REQUEST_UPDATE_PLAN,
    SUCCESS_UPDATE_PLAN,
    FAILURE_UPDATE_PLAN,
} from "../../Constant/actionTypes";

const initilizeState = {
    updatePlanFlag: ''

}


export default function updatePlanReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_UPDATE_PLAN:
            return{
                ...state,
                updatePlanFlag: 'request'
            }
        case SUCCESS_UPDATE_PLAN:
            return{
                ...state,
                updatePlanFlag: 'success',
                updateData: action.updateData
            }
        case FAILURE_UPDATE_PLAN:
            return{
                ...state,
                updatePlanFlag: 'fail'
            }
        default: return state
    }
}