import {
    REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY,
    SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY,
    FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY,
} from "../../Constant/actionTypes";

const initilizeState = {
    volatilityFlag: false
}

export default function volatilityPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY:
            return {
                ...state,
                volatilityFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY:
            return {
                ...state,
                volatilityData: action.volatilityData,
                volatilityFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY:
            return{
                ...state,
                volatilityFlag: false
            }
        default: return state;
    }
}