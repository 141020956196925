import {
    REQUEST_PORTFOLIO_ANALYTICS_HOLDING,
    SUCCESS_PORTFOLIO_ANALYTICS_HOLDING,
    FAILURE_PORTFOLIO_ANALYTICS_HOLDING,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestHoldingPortfolio = ()  =>{
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOLDING
    }
}

export const successHoldingPortfolio = (holdingData)  =>{
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOLDING,
        holdingData:holdingData
    }
}

export const failureHoldingPortfolio = (error)  =>{
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOLDING,
        holdingError: error
    }
}

export const hanndleHoldingPortfolioAction = (dropDownPortFolio, accessToken) => {
    return function(dispatch){
        dispatch(requestHoldingPortfolio())
        Api.holdingPortfolioApi(dropDownPortFolio, accessToken).then((result)=>{
            dispatch(successHoldingPortfolio(result))
        }).catch(error=>{
            dispatch(failureHoldingPortfolio(error))
        })
    }
}