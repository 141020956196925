import {
    REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE,

} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeStyleDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE:
            return{
                ...state,
                homeStyleData: action.homeStyleData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE:
            return{
                ...state,
            }
        default:
            return state
    }
}

export default homeStyleDashboardReducer;