import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./holdingsTable.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'
import PropTypes from 'prop-types';

class HoldingTable extends Component {
    constructor() {
        super();
        this.state = {
            height: 100
        }
    }

    onGridReady(params) {
        // setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    }

    componentDidUpdate(preProps) {
        if (preProps.holdingData != this.props.holdingData) {
            if (this.props.holdingData && this.props.holdingData.length < 7) {
                this.setState({
                    height: this.props.holdingData.length * 47 + 100
                })
            }

            else {
                this.setState({
                    height: 400
                })
            }
        }
    }

    render() {
        const { holdingData } = this.props;
        const defaultColDef = {
            // resizable: true,
            cellStyle: { textAlign: 'left' }
        };
        const hasHolding = this.props.showTableData?.indexOf('holdings') >-1;
        return (
            <div className="holdings-table-container" id="Holdings">
                <TableHeader title='Holdings' />
                {
                    hasHolding ?<div className="holdings-table">
                    <div className="ag-theme-alpine w-100" style={{ height: this.state.height+5 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={holdingData}
                            defaultColDef={defaultColDef}
                        >
                            <AgGridColumn headerName='Symbol' field="stock" minWidth={100}>
                                <AgGridColumn headerName='' minWidth={100} field="stock"></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='name' minWidth={200} field="stock_name">
                                <AgGridColumn headerName='' minWidth={250} field="stock_name"></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='Shares Held' minWidth={80} field="shareheld">
                                <AgGridColumn headerName='' minWidth={85} field="num_shares"></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='Price' minWidth={80} field="price">
                                <AgGridColumn headerName='' minWidth={85} field="price"></AgGridColumn>
                            </AgGridColumn>
                            <AgGridColumn headerName='Weight' minWidth={80} field="percent">
                                <AgGridColumn headerName='' minWidth={85} cellStyle={(i) => ({ textAlign: 'center', padding: 0 })} field="percent"></AgGridColumn>
                            </AgGridColumn>


                            <AgGridColumn headerName='Market Value' minWidth={80} field="market_value">
                                <AgGridColumn minWidth={150} headerName='' cellRenderer={(nav) =>
                                    `${(1000000000 < (nav.value)) ? (((nav.value) / 1000000000)).toFixed(2) + 'B' :
                                        (1000000 <= (nav.value) && (nav.value) < 1000000000) ? (((nav.value) / 1000000)).toFixed(2) + 'M' :
                                            (1000 <= (nav.value) && (nav.value) < 1000000) ? (((nav.value) / 1000)).toFixed(2) + 'K' :
                                                (-1000000000 > (nav.value)) ? (((nav.value) / 1000000000)).toFixed(2) + 'B' :
                                                    (-1000000 >= (nav.value) && (nav.value) > -1000000000) ? (((nav.value) / 1000000)).toFixed(2) + 'M' :
                                                        (-1000 >= (nav.value) && (nav.value) > -1000000) ? (((nav.value) / 1000)).toFixed(2) + 'K' :
                                                            (nav.value).toFixed(2)

                                    }`} field="market_value"></AgGridColumn>
                            </AgGridColumn>

                            <AgGridColumn headerName='Returns(%)' minWidth={570}field="currentweight">
                                <AgGridColumn headerName='1D' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.1d"></AgGridColumn>
                                <AgGridColumn headerName='YTD' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.YTD"></AgGridColumn>
                                <AgGridColumn headerName='1W' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.1w"></AgGridColumn>
                                <AgGridColumn headerName='1M' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.1M"></AgGridColumn>
                                <AgGridColumn headerName='3M' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.3M"></AgGridColumn>
                              {/* <AgGridColumn headerName='1Y' cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.1Y"></AgGridColumn>*/}
                                <AgGridColumn headerName='2Y' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.2Y"></AgGridColumn>
                                <AgGridColumn headerName='5Y' minWidth={80} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="returns.5Y"></AgGridColumn>
                            </AgGridColumn>

                        </AgGridReact>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
            </div>
        )
    }
}

HoldingTable.propTypes = {
    handleEditHolding: PropTypes.func,
    handleDeleteHolding: PropTypes.func,
};

export default HoldingTable;