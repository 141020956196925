import {
    REQUEST_PLAID_LINK_INITIATE,
    SUCCESS_PLAID_LINK_INITIATE,
    FAILURE_PLAID_LINK_INITIATE,
} from "../../Constant/actionTypes";

const initilizeState = {

}

export default function getPlaidLinkInitiateReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_PLAID_LINK_INITIATE:
            return{
                ...state,
            }
        case SUCCESS_PLAID_LINK_INITIATE:
            return{
                ...state,
                plaidLink: action.plaidLink
            }
        case FAILURE_PLAID_LINK_INITIATE:
            return{
                ...state
            }
        default: return state
    }
}