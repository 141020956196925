import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useComponentWillMount } from '../../utils';
import Delete from "../../../../Assets/Images/delete.svg";
import edit from "../../../../Assets/Images/edit.svg";
import Save from "../../../../Assets/Images/save.svg";
import cancel from "../../../../Assets/Images/Vector.svg";
import { handlePerformNewEditTransactionAction } from "../../../../Redux/actions/transactionAction";
import { handleDeleteSecurityTransactionHistoryAction } from "../../../../Redux/actions/transactionAction";

const ActionsRenderer =(props) => {
    let [editing, setEditing] = useState(false);
    // let [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch()

    useEffect( () => {
        
        props.api.addEventListener('rowEditingStarted', onRowEditingStarted);
        props.api.addEventListener('rowEditingStopped', onRowEditingStopped);

        return () => {
            props.api.removeEventListener('rowEditingStarted', onRowEditingStarted);
            props.api.removeEventListener('rowEditingStopped', onRowEditingStopped);
        };
    },[]);

    useComponentWillMount(() => {
        let editingCells = props.api.getEditingCells();
        if (editingCells.length !== 0) {
            // setDisabled(true);
        }
    })


    function onRowEditingStarted(params) { //when we start edit or add the new row
         
        if (props.node === params.node) {
            setEditing(true);
        } else {
            // setDisabled(true);
        }
    };

    function onRowEditingStopped(params) {
        if (props.node === params.node) {
            if (isEmptyRow(params.data)) {
                // deleteRow(true);
            } else {
                setEditing(false);
            }
        } else {
            // setDisabled(false);
        }
    }

    function startEditing() {
          setEditing(true);
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column.colId
        });
      
    }

    function stopEditing(bool){ //this is for stop row
         
        // setEditing(false);
        props.api.stopEditing(bool);
        if(bool===false){
            dispatch(handlePerformNewEditTransactionAction(localStorage.getItem('token'),props.data,props.agGridReact.props.defaultColDef.portfolioId,props.data,props.agGridReact)) 
        }
    }

    function deleteRow(deletes) {
        if(deletes && props.data.transaction_source=="manual_entry"){
            dispatch(handleDeleteSecurityTransactionHistoryAction(localStorage.getItem('token'),props.data))
        }
    };

    function isEmptyRow(data) {
        let dataCopy = { ...data };
        delete dataCopy.id;
        return !Object.values(dataCopy).some(value => value);
    }

    const transaction_info = props.data.transaction_source=="manual_entry"
    return (
        <div>
            {editing
                ? <>
                    <img 
                        alt=''
                        style={{height:"20px",width:"20px",cursor:"pointer"}} 
                        src={Save}
                        onClick={() => stopEditing(false)}
                        // disabled={disabled}
                        ></img>
                        <img
                        alt=''
                        style={{marginLeft:25,height:"20px",width:"20px",cursor:"pointer"}}
                        src={cancel}
                        onClick={() => stopEditing(true)}
                        // disabled={disabled}
                    ></img>
                </>
                : transaction_info? (<>
                    {/* <img
                        alt=''
                        style={{height:"20px",width:"20px",cursor:"pointer"}}
                        src={edit} 
                        onClick={startEditing}
                        // disabled={disabled}
                        ></img> */} 
                    <button onClick={startEditing} style={{marginLeft:10,cursor:"pointer",backgroundColor: '#4CAF50', border: 'none', color: 'white'}} >Add row</button>
                    <img
                        alt=''
                        style={{marginLeft:25,height:"20px",width:"20px",cursor:"pointer"}}
                        src={Delete}
                        onClick={() => deleteRow('delete')}
                        // disabled={disabled}
                        ></img>
                </>): null
            }
        </div>
    )
}
export default ActionsRenderer;