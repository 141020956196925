import React, { useState, useEffect } from 'react'
import BrokerPortfolioLink from '../Page/brokerPortfolioLink';
import BrekerDetail from '../Components/BrokerDetails/BrekerDetail';
import { useDispatch, useSelector } from 'react-redux';
import { handleCloseModalAction } from "../Redux/actions/modalAction";
import { handleSourceLinkAction, handlePortfolioSourceLinkAction } from '../Redux/actions/sourceLinkAction';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function BrokerPortfolioLinkContainer({ modalComponent, handleSubmit, analysisEfficientFrontierDate, handlePortfolioEfficientFrontierDate}) {

    const dispatch = useDispatch();
    const openCloseModal = useSelector(state => state.modalReducer.openCloseModal)
    const state = useSelector(state => state.getUserDetailsReducer.userData)
    const sourceLinkDetails = useSelector(state => state?.sourceLinkReducer?.sourceLinkDetails)
    const flag = useSelector(state => state?.sourceLinkReducer?.flag)

    // const multiselectRef = React.forwardRef();

    const handleCloseModal = () => { dispatch(handleCloseModalAction()) }
    const [portfolio, setPortfolio] = useState(state?.portfolios && state?.portfolios[0])
    const [dataStream, setDataStream] = useState([]);
    // const addBroker = () => {
    //     broker.push({
    //         portfolio_id: state && state.portfolios[0],
    //         source_link_id: []
    //     })
    //     setBroker([...broker])
    // }
    const onSelect = (value, key) => {
        if (key == 'source_link_id') {
            setDataStream(value)
        }
        else {
            setPortfolio(value)
        }
    }
    const onRemove = (value) => {
        setDataStream(value)
    }
    // const handleRemoveDetails = (index) => {
    //     broker.splice(index, 1);
    //     setBroker([...broker])
    // }
    const reset = () => {
        setDataStream([])
        setPortfolio(state && state.portfolios[0])
        dispatch(handleCloseModalAction())
    }

    useEffect(() => {
        dispatch(handleSourceLinkAction())
    }, [])

    useEffect(() => {
        if (flag == 'success') {
            dispatch(handleCloseModalAction())
            reset()
        }

    }, [flag])
    const modalSubmit = async () => {
        const date= analysisEfficientFrontierDate;
        const ids = dataStream?.map((item) => {
            return item.object_id;
        });
        if (ids.length !== 0) {
            dispatch(handlePortfolioSourceLinkAction({
                portfolio_id: portfolio,
                link_start_date: date,
                source_link_ids: ids
            }))
        }
        else {
            toast.warning('Please select at least one DataStream')
        }
    }
    return (
        <div>
            <ToastContainer />
            <BrokerPortfolioLink component={
                modalComponent === 'Broker Details' ?
                    <BrekerDetail
                        analysisEfficientFrontierDate={analysisEfficientFrontierDate}
                        handlePortfolioEfficientFrontierDate={handlePortfolioEfficientFrontierDate}
                        openCloseModal={openCloseModal}
                        portfolio={portfolio}
                        dataStream={dataStream}
                        handleCloseModal={handleCloseModal}
                        reset={reset}
                        modalSubmit={modalSubmit}
                    /> : null
            }
                handleCloseModal={handleCloseModal}
                openCloseModal={openCloseModal}
                modalComponent={modalComponent}
                handleSubmit={handleSubmit}
                // addBroker={addBroker}
                onSelect={onSelect}
                onRemove={onRemove}
                sourceLinkDetails={sourceLinkDetails}
                // data={data}
                reset={reset}
                // ref={multiselectRef}
                portfolio={portfolio}
                dataStream={dataStream} />
        </div>
    )
}




