import { toast } from "react-toastify";
import {
    REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,

    REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestHomeCountriesDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY
    }
}

export const successHomeCountriesDashboard = (table_name, result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
        homeCountryData: result
    }
}

export const failureHomeCountriesDashboard = (homeCountryError) => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY,
        homeCountryError
    }
}

export const handleHomeCountriesDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeCountriesDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result.data) {
                dispatch(successHomeCountriesDashboard(table_name, result?.data))
            }
        }).catch(error => {
            if (error.response) {
                dispatch(failureHomeCountriesDashboard(error.response.data.msg))
            }
            else {
                toast.error('Network Error')
            }
        })
    }
}



export const requestHomeFixedIncomeDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME
    }
}

export const successHomeFixedIncomeDashboard = (table_name, result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
        homeFixedIncomeData: result
    }
}

export const failureHomeFixedIncomeDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
    }
}

export const handleHomeFixedIncomeDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeFixedIncomeDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeFixedIncomeDashboard(table_name, result?.data))
            }
        }).catch(error => {
            if (error.response) {
                dispatch(failureHomeFixedIncomeDashboard(error))
            }
        })
    }
}


export const requestHomeSectorsDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR
    }
}

export const successHomeSectorsDashboard = (table_name, result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR,
        homeSectorData: result
    }
}

export const failureHomeSectorsDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR,
    }
}

export const handleHomeSectorsDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeSectorsDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeSectorsDashboard(table_name, result?.data))
            }
        }).catch(error => {
            if (error.response) {
                dispatch(failureHomeSectorsDashboard(error))
            }
        })
    }
}


export const requestHomeStyleDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE
    }
}

export const successHomeStyleDashboard = (table_name, result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE,
        homeStyleData: result
    }
}

export const failureHomeStyleDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE,
    }
}

export const handleHomeStyleDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeStyleDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeStyleDashboard(table_name, result?.data))
            }
        }).catch(error => {
            if (error.response) {
                dispatch(failureHomeStyleDashboard(error))
            }
        })
    }
}


export const requestHomeCommoditiesDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES
    }
}

export const successHomeCommoditiesDashboard = (table_name, result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
        homeCommoditiesData: result
    }
}

export const failureHomeCommoditiesDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES,
    }
}

export const handleHomeCommoditiesDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeCommoditiesDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeCommoditiesDashboard(table_name, result?.data))
            }
        }).catch(error => {
            dispatch(failureHomeCommoditiesDashboard(error))
        })
    }
}


export const requestHomeRiskDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK
    }
}

export const successHomeRiskDashboard = (result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK,
        homeRiskData: result
    }
}

export const failureHomeRiskDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK,
    }
}

export const handleHomeRiskDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeRiskDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeRiskDashboard(result?.data))
            }
        }).catch(error => {
            dispatch(failureHomeRiskDashboard(error))
        })
    }
}


export const requestHomeGlobalMarketsDashboard = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET
    }
}

export const successHomeGlobalMarketsDashboard = (result) => {
    return {
        type: SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
        homeGlobalMarketData: result
    }
}

export const failureHomeGlobalMarketsDashboard = () => {
    return {
        type: FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET,
    }
}

export const handleHomeGlobalMarketsDashboardAction = (table_name, accessToken) => {
    return function (dispatch) {
        dispatch(requestHomeGlobalMarketsDashboard())
        Api.homeDashboardApi(table_name, accessToken).then((result) => {
            if (result) {
                dispatch(successHomeGlobalMarketsDashboard(result?.data))
            }
        }).catch(error => {
            dispatch(failureHomeGlobalMarketsDashboard(error))
        })
    }
}
