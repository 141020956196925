import {
    REQUEST_SHOW_TABLE,
    SUCCESS_SHOW_TABLE,
    FAILURE_SHOW_TABLE,
} from '../../Constant/actionTypes';
import Api from "../Api/apiCalls";

export const requestShowTable = () => {
    return {
        type: REQUEST_SHOW_TABLE,
    }
}

export const successShowTable = (showTableData) => {
    return {
        type: SUCCESS_SHOW_TABLE,
        showTableData: showTableData,
    }
}

export const failureShowTable = (error) => {
    return {
        type: FAILURE_SHOW_TABLE,
        showTabelError: error,
    }
}

export const handleShowTableAction = (showTable,accessToken) => {
    return function (dispatch) {
        dispatch(requestShowTable())
        Api.Showtable(showTable,accessToken).then(result => {
            if (result) {
                dispatch(successShowTable(result.data))
                
            }
        }).catch(error => {
            dispatch(failureShowTable(error))
        })
    }
}