import React from "react";
import homegreen from "../../Assets/Images/homegreen.png";
import arrow from "../../Assets/Images/arrow.png";
import "./NameRouteList.scss";
import PortfolioDropDown from "../PortfolioDropDown/portfolioDropDown";
import CreatePortfolioButton from "../CreatePortfolioButton/CreatePortfolioButton";
import MoreActions from "../MoreActions/MoreActions";
import PropTypes from 'prop-types';

const NameRouteList = (props) => {
    const { name } = props;
    // const windowRouteValue = window.location.href.split("/")[3];
    return (
        <div className="name-route-list-container">
            <div className="name-route-list">
                <img alt='' className="margin h-100" src={homegreen} />
                <img alt='' className="margin h-25" src={arrow} />
                <h4 className="list-name margin">{name}</h4>
                <img alt='' className="margin h-25" src={arrow} />
                <h4 className="list-name-one margin">
                    {
                        name === 'WatchList' ?
                            props.dropDownWatchlist :
                            name === 'Portfolio' ?
                                props.portfoliosName : null
                    }
                </h4>
            </div>
            {
                name === 'Portfolio' ? <div className="name-route-list-create-action">
                    <PortfolioDropDown {...props} />
                </div> : <div></div>
            }
            {/* <div className="name-route-list-create-action">
                    <MoreActions {...props}/>
                    <CreatePortfolioButton {...props}/>
                </div> */}
        </div>
    )
}

NameRouteList.propTypes = {
    name: PropTypes.string
};

export default NameRouteList;