import {
    REQUEST_SEARCH_TICKER,
    SUCCESS_SEARCH_TICKER,
    FAILURE_SEARCH_TICKER,

    REQUEST_SEARCH_TICKER_FUNDAMENTALS,
    SUCCESS_SEARCH_TICKER_FUNDAMENTALS,
    FAILURE_SEARCH_TICKER_FUNDAMENTALS,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

//search ticker list
export const requestSearchTicker = () => {
    return {
        type: REQUEST_SEARCH_TICKER,
    }
}

export const successSearchTicker = (result) => {
    return {
        type: SUCCESS_SEARCH_TICKER,
        searchTestData: result,
    }
}

export const failureSearchTicker = () => {
    return {
        type: FAILURE_SEARCH_TICKER
    }
}

export const handleSearchTickerAction = (token, searchText) => {
    return function (dispatch) {
        dispatch(requestSearchTicker())
        Api.SearchTickerApi(token, searchText).then((result) => {
            dispatch(successSearchTicker(result))
        }).catch((error) => {
            dispatch(failureSearchTicker(error))
        })
    }
}

//fundamental ticker for ticker search

export const requestSearchTickerFundamental = () => {
    return {
        type: REQUEST_SEARCH_TICKER_FUNDAMENTALS
    }
}

export const successSearchTickerFundamental = (result) => {
    return {
        type: SUCCESS_SEARCH_TICKER_FUNDAMENTALS,
        searchTickerFundamental: result
    }
}

export const failureSearchTickerFundamental = () => {
    return {
        type: FAILURE_SEARCH_TICKER_FUNDAMENTALS
    }
}

export const handleSearchTickerFundamentalAction = (token, eodTicker, subSection) => {
    return function (dispatch) {
        dispatch(requestSearchTickerFundamental())
        Api.SearchTickerFundamentalApi(token, eodTicker, subSection).then((result) => {
            dispatch(successSearchTickerFundamental(result.data))
        }).catch((error) => {
            dispatch(failureSearchTickerFundamental(error))
        })
    }
}