import React from "react";
import "./termCondition.css";
export default function TermCondition(props) {
  return (
    <div className="cantainer">
      <div>
        <h1>Stoxdoc Terms and Conditions</h1>
        <h3>Financial Advice Warning</h3>
        <p>
          The information given by Stoxdoc and provided in the web and/or mobile
          applications (Platforms) is either factual information or general
          advice. It is based upon the results of our analysis model.
          <br />
          Any advice contained in this website is general advice only and has
          been prepared without considering your objectives, financial situation
          or needs. You should not rely on any advice and/or information
          contained in this website and before making any investment decision we
          recommend that you consider whether it is appropriate for your
          situation and seek appropriate financial, taxation and legal advice.
        </p>
        <h3>Account Creation</h3>
        <h4>To create an account, you must be:</h4>
        <p>
          - at least 18 years of age;
          <br />- possess the legal right and ability to enter into a legally
          binding agreement with us; and
          <br />- agree and warrant to use these Platforms in accordance with
          these Terms.
        </p>
        <p>
          We retain the right to cancel your account for any reason, or refuse
          your account creation request.
        </p>
        <h3>Collection Notice</h3>
        <p>
          We collect personal information about you in order to provide our
          services and for purposes otherwise set out in our Privacy Policy.
          That document should be read in conjunction with this document.
        </p>
        <h3>Accuracy, completeness and timeliness of information</h3>
        <p>
          The information on our Platforms is not comprehensive and is intended
          to provide a summary of the subject matter covered. While we use all
          reasonable attempts to ensure the accuracy and completeness of the
          data and information on our Platforms, to the extent permitted by law,
          we make no warranty regarding the information on these Platforms. You
          should monitor any changes to the information contained on these
          Platforms.
          <br />
          Furthermore we make no commitments in regards to the minimum amount of
          uptime that our platforms will maintain, although we will make ever
          reasonable attempt to ensure that the platforms are operational.
          <br />
          We are not liable to you or anyone else if interference with or damage
          to your computer systems occurs in connection with the use of these
          Platforms or a linked website. You must take your own precautions to
          ensure that whatever you select for your use from our Platforms is
          free of viruses or anything else (such as worms or Trojan horses) that
          may interfere with or damage the operations of your computer systems.
          <br />
          We may, from time to time and without notice, change or add to the
          Platforms (including the Terms) or the information, products or
          services described in it. However, we do not undertake to keep the
          Platforms updated. We are not liable to you or anyone else if errors
          occur in the information or the Platforms is not up-to-date.
        </p>
        <h3>Promotions and competitions</h3>
        <p>
          For certain campaigns, promotions or contests, additional terms and
          conditions may apply. If you want to participate in such a campaign,
          promotion or contest, you need to agree to the relevant terms and
          conditions applicable to that campaign, promotion or contest. In case
          of any inconsistency between such terms and conditions and these
          Terms, those terms and conditions will prevail.
        </p>
        <h3>Trials</h3>
        <p>
          New users to Stoxdoc are able to request a trial subscription. This
          trial shall be up to a maximum of 30 days to allow the user to assess
          the full Stoxdoc experience. Users (or households) are restricted to
          one trial per 12 months, and approval of a trial is at our sole
          discretion.
        </p>
        <h3>Purchases</h3>
        <p>
          Users are able to purchase premium services from Stoxdoc. These
          services can be delivered via subscription or one off payments. We
          reserve the right to cancel or refuse access to our premium services
          for any user. The following terms apply to those users that have
          purchased said premium features.
        </p>
        <h3>Discounts/Promotional Prices;</h3>
        <p>
          Users that subscribe under a reduced rate shall have the discount
          applied for that period only (i.e. as advised on the offer, up to a
          maximum of 12 months). After which time, their rate shall revert to
          standard pricing at that date.
        </p>
        <h3>Changes in price;</h3>
        <p>
          Stoxdoc reserves the right to change our pricing at any time, and with
          reasonable notice to our subscribers. At the end of the notice period
          the user’s next billing period (i.e. monthly or annually) payment
          shall be at the revised price.
        </p>
        <h3>Payment methods and security;</h3>
        <p>
          Payments are via credit card and are handled by a third party payment
          service called Stripe. Credit card details are not stored on Stoxdoc’s
          systems.
        </p>
        <h3>Cancellation;</h3>
        <p>
          Users can cancel their subscription at any time. Users shall not be
          entitled to a partial refund, however, at our sole discretion, we may
          choose to give users a partial refund.
        </p>
        <h3>Refunds;</h3>
        <p>
          We offer no money back guarantee for all new subscribers from their
          sign up date.
        </p>
        <h3>Subscription fees</h3>
        <p>
          If applicable, users authorise Stoxdoc to automatically debit their
          credit/debit card on their initial registration and then on each
          subsequent anniversary of their billing cycle (i.e. monthly or
          annually), until cancelled.
        </p>
        <h3>Sole use only</h3>
        <p>
          Users are unable to share access to their premium features unless
          explicitly approved under their subscription level.
        </p>
        <h3>Linked sites</h3>
        <p>
          Our Platforms may contain links to websites operated by third parties.
          Those links are provided for convenience and may not remain current or
          be maintained. Unless expressly stated otherwise, we do not endorse
          and are not responsible for the content on those linked websites and
          have no control over or rights in those linked websites.
        </p>
        <h3>Intellectual property rights</h3>
        <p>
          Unless otherwise indicated, we own or license from third parties all
          rights, title and interest (including copyright, designs, patents,
          trademarks and other intellectual property rights) in these Platforms
          and in all of the material (including all text, graphics, logos, audio
          and software) made available on these Platforms (Content).
          <br />
          Your use of these Platforms and use of and access to any Content does
          not grant or transfer any rights, title or interest to you in relation
          to these Platforms or the Content. However we do grant you a licence
          to access these Platforms and view the Content on the terms and
          conditions set out in this Agreement and, where applicable, as
          expressly authorised by us and/or our third party licensors.
          <br />
          Outside of the affiliate or partnership program, social sharing
          buttons, infographic download feature, PDF download feature (only
          available with selected subscription levels) or with written
          permission any reproduction or redistribution of these Platforms or
          the Content is prohibited and may result in civil and criminal
          penalties. In addition, you must not copy the Content to any other
          server, location or support for publication, reproduction or
          distribution is expressly prohibited.
          <br />
          All other use, copying or reproduction of these Platforms, the Content
          or any part of it is prohibited, except to the extent permitted by
          law.
        </p>
        <h3>No commercial use</h3>
        <p>
          Outside of the affiliate/partnership programs, and the professional
          membership levels these Platforms are for your personal,
          non-commercial use only. You may not modify, copy, distribute,
          transmit, display, perform, reproduce, publish, license, commercially
          exploit, create derivative works from, transfer, or sell any Content,
          software, products or services contained within these Platforms. You
          may not use these Platforms, or any of its Content, to further any
          commercial purpose, including any advertising or advertising revenue
          generation activity on your own website.
        </p>
        <h3>Unacceptable activity</h3>
        <h4>
          You must not do any act that we would deem to be inappropriate, is
          unlawful or is prohibited by any laws applicable to these Platforms,
          including but not limited to:
        </h4>
        <p>
          - any act that would constitute a breach of either the privacy
          (including uploading private or personal information without an
          individual's consent) or any other of the legal rights of individuals;
          <br />- using these Platforms to defame or libel us, our employees or
          other individuals;
          <br />- uploading files that contain viruses that may cause damage to
          our property or the property of other individuals; and
          <br />- posting or transmitting to these Platforms any non-authorised
          material including, but not limited to, material that is, in our
          opinion, likely to cause annoyance, or which is defamatory, racist,
          obscene, threatening, pornographic or otherwise or which is
          detrimental to or in violation of our systems or a third party's
          systems or network security.
        </p>
        <p>
          If we allow you to post any information to our Platforms, we have the
          right to take down this information at our sole discretion and without
          notice.
        </p>
        <h3>Warranties and disclaimers</h3>
        <p>
          To the maximum extent permitted by law, including the Indian Consumer
          Law, we make no warranties or representations about these Platforms or
          the Content, including but not limited to warranties or
          representations that they will be complete, accurate or up-to-date,
          that access will be uninterrupted or error-free or free from viruses,
          or that these Platforms will be secure.
          <br />
          We reserve the right to restrict, suspend or terminate without notice
          your access to these Platforms, any Content, or any feature of these
          Platforms at any time without notice and we will not be responsible
          for any loss, cost, damage or liability that may arise as a result.
        </p>
        <h3>Liability</h3>
        <p>
          To the maximum extent permitted by law, including the Indian Consumer
          Law, in no event shall we be liable for any direct and indirect loss,
          damage or expense – irrespective of the manner in which it occurs –
          which may be suffered due to your use of our Platforms and/or the
          information or materials contained on it, or as a result of the
          inaccessibility of these Platforms and/or the fact that certain
          information or materials contained on it are incorrect, incomplete or
          not up-to-date.
        </p>
        <h3>Cookies</h3>
        <p>
          This website utilises cookies. If you do not have cookies enabled in
          your web browser some functions of the site may not work as intended.
        </p>
        <h3>Jurisdiction and governing law</h3>
        <p>
          Your use of these Platforms and these Terms are governed by the law of
          India and you submit to the non-exclusive jurisdiction of the courts
          exercising jurisdiction in Delhi, India
        </p>
      </div>
    </div>
  );
}
