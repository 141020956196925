import {
    REQUEST_NAV_LINES,
    SUCCESS_NAV_LINES,
    FAILURE_NAV_LINES,

    REQUEST_HIT_RATIO,
    SUCCESS_HIT_RATIO,
    FAILURE_HIT_RATIO,

    REQUEST_MONTHLY_RETURNS,
    SUCCESS_MONTHLY_RETURNS,
    FAILURE_MONTHLY_RETURNS,

    REQUEST_MONTHLY_ABSOLUTE_RETURNS,
    SUCCESS_MONTHLY_ABSOLUTE_RETURNS,
    FAILURE_MONTHLY_ABSOLUTE_RETURNS
} from "../../Constant/actionTypes";

import Api from "../Api/apiCalls";


//NAV Lines Action

export const requestNavLines = () => {
    return {
        type: REQUEST_NAV_LINES
    }
}

export const successNavLines = (result) => {
    return {
        type: SUCCESS_NAV_LINES,
        navLineData: result
    }
}

export const failureNavLines = () => {
    return {
        type: FAILURE_NAV_LINES
    }
}

export const handleNavLinesAction = (dropDownPortFolio, backtest_days, aggregate_period, token) => {
    return function (dispatch) {
        dispatch(requestNavLines())
        Api.navLineApi(dropDownPortFolio, backtest_days, aggregate_period, token).then((result) => {
            dispatch(successNavLines(result))
        }).catch((error) => {
            dispatch(failureNavLines(error))
        })
    }
}

//Hit Ratio Action

export const requestHitRatio = () => {
    return {
        type: REQUEST_HIT_RATIO
    }
}

export const successHitRatio = (result) => {
    return {
        type: SUCCESS_HIT_RATIO,
        hitRatioData: result
    }
}

export const failureHitRatio = () => {
    return {
        type: FAILURE_HIT_RATIO
    }
}

export const handleHitRatioAction = (dropDownPortFolio, backtest_days, token) => {
    return function (dispatch) {
        dispatch(requestHitRatio())
        Api.hitRatioApi(dropDownPortFolio, backtest_days, token).then((result) => {
            dispatch(successHitRatio(result))
        }).catch((error) => {
            dispatch(failureHitRatio(error.message))
        })
    }
}

// Monthly Returns Action
export const requestMonthlyReturns = () => {
    return {
        type: REQUEST_MONTHLY_RETURNS
    }
}

export const successMonthlyReturns = (result) => {
    return {
        type: SUCCESS_MONTHLY_RETURNS,
        monthlyReturnsData: result
    }
}

export const failureMonthlyReturns = () => {
    return {
        type: FAILURE_MONTHLY_RETURNS
    }
}

export const requestMonthlyAbsoluteReturns = () => {
    return {
        type: REQUEST_MONTHLY_ABSOLUTE_RETURNS
    }
}

export const successMonthlyAbsoluteReturns = (result) => {
    return {
        type: SUCCESS_MONTHLY_ABSOLUTE_RETURNS,
        monthlyAbsoluteReturnsData: result
    }
}

export const failureMonthlyAbsoluteReturns = () => {
    return {
        type: FAILURE_MONTHLY_ABSOLUTE_RETURNS
    }
}

export const handleMonthlyReturnsAction = (dropDownPortFolio, returnData = "percentage_returns", token) => {
    return function (dispatch) {
        dispatch(requestMonthlyReturns())
        Api.monthlyReturnApi(dropDownPortFolio, returnData, token).then((result) => {
            console.log("resultresult",result)
            dispatch(successMonthlyReturns(result))
        }).catch((error) => {
            // console.log("Result?????---PPPPPPP----------",error?.response?.data);
            dispatch(failureMonthlyReturns(error))
        })
    }
}

export const handleMonthlyAbsoluteReturnsAction = (dropDownPortFolio, returnData = "absolute_returns", token) => {
    return function (dispatch) {
        dispatch(requestMonthlyAbsoluteReturns())
        Api.monthlyAbsoluteReturnApi(dropDownPortFolio, returnData, token).then((result) => {
console.log("resultresult",result)
            dispatch(successMonthlyAbsoluteReturns(result))
        }).catch((error) => {
            // console.log("Result?????---PPPPPPP----------",error?.response?.data);
            dispatch(failureMonthlyAbsoluteReturns(error))
        })
    }
}