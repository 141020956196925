import {
    SCROLL_ONCLICK,
    SCROLL_POSITION,
    LEFT_NAV_VALUE,
    CONTAINER_VALUE,
    TRANSACTION_VALUE,
} from "../../Constant/actionTypes";

export const scrollOnClick = (menuName) => {
    return {
        type: SCROLL_ONCLICK,
        menuName
    }
}

export const scrollPosition = (isVisible,componentName) => {
    return {
        type: SCROLL_POSITION,
        isVisible,
        componentName,
    }
}

export const leftNavValue = (navValue) => {
    return {
        type: LEFT_NAV_VALUE,
        navValue
    }
}

export const containerValues = (id,containerValue) => {
    return {
        type: CONTAINER_VALUE,
        containerValue :containerValue
    }
}

export const values = (value) =>{
    return {
        type: TRANSACTION_VALUE,
        transactionValue: value
    }
}

export const handleLeftNavValue = (navValue) => {
    return function (dispatch) {
        dispatch(leftNavValue(navValue))
    }
}

export const handleScrollAction = (menuName) => {
    return function (dispatch) {
        dispatch(scrollOnClick(menuName))
    }
}

export const handleScrollChangeComponentAction = (isVisible,componentName) => {
    return function (dispatch){
        dispatch(scrollPosition(isVisible,componentName))
    }
}

export const handleContainerValueAction = (id,containerValue) => {
    return function (dispatch){
        dispatch(containerValues(id,containerValue))
    }
}

export const handleValue = (value) => {
    return function(dispatch){
        dispatch(values(value))
    }
}