import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./CorrelationMatrix.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class CorrelationMatrixTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 100
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
    }

    onColumnResized = (params) => {
        console.log(params);
      };
    componentDidUpdate(preProps) {
        if (preProps.correlationData != this.props.correlationData) {
            if (this.props.correlationData && this.props.correlationData.length < 7) {
                this.setState({
                    height: this.props.correlationData.length * 47 + 47
                })
            }

            else {
                this.setState({
                    height: 480
                })
            }
        }
    }


    render() {
        const { correlationData } = this.props;
        const hasCorrelationMatrix = this.props.showTableData?.indexOf("correlation_matrix") >-1
        return (
            <div className="correlationmatrixData-table-container" id="correlationmatrix">
                <TableHeader title='Correlation Matrix' />
                {
                    hasCorrelationMatrix?<div className="correlationmatrixData-table">
                    <div className="ag-theme-alpine" style={{ height: this.state.height }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={correlationData}
                            className='overflow-initial'
                            onColumnResized={this.onColumnResized.bind(this)}
                        >
                            <AgGridColumn headerName='' field="eod_ticker" minWidth={100}></AgGridColumn>
                            {
                                correlationData &&
                                correlationData.map((item, index) => {
                                    return (
                                        <AgGridColumn
                                            // style={{ width: '10%', minWidth: '10%', maxWidth: '10%' }}
                                            minWidth={110}
                                            key={index}
                                            field={item.eod_ticker}
                                            headerName={item.eod_ticker}
                                            cellStyle={(i) => (parseFloat(i.data[`${item.eod_ticker}`]) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}
                                            cellRendererFramework={(i) => ((i.data[`${item.eod_ticker}`]) ? ((parseFloat(i.data[`${item.eod_ticker}`]).toFixed(3))) : (i.data[`${item.eod_ticker}`]))}>
                                        </AgGridColumn>
                                    )
                                })
                            }
                        </AgGridReact>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
                
            </div>
        )
    }
}

export default CorrelationMatrixTable;

