import React from "react";
import "../paymentCard/currentPlan.scss";
import { useSelector, useDispatch } from 'react-redux';

const CurrentPlan = (props) => {
    return (
        <div className="current-plan-container">
            <p className="current-plan">CURRENT PLAN : <span className='current-Plan-Name'>{props?.loginUserDetails?.stripe_details?.plan_name} {props?.loginUserDetails?.stripe_details?.payment_frequency ? `( ${props?.loginUserDetails?.stripe_details?.payment_frequency})` : null}  </span> </p>
            <div className="current-plan-details">
                {
                    <div>
                        {props.updateText != 'Update' && <h3>
                            {
                                props?.loginUserDetails?.stripe_details?.status == 'trial'
                                    ? `Your trial expires on ${props.loginUserDetails?.stripe_details?.current_cycle_end}.` :
                                    props?.loginUserDetails?.stripe_details?.status == 'cancelled'
                                        ? 'You are not active ' :
                                        `Your plan renews on ${props.loginUserDetails?.stripe_details?.current_cycle_end}.`
                            }
                        </h3>}
                    </div>
                }
                {
                    props.updateText != 'Update' &&
                    <div className="plan-button">
                        {
                            props?.loginUserDetails?.stripe_details?.status == 'active' ?
                                <>
                                    <button className="update-plan" onClick={() => props.updatePlan('Update')}>Update Plan</button>
                                    <button className="cancel-plan" onClick={props.handleCancelPlan}>Cancel Plan</button>
                                </> : null
                        }
                        {/* <button className="initiate-plan" onClick={()=>props.handleInitiatePlan('monthly', 'Bronze', '1587.00')}>Initiate Plan</button> */}

                    </div>
                }
            </div>
        </div>
    )
}

export default CurrentPlan;