import React, { useRef } from 'react';
import { useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import './brokerPortfolioLink.scss'
import Modal from '../Components/Modal/modal';


export default function BrokerPortfolioLink(props) {
    const state = useSelector(state => state.getUserDetailsReducer.userData)
    return (
        <div className='brokerPortfolioLink'>
            <Modal {...props} />

            <div>
                <div className='brokerRow'>
                    <select
                        value={props?.portfolio_id}
                        className='dropdown'
                        onChange={(e) => props.onSelect(e.target.value, 'portfolio_id')}
                    >
                        {
                            state && state.portfolios.map((item, index) => {
                                return <option key={index} value={item}>{item.slice(item.indexOf('_') + 1, item.length)}</option>
                            })
                        }
                    </select>
                    <Multiselect
                        selectedValues={props?.dataStream}
                        options={props?.sourceLinkDetails ? props?.sourceLinkDetails : []} // Options to display in the dropdown
                        onSelect={(e) => props.onSelect(e, 'source_link_id')} // Function will trigger on select event
                        onRemove={(e) => props.onRemove(e)}
                        displayValue="link_alias" // Property name to display in the dropdown options
                        placeholder='Click to selected Data Stream'
                        id='css_custom'
                    />
                    {/* <button onClick={() => props.addBroker()} className="broker-btn">Add More</button> */}
                </div>
                {props?.sourceLinkDetails && props?.sourceLinkDetails ? <table className='broker_table w-100 mt-15' cellSpacing='0' cellPadding='0'>
                    <thead>
                        <tr>
                            <td className='tr-border'>Brokerage account</td>
                            <td className='tr-border'>Data Stream</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props?.sourceLinkDetails && props?.sourceLinkDetails?.map((item) => {
                            
                            return (
                                item?.portfolio_ids.length>0?
                            <tr key={item?.id}>
                                <td>{item?.portfolio_ids && item?.portfolio_ids?.map((data) => {
                                    return <tr key={data?.id}>
                                        <td>{data?.portfolio_id?.slice(data?.portfolio_id?.indexOf('_') + 1)}</td>
                                    </tr>
                                })}</td>
                                <td>{item?.link_alias }</td>
                            </tr>:null)
               //    item?.object_id?.slice(item?.object_id?.indexOf('_') + 1)
                        })}
                    </tbody>
                </table>
                    : null}
            </div>
            <div>
                <button onClick={(e) => props.handleSubmit(e, 'Broker Details')} className="broker-btn broker-submit-button">SUBMIT</button>
            </div>

        </div >
    )
}
