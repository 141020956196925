import {
    REQUEST_CANCEL_PLAN,
    SUCCESS_CANCEL_PLAN,
    FAILURE_CANCEL_PLAN,
} from "../../Constant/actionTypes";

const initilizeState = {
    cancelPlanFlag:''
}

export default function cancelPlanReducer(state=initilizeState,action){
    switch(action.type){
        case REQUEST_CANCEL_PLAN:
            return{
                ...state,
                cancelPlanFlag: 'request'
            }
        case SUCCESS_CANCEL_PLAN:
            return{
                ...state,
                cancelPlanMessage: action.cancelPlanMessage,
                cancelPlanFlag: 'success'
            }
        case FAILURE_CANCEL_PLAN:
            return{
                ...state,
                cancelPlanFlag: 'fail'
            }
        default:
            return state;
    }
}
