import {

    REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
    SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,
    FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME,

} from "../../Constant/actionTypes";

const initilizeState = {
}

function homeFixedIncomeDashboardReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME:
            return{
                ...state,
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME:
            return{
                ...state,
                homeFixedIncomeData: action.homeFixedIncomeData
            }
        case FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME:
            return{
                ...state,
            }
        default:
            return state
    }
}

export default  homeFixedIncomeDashboardReducer;