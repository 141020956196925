import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as LogoTagline } from "../../Assets/Images/logoTagline.svg";
import { Link, useHistory } from 'react-router-dom';
import "./login.scss";
import { handleResetAction } from "../../Redux/actions/signUpAction";
import { handleLoginAction } from "../../Redux/actions/loginAction";
import { handleSubmitForgetPasswordAction } from "../../Redux/actions/forgetPasswordAction";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '20%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#051E55',
  color:"#fff",
  boxShadow: 15,
  p: 4,
};

const Login = (props) => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [loginUser, setLoginUser] = useState({ email: '', password: '' });
    const errorMessage = useSelector(state => state.loginReducer.errorMessage);
    const loginUserDetails = useSelector(state => state.loginReducer.loginUserDetails);
    const forgetpasswordMessage = useSelector(state => state.forgetPasswordReducer.forgetpasswordMessage)
    const [errorEmail, setErrorEmail] = useState('');
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event) => {
        setLoginUser((prevProps) => ({
            ...prevProps,
            [event.target.name]: event.target.value
        }))
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/rightContainer')
        }
    })

    useEffect(() => {
        setOpen(false)
    }, [forgetpasswordMessage])

    useEffect(() => {
        dispatch(handleResetAction())
    }, [])

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        if (loginUser.email.includes('@')) {
            dispatch(handleLoginAction(loginUser))
        }
        else {
            setErrorEmail('Invalid Email format')
        }
    }

    const handleSubmitForgetPassword = event => {
        event.preventDefault();
        if (loginUser.email.includes('@')) {
            setErrorEmail('')
            dispatch(handleSubmitForgetPasswordAction(loginUser))
        }
        else {
            setErrorEmail('Invalid Email format')
        }
       
    }

    return (
        <div className="login-container">
            <div className="Login reg-form">
                <LogoTagline className="reg-logo" width="150" height="120" />
                <div className="reg-tab">
                    <span className="tab-btn active" style={{ cursor: 'pointer' }}>Log In</span>
                    <Link to='/register' className="tab-btn">Sign Up</Link>
                </div>
                <form className="form">
                    <fieldset className="fieldset">
                        <label className="label">Email address</label>
                        <input className="input" type="text" name="email" value={loginUser.email} onChange={handleChange} placeholder="e.g. example@gmail.com" />
                        <span style={{ color: 'red' }}>{errorEmail}</span>
                    </fieldset>
                    <fieldset className="fieldset">
                        <label className="label">Password</label>
                        <input className="input" type="password" name="password" value={loginUser.password} onChange={handleChange} placeholder="••••••••••" />
                        {errorMessage && errorMessage.length && <span style={{ color: 'red' }}>{errorMessage}</span>}
                        {forgetpasswordMessage.length && forgetpasswordMessage === 'Email to user successfully sent' ? <span style={{ color: 'green' }}>{forgetpasswordMessage}</span> : <span style={{ color: 'red' }}>{forgetpasswordMessage}</span>}
                    </fieldset>
                    <button className="reg-btn" type="submit" onClick={handleLoginSubmit}>Log In</button>
                </form>
                <div style={{ display: 'flex' }}>
                    {/* <div className="reg-bottom" onClick={handleSubmitUpdatePassword}>
                        <div className="text-link">Update your password?</div>
                    </div> */}
                    <div className="reg-bottom" onClick={handleOpen}>
                        <div className="text-link">Forgot your password?</div>
                    </div>
                </div>
                <div style={{
                    display: 'flex', justifyContent: "space-around", marginTop: '25px'
                }}>
                    {/* <Link to='/support' className="help-link">Support</Link> */}
                    <a href="/privacy" target="_blank" rel="noopener noreferrer" className="help-link">Privacy</a>
                    <a href="/terms" target="_blank" rel="noopener noreferrer" className="help-link">Terms</a>
                </div>
            </div>






{/* Modal */}
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <h2 id="parent-modal-title">Reset Password</h2>
                        <label >Email address</label>
<br/>
                        <input  type="text" name="email" style={{marginTop: '10px',width:"100%",color:"white",backgroundColor:"#051E55", padding:"8px",border: 'none',borderBottom:"1px solid black"}} value={loginUser.email} onChange={handleChange} placeholder="e.g. example@gmail.com" />
                        <span style={{ color: 'red' }}>{errorEmail}</span><br/>
                 
                        <button style={{padding:"5px",marginTop: '10px',border: 'none',textDecoration: 'none',backgroundColor:"#DD71EC"}} type="submit" onClick={handleSubmitForgetPassword}>Submit</button>
        </Box>
      </Modal>
        </div >

        
    )
}

export default Login;