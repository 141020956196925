import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./Heading.scss";
import Waterfall from "./HeadingWaterFall";
import zoomInOut from "../../Assets/Images/zoomInOut.svg";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class HeadingTable extends Component{
    constructor(props){
        super(props);
        this.state = {
        }
    }
   
    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    render(){
        const {headingDay,handleOnChangeHeading, headingData} = this.props;
        const defaultColDef={
            cellStyle: {textAlign: 'center'}
        };   
        const hasHeadingTable = this.props.showTableData?.indexOf("return_attributions (in USD)") >-1
        return(
            <div className="headingData-table-container" id="heading">
                <TableHeader title='Return Attributions in USD' component={<select value={headingDay} onChange={handleOnChangeHeading} style={{backgroundColor: '#94B3FC',color:'#ffffff', 
                borderColor: '#051C55', verticalAlign: 'top',height:30, width: 100}}  className="heading-select"><option value='7'>1 Week</option><option value='YTD'>YTD</option><option value='30'>1 Month</option><option value='90'>3 Month</option><option value='365'>1 Year</option><option value='730'>2 Year</option><option value='1095'>3 Year</option><option value='-1'>Since Inception</option></select>}/>
                {
                    hasHeadingTable?<div style={{display: "flex",justifyContent:"space-between"}}>
                    <Waterfall {...this.props}/>
                    <div style={{display:"flex",flexDirection:"column", width:"25%", margin: " 0 1% 0 0" }}>
                        <div className="headingData-table"> 
                            <div className="ag-theme-alpine" >
                                <AgGridReact
                                    onGridReady={this.onGridReady}
                                    rowData={headingData}
                                    defaultColDef={defaultColDef}
                                >
                                    <AgGridColumn headerName='Stock' field="eod_ticker" minWidth={120} cellStyle={()=>({left: '200'})}></AgGridColumn>
                                    <AgGridColumn headerName='Attributable Return' field="attribution.absolute_returns_table" minWidth={169}
                                    cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}
                                    >
                                    </AgGridColumn>
                                    {/* <AgGridColumn headerName='Attributable Return' field="attribution.absolute_returns"
                                    cellStyle=
                                    {   
                                        (i)=>(parseInt(i.data.attribution)>=0? {color: '#0DFD32'} : {color: '#FF4C4D'} )
                                    }
                                    >
                                    </AgGridColumn> */} 
                                </AgGridReact>
                            </div>
                        </div>
                        <div  style={{ display:"flex", justifyContent: "flex-end", margin: "5% 0 0 0 "  }}>
                            <img src={zoomInOut} alt=''></img> 
                        </div>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
            </div>
        )
    }
}

export default HeadingTable;