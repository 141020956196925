import {
    REQUEST_UPDATE_PASSWORD,
    SUCCESS_UPDATE_PASSWORD,
    FAILURE_UPDATE_PASSWORD,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestUpdatePassword = () =>{
    return{
        type:  REQUEST_UPDATE_PASSWORD
    }
}

export const successUpdatePassword = (result) => {
    return{
        type: SUCCESS_UPDATE_PASSWORD,
        updatePasswordMessage: result
    }
}

export const failureUpdatePassword = () => {
    return{
        type: FAILURE_UPDATE_PASSWORD
    }
}

export const handleSubmitUpdatePasswordAction = (email,password,token) =>{
    return function(dispatch){
        dispatch(requestUpdatePassword())
        Api.UpdatePasswordApi(email,password,token).then((result)=>{
            dispatch(successUpdatePassword(result.data))
        }).catch((error)=>{
            dispatch(failureUpdatePassword())
        })
    }
}