import React, { useState, useEffect } from "react";
import "./createPortfolio.scss";
import Input from '@material-ui/core/Input';
import ToggleButton from "../toggleButton/Togglebutton";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { createStyles, makeStyles, Switch, Theme } from '@material-ui/core';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import {handleGetBenchMarkListAction} from '../../Redux/actions/getListOfBenchMarkAction'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: theme.spacing(1),
            '& label.Mui-focused': {
                color: 'white',
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#94B3FC',
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                },
                '&:hover fieldset': {
                    borderColor: 'white',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#94B3FC',
                },
            },
            '&.MuiSwitch-colorPrimary.Mui-checked': {
                color: '#94B3FC',
            },
            padding: 0,
        },
        switchBase: {
            padding: 1,
            '&$checked': {
                // This is the part that animates the thumb when the switch is toggled (to the right)
                transform: 'translateX(16px)',
                // This is the thumb color
                color: theme.palette.common.white,
                '& + $track': {
                    // This is the track's background color (in this example, the iOS green)
                    backgroundColor: '#94B3FC',
                    opacity: 1,
                    border: 'none',
                },
            },
        },
        thumb: {
            width: 21,
            height: 21,

        },
        track: {
            borderRadius: 19,
            border: `1px solid ${theme.palette.grey[300]}`,
            // This is the background color when the switch is off
            backgroundColor: theme.palette.grey[200],
            height: 21,
            opacity: 1,
            marginTop: 1,
            width: 38,
            transition: theme.transitions.create(['background-color', 'border']),
        },
        checked: {},
        focusVisible: {},
    })
);

const CreatePortfolioModal = (props) => {
    const styles = useStyles();
    const [name, setName] = useState('')
    const [base_currency, setBase_currency] = useState('')
    const [benchmark, setBenchmark] = useState('')
    const [toggel, setToggel] = useState(true)
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch()
    // const benchmarksListData = useSelector(state=>state.getListOfBenchMarkReducer.benchmarksList) 
    
    const heldlevalue = (event, name) => {
        if (name === "name") {
            setName(event.target.value)
        }
        if (name === "password") {
            setPassword(event.target.value)
        }
    }

    const hendletoggel = () => {
        setToggel(!toggel)
    }
    useEffect(() => {
        // dispatch(handleGetBenchMarkListAction())
    }, [])
    // const handleChangeBroker = (value, key, index) => {
    //     this.state.broker_value[index][key] = value
    //     this.setState([...this.state.broker_value])
    // }
    // AddBroker = () => {
    //     this.state.broker_value.push({
    //         broker_value: ''
    //     })
    //     this.setState([...this.state.broker_value])
    // }
    // RemoveBroker = (index) => {
    //     this.state.broker_value.splice(index, 1)
    //     this.setState([...this.state.broker_value])
    // }
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = () => {
        if (base_currency === '' || name === '') {
            if (base_currency === '') {
                toast.warning('Please add base currency')
            }
            if (name === '') {
                toast.warning('Please add name')
            }
        }
        else {
            props.handleCreatePortfolios({
                name: name,
                base_currency: base_currency,
                benchmark_indices: benchmark,
                password: password,
                share_portfolio: toggel
            })
        }
    }
    

    return (
        <div className="create-portfolio-modal-container">
            <div className="create-portfolio-modal-table">
                <table className="create-portfolio-table">
                    <thead>
                        <tr className="create-portfolio-data">
                            <th>Portfolio Name</th>
                            <th>Base Currency</th>
                            <th>Benchmark</th>
                            <th>Share portfolio</th>
                            <th>Password</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="create-portfolio-data">
                            <td>
                                <TextField
                                    classes={{ root: styles.root }}
                                    value={name}
                                    name="name"
                                    placeholder="Enter porfolio Name..."
                                    type="text"
                                    onChange={(event) => { heldlevalue(event, 'name') }}
                                    required
                                    style={{left:-15}}
                                />
                            </td>
                            <td>
                                <Autocomplete
                                    id="Base_Currency"
                                    options={props.currencyarr ? props.currencyarr : []}
                                    getOptionLabel={(option) => option.label}
                                    style={{ width: 100, border: "none" }}
                                    onInputChange={(e, value) => setBase_currency(value)}
                                    renderInput={(params) =>
                                        <TextField
                                            classes={{ root: styles.root }}
                                            {...params}
                                            style={{left:-15}}
                                            required
                                        />} />
                            </td>
                            <td>
                                <Autocomplete
                                    id="benchmark_indices"
                                    options={props.benchmarkarr?props.benchmarkarr:[]}
                                    getOptionLabel={(option) => option.label.Name}
                                    style={{ width: 300 }}
                                    onChange={(e,value)=>setBenchmark(value.label.eod_ticker)}
                                    onInputChange={(e, value) => setBenchmark(value)}

                                    renderInput={(params) =>
                                        <TextField
                                            classes={{ root: styles.root }}
                                            {...params}
                                            
                                            style={{left:-4, width:290}}
                                        />} />
                            </td>
                            <td>
                                {/* <ToggleButton toggel={toggel} hendletoggel={this.hendletoggel} /> */}
                                <FormControlLabel style={{marginLeft:2}}
                                    control={
                                        <Switch
                                            checked={toggel}
                                            onChange={hendletoggel}
                                            name="checkedB"
                                            color="primary"
                                            classes={{
                                                root: styles.root,
                                                switchBase: styles.switchBase,
                                                thumb: styles.thumb,
                                                track: styles.track,
                                                checked: styles.checked,
                                            }}
                                        />
                                    }
                                // label="Primary"
                                />
                            </td>
                            <td>
                                <Input
                                    // id="standard-adornment-password"
                                    classes={{ root: styles.root }}
                                    value={password}
                                    name="password"
                                    placeholder="Enter the password..."
                                    type={showPassword ? 'text' : 'password'}
                                    onChange={(event) => { heldlevalue(event, 'password') }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton style={{padding:2}}
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='broker-div'>
                {/* {broker_value?.map((item, index) => {
                        return <div className='broker-array-div'>
                            <div style={{ width: '50%' }}>
                                <label>Broker (optional) : &nbsp;
                            <input type='text' name='broker_value' className='input-field' value={item.broker_value} onChange={(e) => this.handleChangeBroker(e.target.value, 'broker_value', index)}></input>
                                </label>
                            </div>
                            <div className="create-btn broker-btn-div">
                                <button className="btn" onClick={this.AddBroker}>Add More</button>
                                <button className="btn">Finished</button>
                                <button disabled={broker_value.length === 1} className="btn" onClick={() => this.RemoveBroker(index)}>Cancel</button>
                            </div>
                        </div>
                    })} */}
            </div>
            <div className="create-btn">
                <button className="btn" onClick={handleSubmit}>Create</button>
            </div>
        </div>
    )
}

export default CreatePortfolioModal;