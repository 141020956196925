import {
    REQUEST_EDIT_TRANSACTION,
    SUCCESS_EDIT_TRANSACTION,
    FAILURE_EDIT_TRANSACTION,
} from "../../Constant/actionTypes";

const initilizeState = {
    editTransactionMessage: '',
    EditTransaction: '',
    ErrorTransactionMessage:''
}

export default function performEditTransactionReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_EDIT_TRANSACTION:
            return{
                ...state,
                EditTransaction:'request'
            }
        case SUCCESS_EDIT_TRANSACTION:
            return {
                ...state,
                editTransactionMessage: action.editTransactionMessage,
                EditTransaction: 'success'
            }
        case FAILURE_EDIT_TRANSACTION:
            return {
                ...state,
                ErrorTransactionMessage:action.ErrorTransactionMessage,
                EditTransaction: 'fail'
            }
        default: 
            return state;
    }
}