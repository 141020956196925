import {
    REQUEST_UPDATE_PLAN,
    SUCCESS_UPDATE_PLAN,
    FAILURE_UPDATE_PLAN,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";
import { handleGetUserDetailsAction } from '../actions/getUserDetailsAction'

export const requestUpdatePlan = () =>{
    return{
        type: REQUEST_UPDATE_PLAN
    }
}

export const successUpdatePlan = (data) =>{
    return{
        type: SUCCESS_UPDATE_PLAN,
        updateData: data
    }
}

export const failureUpdatePlan = () =>{
    return{
        type: FAILURE_UPDATE_PLAN
    }
}

export const handleUpdatePlanAction = (payment_frequency, plan_name, token) =>{
    return function(dispatch){
        dispatch(requestUpdatePlan())
        Api.UpdatePlanApi(payment_frequency, plan_name, token).then(result=>{
            dispatch(successUpdatePlan(result.data))
            dispatch(handleGetUserDetailsAction(token))
        }).catch(error=>{
            dispatch(failureUpdatePlan(error))
        })
    }
}