import {
    REQUEST_PAST_INVOICE,
    SUCCESS_PAST_INVOICE,
    FAILURE_PAST_INVOICE,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestPastInvoice = () => {
    return {
        type: REQUEST_PAST_INVOICE,
    }
}

export const successPastInvoice = (result) => {
    return {
        type: SUCCESS_PAST_INVOICE,
        invoiceData: result
    }
}

export const failurePastInvoice = () => {
    return {
        type: FAILURE_PAST_INVOICE
    }
}

export const handlePastInvoiceAction = (token) => {
    return function(dispatch){
        dispatch(requestPastInvoice())
        Api.PastInvoiceApi(token).then((result)=>{
            dispatch(successPastInvoice(result))
        }).catch((error)=>{
            dispatch(failurePastInvoice())
        })
    }
}