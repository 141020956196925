import React, { Component } from "react";
import "./homeTable.scss";
import TableHeader from "../tableHeader/tableHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

class HomeTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sectorsTitle: 'SP500',
            SP500: true,
            Europe: false,
            MSCIWorld: false,
            height: 390,
        }
        this.onChangeSectorsValue = this.onChangeSectorsValue.bind(this);
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    onChangeSectorsValue(event) {
        if (event.target.value === 'SP500') {
            this.setState({
                SP500: true,
                Europe: false,
                MSCIWorld: false
            })
        }
        else if (event.target.value === 'Europe') {
            this.setState({
                Europe: true,
                SP500: false,
                MSCIWorld: false
            })
        }
        else if (event.target.value === 'MSCIWorld') {
            this.setState({
                MSCIWorld: true,
                SP500: false,
                Europe: false,
            })
        }
        this.setState({
            sectorsTitle: event.target.value
        })
    }
    
    componentDidUpdate(preprops) {
        if (preprops != this.props) {
            if (this.props.title === 'Countries') {
                if (this.props.homeCountryData.length < 10) {
                    this.setState({
                        height: this.props.homeCountryData.length * 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Fixed Income') {
                if (this.props.homeFixedIncomeData.length < 10) {
                    this.setState({
                        height: this.props.homeFixedIncomeData.length * 43
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Sectors' && this.state.sectorsTitle === 'SP500') {
                if (Object.entries(this.props.homeSectorData).length != 0 && this.props.homeSectorData['S&P 500'].length < 10) {
                    this.setState({
                        height: this.props.this.props.homeSectorData['S&P 500'].length * 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Sectors' && this.state.sectorsTitle === 'Europe') {
                if (Object.entries(this.props.homeSectorData).length != 0 && this.props.homeSectorData['Europe'].length < 10) {
                    this.setState({
                        height: this.props.homeSectorData['Europe'].length * 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Sectors' && this.state.sectorsTitle === 'MSCIWorld') {
                if (Object.entries(this.props.homeSectorData).length != 0 && this.props.homeSectorData['MSCI World'].length < 10) {
                    this.setState({
                        height: this.props.homeSectorData['MSCI World'].length * 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Commodities') {
                if (this.props.homeCommoditiesData.length < 10) {
                    this.setState({
                        height: this.props.homeCommoditiesData.length * 46
                    })
                }
                else if (this.props.homeCommoditiesData.length == 0) {
                    this.setState({
                        height: 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Risk') {
                if (this.props.homeRiskData.length < 10) {
                    this.setState({
                        height: this.props.homeRiskData.length * 48
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Global Markets') {
                if (this.props.homeGlobalMarketData.length < 10) {
                    this.setState({
                        height: this.props.homeGlobalMarketData.length * 46
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
            if (this.props.title === 'Style') {
                if (this.props.homeStyleData.length < 10) {
                    this.setState({
                        height: this.props.homeStyleData.length * 43
                    })
                }
                else {
                    this.setState({
                        height: 390
                    })
                }
            }
        }
    }


    render() {

        const { homeGlobalMarketData, homeRiskData, homeCommoditiesData, homeStyleData, homeSectorData, homeFixedIncomeData, homeCountryData, title } = this.props;

        const defaultColDef = {
            resizable: true,
            // cellStyle: { minwidth: 200 },

        }

        return (
            <div className="home-table-container" id={title}>
                <TableHeader {...this.props} component={title === 'Sectors' &&
                    <div onChange={this.onChangeSectorsValue} >
                        <button className="header_nav" value="SP500" name="SP500" onClick={this.onChangeSectorsValue}
                            style={{ backgroundColor: this.state.SP500 ? "white" : "#051C55" }}
                        ></button><label style={{ color: this.state.SP500 ? "white" : "white" }}>S&#38;P 500</label>

                        <button className="header_nav" value="Europe" name="Europe" onClick={this.onChangeSectorsValue}
                            style={{ backgroundColor: this.state.Europe ? "white" : "#051C55" }}
                        ></button>

                        <label style={{ color: this.state.Europe ? "white" : "white" }} >Europe</label>
                        <button className="header_nav" value="MSCIWorld" name="MSCIWorld" onClick={this.onChangeSectorsValue}
                            style={{ backgroundColor: this.state.MSCIWorld ? "white" : "#051C55" }}
                        ></button><label style={{ color: this.state.MSCIWorld ? "white" : "white" }}
                        >MSCI World</label>
                    </div>
                }
                />
                <div className="home-table fixed-income">
                    <div className="ag-theme-alpine" style={{ height: this.state.height }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            // columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowData={title === 'Fixed Income'
                                ? homeFixedIncomeData && homeFixedIncomeData : title === 'Countries'
                                    ? homeCountryData && homeCountryData : (title === 'Sectors' && this.state.sectorsTitle === 'SP500')
                                        ? homeSectorData && homeSectorData['S&P 500'] : (title === 'Sectors' && this.state.sectorsTitle === 'Europe')
                                            ? homeSectorData && homeSectorData['Europe'] : (title === 'Sectors' && this.state.sectorsTitle === 'MSCIWorld')
                                                ? homeSectorData && homeSectorData['MSCI World'] : title === 'Commodities'
                                                    ? homeCommoditiesData && homeCommoditiesData : title === 'Risk'
                                                        ? homeRiskData && homeRiskData : title === 'Global Markets'
                                                            ? homeGlobalMarketData && homeGlobalMarketData : title === 'Style'
                                                                ? homeStyleData && homeStyleData : [{}]}
                        >
                            <AgGridColumn  minWidth={220}  headerName='Index' field="index" cellStyle={() => ({ color: '#0FC9FC' })}></AgGridColumn>
                            {/* <AgGridColumn headerName='Stock'   field="eod_ticker"></AgGridColumn> */}
                            {/* <AgGridColumn headerName='Date'    field="date"></AgGridColumn> */}
                            <AgGridColumn headerName='Value' minWidth={97}  field="value"></AgGridColumn>
                            <AgGridColumn headerName='1D' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field='1d'></AgGridColumn>
                            <AgGridColumn headerName='YTD' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="YTD"></AgGridColumn>
                            <AgGridColumn headerName='1W' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="1w"></AgGridColumn>
                            <AgGridColumn headerName='1M' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="1M"></AgGridColumn>
                            <AgGridColumn headerName='3M' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="3M"></AgGridColumn>
                            <AgGridColumn headerName='1Y' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="1Y"></AgGridColumn>
                            <AgGridColumn headerName='2Y' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="2Y"></AgGridColumn>
                            <AgGridColumn headerName='3Y' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="3Y"></AgGridColumn>
                            <AgGridColumn headerName='5Y' minWidth={97}  cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} field="5Y"></AgGridColumn>
                        </AgGridReact>
                    </div>
                </div>
            </div >
        )
    }
}
export default HomeTable;