import {
    REQUEST_GET_TRANSACTION_DETAILS_HISTORY,
    SUCCESS_GET_TRANSACTION_DETAILS_HISTORY,
    FAILURE_GET_TRANSACTION_DETAILS_HISTORY,

    REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY,
    SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY,
    FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY,

    REQUEST_BUY_TRANSACTION,
    SUCCESS_BUY_TRANSACTION,
    FAILURE_BUY_TRANSACTION,

    REQUEST_SELL_TRANSACTION,
    SUCCESS_SELL_TRANSACTION,
    FAILURE_SELL_TRANSACTION,

    REQUEST_DEPOSIT_TRANSACTION,
    SUCCESS_DEPOSIT_TRANSACTION,
    FAILURE_DEPOSIT_TRANSACTION,

    REQUEST_WITHDRAW_TRANSACTION,
    SUCCESS_WITHDRAW_TRANSACTION,
    FAILURE_WITHDRAW_TRANSACTION,

    REQUEST_EDIT_TRANSACTION,
    SUCCESS_EDIT_TRANSACTION,
    FAILURE_EDIT_TRANSACTION,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";


// GET TRANSACTION HISTORY DETAILS DATA
export const requestGetTransactionHistory = () =>{
    return{
        type: REQUEST_GET_TRANSACTION_DETAILS_HISTORY
    }
}

export const successGetTransactionHistory = (result) => {
    return{
        type: SUCCESS_GET_TRANSACTION_DETAILS_HISTORY,
        transactionHistoryDetails: result,
    }
}

export const failureGetTransactionHistory = () =>{
    return{
        type: FAILURE_GET_TRANSACTION_DETAILS_HISTORY
    }
}

export const handleGetTransactionHistoryAction = (dropDownPortFolio, token,portfolioid) =>{
    return function(dispatch){
        dispatch(requestGetTransactionHistory())
        Api.GetTransactionHistoryApi(dropDownPortFolio, token,portfolioid).then(result=>{
            dispatch(successGetTransactionHistory(result))
        }).catch(error=>{
            dispatch(failureGetTransactionHistory(error))
        })
    }
}

//DELETE SECURITY TRANSACTION HISTORY

export const requestDeleteSecurityTransactionHistory = () => {
    return{
        type: REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY 
    }
}

export const successDeleteSecurityTransactionHistory = (result) => {
    return{
        type: SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY,
        deleteTransactionMessage: result
    }
}

export const failureDeleteSecurityTransactionHistory = () =>{
    return{
        type: FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY
    }
}

export const handleDeleteSecurityTransactionHistoryAction = (token,data) =>{
    return function(dispatch){
        dispatch(requestDeleteSecurityTransactionHistory())
        Api.DeleteSecuritiesTransactionApi(token,data).then((result)=>{
            dispatch(successDeleteSecurityTransactionHistory(result.data))
            dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        }).catch(error=>{
            dispatch(failureDeleteSecurityTransactionHistory(error))
            // dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        })
    }
}

//PERFORM BUY TRANSACTION 
export const requestPerformBuyTransaction = () =>{
    return{
        type: REQUEST_BUY_TRANSACTION
    }
}

export const successPerformaBuyTransaction = (result) =>{
    return{
        type: SUCCESS_BUY_TRANSACTION
    }
}

export const failurePerformaBuyTransaction = () =>{
    return{
        type: FAILURE_BUY_TRANSACTION
    }
}

export const handlePerformBuyTransactionAction = (token,data) => {
    return function(dispatch){
        dispatch(requestPerformBuyTransaction())
        Api.PerformBuyTransactionApi(token,data).then((result)=>{
            dispatch(successPerformaBuyTransaction(result))
            dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        }).catch(error=>{
            dispatch(failurePerformaBuyTransaction(error))
        })
    }
}

//PERFORM SELL TRANSACTION
export const requestPerformSellTransaction = () => {
    return{
        type: REQUEST_SELL_TRANSACTION,
    }
}

export const successPerformSellTransaction = () => {
    return{
        type: SUCCESS_SELL_TRANSACTION,
    }
}

export const failurePerformSellTransaction = () => {
    return{
        type: FAILURE_SELL_TRANSACTION,
    }
}

export const handlePerformSellTransactionAction = (token,data) =>{
    return function(dispatch){
        dispatch(requestPerformSellTransaction())
        Api.PerformSellTransactionApi(token,data).then((result)=>{
            dispatch(successPerformSellTransaction(result))
            dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        }).catch(error=>{
            dispatch(failurePerformSellTransaction(error))
        })
    }
}

//PERFORM DEPOSIT TRANSACTION
export const requestPerformDepositTransaction = () => {
    return{
        type: REQUEST_DEPOSIT_TRANSACTION,
    }
}

export const successPerformDepositTransaction = () => {
    return{
        type: SUCCESS_DEPOSIT_TRANSACTION,
    }
}

export const failurePerformDepositTransaction = () => {
    return{
        type: FAILURE_DEPOSIT_TRANSACTION,
    }
}

export const handlePerformDepositTransactionAction = (token,data) =>{
    return function(dispatch){
        dispatch(requestPerformDepositTransaction())
        Api.PerformDepositTransactionApi(token,data).then((result)=>{
            dispatch(successPerformDepositTransaction(result))
            dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        }).catch(error=>{
            dispatch(failurePerformDepositTransaction(error))
        })
    }
}


//PERFORM WITHDRAW TRANSACTION
export const requestPerformWithdrawTransaction = () => {
    return{
        type: REQUEST_WITHDRAW_TRANSACTION,
    }
}

export const successPerformWithdrawTransaction = () => {
    return{
        type: SUCCESS_WITHDRAW_TRANSACTION,
    }
}

export const failurePerformWithdrawTransaction = () => {
    return{
        type: FAILURE_WITHDRAW_TRANSACTION,
    }
}

export const handlePerformWithdrawTransactionAction = (token,data) =>{
    return function(dispatch){
        dispatch(requestPerformWithdrawTransaction())
        Api.PerformWithdrawTransactionApi(token,data).then((result)=>{
            dispatch(successPerformWithdrawTransaction(result))
            dispatch(handleGetTransactionHistoryAction(data.portfolio_id,token))
        }).catch(error=>{
            dispatch(failurePerformWithdrawTransaction(error))
        })
    }
}


//PERFORM EDIT TRANSACTION
export const requestPerformEditTransaction = () => {
    return{
        type: REQUEST_EDIT_TRANSACTION,
    }
}

export const successPerformEditTransaction = (result) => {
    return{
        type: SUCCESS_EDIT_TRANSACTION,
        editTransactionMessage:result
    }
}

export const failurePerformEditTransaction = (result) => {
    return{
        type: FAILURE_EDIT_TRANSACTION,
        ErrorTransactionMessage:result
    }
}

export const handlePerformNewEditTransactionAction = (token,data,portfolioid,value) =>{
    return function(dispatch){
        dispatch(requestPerformEditTransaction())
        Api.PerformNewEditTransactionApi(token,data,portfolioid,value).then((result)=>{
            dispatch(successPerformEditTransaction(result?.data))
            dispatch(handleGetTransactionHistoryAction(data?.portfolio_id,token,portfolioid))
        }).catch(error=>{
            if(error?.response?.status==500){

                dispatch(failurePerformEditTransaction("Internal Server error"))

                console.log("errorerror1",error.response)

            }else{

                dispatch(failurePerformEditTransaction(error?.response?.data))

            }
             
        })
    }
}

