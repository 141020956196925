import {
    REQUEST_GET_SUMMARY_CARD,
    SUCCESS_GET_SUMMARY_CARD,
    FAILURE_GET_SUMMARY_CARD,
} from "../../Constant/actionTypes";

const initilizeState = {
   
}

export default function summaryCardReducer (state=initilizeState, action){
    switch(action.type){
        case REQUEST_GET_SUMMARY_CARD:
            return{
                ...state,
            }
        case SUCCESS_GET_SUMMARY_CARD:
            return{
                ...state,
                summaryCardData: action.summaryCardData,
            }
        case FAILURE_GET_SUMMARY_CARD:
            return{
                ...state,
            }
        default:
            return state;
    }
}