import React, { Component } from "react";
import "./MaxDrawdown.scss";
import TableHeader from "../tableHeader/tableHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class MaxDrawdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 100
        }
    }

    onGridReady(params) {
        // setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
    }

    componentDidUpdate(preProps) {
        if (preProps.maxDrawdownDetails != this.props.maxDrawdownDetails) {
            if (this.props.maxDrawdownDetails && this.props.maxDrawdownDetails.length < 7) {
                this.setState({
                    height: this.props.maxDrawdownDetails.length * 47 + 100
                })
            }

            else {
                this.setState({
                    height: 400
                })
            }
        }
    }

    render() {
        const { portfoliosName, maxDrawdownDetails } = this.props;
        const hasMaxDropdown = this.props.showTableData?.indexOf('max_drawdown') >-1
        return (
            <div className="maxdrawdown-table-container" id="maxdrawdown">
                <TableHeader title={`Max Drawdown `} />
                {
                    hasMaxDropdown?<div className="maxdrawdown-table">
                    <div className="ag-theme-alpine w-100" style={{ height: this.state.height+5 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={maxDrawdownDetails}
                        // defaultColDef={defaultColDef}
                        >
                            <AgGridColumn headerName='Stock' field="" minWidth={200}>
                                <AgGridColumn headerName='' field="security" minWidth={200}></AgGridColumn>
                            </AgGridColumn>
                            <AgGridColumn headerName='Drawdown' field="" minWidth={990}>
                                <AgGridColumn headerName='1D' field="max_drawdown.1d" minWidth={198} cellStyle=
                                    {
                                        (i) => (parseInt(i.data.max_drawdown['1d']) ? { color: '#FF4C4D' } : { color: '#0DFD32' })
                                    }
                                ></AgGridColumn>
                                <AgGridColumn headerName='1W' field="max_drawdown.1w" minWidth={198} cellStyle=
                                    {
                                        (i) => (parseInt(i.data.max_drawdown['1w']) ? { color: '#FF4C4D' } : { color: '#0DFD32' })
                                    }
                                ></AgGridColumn>
                                <AgGridColumn headerName='1M' field="max_drawdown.1m" minWidth={198} cellStyle=
                                    {
                                        (i) => (parseInt(i.data.max_drawdown['1m']) ? { color: '#FF4C4D' } : { color: '#0DFD32' })
                                    }
                                ></AgGridColumn>
                                <AgGridColumn headerName='3M' field="max_drawdown.3m" minWidth={198} cellStyle=
                                    {
                                        (i) => (parseInt(i.data.max_drawdown['1m']) ? { color: '#FF4C4D' } : { color: '#0DFD32' })
                                    }
                                ></AgGridColumn>
                                <AgGridColumn headerName='6M' field="max_drawdown.6m" minWidth={198} cellStyle=
                                    {
                                        (i) => (parseInt(i.data.max_drawdown['1m']) ? { color: '#FF4C4D' } : { color: '#0DFD32' })
                                    }
                                ></AgGridColumn>
                            </AgGridColumn>
                        </AgGridReact>
                    </div>
                    {/* <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={maxdrawdownData}
                            // setGridApi={this.setGridApi}
                            // setGridColumnApi={this.setGridColumnApi}
                        >
                            <AgGridColumn headerName='Stock' field="">
                                <AgGridColumn headerName='' field="Stock"></AgGridColumn>
                            </AgGridColumn>
                            <AgGridColumn headerName='Drawdown' field="">
                                <AgGridColumn headerName='1D'  field="1D"></AgGridColumn>
                                <AgGridColumn headerName='1W'  field="1W"></AgGridColumn>
                                <AgGridColumn headerName='1M'  field="1M"></AgGridColumn>
                                <AgGridColumn headerName='3M'  field="3M"></AgGridColumn>
                                <AgGridColumn headerName='6M'  field="6M"></AgGridColumn>
                                <AgGridColumn headerName='12M' field="12M"></AgGridColumn>
                                <AgGridColumn headerName='2Y'  field="2Y"></AgGridColumn>
                                <AgGridColumn headerName='3Y'  field="3Y"></AgGridColumn>
                            </AgGridColumn>
                        </AgGridReact> */}
                    {/* </div> */}
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }               
            </div>
        )
    }
}

export default MaxDrawdown;