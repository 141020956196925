import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'
import "./Attribution.scss";

class AttributionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 100
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }

    componentDidUpdate(preProps) {
        if (preProps.attributionsData != this.props.attributionsData) {
            if (this.props.attributionsData && this.props.attributionsData.length < 7) {
                this.setState({
                    height: this.props.attributionsData.length * 47 + 100
                })
            }

            else {
                this.setState({
                    height: 390
                })
            }
        }
    }
    
    render() {
        const { attributionsData } = this.props;
        const defaultColDef = {
            cellStyle: { textAlign: 'center', background: '#0D1013' }
        };
        const hasAttributions = this.props.showTableData?.indexOf("attributions") >-1
        return (
            <div className="attributionData-table-container" id="attribution">
                <TableHeader title='Attributions' />
                {
                    hasAttributions?<div className="attributionData-table">
                    <div className="ag-theme-alpine w-100" style={{ height: this.state.height }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={attributionsData}
                            defaultColDef={defaultColDef}
                        >
                            <AgGridColumn headerName='Absolute Returns By Asset Class' field="" >
                                <AgGridColumn headerName='Security' minWidth={230} field="security_name" cellStyle={() => ({ color: '#94B3FC', background: '#051C55' })}></AgGridColumn>
                                <AgGridColumn headerName='1D' field="1d" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='YTD' field="YTD" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='1W' field="1w" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='1M' field="1M" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })} ></AgGridColumn>
                                <AgGridColumn headerName='3M' field="3M" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='1Y' field="1Y" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='2Y' field="2Y" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='3Y' field="3Y" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                                <AgGridColumn headerName='5Y' field="5Y" minWidth={107} cellStyle={(i) => (parseFloat(i.value) >= 0 ? { color: '#0DFD32' } : { color: '#FF4C4D' })}></AgGridColumn>
                            </AgGridColumn>
                        </AgGridReact>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }
            </div>
        )
    }
}

export default AttributionTable;
// 0D1013