import React, { Component } from "react";
import "../paymentCard/paymentCard.scss";
import { connect } from "react-redux";
import { handleGetPaymentPlanAction } from "../../Redux/actions/paymentPlanAction";
import check from "../../Assets/Images/Checkmark.svg";
import vector from "../../Assets/Images/Vector.svg"

class PaymentCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            duration: 'monthly',
            durationColor: "#27EE80",
            // modalComponent:'',
        }
    }

    componentDidMount() {
        this.props.handleGetPaymentPlan(localStorage.getItem('token'));
    }

    flip = (duration) => {
        this.setState({
            duration: duration
        }, () => { })
    }

    render() {
        const { duration } = this.state;
        const { paymentPlan, updateText, initiatePlanData, button, userData } = this.props;
        
        return (
          
            <div className="paymentCard-container">
                <form className="yc-form">
                    <input type="radio" id="r-monthly" name="yc-form-switch" value="monthly" onClick={() => this.flip('monthly')} />
                    {
                        duration === 'monthly' ? <label style={{ backgroundColor: '#DB55EB', color: 'white', fontWeight: 'bold' }} for="r-monthly">Monthly</label> : <label style={{ backgroundColor: '#051C55', color: 'white', fontWeight: 'bold' }} for="r-monthly">Monthly</label>
                    }
                    <input type="radio" id="r-yearly" name="yc-form-switch" value="yearly" onClick={() => this.flip('yearly')} />
                    {
                        duration === 'yearly' ? <label style={{ backgroundColor: '#DB55EB', color: 'white', fontWeight: 'bold' }} for="r-yearly">yearly</label> : <label style={{ backgroundColor: '#051C55', color: 'white', fontWeight: 'bold' }} for="r-yearly">Yearly</label>
                    }
                </form>

                <div className="table wh-color" style={updateText == 'Update' ? { border: 'none' } : { border: '1px solid silver' }}>
                    <table width='100%' cellSpacing='0' cellPadding='0'>
                        <thead className={updateText != 'Update' ? 'blue' : 'light-blue'}>
                            <tr>
                                <th></th>
                                <th>
                                    <div className={updateText != 'Update' ? 'column-1 column' : ' column column-1 blue'}>
                                        {
                                            duration === 'monthly' ?
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#CD7F32' }}>Bronze</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Bronze.monthly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>month</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Bronze', paymentPlan && paymentPlan.Bronze.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Bronze', paymentPlan && paymentPlan.Bronze.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* {this.props.loginUserDetails.userObj.stripe_details && this.props.loginUserDetails.userObj.stripe_details.status === 'trial' ? 
                                                    <button className="subscribe"
                                                        onClick={() =>this.props.handleSubscribe('monthly', 'Bronze', paymentPlan && paymentPlan.Bronze.monthly_charges,updateText === 'Update'? 'Update': null)}
                                         className={updateText!='Update'?'teText = column':' column column-1 blue'}= 'Update' ? 'Update' : 'Subscribe'}</button>
                                                    : null} */}
                                                </div> :
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#CD7F32' }}>Bronze</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Bronze.yearly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>year</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Bronze', paymentPlan && paymentPlan.Bronze.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Bronze', paymentPlan && paymentPlan.Bronze.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* <button className="subscribe"
                                                    onClick={()=>this.props.handleSubscribe('yearly','Bronze',paymentPlan && paymentPlan.Bronze.yearly_charges,updateText === 'Update'? 'Update': null)}
                                                    >{ updateText === 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                </div>}
                                    </div>
                                </th>
                                <th>
                                    <div className={updateText != 'Update' ? 'column-1 column' : ' column column-1 blue'}>
                                        {
                                            duration === 'monthly' ?
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#C0C0C0' }}>Silver</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Silver.monthly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>month</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Silver', paymentPlan && paymentPlan.Silver.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Silver', paymentPlan && paymentPlan.Silver.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/*                                                     
                                                    <button className="subscribe"
                                         className={updateText!='Update'?'lick={() column':' column column-1 blue'}=>this.props.handleSubscribe('monthly', 'Silver', paymentPlan && paymentPlan.Silver.monthly_charges,updateText === 'Update'? 'Update': null)}
                                                    >{ updateText === 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                </div> :
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#C0C0C0' }}>Silver</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Silver.yearly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>year</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Silver', paymentPlan && paymentPlan.Silver.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Silver', paymentPlan && paymentPlan.Silver.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* <button className="subscribe"
                                                        onClick={() =>this.props.handleSubscribe('yearly', 'Silver', paymentPlan && paymentPlan.Silver.yearly_charges,updateText === 'Update'? 'Update': null)}
                                                    >{ updateText === 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                </div>}
                                    </div>
                                </th>
                                <th>
                                    <div className={updateText != 'Update' ? 'column-1 column' : ' column column-1 blue'}>
                                        {
                                            duration === 'monthly' ?
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#FFD700' }}>Gold</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Gold.monthly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>month</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Gold', paymentPlan && paymentPlan.Gold.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Gold', paymentPlan && paymentPlan.Gold.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* <button className="subscribe"
                                                        onClick={() =>this.props.handleSubscribe('monthly', 'Gold', paymentPlan && paymentPlan.Gold.monthly_charges,updateText === 'Update'? 'Update': null)}
                                         className={updateText!='Update'?'teText = column':' column column-1 blue'}= 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                </div>
                                                :
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#FFD700' }}>Gold</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Gold.yearly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>year</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Gold', paymentPlan && paymentPlan.Gold.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Gold', paymentPlan && paymentPlan.Gold.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* <button className="subscribe"
                                                        onClick={() =>this.props.handleSubscribe('yearly', 'Gold', paymentPlan && paymentPlan.Gold.yearly_charges,updateText === 'Update'? 'Update': null)}
                                                    >{ updateText === 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                </div>
                                        }
                                    </div>
                                </th>
                                {/* <th>
                                    <div className={updateText != 'Update' ? 'column-1 column' : ' column column-1 blue'}>
                                        {
                                            duration === 'monthly' ?
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#E5E4E2' }}>Platinum</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Platinum.monthly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>month</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Platinum', paymentPlan && paymentPlan.Platinum.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('monthly', 'Platinum', paymentPlan && paymentPlan.Platinum.monthly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                </div>
                                                :
                                                <div className={updateText != 'Update' ? 'column-front' : 'column-front blue'} >
                                                    <h2 style={{ color: '#E5E4E2' }}>Platinum</h2>
                                                    <p><span style={{ color: '#ffffff' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Platinum.yearly_charges}</span>
                                                        <div>
                                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                                            <span className='mo'>year</span>
                                                        </div>
                                                    </p>
                                                    {
                                                        userData?.stripe_details?.status == 'cancelled' || userData?.stripe_details?.status == 'trial' ?
                                                            <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Platinum', paymentPlan && paymentPlan.Platinum.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText === 'Update' ? 'Update' : 'Initiate'}</button>
                                                            :
                                                            updateText === 'Update' && <button className="subscribe"
                                                                onClick={() => this.props.handleSubscribe('yearly', 'Platinum', paymentPlan && paymentPlan.Platinum.yearly_charges, updateText === 'Update' ? 'Update' : null)}
                                                            >{updateText}</button>
                                                    }
                                                    {/* <button className="subscribe"
                                                        onClick={() =>this.props.handleSubscribe('yearly', 'Platinum', paymentPlan && paymentPlan.Platinum.yearly_charges,updateText === 'Update'? 'Update': null)}
                                                    >{ updateText === 'Update' ? 'Update' : 'Subscribe'}</button> */}
                                                {/* </div>}</div>
                                </th> */} 
                            </tr>
                        </thead>
                        {
                            updateText != 'Update' && <tbody>
                                <tr style={{ background: '#708DD2', color: '#051C55' }}>
                                    <td style={{ fontWeight: 'bold' }}><b>Features</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    {/* <td></td> */}
                                </tr>
                                <tr>
                                    <td style={{ fontWeight: 'bold' }}>Summary</td>
                                    <td>{duration === 'monthly' ? <img alt='' src={check} className='check-img' /> : <img alt='' src={check} className='check-img' />}</td>
                                    <td>{duration === 'monthly' ? <img alt='' src={check} className='check-img' /> : <img alt='' src={check} className='check-img' />}</td>
                                    <td>{duration === 'monthly' ? <img alt='' src={check} className='check-img' /> : <img alt='' src={check} className='check-img' />}</td>
                                    {/* <td>{duration === 'monthly' ? <img alt='' src={check} className='check-img' /> : <img alt='' src={check} className='check-img' />}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Performance</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Intermediate' : 'Intermediate'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Composition</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Intermediate' : 'Intermediate'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Price Action</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Risk</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Intermediate' : 'Intermediate'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Attribution</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Intermediate' : 'Intermediate'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Decision Stats</td>
                                    <td>{duration === 'monthly' ? 'Basic' : 'Basic'}</td>
                                    <td>{duration === 'monthly' ? 'Intermediate' : 'Intermediate'}</td>
                                    <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td>
                                    {/* <td>{duration === 'monthly' ? 'Advanced' : 'Advanced'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Portfolios</td>
                                    <td>{duration === 'monthly' ? '1' : '1'}</td>
                                    <td>{duration === 'monthly' ? '2' : '2'}</td>
                                    <td>{duration === 'monthly' ? '5' : '5'}</td>
                                    {/* <td>{duration === 'monthly' ? '10' : '10'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Watchlist</td>
                                    <td>{duration === 'monthly' ? '2' : '2'}</td>
                                    <td>{duration === 'monthly' ? '5' : '5'}</td>
                                    <td>{duration === 'monthly' ? '10' : '10'}</td>
                                    {/* <td>{duration === 'monthly' ? '20' : '20'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Total Stocks in Watchlist</td>
                                    <td>{duration === 'monthly' ? '25' : '25'}</td>
                                    <td>{duration === 'monthly' ? '60' : '60'}</td>
                                    <td>{duration === 'monthly' ? '100' : '100'}</td>
                                    {/* <td>{duration === 'monthly' ? '600' : '600'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Total Stocks in Portfolio</td>
                                    <td>{duration === 'monthly' ? '25' : '25'}</td>
                                    <td>{duration === 'monthly' ? '60' : '60'}</td>
                                    <td>{duration === 'monthly' ? '100' : '100'}</td>
                                    {/* <td>{duration === 'monthly' ? '500' : '500'}</td> */}
                                </tr> <tr>
                                    <td style={{ fontWeight: 'bold' }}>Free Trial Period</td>
                                    <td>{duration === 'monthly' ? '30 days' : '30 days'}</td>
                                    <td>{duration === 'monthly' ? '30 days' : '30 days'}</td>
                                    <td>{duration === 'monthly' ? '30 days' : '30 days'}</td>
                                    {/* <td>{duration === 'monthly' ? '30 days' : '30 days'}</td> */}
                                </tr> <tr>
                                </tr>
                            </tbody>
                        }</table>

                    {/* <div className="column column-1">
                        {
                            duration === 'monthly' ?
                                <div className="column-front">
                                    <h2 style={{ color: '#CD7F32' }}>Bronze</h2>
                                    <p><span style={{ color: '#708DD2' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Bronze.monthly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>month</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('monthly', 'Bronze', paymentPlan && paymentPlan.Bronze.monthly_charges)}
                                    >Subscribe</button>@
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="column-front">
                                    <h2 style={{ color: '#CD7F32' }}>Bronze</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Bronze.yearly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>year</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                    // onClick={()=>this.props.handleSubscribe('yearly','Bronze',paymentPlan && paymentPlan.Bronze.yearly_charges)}
                                    >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                        }
                    </div> */}

                    {/* <div className="column column-1">
                        {
                            duration === 'monthly' ?
                                <div className="column-front">
                                    <h2 style={{ color: '#C0C0C0' }}>Silver</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Silver.monthly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>month</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('monthly', 'Silver', paymentPlan && paymentPlan.Silver.monthly_charges)}
                                    >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="column-front">
                                    <h2 style={{ color: '#C0C0C0' }}>Silver</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Silver.yearly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>year</span>
                                        </div>
                                    </p>initiatePlanMessage
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('yearly', 'Silver', paymentPlan && paymentPlan.Silver.yearly_charges)}
                                        >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                        }
                    </div>
                    
                    <div className="column column-1">
                        {
                            duration === 'monthly' ?
                            <div className="column-front">
                                    <h2 style={{ color: '#FFD700' }}>Gold</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Gold.monthly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>month</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('monthly', 'Gold', paymentPlan && paymentPlan.Gold.monthly_charges)}
                                        >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="column-front">
                                    <h2 style={{ color: '#FFD700' }}>Gold</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Gold.yearly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>year</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('yearly', 'Gold', paymentPlan && paymentPlan.Gold.yearly_charges)}
                                        >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                        }
                    </div>

                    <div className="column column-1">
                        {
                            duration === 'monthly' ?
                            <div className="column-front">
                                    <h2 style={{ color: '#E5E4E2' }}>Platinum</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Platinum.monthly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>month</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('monthly', 'Platinum', paymentPlan && paymentPlan.Platinum.monthly_charges)}
                                    >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className="column-front">
                                    <h2 style={{ color: '#E5E4E2' }}>Platinum</h2>
                                    <p><span style={{ color: 'chartreuse' }}>$</span><span style={{ fontSize: '2.67vw' }}>{paymentPlan && paymentPlan.Platinum.yearly_charges}</span>
                                        <div>
                                            <span className='mo' style={{ marginRight: '5px' }}>per</span>
                                            <span className='mo'>year</span>
                                        </div>
                                    </p>
                                    <button className="subscribe"
                                        onClick={() =>this.props.handleSubscribe('yearly', 'Platinum', paymentPlan && paymentPlan.Platinum.yearly_charges)}
                                    >Subscribe</button>
                                    {
                                        updateText != 'Update' &&
                                        <div className="plan-property" style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>
                                            <span><img alt='' src={check} />Summary:Yes</span>
                                            <span><img alt='' src={check} />Performance:Basic</span>
                                            <span><img alt='' src={check} />Composition:Basic</span>
                                            <span><img alt='' src={check} />Price Action: Basic</span>
                                            <span><img alt='' src={check} />Risk: No</span>
                                            <span><img alt='' src={check} />Attribution: No</span>
                                            <span><img alt='' src={check} />Decision Stats: No</span>
                                            <span><img alt='' src={check} />Porfolios:1</span>
                                            <span><img alt='' src={check} />Watchlist:2</span>
                                            <span><img alt='' src={check} />Total Stocks in Watchlist:25</span>
                                            <span><img alt='' src={check} />Total Stocks in Portfolio:25</span>
                                            <span><img alt='' src={check} />Free Trial Period:30 days</span>
                                        </div>
                                    }
                                </div>
                        } 
                    </div> */}

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        paymentPlan: state.paymentPlanReducer.paymentPlan,
        initiatePlanData: state.initiatePlanReducer.initiatePlanData,
        button: state.updatePlanReducer.updateData,
        userData: state.getUserDetailsReducer.userData,
        // openCloseModal: state.modalReducer.openCloseModal,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        handleGetPaymentPlan: (token) => dispatch(handleGetPaymentPlanAction(token)),
        // handleOpenModal: () => dispatch(handleOpenModalAction()),
        // handleCloseModal: () => dispatch(handleCloseModalAction()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentCard);
{/* <p>${paymentPlan && paymentPlan.Silver.yearly_charges}per<span className='year'>year</span></p> */ }