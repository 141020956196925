import React, { Component } from "react";
import TableHeader from "../tableHeader/tableHeader";
import "./Fundamentals.scss";
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LockScreen from '../../Assets/Images/lockScreen.jpg'

class FundamentalsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 50
        }
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
    }


    componentDidUpdate(preProps) {
        if (preProps.fundamentalsData != this.props.fundamentalsData) {
            if (this.props.fundamentalsData && this.props.fundamentalsData.length < 7) {
                this.setState({
                    height: this.props.fundamentalsData.length * 47 + 100
                })
            }

            else {
                this.setState({
                    height: 390
                })
            }
        }
    }

    render() {
        const { fundamentalsData } = this.props;
        const hasFundamentals = this.props.showTableData?.indexOf("fundamentals") >-1
        return (
            <div className="fundamentables-table-container" id="fundamentables">
                <TableHeader title='Fundamentals' />
                {
                    hasFundamentals?<div className="fundamentables-table">
                    <div className="ag-theme-alpine w-100" style={{ height: this.state.height+5 }}>
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            rowData={fundamentalsData}
                        >
                            <AgGridColumn headerName='Stock' field="eod_ticker" minWidth={146} cellStyle={(i) => ({ textAlign: 'left' })}></AgGridColumn>
                            <AgGridColumn headerName='Market Cap(USD)' field="market_cap_usd" minWidth={170} cellStyle={() => ({ textAlign: 'left' })}></AgGridColumn>
                            <AgGridColumn headerName='EV/Revenue' field="EVRevenue" minWidth={146.5} cellStyle={(i) => ({ textAlign: 'left' })}></AgGridColumn>
                            <AgGridColumn headerName='EV/EBITDA' field="EVEbidta" minWidth={146.5} cellStyle={() => ({ textAlign: 'left' })}></AgGridColumn>
                            <AgGridColumn headerName='P/B' field="PB" minWidth={146.5} cellStyle={(i) => ({ textAlign: 'left' })}></AgGridColumn>
                            <AgGridColumn headerName='P/S' field="P/S" minWidth={146.5} cellStyle={() => ({ textAlign: '' })}></AgGridColumn>
                            <AgGridColumn headerName='P/E' field="PE" minWidth={146.5} cellStyle={() => ({ textAlign: ' ' })}></AgGridColumn>
                            <AgGridColumn headerName='P/E1' field="PE1" minWidth={146.5} cellStyle={(i) => ({ textAlign: ' ' })}></AgGridColumn>
                            {/* <AgGridColumn headerName='Percent' field="percent" cellStyle={(i)=>({textAlign:'center' })}></AgGridColumn> */}
                        </AgGridReact>
                    </div>
                </div>:<div className="lockScreen">
                    <img alt='' src={LockScreen} width= '1200' height= '500' />
                </div>
                }               
            </div>
        )
    }
}

export default FundamentalsTable;