import React, { Component } from 'react';
import WatchList from "../Page/watchlist";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { handleContainerValueAction } from "../Redux/actions/menuScrollAction";
import { handleOpenModalAction, handleCloseModalAction } from "../Redux/actions/modalAction";
import { handleCreateWatchListAction, handleViewWatchListAction, handleDeleteSecuritiesAction, handleAddSecuritiesAction, handleRemoveWatchlistAction } from "../Redux/actions/watchListAction";
import CreateNewWatchlist from "../Components/CreateNewWatchlist/CreateNewWatchlist";
import RemoveWatchlistModal from '../Components/RemoveWatchlist/RemoveWatchlistModal';
import { handleGetUserDetailsAction } from "../Redux/actions/getUserDetailsAction";

class WatchListContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalComponent: '',
            ticker: '',
            watchListName: '',
            dropDownWatchlist: ''
            //  props.userData.watchlists.length!==0  ? props.userData.watchlists[0].slice(props.userData.watchlists[0].indexOf('_')+1,props.userData.watchlists[0].length) : '',
        }
        this.onCellValueChanged = this.onCellValueChanged.bind(this);
        this.handleRemoveWatchlistData = this.handleRemoveWatchlistData.bind(this);
    }

    handleChangeCreateWatchList = (event) => {
        this.setState({
            watchListName: event.target.value,
        }, () => { });
    }

    handleRemoveWatchlistData = () => {
        this.props.handleRemoveWatchlist(this.props.dropDownWatchlist, localStorage.getItem('token'), this.props.userData);
        this.props.handleOpenModal();
        this.props.handleViewWatchList(this.props.userData.watchlists[0].slice(this.props.userData.watchlists[0].indexOf('_') + 1, this.props.userData.watchlists[0].length), localStorage.getItem('token'));
    }
    addSecurities = (watchListName, security, token) => {
        this.props.handleAddSecurities(watchListName, security, token)
    }

    componentDidMount() {
        // this.props.handleViewWatchList(this.props.userData.watchlists.length!==0 ? (this.props.userData.watchlists && this.props.userData.watchlists[0].slice(this.props.userData.watchlists[0].indexOf('_')+1,this.props.userData.watchlists[0].length),localStorage.getItem('token')): [{}]);
        if (this.props.userData.watchlists) {
            this.setState({
                // dropDownWatchlist: this.props.dropDownWatchlist
            }, () => { this.props.handleViewWatchList(this.state.dropDownWatchlist, localStorage.getItem('token')); })

        }
    }

    deleteSecurities = (i) => {
        this.props.handleDeleteSecurities(this.props.dropDownWatchlist, i.data.eod_ticker, localStorage.getItem('token'));
        this.props.deleteMessage.length && this.props.handleViewWatchList(this.props.dropDownWatchlist, localStorage.getItem('token'));
    }

    onCellValueChanged(event) {
        this.setState({
            ticker: event.data.eod_ticker
        }, () => { this.addSecurities(this.props.dropDownWatchlist, event.data.eod_ticker, localStorage.getItem('token')) })
    }

    componentDidUpdate(preProps, prevState) {
        // console.log(this.props.createWatchlistFlag,this.props.dropDownWatchlist,this.state.watchListName,this.props.userData.watchlists[0].split("_")[1])
        // console.log(this.props.userData.watchlists[this.props.userData.watchlists.length-1].split("_")[1])
        if (preProps.userData.watchlists != this.props.userData.watchlists) {
            this.setState({
                dropDownWatchlist: this.props.userData.watchlists.length ? this.props.userData.watchlists[this.props.userData.watchlists.length - 1].split("_")[1] : ''
            }, () => { this.props.handleViewWatchList(this.props.dropDownWatchlist, localStorage.getItem('token')); })
        }
    }

    render() {
        const { modalComponent, watchListName } = this.state;
        const { openCloseModal, userData, handleCloseModal, handleCreateWatchList, viewWatchListData, createWatchlistFlag, removeWatchlistFlag, deleteWatchlistflag } = this.props;

        return (
            <div className="inner-part">
                <WatchList
                    component={
                        this.props.modalComponent === 'Create watchlist' ?
                            <CreateNewWatchlist
                                handleCreateWatchList={handleCreateWatchList}
                                handleChangeCreateWatchList={this.handleChangeCreateWatchList}
                                watchListName={watchListName}
                                handleCloseModal={handleCloseModal}
                                openCloseModal={openCloseModal}
                            />
                            :
                            this.props.modalComponent === 'Remove Watch List' ?
                                <RemoveWatchlistModal
                                    handleRemoveWatchlistData={this.handleRemoveWatchlistData}
                                    handleCloseModal={handleCloseModal}
                                    dropDownWatchlist={this.props.dropDownWatchlist}
                                />
                                :
                                null
                    }
                    createWatchlistFlag={createWatchlistFlag}
                    removeWatchlistFlag={removeWatchlistFlag}
                    deleteWatchlistflag={deleteWatchlistflag}
                    modalComponent={this.props.modalComponent}
                    openCloseModal={openCloseModal}
                    handleCloseModal={handleCloseModal}
                    handleCreateWatchlistPortfolio={this.handleCreateWatchlistPortfolio}
                    handleEdit={this.handleEdit}
                    handleDeleteWatchListModal={this.props.handleDeleteWatchListModal}
                    name='WatchList'
                    btnName='Create New'
                    viewWatchListData={this.props.userData.watchlists && this.props.userData.watchlists.length !== 0 ? viewWatchListData : []}
                    userData={userData}
                    dropDownWatchlist={this.props.dropDownWatchlist}
                    handleWatchlistDropDownChange={this.props.handleWatchlistDropDownChange}
                    deleteSecurities={this.deleteSecurities}
                    handleViewWatchList={this.props.handleViewWatchList}
                    onCellValueChanged={this.onCellValueChanged}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    let temp = [];
    if (state.viewWatchListReducer.viewWatchListData.data) {
        state.viewWatchListReducer.viewWatchListData.data.map((item, i) => {
            temp.push({
                ...item,
                "price": !item["price"] ? '-' : (item["price"] * 100) && (item["price"]).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "1M": !item["1M"] ? '-' : (item["1M"] * 100) && (item["1M"] * 100).toFixed(1) + '%',
                "1d": !item["1d"] ? '-' : (item["1d"] * 100) && (item["1d"] * 100).toFixed(1) + '%',
                "1Y": !item["1Y"] ? '-' : (item["1Y"] * 100) && (item["1Y"] * 100).toFixed(1) + '%',
                "1w": !item["1w"] ? '-' : (item["1w"] * 100) && (item["1w"] * 100).toFixed(1) + '%',
                "3M": !item["3M"] ? '-' : (item["1M"] * 100) && (item["3M"] * 100).toFixed(1) + '%',
                "2Y": !item["2Y"] ? '-' : (item["2Y"] * 100) && (item["2Y"] * 100).toFixed(1) + '%',
                "5Y": !item["5Y"] ? '-' : (item["5Y"] * 100) && (item["5Y"] * 100).toFixed(1) + '%',
                "3Y": !item["3Y"] ? '-' : (item["3Y"] * 100) && (item["3Y"] * 100).toFixed(1) + '%',
                "YTD": !item["YTD"] ? '-' : (item["YTD"] * 100) && (item["YTD"] * 100).toFixed(1) + '%',

                "adjusted_close_ema_10": !item["adjusted_close_ema_10"] ? '-' : item["adjusted_close_ema_10"] && item["adjusted_close_ema_10"].toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "adjusted_close_ema_12": !item["adjusted_close_ema_12"] ? '-' : item["adjusted_close_ema_12"] && item["adjusted_close_ema_12"].toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "adjusted_close_ema_50": !item["adjusted_close_ema_50"] ? '-' : item["adjusted_close_ema_50"] && item["adjusted_close_ema_50"].toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "adjusted_close_ema_200": !item["adjusted_close_ema_200"] ? '-' : item["adjusted_close_ema_200"] && item["adjusted_close_ema_200"].toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),

                "52_week_high": !item["52_week_high"] ? '-' : (item["52_week_high"] * 100) && (item["52_week_high"]).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                "52_week_low": !item["52_week_low"] ? '-' : (item["52_week_low"] * 100) && (item["52_week_low"]).toFixed(1).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            })
        })
        temp.push({});
    }
    return {
        openCloseModal: state.modalReducer.openCloseModal,
        userData: state.getUserDetailsReducer.userData,
        viewWatchListData: temp,
        deleteMessage: state.deleteSecuritiesWatchlistReducer.deleteMessage,
        createWatchlistFlag: state.createNewWatchlistReducer.createWatchlistFlag,
        removeWatchlistFlag: state.removeWatchlistReducer.removeWatchlistFlag,
        createWatchlistData: state.createNewWatchlistReducer.createWatchlistData,
        deleteWatchlistflag: state.deleteSecuritiesWatchlistReducer.deleteWatchlistflag,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleOpenModal: () => dispatch(handleOpenModalAction()),
        handleCloseModal: () => dispatch(handleCloseModalAction()),
        handleCreateWatchList: (watchListName) => dispatch(handleCreateWatchListAction(watchListName)),
        handleViewWatchList: (watchListName, token) => dispatch(handleViewWatchListAction(watchListName, token)),
        handleDeleteSecurities: (watchListName, security, token) => dispatch(handleDeleteSecuritiesAction(watchListName, security, token)),
        handleAddSecurities: (watchListName, security, token) => dispatch(handleAddSecuritiesAction(watchListName, security, token)),
        handleRemoveWatchlist: (watchListName, token) => dispatch(handleRemoveWatchlistAction(watchListName, token)),
        handleGetUserDetails: (token) => dispatch(handleGetUserDetailsAction(token)),
        handleContainerValue: (id, component) => dispatch(handleContainerValueAction(id, component))
    }
};


WatchListContainer.propTypes = {
    openCloseModal: PropTypes.bool,
    handleCloseModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchListContainer);