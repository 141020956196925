import {
    REQUEST_PORTFOLIO_ANALYTICS_CORRELATION,
    SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION,
    FAILURE_PORTFOLIO_ANALYTICS_CORRELATION,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestCorrelation = () => {
    return {
        type: REQUEST_PORTFOLIO_ANALYTICS_CORRELATION,
    }
}

export const successCorrelation = (result) => {
    return{
        type: SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION,
        correlationData: result,
    }
}

export const failureCorrelation = (error) => {
    return{
        type: FAILURE_PORTFOLIO_ANALYTICS_CORRELATION,
    }
}

export const handleCorrelationAction = (dropDownPortFolio,accessToken) => {
    return function(dispatch){
        dispatch(requestCorrelation())
        Api.CorrelationApi(dropDownPortFolio,accessToken).then(result=>{
            dispatch(successCorrelation(result.data))
        }).catch(error=>{
            dispatch(failureCorrelation(error))
        })
    }
}