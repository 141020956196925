import {
    REQUEST_PORTFOLIO_ANALYTICS_CORRELATION,
    SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION,
    FAILURE_PORTFOLIO_ANALYTICS_CORRELATION,
} from "../../Constant/actionTypes";

const initilizeState = {
    correlationFlag: false
}

export default function correlationPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case REQUEST_PORTFOLIO_ANALYTICS_CORRELATION:
            return{
                ...state,
                correlationFlag: false 
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION:
            return{
                ...state,
                correlationData:action.correlationData,
                correlationFlag: true,
            }
        case FAILURE_PORTFOLIO_ANALYTICS_CORRELATION:
            return{
                ...state,
                correlationFlag: false
            }
        default:
            return state
    }
}
