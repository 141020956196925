import axios from 'axios';
import { API_BASE_URL } from "../../Constant/apiConstant";

const Api = {}

Api.CreateWatchlist = (watchlist_name, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/watchlist/create`,
        data: {
            watchlist_name: watchlist_name,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.ViewWatchListApi = (watchlist_name, token) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/watchlist/view/detail`,
        data: {
            watchlist_name: watchlist_name
        },
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })
}

Api.DeleteSecuritiesApi = (watchListName, security, token) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/watchlist/remove`,
        data: {
            watchlist_name: watchListName,
            securities: security,
        },
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })
}

Api.AddSecuritiesApi = (watchListName, security, token) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/watchlist/add`,
        data: {
            watchlist_name: watchListName,
            securities: [security.label],
        },
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })
}

Api.RemoveWatchList = (watchListName, token) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/watchlist/delete`,
        data: {
            watchlist_name: watchListName,
        },
        headers: {
            "Authorization": `Bearer ${token}`,
        }
    })
}

Api.CreatePortfolo = (portfoliolist, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/create`,
        data: portfoliolist,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.RemoveEntirePortfolo = (portfolioId, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/delete`,
        data: {
            portfolio_id: portfolioId,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.Showtable = (showTable,accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/tables/show`,
        data: {
            showTable_name: showTable,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.LoginApi = (loginUser, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/login`,
        data: loginUser,
        // headers: {
        //     "Authorization": `Bearer ${accessToken}`,
        // }
    })
}

Api.SignUpApi = (registerUser) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/register`,
        data: registerUser,
    })
}

Api.ForgetPasswordApi = (loginUser) => {
    let email = loginUser.email;
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/forgot/password`,
        data: { email },
    })
}

Api.UpdatePasswordApi = (email, password, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/forgot/change/password`,
        data: {
            email: email,
            password: password
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.GetUserDetailsApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/details`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.GetCurrencyListApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/basic_info/currencies`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.GetBenchMarkListApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/basic_info/benchmarks`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.GetTransactionHistoryApi = (dropDownPortFolio, accessToken, portfolioid) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/transact/history`,
        data: {
            portfolio_id: dropDownPortFolio ? dropDownPortFolio : portfolioid
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.DeleteSecuritiesTransactionApi = (accessToken, data) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/transact/delete`,
        data: {
            portfolio_id: data.portfolio_id,
            transaction_id: data.transaction_id,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformBuyTransactionApi = (accessToken, data) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/transact/new`,
        data: {
            portfolio_id: data.portfolio_id,
            transaction_type: data.transaction_type.toLowerCase(),
            security: data.security,
            num_shares: parseInt(data.num_shares),
            security_price: parseFloat(data.security_price),
            currency: data.currency,
            order_date: (data.order_date).split('/').reverse().join('-'),
            margin_percentage: parseInt(data.margin_percentage),
            exchange_fees: parseInt(data.exchange_fees),
            commission: parseInt(data.commission),
            tax: parseInt(data.tax),
            tax_basis_election: 0,
            tag1: data.tag1
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformSellTransactionApi = (accessToken, data) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/transact/new`,
        data: {
            portfolio_id: data.portfolio_id,
            transaction_type: data.transaction_type.toLowerCase(),
            security: data.security,
            currency: data.currency,
            exchange_fees: parseInt(data.exchange_fees),
            margin_percentage: parseInt(data.margin_percentage),
            num_shares: parseInt(data.num_shares),
            order_date: (data.order_date).split('/').reverse().join('-'),
            security_price: parseFloat(data.security_price),
            commission: parseInt(data.commission),
            tax: parseInt(data.tax),
            tax_basis_election: 0,
            tag1: data.tag1
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformDepositTransactionApi = (accessToken, data) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/cash/add`,
        data: {
            portfolio_id: data.portfolio_id,
            cash: parseInt(data.security_price),
            order_date: (data.order_date).split('/').reverse().join('-'),
            commission: parseInt(data.commission),
            exchange_fees: parseInt(data.exchange_fees),
            tax_basis_election: 0,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformWithdrawTransactionApi = (accessToken, data) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/cash/withdraw`,
        data: {
            portfolio_id: data.portfolio_id,
            cash: parseInt(data.security_price),
            order_date: (data.order_date).split('/').reverse().join('-'),
            commission: parseInt(data.commission),
            exchange_fees: parseInt(data.exchange_fees),
            tax_basis_election: 0,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformNewEditTransactionApi = (accessToken, data, portfolioid, value) => {
    return axios({
        method: 'POST',
        url: value && value.object_id && value.object_id.length ? `${API_BASE_URL}/portfolio/transact/edit` : `${API_BASE_URL}/portfolio/transact/combined/new`,
        data: {
            // portfolio_id : data.portfolio_id ? data.portfolio_id :portfolioid ,
            portfolio_id: data.portfolio_id ? data.portfolio_id : portfolioid,
            transaction_id: data.transaction_id,
            // (data.transaction_id) && ((data.transaction_id).includes(data.portfolio_id)&&(data.transaction_id).substr(((data.portfolio_id).length)+1,(data.transaction_id).length)),
            commission: parseInt(data.commission),
            currency: data?.currency?.toUpperCase(),
            num_shares: data.num_shares == null ? 0 : parseInt(data.num_shares),
            order_date: (data.order_date).split('/').reverse().join('-'),
            security: data?.security?.toUpperCase(),
            transaction_type: data.transaction_type && (data.transaction_type === 'Deposit' ? 'cash_deposit' : data.transaction_type === 'Withdraw' ? 'cash_withdraw' : ((data.transaction_type).toLowerCase())),
            security_price: parseInt(data?.security_price),
            exchange_fees: parseInt(data.exchange_fees),
            cash: parseInt(data?.security_price),
            margin_percentage: data.margin_percentage == null ? 1 : parseInt(data.margin_percentage),
            tax: data.tax == null ? 0 : parseInt(data?.tax),
            tax_basis_election: 0,
            tag1: data.tag1
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.SearchTickerApi = (accessToken, searchText) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/security/search/ES`,
        data: {
            search_text: searchText
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.SearchTickerFundamentalApi = (accessToken, eodTicker, subSection) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/security/fundamentals`,
        data: {
            eod_ticker: eodTicker,
            sub_section: subSection,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.portfolioUpdateApi = (updateData, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/update`,
        data: updateData,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.portfolioGetApi = (portfolio_id, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/get`,
        data: {
            portfolio_id: portfolio_id
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.holdingPortfolioApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/holdings`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.compositionPortfolioApi = (dropDownPortFolio, analysisDate, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/composition`,
        data: {
            portfolio_id: dropDownPortFolio,
            analysis_date: analysisDate,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.EfficientFrontierPortfolioApi = (dropDownPortFolio, analysisDate, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/efficient_frontier_weight`,
        data: {
            portfolio_id: dropDownPortFolio,
            analysis_date: analysisDate,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.priceTrendApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/price_trends`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PerformanceApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/performance`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.AttributionsApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/attributions`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.MaxDrawdownApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/securities/max_drawdown`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}


Api.CorrelationApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/correlation`,
        data: {
            portfolio_id: dropDownPortFolio
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.HeadingApi = (dropDownPortFolio, headingDay, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/attributions/headings`,
        data: {
            portfolio_id: dropDownPortFolio,
            backtest_days: parseInt(headingDay),
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.NAVPortfolioApi = (dropDownPortFolio, navDay, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/nav/timeseries/eod`,
        data: {
            portfolio_id: dropDownPortFolio,
            backtest_days: parseInt(navDay),
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })

}

Api.LiquidityPortfolioApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/liquidity/securities`,
        data: {
            portfolio_id: dropDownPortFolio,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.VolatilityPortfolioApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/securities/volatility`,
        data: {
            portfolio_id: dropDownPortFolio,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })

}

Api.fundamentalsPortfolioApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/fundamentals/highlights`,
        data: {
            portfolio_id: dropDownPortFolio,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })

}

Api.backtestingApi = (dropDownPortFolio, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/analytics/backtest/snapshot/multiple`,
        data: {
            portfolio_id: dropDownPortFolio,
            all_backtest_days: [1, 7, 30, 90, 180]
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        }
    })
}

Api.homeDashboardApi = (table_name, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/basic_info/dashboard/tables`,
        data: {
            table_name: table_name === 'fixed income' ? 'fixed_income_stocks' : table_name === 'global_marktes' ? 'global_markets' : table_name,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.summaryCardApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/portfolio/summary/all`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}
//Decision Stats return_lines Api
Api.navLineApi = (dropDownPortFolio, backtest_days, aggregate_period, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/decision_stats/return_lines`,
        data: {
            portfolio_id: dropDownPortFolio,
            backtest_days: parseInt(backtest_days),
            // aggregate_period: aggregate_period
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}
//decision States monthly_returns Api
Api.monthlyReturnApi = (dropDownPortFolio, returnData, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/decision_stats/monthly_returns`,
        data: {
            portfolio_id: dropDownPortFolio,
            // backtest_days: parseInt(backtest_days),
            return_type: returnData
            // return_type: "percentage_returns"
            // aggregate_period: aggregate_period
        },
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    })
}

Api.monthlyAbsoluteReturnApi = (dropDownPortFolio, returnData, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/decision_stats/monthly_returns`,
        data: {
            portfolio_id: dropDownPortFolio,
            // backtest_days: parseInt(backtest_days),
            return_type: returnData
            // return_type: "percentage_returns"
            // aggregate_period: aggregate_period
        },
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
        }
    })
}

//Decision Stats Api
Api.hitRatioApi = (dropDownPortFolio, backtest_days, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/decision_stats/hit_ratio`,
        data: {
            portfolio_id: dropDownPortFolio,
            backtest_days: parseInt(backtest_days)
            // parseInt(backtest_days),
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.GetPaymentPlanApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/payment/plans`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}



Api.InitiatePlanApi = (line1, city, state, country, stripe_card_token, plan_name, payment_frequency, accessToken, discount) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/payment/initiate`,
        data: {
            address: {
                line1: line1,
                city: city,
                state: state,
                country: country,
            },
            stripe_card_token: stripe_card_token,
            plan_name: plan_name,
            payment_frequency: payment_frequency,
            discount_code: discount
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}


Api.PaymentInitiateValidateApi= ( accessToken, id,Truefalse) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/payment/initiate/validate`,
        data: {
            "subscripttion_id" : id,
            "confirm_card" : Truefalse
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}


Api.UpdatePlanApi = (payment_frequency, plan_name, accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/payment/change/plan`,
        data: {
            plan_name: plan_name,
            payment_frequency: payment_frequency,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        },
    })
}

Api.cancelPlanApi = (accessToken) => {
    return axios({
        method: 'DELETE',
        url: `${API_BASE_URL}/user/payment/cancel`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.PastInvoiceApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/payment/invoices/past`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.ibkrInitiateApi = (ids, ibkrAccount, accessToken,analysisDate) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/source/link/ibkr/initiate`,
        data: {
            accounts: ibkrAccount,
            portfolio_ids: ids,
            link_start_date: analysisDate,
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.getPlaidLinkInitiateApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/source/link/plaid/link/initiate`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

// SOURCE LINK
Api.sourceLinkApi = (accessToken) => {
    return axios({
        method: 'GET',
        url: `${API_BASE_URL}/user/source/link/all`,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.portfolioSourceLinkApi = (accessToken, payload) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/portfolio/source/link/initiate`,
        data: payload,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

// Coupon code 
Api.CouponValidation = (accessToken, payload) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/payment/coupon/validate`,
        data: {
            coupon_code: payload
        },
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}

Api.ibkrVerifyApi = (payload,accessToken) => {
    return axios({
        method: 'POST',
        url: `${API_BASE_URL}/user/source/link/ibkr/verify/first_check`,
        data: payload,
        headers: {
            "Authorization": `Bearer ${accessToken}`,
        }
    })
}


export default Api;
