import React, { Component } from "react";
import PaymentCard from "../Components/paymentCard/paymentCard";
import Modal from "../Components/Modal/modal";
import ToastBar from "../Components/ToastBar/toastBar";
import CurrentPlan from "../Components/paymentCard/currentPlan";
import BillingHistory from "../Components/paymentCard/billingHistory";

class StripePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            toastState: '',
            type: "",
            updatePlanFlag: false,
            updateText: ''
        }
    }

    updatePlan = (value) => {
        this.setState({
            updatePlanFlag: !this.state.updatePlanFlag,
            updateText: value
        }, () => { })
    }

    componentDidUpdate(preProps, preState) {
        if (this.props.initiatePlanFlag === "success" && preProps.initiatePlanFlag === "request") {
            this.setState({
                open: true,
                toastState: this.props.initiatePlanMessage,
                type: "success"
            })
        }
        if (this.props.initiatePlanFlag === "fail" && preProps.initiatePlanFlag === "request") {
            this.setState({
                open: true,
                toastState: this.props.initiatePlanErrorMessage,
                type: "error"
            })
        }
    }

    setToastOpen = () => {
        this.setState({
            open: false
        })
    }

    render() {

        return (
            <div className="inner-part">
                <div className="stoxdoc-innerpart">
                    <div className="stripeContainer">
                        <Modal {...this.props} />
                        <ToastBar
                            vailed={this.state.open}
                            setToastOpen={this.setToastOpen}
                            toastState={this.state.toastState}
                            type={this.state.type}
                        />
                        <div style={{ display: "flex" }}>
                            {!this.state.updatePlanFlag ? <h1 style={{ marginLeft: '65px' }}></h1> : <h4><span style={{ cursor: 'pointer' }} onClick={() => { this.setState({ updatePlanFlag: false }) }}>Billing</span> &#62; Plan</h4>}
                        </div>
                        {
                            this.state.updatePlanFlag ?
                                <div>
                                    <h1 style={{ color: "#27EE80" }}>Update your plan</h1>
                                    <CurrentPlan updateText={this.state.updateText} loginUserDetails={this.props.loginUserDetails} />
                                    <PaymentCard {...this.props} updateText={this.state.updateText} />
                                </div>
                                :
                                <div>
                                    <PaymentCard {...this.props} />
                                    <div>
                                        <CurrentPlan {...this.props} updatePlan={this.updatePlan} updatePlanFlag={this.state.updatePlanFlag} />
                                        <BillingHistory invoiceData={this.props.invoiceData} />
                                    </div>
                                </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default StripePayment;