//SCROLL OF LEFT AND RIGHT ACTION TYPE
export const SCROLL_ONCLICK = "SCROLL_ONCLICK";
export const SCROLL_POSITION = "SCROLL_POSITION";
export const LEFT_NAV_VALUE = "LEFT_NAV_VALUE";

export const REQUEST_SHOW_TABLE = "REQUEST_SHOW_TABLE";
export const SUCCESS_SHOW_TABLE = "SUCCESS_SHOW_TABLE";
export const FAILURE_SHOW_TABLE = "FAILURE_SHOW_TABLE";

//STORE THE PORTFOLIO EMAIL ID
export const STORE_PORTFOLIO_ID = "STORE_PORTFOLIO_ID";

//MODAL ACTION TYPE
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

//RIGHT-CONTAINER ACTION TYPE
export const CONTAINER_VALUE = "CONTAINER_VALUE";

export const TRANSACTION_VALUE = "TRANSACTION_VALUE";

//LOGIN USER ACTION TYPE
export const REQUEST_USER_LOGIN = "REQUEST_USER_LOGIN";
export const SUCCESS_USER_LOGIN = "SUCCESS_USER_LOGIN";
export const FAILURE_USER_LOGIN = "FAILURE_USER_LOGIN";

//REGISTER USER ACTION TYPE
export const REQUEST_USER_SIGN_UP = "REQUEST_USER_SIGN_UP";
export const SUCCESS_USER_SIGN_UP = "SUCCESS_USER_SIGN_UP";
export const FAILURE_USER_SIGN_UP = "FAILURE_USER_SIGN_UP";
export const RESET_SIGNUP = 'RESET_SIGNUP'

//REGISTER FORGET PASSWORD ACTION TYPE
export const REQUEST_FORGET_PASSWORD = "REQUEST_FORGET_PASSWORD";
export const SUCCESS_FORGET_PASSWORD = "SUCCESS_FORGET_PASSWORD";
export const FAILURE_FORGET_PASSWORD = "FAILURE_FORGET_PASSWORD";

//REGISTER UPDATE PASSWORD ACTION TYPE
export const REQUEST_UPDATE_PASSWORD = "REQUEST_UPDATE_PASSWORD";
export const SUCCESS_UPDATE_PASSWORD = "SUCCESS_UPDATE_PASSWORD";
export const FAILURE_UPDATE_PASSWORD = "FAILURE_UPDATE_PASSWORD";

//CREATE_WATCH LIST ACTION TYPE
export const REQUEST_CREATE_WATCHLIST = "REQUEST_CREATE_WATCHLIST";
export const SUCCESS_CREATE_WATCHLIST = "SUCCESS_CREATE_WATCHLIST";
export const FAILURE_CREATE_WATCHLIST = "FAILURE_CREATE_WATCHLIST";

//VIEW WATCH LIST ACTION TYPE
export const REQUEST_VIEW_CREATE_WATCHLIST = "REQUEST_VIEW_CREATE_WATCHLIST";
export const SUCCESS_VIEW_CREATE_WATCHLIST = "SUCCESS_VIEW_CREATE_WATCHLIST";
export const FAILURE_VIEW_CREATE_WATCHLIST = "FAILURE_VIEW_CREATE_WATCHLIST";

//DELETE WATCH LIST SECURITY ACTION TYPE
export const REQUEST_DELETE_SECURITY_WATCHLIST = "REQUEST_DELETE_SECURITY_WATCHLIST";
export const SUCCESS_DELETE_SECURITY_WATCHLIST = "SUCCESS_DELETE_SECURITY_WATCHLIST";
export const FAILURE_DELETE_SECURITY_WATCHLIST = "FAILURE_DELETE_SECURITY_WATCHLIST";

//ADD WATCH LIST SECURITY ACTION TYPE
export const REQUEST_ADD_SECURITY_WATCHLIST = "REQUEST_ADD_SECURITY_WATCHLIST";
export const SUCCESS_ADD_SECURITY_WATCHLIST = "SUCCESS_ADD_SECURITY_WATCHLIST";
export const FAILURE_ADD_SECURITY_WATCHLIST = "FAILURE_ADD_SECURITY_WATCHLIST";

//REMOVE WATCHLIST ACTION TYPE
export const REQUEST_REMOVE_WATCHLIST = "REQUEST_REMOVE_WATCHLIST";
export const SUCCESS_REMOVE_WATCHLIST = "SUCCESS_REMOVE_WATCHLIST";
export const FAILURE_REMOVE_WATCHLIST = "FAILURE_REMOVE_WATCHLIST";

//CREATE PORTFOLIO ACTION TYPE
export const REQUEST_CREATE_PORTFOLIO = "REQUEST_CREATE_PORTFOLIO";
export const SUCCESS_CREATE_PORTFOLIO = "SUCCESS_CREATE_PORTFOLIO";
export const FAILURE_CREATE_PORTFOLIO = "FAILURE_CREATE_PORTFOLIO";

//DELETE PORTFOLIO ACTION TYPE
export const REQUEST_DELETE_PORTFOLIO = "REQUEST_DELETE_PORTFOLIO";
export const SUCCESS_DELETE_PORTFOLIO = "SUCCESS_DELETE_PORTFOLIO";
export const FAILURE_DELETE_PORTFOLIO = "FAILURE_DELETE_PORTFOLIO";

//GET LIST OF CURRENCY
export const REQUEST_GET_CURRENCY_LIST = "REQUEST_GET_CURRENCY_LIST";
export const SUCCESS_GET_CURRENCY_LIST = "SUCCESS_GET_CURRENCY_LIST";
export const FAILURE_GET_CURRENCY_LIST = "FAILURE_GET_CURRENCY_LIST";

//GET LIST OF BENCHMARKS
export const REQUEST_GET_BENCHMARK_LIST = "REQUEST_GET_BENCHMARK_LIST";
export const SUCCESS_GET_BENCHMARK_LIST = "SUCCESS_GET_BENCHMARK_LIST";
export const FAILURE_GET_BENCHMARK_LIST = "FAILURE_GET_BENCHMARK_LIST";

//GET USER DETAIS 
export const REQUEST_GET_USER_DETAILS = "REQUEST_GET_USER_DETAILS";
export const SUCCESS_GET_USER_DETAILS = "SUCCESS_GET_USER_DETAILS";
export const FAILURE_GET_USER_DETAILS = "FAILURE_GET_USER_DETAILS";

//GET TRANSACTION HISTORY DETAIILS ACTION TYPE
export const REQUEST_GET_TRANSACTION_DETAILS_HISTORY = "REQUEST_GET_TRANSACTION_DETAILS_HISTORY";
export const SUCCESS_GET_TRANSACTION_DETAILS_HISTORY = "SUCCESS_GET_TRANSACTION_DETAILS_HISTORY";
export const FAILURE_GET_TRANSACTION_DETAILS_HISTORY = "FAILURE_GET_TRANSACTION_DETAILS_HISTORY";

//DELETE TRANSACTION SECURITY ACTION TYPE
export const REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY = "REQUEST_DELETE_SECURITY_TRANSACTION_HISTORY";
export const SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY = "SUCCESS_DELETE_SECURITY_TRANSACTION_HISTORY";
export const FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY = "FAILURE_DELETE_SECURITY_TRANSACTION_HISTORY";

//PERFORM BUY TRANSACTION SECURITY ACTION TYPE
export const REQUEST_BUY_TRANSACTION = "REQUEST_BUY_TRANSACTION";
export const SUCCESS_BUY_TRANSACTION = "SUCCESS_BUY_TRANSACTION";
export const FAILURE_BUY_TRANSACTION = "FAILURE_BUY_TRANSACTION";

//PERFORM SELL TRANSACTION SECURITY ACTION TYPE
export const REQUEST_SELL_TRANSACTION = "REQUEST_SELL_TRANSACTION";
export const SUCCESS_SELL_TRANSACTION = "SUCCESS_SELL_TRANSACTION";
export const FAILURE_SELL_TRANSACTION = "FAILURE_SELL_TRANSACTION";

//PERFORM DEPOSIT TRANSACTION SECURITY ACTION TYPE
export const REQUEST_DEPOSIT_TRANSACTION = "REQUEST_DEPOSIT_TRANSACTION";
export const SUCCESS_DEPOSIT_TRANSACTION = "SUCCESS_DEPOSIT_TRANSACTION";
export const FAILURE_DEPOSIT_TRANSACTION = "FAILURE_DEPOSIT_TRANSACTION";

//PERFORM WITHDRAW TRANSACTION SECURITY ACTION TYPE
export const REQUEST_WITHDRAW_TRANSACTION = "REQUEST_WITHDRAW_TRANSACTION";
export const SUCCESS_WITHDRAW_TRANSACTION = "SUCCESS_WITHDRAW_TRANSACTION";
export const FAILURE_WITHDRAW_TRANSACTION = "FAILURE_WITHDRAW_TRANSACTION";

//PERFORM EDIT TRANSACTION SECURITY ACTION TYPE
export const REQUEST_EDIT_TRANSACTION = "REQUEST_EDIT_TRANSACTION";
export const SUCCESS_EDIT_TRANSACTION = "SUCCESS_EDIT_TRANSACTION";
export const FAILURE_EDIT_TRANSACTION = "FAILURE_EDIT_TRANSACTION";

//TICKER (SECURITY) SEARCH ACTION TYPE
export const REQUEST_SEARCH_TICKER = "REQUEST_SEARCH_TICKER";
export const SUCCESS_SEARCH_TICKER = "SUCCESS_SEARCH_TICKER";
export const FAILURE_SEARCH_TICKER = "FAILURE_SEARCH_TICKER";

//TICKER (SECURITY) SEARCH FUNDAMENTALS ACTION TYPE
export const REQUEST_SEARCH_TICKER_FUNDAMENTALS = "REQUEST_SEARCH_TICKER_FUNDAMENTALS";
export const SUCCESS_SEARCH_TICKER_FUNDAMENTALS = "SUCCESS_SEARCH_TICKER_FUNDAMENTALS";
export const FAILURE_SEARCH_TICKER_FUNDAMENTALS = "FAILURE_SEARCH_TICKER_FUNDAMENTALS";

//PORTFOLIO ANALYTICS HOLDING
export const REQUEST_PORTFOLIO_ANALYTICS_HOLDING = "REQUEST_PORTFOLIO_ANALYTICS_HOLDING";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOLDING = "SUCCESS_PORTFOLIO_ANALYTICS_HOLDING";
export const FAILURE_PORTFOLIO_ANALYTICS_HOLDING = "FAILURE_PORTFOLIO_ANALYTICS_HOLDING";

//PORTFOLIO ANALYTICS COMPOSOTION
export const REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION = "REQUEST_PORTFOLIO_ANALYTICS_COMPOSITION";
export const SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION = "SUCCESS_PORTFOLIO_ANALYTICS_COMPOSITION";
export const FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION = "FAILURE_PORTFOLIO_ANALYTICS_COMPOSITION";

//PORTFOLIO ANALYTICS EFFICIENT FRONTIER
export const REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER = "REQUEST_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER";
export const SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER = "SUCCESS_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER";
export const FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER = "FAILURE_PORTFOLIO_ANALYTICS_EFFICIENT_FRONTIER";

//PORTFOLIO ANALYTICS PRICE TRENDS
export const REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS = "REQUEST_PORTFOLIO_ANALYTICS_PRICE_TRENDS";
export const SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS = "SUCCESS_PORTFOLIO_ANALYTICS_PRICE_TRENDS";
export const FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS = "FAILURE_PORTFOLIO_ANALYTICS_PRICE_TRENDS";

//PORTFOLIO ANALYTICS PERFORMANCE
export const REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE = "REQUEST_PORTFOLIO_ANALYTICS_PERFORMANCE";
export const SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE = "SUCCESS_PORTFOLIO_ANALYTICS_PERFORMANCE";
export const FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE = "FAILURE_PORTFOLIO_ANALYTICS_PERFORMANCE";

//PORTFOLIO ANALYTICS ATTRIBUTIONS
export const REQUEST_PORTFOLIO_ANALYTICS_ATTRIBUTIONS = "REQUEST_PORTFOLIO_ANALYTICS_ATTRIBUTIONS";
export const SUCCESS_PORTFOLIO_ANALYTICS_ATTRIBUTIONS = "SUCCESS_PORTFOLIO_ANALYTICS_ATTRIBUTIONS";
export const FAILURE_PORTFOLIO_ANALYTICS_ATTRIBUTIONS = "FAILURE_PORTFOLIO_ANALYTICS_ATTRIBUTIONS";

//PORTFOLIO ANALYTICS MAX DRAWDOWN
export const REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN = "REQUEST_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN";
export const SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN = "SUCCESS_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN";
export const FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN = "FAILURE_PORTFOLIO_ANALYTICS_MAX_DRAWDOWN";

//PORTFOLIO ANALYTICS CORRELATION
export const REQUEST_PORTFOLIO_ANALYTICS_CORRELATION = "REQUEST_PORTFOLIO_ANALYTICS_CORRELATION";
export const SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION = "SUCCESS_PORTFOLIO_ANALYTICS_CORRELATION";
export const FAILURE_PORTFOLIO_ANALYTICS_CORRELATION = "FAILURE_PORTFOLIO_ANALYTICS_CORRELATION";

////PORTFOLIO ANALYTICS HEADING
export const REQUEST_PORTFOLIO_ANALYTICS_HEADING = "REQUEST_PORTFOLIO_ANALYTICS_HEADING";
export const SUCCESS_PORTFOLIO_ANALYTICS_HEADING = "SUCCESS_PORTFOLIO_ANALYTICS_HEADING";
export const FAILURE_PORTFOLIO_ANALYTICS_HEADING = "FAILURE_PORTFOLIO_ANALYTICS_HEADING";

//PORTFOLIO ANALYTICS NAV
export const REQUEST_PORTFOLIO_ANALYTICS_NAV = "REQUEST_PORTFOLIO_ANALYTICS_NAV";
export const SUCCESS_PORTFOLIO_ANALYTICS_NAV = "SUCCESS_PORTFOLIO_ANALYTICS_NAV";
export const FAILURE_PORTFOLIO_ANALYTICS_NAV = "FAILURE_PORTFOLIO_ANALYTICS_NAV";

//PORTFOLIO ANALYTICS Liquidity
export const REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY = "REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY";
export const SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY = "SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY";
export const FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY = "FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY";

//PORTFOLIO ANALYTICS Volatility
export const REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY = "REQUEST_PORTFOLIO_ANALYTICS_VOLATILITY";
export const SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY = "SUCCESS_PORTFOLIO_ANALYTICS_VOLATILITY";
export const FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY = "FAILURE_PORTFOLIO_ANALYTICS_VOLATILITY";

//PORTFOLIO ANALYTICS FUNDAMENTALS
export const REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS = "REQUEST_PORTFOLIO_ANALYTICS_FUNDAMENTALS";
export const SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS = "SUCCESS_PORTFOLIO_ANALYTICS_FUNDAMENTALS";
export const FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS = "FAILURE_PORTFOLIO_ANALYTICS_FUNDAMENTALS";

//PORTFOLIO ANALYTICS BACKTESTING
export const REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING = "REQUEST_PORTFOLIO_ANALYTICS_BACKTESTING";
export const SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING = "SUCCESS_PORTFOLIO_ANALYTICS_BACKTESTING";
export const FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING = "FAILURE_PORTFOLIO_ANALYTICS_BACKTESTING";

//HOME DASHBOARD Global MArket
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET = "REQUEST_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET = "FAILURE_PORTFOLIO_ANALYTICS_HOME_GLOBAL_MARKET";

//HOME DASHBOARD Risk
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK = "REQUEST_PORTFOLIO_ANALYTICS_HOME_RISK";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_RISK";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK = "FAILURE_PORTFOLIO_ANALYTICS_HOME_RISK";

//HOME DASHBOARD Commodities
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES = "REQUEST_PORTFOLIO_ANALYTICS_HOME_COMMODITIES";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_COMMODITIES";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES = "FAILURE_PORTFOLIO_ANALYTICS_HOME_COMMODITIES";

//HOME DASHBOARD Style
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE = "REQUEST_PORTFOLIO_ANALYTICS_HOME_STYLE";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_STYLE";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE = "FAILURE_PORTFOLIO_ANALYTICS_HOME_STYLE";

//HOME DASHBOARD Sector
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR = "REQUEST_PORTFOLIO_ANALYTICS_HOME_SECTOR";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_SECTOR";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR = "FAILURE_PORTFOLIO_ANALYTICS_HOME_SECTOR";

//HOME DASHBOARD Fixed Income
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME = "REQUEST_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME = "FAILURE_PORTFOLIO_ANALYTICS_HOME_FIXED_INCOME";

//HOME DASHBOARD Country
export const REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY = "REQUEST_PORTFOLIO_ANALYTICS_HOME_COUNTRY";
export const SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY = "SUCCESS_PORTFOLIO_ANALYTICS_HOME_COUNTRY";
export const FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY = "FAILURE_PORTFOLIO_ANALYTICS_HOME_COUNTRY";

//SUMMARY CARD
export const REQUEST_GET_SUMMARY_CARD = "REQUEST_GET_SUMMARY_CARD";
export const SUCCESS_GET_SUMMARY_CARD = "SUCCESS_GET_SUMMARY_CARD";
export const FAILURE_GET_SUMMARY_CARD = "FAILURE_GET_SUMMARY_CARD";

//DECISION STATS
//1. NAV LINES
export const REQUEST_NAV_LINES = "REQUEST_NAV_LINES";
export const SUCCESS_NAV_LINES = "SUCCESS_NAV_LINES";
export const FAILURE_NAV_LINES = "FAILURE_NAV_LINES";

//2. MONTHLY RETURN
export const REQUEST_MONTHLY_RETURNS = "REQUEST_MONTHLY_RETURNS";
export const SUCCESS_MONTHLY_RETURNS = "SUCCESS_MONTHLY_RETURNS";
export const FAILURE_MONTHLY_RETURNS = "FAILURE_MONTHLY_RETURNS";


//3. MONTHLY ABSOLUTE RETURN
export const REQUEST_MONTHLY_ABSOLUTE_RETURNS = "REQUEST_MONTHLY_ABSOLUTE_RETURNS";
export const SUCCESS_MONTHLY_ABSOLUTE_RETURNS = "SUCCESS_MONTHLY_ABSOLUTE_RETURNS";
export const FAILURE_MONTHLY_ABSOLUTE_RETURNS = "FAILURE_MONTHLY_ABSOLUTE_RETURNS";


//2. HIT RATIO
export const REQUEST_HIT_RATIO = "REQUEST_HIT_RATIO";
export const SUCCESS_HIT_RATIO = "SUCCESS_HIT_RATIO";
export const FAILURE_HIT_RATIO = "FAILURE_HIT_RATIO";

//GET ALL PAYMENT PLAN
export const REQUEST_PAYMENT_PLAN = "REQUEST_PAYMENT_PLAN";
export const SUCCESS_PAYMENT_PLAN = "SUCCESS_PAYMENT_PLAN";
export const FAILURE_PAYMENT_PLAN = "FAILURE_PAYMENT_PLAN";

//INITIATE PLAN
export const REQUEST_INITIATE_PLAN = "REQUEST_INITIATE_PLAN";
export const SUCCESS_INITIATE_PLAN = "SUCCESS_INITIATE_PLAN";
export const FAILURE_INITIATE_PLAN = "FAILURE_INITIATE_PLAN";

//UPDATE PLAN
export const REQUEST_UPDATE_PLAN = "REQUEST_UPDATE_PLAN";
export const SUCCESS_UPDATE_PLAN = "SUCCESS_UPDATE_PLAN";
export const FAILURE_UPDATE_PLAN = "FAILURE_UPDATE_PLAN";

//CANCEL PLAN
export const REQUEST_CANCEL_PLAN = "REQUEST_CANCEL_PLAN";
export const SUCCESS_CANCEL_PLAN = "SUCCESS_CANCEL_PLAN";
export const FAILURE_CANCEL_PLAN = "FAILURE_CANCEL_PLAN";

//CANCEL PLAN
export const REQUEST_PAST_INVOICE = "REQUEST_PAST_INVOICE";
export const SUCCESS_PAST_INVOICE = "SUCCESS_PAST_INVOICE";
export const FAILURE_PAST_INVOICE = "FAILURE_PAST_INVOICE";

//IBKR INITIATE
export const REQUEST_IBKR_INITIATE = "REQUEST_IBKR_INITIATE";
export const SUCCESS_IBKR_INITIATE = "SUCCESS_IBKR_INITIATE";
export const FAILURE_IBKR_INITIATE = "FAILURE_IBKR_INITIATE";

//PLAID LINK INITIATE
export const REQUEST_PLAID_LINK_INITIATE = "REQUEST_PLAID_LINK_INITIATE";
export const SUCCESS_PLAID_LINK_INITIATE = "SUCCESS_PLAID_LINK_INITIATE";
export const FAILURE_PLAID_LINK_INITIATE = "FAILURE_PLAID_LINK_INITIATE";

// DEFAULT CASH VALUE
export const REQUEST_CASH_VALUE = "REQUEST_CASH_VALUE";
export const SUCCESS_CASH_VALUE = "SUCCESS_CASH_VALUE";
export const FAILURE_CASH_VALUE = "FAILURE_CASH_VALUE";

// SOUCE LINK 
export const REQUEST_SOURCE_LINK = "REQUEST_SOURCE_LINK";
export const SUCCESS_SOURCE_LINK = "SUCCESS_SOURCE_LINK";
export const FAILURE_SOURCE_LINK = "FAILURE_SOURCE_LINK";

// Add source link to portfolio
export const REQUEST_PORTFOLIO_SOURCE_LINK = "REQUEST_PORTFOLIO_SOURCE_LINK";
export const SUCCESS_PORTFOLIO_SOURCE_LINK = "SUCCESS_PORTFOLIO_SOURCE_LINK";
export const FAILURE_PORTFOLIO_SOURCE_LINK = "FAILURE_PORTFOLIO_SOURCE_LINK";

// get_portfolio

export const REQUEST_GET_PORTFOLIO = "REQUEST_GET_PORTFOLIO";
export const SUCCESS_GET_PORTFOLIO = "SUCCESS_GET_PORTFOLIO";
export const FAILURE_GET_PORTFOLIO = "FAILURE_GET_PORTFOLIO";

// update  PORTFOLIO
export const REQUEST_UPDATE_PORTFOLIO = "REQUEST_UPDATE_PORTFOLIO";
export const SUCCESS_UPDATE_PORTFOLIO = "SUCCESS_UPDATE_PORTFOLIO";
export const FAILURE_UPDATE_PORTFOLIO = "FAILURE_UPDATE_PORTFOLIO";

// coupon validation
export const REQUEST_COUPON_VERIFICATION = "REQUEST_COUPON_VERIFICATION";
export const SUCCESS_COUPON_VERIFICATION = "SUCCESS_COUPON_VERIFICATION";
export const FAILURE_COUPON_VERIFICATION = "FAILURE_COUPON_VERIFICATION";


// ibkr verify
export const REQUEST_IBKR_VERIFY = "REQUEST_IBKR_VERIFY";
export const SUCCESS_IBKR_VERIFY = "SUCCESS_IBKR_VERIFY";
export const FAILURE_IBKR_VERIFY = "FAILURE_IBKR_VERIFY";

//reset
export const RESET = "RESET";

export const PAYMENT_INITIATE_DATA="PAYMENT_INITIATE_DATA"
export const PAYMENT_INITIATE_VALIDATE="PAYMENT_INITIATE_VALIDATE"