import {
    REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY,
    SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY,
    FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY,
} from "../../Constant/actionTypes";

const initilizeState = {
    liquidityFlag: false
}

export default function liquidityPortfolioReducer (state=initilizeState, action) {
    switch(action.type){
        case  REQUEST_PORTFOLIO_ANALYTICS_LIQUIDITY:
            return {
                ...state,
                liquidityFlag: false
            }
        case SUCCESS_PORTFOLIO_ANALYTICS_LIQUIDITY:
            return {
                ...state,
                liquidityData: action.liquidityData,
                liquidityFlag: true
            }
        case FAILURE_PORTFOLIO_ANALYTICS_LIQUIDITY:
            return{
                ...state,
                liquidityFlag: false
            }
        default: return state;
    }
}