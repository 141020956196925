import {
    REQUEST_GET_BENCHMARK_LIST,
    SUCCESS_GET_BENCHMARK_LIST,
    FAILURE_GET_BENCHMARK_LIST,
} from "../../Constant/actionTypes";
import Api from "../Api/apiCalls";

export const requestGetBenchMarkList = () =>{
    return{
        type: REQUEST_GET_BENCHMARK_LIST,
    }
}

export const successGetBenchMarkList = (benchmarksList) =>{
   return{
       type: SUCCESS_GET_BENCHMARK_LIST,
       benchmarksList:benchmarksList
   }
}

export const failureGetBenchMarkList = () =>{
   return{
       type: FAILURE_GET_BENCHMARK_LIST,
   }
}

export const handleGetBenchMarkListAction = (accessToken) =>{
   return function(dispatch){
       dispatch(requestGetBenchMarkList())
       Api.GetBenchMarkListApi(accessToken).then(result=>{
           dispatch(successGetBenchMarkList(result.data))
       }).catch(error=>{
           dispatch(failureGetBenchMarkList(error))
       })
   }
}